import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initialState';
import moment from 'moment';
import { isEmpty } from "lodash";

const hasFilters = (filters) => {
   const currentFilters = { ...filters }
   delete currentFilters?.page
   if(currentFilters?.search === ''){
      delete currentFilters.search
   }
   if(currentFilters?.status?.includes('all')){
      delete currentFilters.status
   }
   return !isEmpty(currentFilters)
}

const isFilteredByStatus = (filters = {}, status) => {

   const currentFilters = { ...filters }
   if(Array.isArray(currentFilters.status)) {
      currentFilters.status = currentFilters.status.filter(itm => itm !== 'all')
      return (!Boolean(currentFilters.status) || currentFilters.status.includes(status) || currentFilters.status.includes('all') || currentFilters.status.length === 0)
   }
   return false
}

let reducersMap = {
   [types.FETCH_DETAILS_START]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const data = action.payload
      const emptyState = {}
      if(hasFilters(state.promotionsFilters)) {
         emptyState.isEmpty = false
         emptyState.isEmptyByFilter = data.data.length === 0
      } else {
         emptyState.isEmpty = data.data.length === 0
         emptyState.isEmptyByFilter = false
      }
      return {
         ...state,
         isFetching: false,
         list: data,
         ...emptyState,
      }
   },
   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
         listError: action.payload,
      }
   },

   [types.FETCH_DETAILS_BY_FILTER_START]: (state) => {
      return {
         ...state,
         isFetchingByFilter: true,
         isEmptyByFilter: false,
         isEmpty: false,
      }
   },
   [types.FETCH_DETAILS_BY_FILTER_COMPLETED]: (state, action) => {
      const { data, filters } = action.payload;
      return {
         ...state,
         isFetchingByFilter: false,
         list: data,
         isEmptyByFilter: data.data.length === 0,
         promotionsFilters: filters,
         isEmpty: false,
      }
   },
   [types.FETCH_DETAILS_BY_FILTER_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingByFilter: false,
         listError: action.payload,
      }
   },
   [types.UPDATE_FILTERS_LIST]: (state, action) => {
      return {
         ...state,
         promotionsFilters: action.payload,
      }
   },

   [types.NEW_FETCH_REQUEST]: (state) => {
      return {
         ...state,
         isNewFetching: true,
      }
   },
   [types.NEW_FETCH_DETAILS_COMPLETED]: (state, action) => {
      return {
         ...state,
         isNewFetching: false,
         list: {
            ...action.payload,
            data: [...state.list.data, ...action.payload.data],
         },
      }
   },
   [types.NEW_FETCH_REQUEST_FAILED]: (state) => {
      return {
         ...state,
         isNewFetching: false,
      }
   },

   [types.FETCH_BY_ID_START]: (state) => {
      return {
         ...state,
         isFetchingById: true,
      }
   },
   [types.FETCH_BY_ID_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingById: false,
         currentPromotion: action.payload,
      }
   },
   [types.FETCH_BY_ID_FAILED]: (state) => {
      return {
         ...state,
         isFetchingById: false,
      }
   },

   // create
   [types.UPDATE_CREATE_DATA]: (state, action) => {
      return {
         ...state,
         createData: {
            ...state.createData,
            ...action.payload,
         },
      }
   },
   [types.ADD_NEW_START]: (state, action) => {
      return {
         ...state,
         isFetchingCreate: action.payload,
      }
   },
   [types.ADD_NEW_FAILED]: (state, action) => {
      const errors = { ...action.payload }
      const errorKeys = Object.keys(errors)

      // pricing which discounted price lower than 3$ "membership_pricings.{id}.id" (
      const createDataMembershipPricings = [...state.createData.membership_pricings]
      const unprocessablePricings = errorKeys
         .filter(key => (key.includes('membership_pricings.')))
         .map(key => {
            const idOfUnprocessablePricing = key.split('.')[1]
            return createDataMembershipPricings[idOfUnprocessablePricing].id
         })

      return {
         ...state,
         isFetchingCreate: false,
         createErrors: { ...errors, unprocessablePricings },
         isUnprocessablePricingWarning: unprocessablePricings.length > 0,
      }
   },
   [types.UPDATE_CREATE_ERRORS]: (state, action) => {
      let newState = {
         createErrors: action.payload,
      }
      if('isUnprocessablePricingWarning' in action.payload){
         newState = {
            ...newState,
            isUnprocessablePricingWarning: action.payload.isUnprocessablePricingWarning,
         }
      }
      return {
         ...state,
         ...newState,
      }
   },

   // edit
   [types.UPDATE_EDIT_DATA]: (state, action) => {
      return {
         ...state,
         currentPromotion: {
            ...action.payload,
         },
      }
   },
   [types.EDIT_PROMOTION_START]: (state) => {
      return {
         ...state,
         isFetchingEditPromotion: true,
      }
   },
   [types.EDIT_PROMOTION_COMPLETED]: (state) => {
      return {
         ...state,
         isFetchingEditPromotion: false,
         editErrors: {},
      }
   },
   [types.EDIT_PROMOTION_FAILED]: (state, action) => {
      const errors = { ...action.payload }
      const errorKeys = Object.keys(errors)

      // pricing which discounted price lower than 3$ "membership_pricings.{id}.id" (
      const createDataMembershipPricings = [...state.currentPromotion.membership_pricings]
      const unprocessablePricings = errorKeys
         .filter(key => (key.includes('membership_pricings.')))
         .map(key => {
            const idOfUnprocessablePricing = key.split('.')[1]
            return createDataMembershipPricings[idOfUnprocessablePricing].id
         })
      return {
         ...state,
         isFetchingEditPromotion: false,
         editErrors: { ...errors, unprocessablePricings },
         isUnprocessablePricingWarning: 'isUnprocessablePricingWarning' in errors ? errors.isUnprocessablePricingWarning :  unprocessablePricings.length > 0,
      }
   },
   [types.UPDATE_EDIT_ERRORS]: (state, action) => {
      let newState = {
         editErrors: action.payload,
      }
      if('isUnprocessablePricingWarning' in action.payload){
         newState = {
            ...newState,
            isUnprocessablePricingWarning: action.payload.isUnprocessablePricingWarning,
         }
      }
      return {
         ...state,
         ...newState,
      }
   },

   [types.DELETE_PROMOTION_COMPLETED]: (state, action) => {
      const id = action.payload
      let newData = []
      if(Boolean(state.list.data)) {
         newData = state.list.data.filter(promotion => promotion.id !== id)
      }

      const emptyState = {}
      if(hasFilters(state.promotionsFilters)) {
         emptyState.isEmpty = false
         emptyState.isEmptyByFilter = newData.length === 0
      } else {
         emptyState.isEmpty = newData.length === 0
         emptyState.isEmptyByFilter = false
      }

      return {
         ...state,
         isFetchingOptionsAction: false,
         ...emptyState,
         list: {
            ...state.list,
            data: newData,
         },
      }
   },

   // options
   [types.EXPIRE_PROMOTION_COMPLETED]: (state, action) => {
      const id = action.payload;
      let newData = []
      if(Boolean(state.list.data)){
         if(isFilteredByStatus(state.promotionsFilters, 'expired')){
            newData = state.list.data.map(promotion => {
               if(promotion.id === id) {
                  promotion.expired = true;
                  promotion.status = 'expired';
               }
               return promotion
            })
         } else {
            newData = state.list.data.filter(promotion => promotion.id !== id)
         }
      }

      return {
         ...state,
         isFetchingOptionsAction: false,
         isEmptyByFilter: newData.length === 0,
         list: {
            ...state.list,
            data: newData,
         },
      }
   },
   [types.ACTIVATE_PROMOTION_COMPLETED]: (state, action) => {
      const id = action.payload;

      let newData = []
      if(isFilteredByStatus(state.promotionsFilters, 'active')){
         newData = state.list.data.map(promotion => {
            if(promotion.id === id) {
               promotion.is_published = true;
               promotion.status = 'active';
               promotion.expired = false
               if(promotion.count_of_current_usage) {
                  promotion.count_of_current_usage = 0
               }

               const newExpireDate = moment().add(promotion.discount_duration_in_hours, 'hours').format('YYYY-MM-DD hh:mm:ss A Z')
               promotion.expiration_date = new Date(newExpireDate).toISOString()
            }
            return promotion
         })
      } else if(state.list?.data) {
         newData = state.list.data.filter(promotion => promotion.id !== id)
      }

      return {
         ...state,
         isFetchingOptionsAction: false,
         isEmptyByFilter: newData.length === 0,
         list: {
            ...state.list,
            data: newData,
         },
      }
   },
   [types.DUPLICATE_PROMOTION_COMPLETED]: (state, action) => {

      let newData = []
      if(isFilteredByStatus(state.promotionsFilters, 'inactive')){
         newData = [action.payload, ...state.list.data]
      } else {
         newData = [...state.list.data]
      }
      return {
         ...state,
         isFetchingOptionsAction: false,
         list: {
            ...state.list,
            data: newData,
         },
      }
   },

   [types.UPDATE_ACTIONS_ERRORS]: (state, action) => {
      return {
         ...state,
         actionsErrors: { ...action.payload },
      }
   },

   [types.ACTION_WITH_PROMOTION_START]: (state) => {
      return {
         ...state,
         isFetchingOptionsAction: true,
      }
   },
   [types.ACTION_WITH_PROMOTION_FAILED]: (state, action) => {
      const { type, error } = action.payload

      return {
         ...state,
         isFetchingOptionsAction: false,
         // duplicateErrors: type === 'copy' ? error : {},
         // activateErrors: type === 'activate' ? error : {},
         actionsErrors: {
            ...state.actionsErrors,
            [type]: error,
         },
      }
   },
   [types.INIT_PROMOTIONS_FOR_FILTER_START]: (state) => {
      return {
         ...state,
         isFetchingPromotionsForFilter: true,
      }
   },
   [types.INIT_PROMOTIONS_FOR_FILTER_COMPLETED]: (state, action) => {
      const data = action.payload.map(promo => ({ name: promo.name, id: String(promo.id) }))
      return {
         ...state,
         isFetchingPromotionsForFilter: false,
         promotionsForFilter: data,
      }
   },
   [types.INIT_PROMOTIONS_FOR_FILTER_FAILED]: (state) => {
      return {
         ...state,
         isFetchingPromotionsForFilter: false,
      }
   },
   [types.INIT_INFO_START]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         isFetchingInfoFilter: payload,
      }
   },
}

export default createReducer(initialState)(reducersMap);
