/* eslint-disable react/prop-types */
import React from 'react';
import cx from 'classnames';
import LoaderGray from 'common/components/elements/loader/circle';
import Icon from 'common/components/elements/icons';
import Button from 'common/components/elements/buttons/primary';
import './style.scss';
import {
   sortableHandle,
} from 'react-sortable-hoc';
import CheckboxLabel from 'common/components/elements/inputs/checkbox-label';
import PropTypes from 'prop-types'
const DragHandle = sortableHandle(() => (
   <div className='flex drag-cursor' >
      <Icon name='drag' color='major-dark' size='2xl' />
      <p className='text-major-dark ml-1 '>Drag to reorder</p>
   </div>
));

function AddNewPhotosetCard({
   photo,
   className,
   selectCard,
   active,
   removePhoto,
   makeCover,
   cover,
   lockedImageIsBlur,
   onClickUpdate,
}) {
   let status = photo.status
   if(!photo.status && photo.id) {
      status = 'complete'
   }
   switch (status) {
      case 'error':
         return (
            <div
               className={ `relative p-3 photos-card-width h-auto border border-divider rounded-label flex items-center justify-center ${ className }` }>
               <div className='aspect-ratio-padding' />
               <div
                  onClick={ (e) => e.stopPropagation() }
                  role='presentation'
                  className='absolute top-0 right-0 m-2 flex items-center'
               >
                  <Button
                     textColor='major-dark'
                     backgroundColor='transparent'
                     iconName='cancel'
                     fontIconColor='secondary'
                     padding='none'
                     onClick={ () => removePhoto({ index: photo.index })  }
                     borderRadius='large'
                     textSize='base'
                  />
               </div>
               <div className='flex flex-col items-center'>
                  <Icon name='warning' color='secondary' size='5xl' />
                  <p className='mt-5 text-error font-bold'>Error</p>
                  <p className='text-major text-sm'>Try again or contact support</p>
               </div>
            </div>
         );
      case 'loading':
         return (
            <div
               className={ `relative p-3 bg-placeholder photos-card-width rounded-label flex items-center justify-center ${ className }` }>
               <div className='aspect-ratio-padding' />
               {/* <div
                  onClick={ (e) => e.stopPropagation() }
                  role='presentation'
                  className='absolute top-0 right-0 m-2 flex items-center'
               >
                  <Button
                     textColor='major-dark'
                     backgroundColor='transparent'
                     iconName='cancel'
                     fontIconColor='major-dark'
                     padding='none'
                     onClick={ () => {} }
                     borderRadius='large'
                     textSize='base'
                  />
               </div> */}
               <LoaderGray color='major-dark' />
            </div>
         );
      case 'complete':
         return (
            <div
               style={ { background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0) 19.7%), linear-gradient(180deg, rgba(0, 0, 0, 0) 62.09%, rgba(0, 0, 0, 0.65) 100%)' } }
               role='presentation' onClick={ () => { selectCard(photo.id); } } className={ `relative overflow-hidden h-auto rounded-lg flex items-center justify-center ${ className }` }>
               <div
                  onClick={ (e) => e.stopPropagation() }
                  role='presentation'
                  className='absolute top-0 right-0 m-1 flex items-center z-10'
               >
                  <Button
                     textColor='major-dark'
                     backgroundColor='transparent'
                     iconName='cancel'
                     fontIconColor='major-dark'
                     padding='none'
                     onClick={ () => removePhoto(photo) }
                     borderRadius='large'
                     textSize='base'
                  />
               </div>
               <div className='relative h-full w-full'>
                  <div className='aspect-ratio-padding' />
                  <div
                     style={ {
                        backgroundImage: `url(${ photo.cf_thumb_v2_path  })`,
                        filter: 'blur(20px)',
                        zIndex: '-1',
                     } }
                     className='absolute inset-0 h-full w-full bg-cover bg-center'
                  />
                  <img className='w-full h-full absolute  top-0 right-0 object-contain object-center' src={ photo.cf_thumb_v2_path } alt='' />
               </div>
               <div className={ cx('w-full h-full p-1 absolute top-0 left-0 flex flex-col justify-between rounded-lg', ((active && !cover) || !cover) ? 'gradient-top' : 'gradient-both') }>
                  <div className='flex items-center cursor-pointer'>
                     <DragHandle />

                  </div>
                  {
                     active
                        ? (
                           <div className='flex justify-between items-end'>
                              {
                                 cover
                                    ? (
                                       <div className='flex items-center select-none mb-2'>
                                          <Icon name='circle-checked' color='major-dark' size='xl' />
                                          <span
                                             style={ {
                                                lineHeight: '20px',
                                             } }
                                             className='text-major-dark ml-1 leading-4 font-medium  text-sm'>Thumbnail</span>
                                       </div>
                                    )
                                    : (
                                       <div className='flex items-center cursor-pointer mb-2'
                                          onClick={ (e) => {
                                             e.stopPropagation()
                                             makeCover(photo.id)
                                          } }
                                          role='presentation'
                                       >
                                          <Icon name='circle-unchecked' color='major-dark' size='xl' />
                                          <span
                                             style={ {
                                                lineHeight: '20px',
                                             } }
                                             className='text-major-dark text-sm leading-tight font-medium  ml-1'>Make thumbnail photo</span>
                                       </div>
                                    )
                              }
                              {/* <div className='rounded-lg flex h-9 w-28'>
                                 <div
                                    className='h-full flex-1'
                                    onClick={ (e) => e.stopPropagation() }
                                    role='presentation'>
                                    <Button
                                       textColor='major-dark'
                                       backgroundColor='transparent'
                                       iconName='rotate-left'
                                       padding='none'
                                       classNames='h-full'
                                       onClick={ () => {} }
                                       borderRadius='large'
                                       textSize='base'
                                    />
                                 </div>
                                 <div className='border-r border-divider my-1' />
                                 <div
                                    className='h-full flex-1'
                                    onClick={ (e) => e.stopPropagation() }
                                    role='presentation'>
                                    <Button
                                       textColor='major-dark'
                                       backgroundColor='transparent'
                                       iconName='rotate-right'
                                       padding='none'
                                       classNames='h-full'
                                       onClick={ () => {} }
                                       borderRadius='large'
                                       textSize='base'
                                    />
                                 </div>
                                 <div className='border-r border-divider my-1' />
                                 <div
                                    className='h-full flex-1'
                                    onClick={ (e) => e.stopPropagation() }
                                    role='presentation'>
                                    <Button
                                       textColor='major-dark'
                                       backgroundColor='transparent'
                                       iconName='flip'
                                       padding='none'
                                       classNames='h-full'
                                       onClick={ () => {} }
                                       borderRadius='large'
                                       textSize='base'
                                    />
                                 </div>
                              </div> */}

                              {
                                 cover && (
                                    <div
                                       className='h-9 flex items-center rounded-lg photos-blur-when-locked bg-major-20 backdrop-blur-[10px]'>
                                       <CheckboxLabel
                                          label='Blur when locked'
                                          name='locked_image_is_blur'
                                          checked={ lockedImageIsBlur }
                                          onChange={ onClickUpdate }
                                          textColor='major-dark'
                                          className='w-full h-full flex items-center blur-checkbox font-medium'
                                          textSize='sm'
                                          padding='1'
                                          margin='1'
                                          lineHeight='20px'
                                       />

                                    </div>
                                 )
                              }
                           </div>
                        )
                        : (
                           <div className='flex justify-between items-end'>
                              {
                                 cover
                                    ? (
                                       <div className='flex items-center mb-2'>
                                          <Icon name='done' color='major-dark' size='xl' />
                                          <span
                                             style={ {
                                                lineHeight: '20px',
                                             } }
                                             className='text-major-dark ml-1 leading-tight'>Cover</span>
                                       </div>
                                    )
                                    : null
                              }
                           </div>
                        )
                  }
               </div>
            </div>
         );
      default:
         break;
   }
}
AddNewPhotosetCard.propTypes = {
   photo: PropTypes.object,
   className: PropTypes.string,
   selectCard: PropTypes.func,
   active: PropTypes.bool,
   removePhoto: PropTypes.func,
   makeCover: PropTypes.func,
   cover: PropTypes.bool,
   lockedImageIsBlur: PropTypes.bool,
   onClickUpdate: PropTypes.func,
}

export default React.memo(AddNewPhotosetCard);
