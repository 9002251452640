const HOMEPAGE = 'HOMEPAGE';
const SIGN_IN = 'SIGN_IN';
const SIGN_UP = 'SIGN_UP';
const CONFIRM_SIGN_UP = 'CONFIRM_SIGN_UP';
const PASSWORD_RESET = 'PASSWORD_RESET';
const PASSWORD_RESET_TOKEN = 'PASSWORD_RESET_TOKEN';

const MEMBERS = 'MEMBERS';
const MEMBER_TAGS = 'MEMBER_TAGS'
const MEMBER_TAGS_SHOW = 'MEMBER_TAGS_SHOW'
const MEMBERS_STATISTICS = 'MEMBERS_STATISTICS';
const MEMBERS_ADD_FREE_MEMBER = 'MEMBERS_ADD_FREE_MEMBER';
const MEMBERS_SHOW = 'MEMBERS_SHOW';
const MEMBERS_ADD_FREE_MEMBER_SELECT_MEMBER = "MEMBERS_ADD_FREE_MEMBER_SELECT_MEMBER";
const MEMBERS_ADD_FREE_MEMBER_CHOOSE_MEMBERSHIP = "MEMBERS_ADD_FREE_MEMBER_CHOOSE_MEMBERSHIP"
const MEMBERS_ADD_FREE_MEMBER_SUCCESS = "MEMBERS_ADD_FREE_MEMBER_SUCCESS"
const MEMBERS_ADD_NEW_PERSON = "MEMBERS_ADD_NEW_PERSON"

const CAST_MEMBERS = 'CAST_MEMBERS';
const CAST_MEMBERS_CREATE = 'CAST_MEMBERS_CREATE';
const CAST_MEMBERS_EDIT = 'CAST_MEMBERS_EDIT';

const CONTENT = 'CONTENT';

const MY_ACCOUNT = 'MY_ACCOUNT';
// const MY_ACCOUNT_PAYOUT = 'MY_ACCOUNT_PAYOUT';
const MY_ACCOUNT_PAYOUT_HOLDS = 'MY_ACCOUNT_PAYOUT_HOLDS';
const MY_ACCOUNT_PAYOUT_ADJUSTMENTS = 'MY_ACCOUNT_PAYOUT_ADJUSTMENTS';
const MY_ACCOUNT_PAYOUT_METHODS_HISTORY = 'MY_ACCOUNT_PAYOUT_METHODS_HISTORY';
const MY_ACCOUNT_PAYOUT_METHOD_EDIT = 'MY_ACCOUNT_PAYOUT_METHOD_EDIT';
const MY_ACCOUNT_PAYOUT_WITHDRAWALS = 'MY_ACCOUNT_PAYOUT_WITHDRAWALS';

const MY_ACCOUNT_ADD_PAYOUT_METHODS = 'MY_ACCOUNT_ADD_PAYOUT_METHODS';
const MY_ACCOUNT_ADD_PAYOUT_METHODS_BANK_DEPOSIT = 'MY_ACCOUNT_ADD_PAYOUT_METHODS_BANK_DEPOSIT';
const MY_ACCOUNT_ADD_PAYOUT_METHODS_BANK_CRYPTO = 'MY_ACCOUNT_ADD_PAYOUT_METHODS_BANK_CRYPTO';
const MY_ACCOUNT_ADD_PAYOUT_SET_E_WALLET = 'MY_ACCOUNT_ADD_PAYOUT_SET_E_WALLET';

const MY_ACCOUNT_CHANGE_PAYOUT_METHODS = 'MY_ACCOUNT_CHANGE_PAYOUT_METHODS';
const MY_ACCOUNT_CHANGE_PAYOUT_SET_E_WALLET = 'MY_ACCOUNT_CHANGE_PAYOUT_SET_E_WALLET';
const MY_ACCOUNT_CHANGE_PAYOUT_METHODS_BANK_DEPOSIT = 'MY_ACCOUNT_CHANGE_PAYOUT_METHODS_BANK_DEPOSIT';
const MY_ACCOUNT_CHANGE_PAYOUT_METHODS_BANK_CRYPTO = 'MY_ACCOUNT_CHANGE_PAYOUT_METHODS_BANK_CRYPTO';

const MY_ACCOUNT_PAYOUT_METHOD = 'MY_ACCOUNT_PAYOUT_METHOD';
const MY_ACCOUNT_OTHER_PAYOUT_METHODS = 'MY_ACCOUNT_OTHER_PAYOUT_METHODS';
const MY_ACCOUNT_OTHER_PAYOUT_METHODS_BANK_DEPOSIT = 'MY_ACCOUNT_OTHER_PAYOUT_METHODS_BANK_DEPOSIT';

const NEED_HELP = 'NEED_HELP';

const CHAT = 'CHAT';
const CHAT_SETTINGS = 'CHAT_SETTINGS';
const CHAT_GET_CONTENT = 'CHAT_GET_CONTENT';

const FINANCIALS = 'FINANCIALS';

const PHOTOSETS = 'PHOTOSETS';
const PHOTOSETS_STATISTICS = 'PHOTOSETS_STATISTICS';
const PHOTOSETS_PUBLISH = 'PHOTOSETS_PUBLISH';
const PHOTOSETS_SHOW = 'PHOTOSETS_SHOW';
const PHOTOSETS_EDIT_PHOTOS = 'PHOTOSETS_EDIT_PHOTOS';
const PHOTOSETS_PUBLISH_PHOTOS = 'PHOTOSETS_PUBLISH_PHOTOS';
const PHOTOSETS_BULK_EDIT = 'PHOTOSETS_BULK_EDIT';
const PHOTOSETS_REORDER = 'PHOTOSETS_REORDER';

// const SETTINGS = 'SETTINGS';
// const SETTINGS_OUTGOING_EMAILS = 'SETTINGS_OUTGOING_EMAILS';
// const SETTINGS_DETAILS = 'SETTINGS_DETAILS';
// const SETTINGS_BLOCKING = 'SETTINGS_BLOCKING';
// const SETTINGS_BLOCKING_IP_WHITE_LIST = 'SETTINGS_BLOCKING_IP_WHITE_LIST';
// const SETTINGS_BLOCKING_IP_BLACK_LIST = 'SETTINGS_BLOCKING_IP_BLACK_LIST';
// const SETTINGS_BLOCKING_MEMBERS = 'SETTINGS_BLOCKING_MEMBERS';
// const SETTINGS_BLOCKING_MEMBERS_SHOW = 'SETTINGS_BLOCKING_MEMBERS_SHOW';
// const SETTINGS_BLOCKING_REGIONS = 'SETTINGS_BLOCKING_REGIONS';
// const SETTINGS_BLOCKING_REGIONS_SHOW = 'SETTINGS_BLOCKING_REGIONS_SHOW';

const STORIES = 'STORIES';
const STORIES_STATISTICS = 'STORIES_STATISTICS';
const STORIES_CREATE = 'STORIES_CREATE';
const STORIES_REORDER = 'STORIES_REORDER';


const VIDEOS = 'VIDEOS';
const VIDEOS_STATISTICS = 'VIDEOS_STATISTICS';
const VIDEOS_PUBLISH = 'VIDEOS_PUBLISH';
const VIDEOS_UPLOADS = 'VIDEOS_UPLOADS';
const VIDEOS_SHOW = 'VIDEOS_SHOW';
const VIDEOS_BULK_EDIT = 'VIDEOS_BULK_EDIT';
const VIDEOS_REORDER = 'VIDEOS_REORDER';

const TIPS = 'TIPS';
const TIPS_STATISTICS = 'TIPS_STATISTICS';
const TIPS_SETTINGS = 'TIPS_SETTINGS';

const STORE = 'STORE';
const STORE_SHIPPING = 'STORE_SHIPPING';
const STORE_DETAILS = 'STORE_DETAILS';
const STORE_MANAGE_CATEGORIES = 'STORE_MANAGE_CATEGORIES';
const STORE_ADD_NEW = 'STORE_ADD_NEW';
const STORE_ADD_NEW_SHIPPING = 'STORE_ADD_NEW_SHIPPING'
const STORE_EDIT_SHIPPING_ZONE = 'STORE_EDIT_SHIPPING_ZONE'
const STORE_ORDER_REQUESTS = 'STORE_ORDER_REQUESTS'
// const MANAGE_ORDER_FORM_TEMPLATES = 'MANAGE_ORDER_FORM_TEMPLATES'
// const CREATE_ORDER_REQUEST_FORM = 'CREATE_ORDER_REQUEST_FORM'
// const EDIT_ORDER_REQUEST_FORM = 'EDIT_ORDER_REQUEST_FORM'
const ORDER_REQUEST_DETAILS = 'ORDER_REQUEST_DETAILS'
const STORE_CREATE_BUNDLE = 'STORE_CREATE_BUNDLE'
const STORE_EDIT_BUNDLE = 'STORE_EDIT_BUNDLE'
const STORE_REORDER = 'STORE_REORDER'


const MEMBERSHIPS = 'MEMBERSHIPS';
const MEMBERSHIPS_STATISTICS = 'MEMBERSHIPS_STATISTICS';
// const MEMBERSHIPS_DETAILS_GENERAL = 'MEMBERSHIPS_DETAILS_GENERAL';
const MEMBERSHIPS_DETAILS_STATS = 'MEMBERSHIPS_DETAILS_STATS';
const MEMBERSHIPS_CREATE = 'MEMBERSHIPS_CREATE';
const MEMBERSHIPS_REORDER = 'MEMBERSHIPS_REORDER';
const MEMBERSHIPS_EDIT_GENERAL = 'MEMBERSHIPS_EDIT_GENERAL';

const STORIES_COMMENTS = 'STORIES_COMMENTS';

// const MY_SITE = 'MY_SITE';
const MY_SITE_COLORS = 'MY_SITE_COLORS';
const MY_SITE_COLORS_PREVIEW = 'MY_SITE_COLORS_PREVIEW';
const MY_SITE_COMING_SOON = 'MY_SITE_COMING_SOON';

const MY_SITE_LOGO = 'MY_SITE_LOGO';
const MY_SITE_DESIGN = 'MY_SITE_DESIGN';
const MY_SITE_HOMEPAGE = 'MY_SITE_HOMEPAGE';
const MY_SITE_HEADER = 'MY_SITE_HEADER';
const MY_SITE_ABOUT_ME = 'MY_SITE_ABOUT_ME';

const MY_SITE_CUSTOM_PAGE = 'MY_SITE_CUSTOM_PAGE';
const MY_SITE_CREATE_NEW_CUSTOM_PAGE = 'MY_SITE_CREATE_NEW_CUSTOM_PAGE';
const MY_SITE_EDIT_CUSTOM_PAGE = 'MY_SITE_EDIT_CUSTOM_PAGE';

const MY_SITE_SETTINGS = 'MY_SITE_SETTINGS';
const MY_SITE_BLOCK_IP_WHITE_LIST = 'MY_SITE_BLOCK_IP_WHITE_LIST';
const MY_SITE_BLOCK_IP_BLACK_LIST = 'MY_SITE_BLOCK_IP_BLACK_LIST';
const MY_SITE_BLOCK_REGIONS = 'MY_SITE_BLOCK_REGIONS';
const MY_SITE_BLOCK_MEMBERS = 'MY_SITE_BLOCK_MEMBERS';
const MY_SITE_SCHEDULED_CONTENT = 'MY_SITE_SCHEDULED_CONTENT';
const MY_SITE_DOMAIN_REDIRECTS = 'MY_SITE_DOMAIN_REDIRECTS';
const MY_SITE_SEO = 'MY_SITE_SEO';
const MY_SITE_TRACKING_CODE = 'MY_SITE_TRACKING_CODE';
const MY_SITE_OUTGOING_NOTIFICATIONS_AND_AUTO_TWEETS = 'MY_SITE_OUTGOING_NOTIFICATIONS_AND_AUTO_TWEETS';
const MY_SITE_OUTGOING_NOTIFICATIONS_AND_AUTO_TWEETS_EDIT = 'MY_SITE_OUTGOING_NOTIFICATIONS_AND_AUTO_TWEETS_EDIT';
const MY_SITE_WATERMARK = 'MY_SITE_WATERMARK';
const MY_SITE_CONTENT_CARDS = 'MY_SITE_CONTENT_CARDS'
const MY_SITE_LEGAL_PAGES = 'MY_SITE_LEGAL_PAGES'
const MY_SITE_LEGAL_PAGES_TERMS_OF_USE = 'MY_SITE_LEGAL_PAGES_TERMS_OF_USE'
const MY_SITE_LEGAL_PAGES_PRIVACY_POLICY = 'MY_SITE_LEGAL_PAGES_PRIVACY_POLICY'
const MY_SITE_LEGAL_PAGES_2257_COMPLIANCE = 'MY_SITE_LEGAL_PAGES_2257_COMPLIANCE'
const MY_SITE_LEGAL_PAGES_DMCA = 'MY_SITE_LEGAL_PAGES_DMCA'
const MY_SITE_LEGAL_PAGES_CCBILL = 'MY_SITE_LEGAL_PAGES_CCBILL'
const SINGULAR_OR_PLURAL_ADMINS = 'SINGULAR_OR_PLURAL_ADMINS'

const TRANSACTIONS = 'TRANSACTIONS';
const TRANSACTIONS_DETAILS = 'TRANSACTIONS_DETAILS';
const TRANSACTIONS_WALLET_ACTIVITY = 'TRANSACTIONS_WALLET_ACTIVITY';
const TRANSACTIONS_WALLET_ACTIVITY_DETAILS = 'TRANSACTIONS_WALLET_ACTIVITY_DETAILS';

const PAYOUT = 'PAYOUT';
const PAYOUT_STATISTICS = 'PAYOUT_STATISTICS';
const PAYOUT_DETAILS = 'PAYOUT_DETAILS';
const MY_DOMAIN_NAME = 'MY_DOMAIN_NAME';

const TAGS = 'TAGS';
const UPLOADS = 'UPLOADS';
const COLLECTIONS = 'COLLECTIONS';
const REFERRALS = 'REFERRALS';
const AFFILIATE_PROGRAM = 'AFFILIATE_PROGRAM';
const MY_AFFILIATES = 'MY_AFFILIATES';

const MASS_MESSAGE = 'MASS_MESSAGE';
const SEND_MASS_MESSAGE = 'SEND_MASS_MESSAGE';
const EDIT_MASS_MESSAGE = 'EDIT_MASS_MESSAGE';
const MANAGE_EMAIL_SUBSCRIBERS = 'MANAGE_EMAIL_SUBSCRIBERS';
const MANAGE_EMAIL_UNSUBSCRIBERS = 'MANAGE_EMAIL_UNSUBSCRIBERS';
const MANAGE_MAILING_LIST = 'MANAGE_MAILING_LIST'

const PROMOTIONS = 'PROMOTIONS';
const PROMOTIONS_CREATE = 'PROMOTIONS_CREATE';
const PROMOTIONS_USED_PROMO_CODES = 'PROMOTIONS_USED_PROMO_CODES';
const PROMOTIONS_DETAILS = 'PROMOTIONS_DETAILS';

const LIVE_STREAM = 'LIVE_STREAM'
const LIVE_STREAM_SCHEDULE = 'LIVE_STREAM_SCHEDULE'
const LIVE_STREAM_STUDIO = 'LIVE_STREAM_STUDIO'
const LIVE_STREAM_CREATE = 'LIVE_STREAM_CREATE'
const RECORDED_VIDEO = 'RECORDED_VIDEO'
const LIVE_STREAM_REORDER = 'LIVE_STREAM_REORDER'

const VAULT = 'VAULT';
const GET_VAULT_VIDEO = 'GET_VAULT_VIDEO';
// const VAULT_REORDER = 'VAULT_REORDER'

const TWITTER_INTEGRATION = 'TWITTER_INTEGRATION';
const TWITTER_CALLBACK = 'TWITTER_CALLBACK';

const SIGN_IN_CANNY = 'SIGN_IN_CANNY';

const STATISTICS_REVENUE = 'STATISTICS_REVENUE';
const STATISTICS_ENGAGEMENT = 'STATISTICS_ENGAGEMENT';
const STATISTICS_ACQUISITION = 'STATISTICS_ACQUISITION';

const GLOBAL_ACTIVITY = 'GLOBAL_ACTIVITY';

const MY_SITE_CONTENT_CATEGORIES = 'MY_SITE_CONTENT_CATEGORIES'

const routes =  {
   [HOMEPAGE]: {
      path: '/home',
      title: 'Home',
      exact: true,
      topLevel: true,
   },
   [SIGN_IN]: {
      path: '/sign-in',
      exact: true,
      topLevel: true,
   },
   [SIGN_UP]: {
      path: '/sign-up',
      exact: true,
      topLevel: true,
   },
   [CONFIRM_SIGN_UP]: {
      path: '/sign-up/:token',
      exact: true,
      topLevel: true,
   },
   [PASSWORD_RESET_TOKEN]: {
      path: '/password-reset/:token',
      exact: true,
      topLevel: true,
   },
   [PASSWORD_RESET]: {
      path: '/password-reset',
      exact: true,
      topLevel: false,
      title: '',
      parentKey: SIGN_IN,
   },
   [MEMBERS]: {
      path: '/members',
      exact: true,
      title: 'Members',
      topLevel: true,
   },
   [MEMBER_TAGS]: {
      path: '/member-tags',
      exact: true,
      title: 'Member tags',
      topLevel: true,
   },
   [MEMBER_TAGS_SHOW]: {
      path: '/member-tags/:id',
      exact: true,
      title: 'New tag name',
      parentKey: MEMBER_TAGS,
      topLevel: false,
   },
   [MEMBERS_SHOW]: {
      path: '/members/:id',
      exact: true,
      topLevel: false,
      parentKey: MEMBERS,
      title: "Member details",
   },
   [MEMBERS_STATISTICS]: {
      path: '/members/statistics',
      exact: true,
      title: 'Members',
      topLevel: true,
   },
   [MEMBERS_ADD_FREE_MEMBER]: {
      path: '/members/invite-complimentary-members',
      exact: true,
      topLevel: false,
      parentKey: MEMBERS,
      title: 'Invite complimentary members',
   },
   [MEMBERS_ADD_NEW_PERSON]: {
      path: '/members/add-new-person',
      exact: true,
      topLevel: false,
      parentKey: MEMBERS_ADD_FREE_MEMBER,
      title: 'Add new person',
   },
   [MEMBERS_ADD_FREE_MEMBER_CHOOSE_MEMBERSHIP]: {
      path: '/members/invite-complimentary-members/choose-membership',
      exact: true,
      topLevel: false,
      parentKey: MEMBERS_ADD_FREE_MEMBER_SELECT_MEMBER,
      title: 'Choose membership',
   },
   [MEMBERS_ADD_FREE_MEMBER_SELECT_MEMBER]: {
      path: '/members/invite-complimentary-members/select-member',
      exact: true,
      topLevel: false,
      parentKey: MEMBERS_ADD_FREE_MEMBER,
      title: 'Select members',
   },
   [MEMBERS_ADD_FREE_MEMBER_SUCCESS]: {
      path: '/members/invite-complimentary-members/success',
      exact: true,
      topLevel: true,
      title: 'Success!',
   },
   [CAST_MEMBERS]: {
      path: '/cast-members',
      exact: true,
      title: 'Cast members',
      topLevel: true,
   },
   [CAST_MEMBERS_CREATE]: {
      path: '/cast-members/add',
      exact: true,
      title: 'Add new cast member',
      topLevel: false,
      parentKey: CAST_MEMBERS,
   },
   [CAST_MEMBERS_EDIT]: {
      path: '/cast-members/:id/edit',
      exact: true,
      title: 'Cast member details',
      topLevel: false,
      parentKey: CAST_MEMBERS,
   },
   [CONTENT]: {
      path: '/content',
      exact: true,
      title: 'Content',
      topLevel: true,
   },
   [MY_ACCOUNT]: {
      path: '/my-account',
      exact: true,
      title: 'My account',
      topLevel: true,
      hasBackSearchParams: true,
   },
   // new payouts
   // [MY_ACCOUNT_PAYOUT]: {
   //    path: '/my-account/payout',
   //    exact: true,
   //    topLevel: false,
   //    title: 'Payout',
   //    parentKey: MY_ACCOUNT,
   // },
   [MY_ACCOUNT_PAYOUT_HOLDS]: {
      path: '/my-account/payout/holds',
      exact: true,
      topLevel: false,
      title: 'Holds',
      parentKey: MY_ACCOUNT,
      backSearchParams: 'activeTab=payouts',
      backSearchParamsTitle: 'Payouts',
   },
   [MY_ACCOUNT_PAYOUT_ADJUSTMENTS]: {
      path: '/my-account/payout/adjustments',
      exact: true,
      topLevel: false,
      title: 'Adjustments',
      parentKey: MY_ACCOUNT,
      backSearchParams: 'activeTab=payouts',
      backSearchParamsTitle: 'Payouts',

   },
   [MY_ACCOUNT_PAYOUT_METHODS_HISTORY]: {
      path: '/my-account/payout/payout-method-change-history',
      exact: true,
      topLevel: false,
      title: 'Payout method change history',
      parentKey: MY_ACCOUNT,
      backSearchParams: 'activeTab=payouts',
      backSearchParamsTitle: 'Payouts',
   },
   [MY_ACCOUNT_PAYOUT_METHOD_EDIT]: {
      path: '/my-account/payout/edit-current-payout-method',
      exact: true,
      topLevel: false,
      title: 'Edit current payout method',
      parentKey: MY_ACCOUNT,
      backSearchParams: 'activeTab=payouts',
      backSearchParamsTitle: 'Payouts',
   },
   [MY_ACCOUNT_PAYOUT_WITHDRAWALS]: {
      path: '/my-account/payout/payout-history',
      exact: true,
      topLevel: false,
      title: 'Payout history',
      parentKey: MY_ACCOUNT,
      backSearchParams: 'activeTab=payouts',
      backSearchParamsTitle: 'Payouts',
   },
   // Add payout method route
   [MY_ACCOUNT_ADD_PAYOUT_METHODS]: {
      path: '/my-account/payout/add-payout-method',
      exact: true,
      topLevel: false,
      title: 'Add payout method',
      parentKey: MY_ACCOUNT,
      backSearchParams: 'activeTab=payouts',
      backSearchParamsTitle: 'Payouts',
   },
   [MY_ACCOUNT_ADD_PAYOUT_METHODS_BANK_DEPOSIT]: {
      path: '/my-account/payout/add-payout-method/bank-deposit',
      exact: true,
      topLevel: false,
      title: 'Bank deposit',
      parentKey: MY_ACCOUNT_ADD_PAYOUT_METHODS,
      backSearchParams: 'activeTab=payouts',
      backSearchParamsTitle: 'Payouts',
   },
   [MY_ACCOUNT_ADD_PAYOUT_METHODS_BANK_CRYPTO]: {
      path: '/my-account/payout/add-payout-method/crypto',
      exact: true,
      topLevel: false,
      title: 'Crypto',
      parentKey: MY_ACCOUNT_ADD_PAYOUT_METHODS,
   },
   [MY_ACCOUNT_ADD_PAYOUT_SET_E_WALLET]: {
      path: '/my-account/payout/add-payout-method/e-wallet/:type',
      exact: true,
      topLevel: false,
      title: 'Edit',
      parentKey: MY_ACCOUNT_ADD_PAYOUT_METHODS,
      backSearchParams: 'activeTab=payouts',
      backSearchParamsTitle: 'Payouts',
   },
   // change payout method route
   [MY_ACCOUNT_CHANGE_PAYOUT_METHODS]: {
      path: '/my-account/payout/change-payout-method',
      exact: true,
      topLevel: false,
      title: 'Change payout method',
      parentKey: MY_ACCOUNT,
      backSearchParams: 'activeTab=payouts',
      backSearchParamsTitle: 'Payouts',
   },
   [MY_ACCOUNT_CHANGE_PAYOUT_SET_E_WALLET]: {
      path: '/my-account/payout/change-payout-method/e-wallet/:type',
      exact: true,
      topLevel: false,
      title: 'Edit',
      parentKey: MY_ACCOUNT_CHANGE_PAYOUT_METHODS,
      backSearchParams: 'activeTab=payouts',
      backSearchParamsTitle: 'Payouts',
   },
   [MY_ACCOUNT_CHANGE_PAYOUT_METHODS_BANK_DEPOSIT]: {
      path: '/my-account/payout/change-payout-method/bank-deposit',
      exact: true,
      topLevel: false,
      title: 'Bank deposit',
      parentKey: MY_ACCOUNT_CHANGE_PAYOUT_METHODS,
   },
   [MY_ACCOUNT_CHANGE_PAYOUT_METHODS_BANK_CRYPTO]: {
      path: '/my-account/payout/change-payout-method/crypto',
      exact: true,
      topLevel: false,
      title: 'Crypto',
      parentKey: MY_ACCOUNT_CHANGE_PAYOUT_METHODS,
   },
   // old payouts
   [MY_ACCOUNT_PAYOUT_METHOD]: {
      path: '/my-account/payout-method',
      exact: true,
      topLevel: false,
      title: 'Payout preferences',
      parentKey: MY_ACCOUNT,
      backSearchParams: 'activeTab=payouts',
      backSearchParamsTitle: 'Payouts',
   },
   [MY_ACCOUNT_OTHER_PAYOUT_METHODS]: {
      path: '/my-account/payout-method/other-payout-method',
      exact: true,
      topLevel: false,
      title: 'Other payout methods',
      parentKey: MY_ACCOUNT_PAYOUT_METHOD,
      backSearchParams: 'activeTab=payouts',
      backSearchParamsTitle: 'Payouts',
   },
   [MY_ACCOUNT_OTHER_PAYOUT_METHODS_BANK_DEPOSIT]: {
      path: '/my-account/payout-method/other-payout-method/bank-deposit',
      exact: true,
      topLevel: false,
      title: 'Bank deposit',
      parentKey: MY_ACCOUNT_OTHER_PAYOUT_METHODS,
      backSearchParams: 'activeTab=payouts',
      backSearchParamsTitle: 'Payouts',
   },

   [NEED_HELP]: {
      path: '/need-help',
      exact: true,
      title: 'Need help',
      topLevel: true,
   },
   [CHAT]: {
      path: '/chat',
      exact: true,
      title: 'Chat',
      topLevel: true,
   },
   [CHAT_SETTINGS]: {
      path: '/chat/settings',
      exact: true,
      title: 'Chat settings',
      topLevel: false,
      parentKey: CHAT,
   },
   [CHAT_GET_CONTENT]: {
      path: '/chat/content/:id',
      exact: true,
      title: 'Chat content',
      topLevel: false,
      parentKey: CHAT,
   },
   [FINANCIALS]: {
      path: '/financials',
      exact: true,
      title: 'Financials',
      topLevel: true,
   },
   [PHOTOSETS]: {
      path: '/photosets',
      exact: true,
      title: 'Photosets',
      topLevel: true,
   },
   [PHOTOSETS_REORDER]: {
      path: '/photosets/reorder',
      exact: true,
      title: 'Photosets',
      topLevel: true,
   },
   [PHOTOSETS_STATISTICS]: {
      path: '/photosets/statistics',
      exact: true,
      title: 'Photosets',
      topLevel: true,
   },

   [PHOTOSETS_PUBLISH]: {
      path: '/photosets/:id/publish',
      exact: true,
      topLevel: false,
      title: 'New photoset',
      parentKey: PHOTOSETS,
   },
   [PHOTOSETS_SHOW]: {
      path: '/photosets/:id/edit',
      exact: true,
      topLevel: false,
      parentKey: PHOTOSETS,
      title: 'Photoset details',
   },
   [PHOTOSETS_EDIT_PHOTOS]: {
      path: '/photosets/:id/edit/photos',
      exact: true,
      topLevel: false,
      parentKey: PHOTOSETS_SHOW,
      title: 'Edit Photoset',
      goBackById: true,
   },
   [PHOTOSETS_PUBLISH_PHOTOS]: {
      path: '/photosets/:id/publish/photos',
      exact: true,
      topLevel: false,
      parentKey: PHOTOSETS_PUBLISH,
      title: 'Edit Photoset',
      goBackById: true,
   },
   [PHOTOSETS_BULK_EDIT]: {
      path: '/photosets/bulk-edit',
      exact: true,
      topLevel: false,
      parentKey: PHOTOSETS,
      title: 'Edit photoset',
      goBackById: true,
   },

   // [SETTINGS]: {
   //    path: '/settings',
   //    exact: true,
   //    title: 'Settings',
   //    topLevel: true,
   // },
   // [SETTINGS_OUTGOING_EMAILS]: {
   //    path: '/settings/outgoing-emails',
   //    exact: true,
   //    title: 'Settings outgoing emails',
   //    topLevel: false,
   //    parentKey: SETTINGS,
   // },
   // [SETTINGS_DETAILS]: {
   //    path: '/settings/details',
   //    exact: true,
   //    title: 'Settings details',
   //    topLevel: false,
   //    parentKey: SETTINGS,
   // },
   // [SETTINGS_BLOCKING]: {
   //    path: '/settings/blocking',
   //    exact: true,
   //    title: 'Settings blocking',
   //    topLevel: false,
   //    parentKey: SETTINGS,
   // },
   // [SETTINGS_BLOCKING_IP_WHITE_LIST]: {
   //    path: '/settings/blocking/ip-white-list',
   //    exact: true,
   //    title: 'Settings blocking',
   //    topLevel: false,
   //    parentKey: SETTINGS_BLOCKING,
   // },
   // [SETTINGS_BLOCKING_IP_BLACK_LIST]: {
   //    path: '/settings/blocking/ip-black-list',
   //    exact: true,
   //    title: 'Settings blocking',
   //    topLevel: false,
   //    parentKey: SETTINGS_BLOCKING,
   // },
   // [SETTINGS_BLOCKING_MEMBERS]: {
   //    path: '/settings/blocking/members',
   //    exact: true,
   //    title: 'Settings blocking',
   //    topLevel: false,
   //    parentKey: SETTINGS_BLOCKING,
   // },
   // [SETTINGS_BLOCKING_MEMBERS_SHOW]: {
   //    path: '/settings/blocking/members/:id',
   //    exact: true,
   //    title: 'Settings blocking',
   //    topLevel: false,
   //    parentKey: SETTINGS_BLOCKING_MEMBERS,
   // },
   // [SETTINGS_BLOCKING_REGIONS]: {
   //    path: '/settings/blocking/regions',
   //    exact: true,
   //    title: 'Settings blocking',
   //    topLevel: false,
   //    parentKey: SETTINGS_BLOCKING,
   // },
   // [SETTINGS_BLOCKING_REGIONS_SHOW]: {
   //    path: '/settings/blocking/regions/:region',
   //    exact: true,
   //    title: 'Settings blocking',
   //    topLevel: false,
   //    parentKey: SETTINGS_BLOCKING_REGIONS,
   // },
   [STORIES]: {
      path: '/stories',
      exact: true,
      title: 'Stories',
      topLevel: true,
   },
   [STORIES_REORDER]: {
      path: '/stories/reorder',
      exact: true,
      title: 'Stories',
      topLevel: true,
   },
   [STORIES_STATISTICS]: {
      path: '/stories/statistics',
      exact: true,
      title: 'Stories',
      topLevel: true,
   },
   [STORIES_CREATE]: {
      path: '/stories/create',
      exact: true,
      topLevel: false,
      title: 'Create story',
      parentKey: STORIES,
   },

   [VIDEOS]: {
      path: '/videos',
      exact: true,
      title: 'Videos',
      topLevel: true,
   },
   [VIDEOS_REORDER]: {
      path: '/videos/reorder',
      exact: true,
      title: 'Videos',
      topLevel: true,
   },
   [VIDEOS_STATISTICS]: {
      path: '/videos/statistics',
      exact: true,
      title: 'Videos',
      topLevel: true,
   },
   [VIDEOS_PUBLISH]: {
      path: '/videos/:id/publish',
      exact: true,
      topLevel: false,
      title: 'New video',
      parentKey: VIDEOS,
   },
   [VIDEOS_UPLOADS]: {
      path: '/videos/uploads',
      exact: true,
      topLevel: false,
      title: 'Uploading video',
      parentKey: VIDEOS,
   },
   [VIDEOS_SHOW]: {
      path: '/videos/:id/edit',
      exact: true,
      title: 'Video details',
      topLevel: false,
      parentKey: VIDEOS,
   },
   [VIDEOS_BULK_EDIT]: {
      path: '/videos/bulk-edit',
      exact: true,
      title: 'Edit videos',
      topLevel: false,
      parentKey: VIDEOS,
   },

   [TIPS]: {
      path: '/tips',
      exact: true,
      topLevel: true,
      title: 'Tips',
   },
   [TIPS_STATISTICS]: {
      path: '/tips/statistics',
      exact: true,
      topLevel: true,
      title: 'Tips',
   },
   [TIPS_SETTINGS]: {
      path: '/tips/settings',
      exact: true,
      topLevel: false,
      title: 'Tips settings',
      parentKey: TIPS,
   },
   [STORE]: {
      path: '/store',
      exact: true,
      title: 'Store',
      topLevel: true,
   },
   [STORE_REORDER]: {
      path: '/store/reorder',
      exact: true,
      title: 'Store',
      topLevel: true,
   },
   [STORE_DETAILS]: {
      path: '/store/edit/:id',
      exact: true,
      title: 'Store item details',
      topLevel: false,
      parentKey: STORE,
   },
   [STORE_MANAGE_CATEGORIES]: {
      path: '/store/manage-categories',
      exact: true,
      title: 'Manage categories',
      topLevel: false,
      parentKey: STORE,
   },
   [STORE_ADD_NEW]: {
      path: '/store/add-new-store-item',
      exact: true,
      title: 'Add new store item',
      topLevel: false,
      parentKey: STORE,
   },
   [STORE_SHIPPING]: {
      path: '/store/shipping',
      exact: true,
      title: 'Shipping',
      topLevel: false,
      parentKey: STORE,
   },
   [STORE_ADD_NEW_SHIPPING]: {
      path: '/store/shipping/add-new-shipping-zone',
      exact: true,
      title: 'Add new shipping zone',
      topLevel: false,
      parentKey: STORE_SHIPPING,

   },
   [STORE_EDIT_SHIPPING_ZONE]: {
      path: '/store/shipping/edit/:id',
      exact: true,
      title: "Edit shipping zone",
      topLevel: false,
      parentKey: STORE_SHIPPING,
   },
   [STORE_ORDER_REQUESTS]: {
      path: '/store/orders',
      exact: true,
      title: "Orders",
      topLevel: false,
      parentKey: STORE,
   },
   // [MANAGE_ORDER_FORM_TEMPLATES]: {
   //    path: '/store/order-requests/order-request-forms',
   //    exact: true,
   //    title: "Order forms",
   //    topLevel: false,
   //    parentKey: STORE_ORDER_REQUESTS,
   // },
   // [CREATE_ORDER_REQUEST_FORM]: {
   //    path: '/store/order-requests/order-request-forms/add-new-order-request-form',
   //    exact: true,
   //    title: "Add new form",
   //    topLevel: false,
   //    parentKey: MANAGE_ORDER_FORM_TEMPLATES,
   // },
   // [EDIT_ORDER_REQUEST_FORM]: {
   //    path: '/store/order-requests/order-request-forms/edit/:id',
   //    exact: true,
   //    title: "Edit form",
   //    topLevel: false,
   //    parentKey: MANAGE_ORDER_FORM_TEMPLATES,
   // },
   [ORDER_REQUEST_DETAILS]: {
      path: '/store/orders/:id',
      exact: true,
      title: "Order request",
      topLevel: false,
      parentKey: STORE_ORDER_REQUESTS,
   },
   [STORE_CREATE_BUNDLE]: {
      path: '/store/create-a-bundle',
      exact: true,
      title: "Create a bundle",
      topLevel: false,
      parentKey: STORE,
   },
   [STORE_EDIT_BUNDLE]: {
      path: '/store/edit-bundle/:id',
      exact: true,
      title: "Edit bundle",
      topLevel: false,
      parentKey: STORE,
   },

   [MEMBERSHIPS]: {
      path: '/memberships',
      exact: true,
      title: 'Memberships',
      topLevel: true,
   },
   [MEMBERSHIPS_STATISTICS]: {
      path: '/memberships/statistics',
      exact: true,
      title: 'Memberships',
      topLevel: true,
   },
   // [MEMBERSHIPS_DETAILS_GENERAL]: {
   //    path: '/memberships/details/:id',
   //    exact: true,
   //    title: 'Membership details',
   //    topLevel: false,
   //    parentKey: MEMBERSHIPS,
   // },
   [MEMBERSHIPS_EDIT_GENERAL]: {
      path: '/memberships/edit/:id',
      exact: true,
      title: 'Membership edit',
      topLevel: false,
      parentKey: MEMBERSHIPS,
   },

   [MEMBERSHIPS_DETAILS_STATS]: {
      path: '/memberships/details-stats',
      exact: true,
      title: 'Membership details',
      topLevel: false,
      parentKey: MEMBERSHIPS,
   },
   [MEMBERSHIPS_CREATE]: {
      path: '/memberships/create',
      exact: true,
      title: 'Create new membership',
      topLevel: false,
      parentKey: MEMBERSHIPS,
   },
   [MEMBERSHIPS_REORDER]: {
      path: '/memberships/reorder',
      exact: true,
      title: 'Reorder',
      topLevel: false,
      parentKey: MEMBERSHIPS,
   },

   [STORIES_COMMENTS]: {
      path: '/stories/:id/comments',
      exact: true,
      title: 'Comments',
      topLevel: false,
      parentKey: STORIES,
   },

   // [MY_SITE]: {
   //    path: '/my-site',
   //    exact: true,
   //    title: 'My site',
   //    topLevel: true,
   // },
   [MY_SITE_DESIGN]: {
      path: '/designer',
      exact: true,
      title: 'Designer',
      topLevel: true,
      // parentKey: MY_SITE,
   },
   [MY_SITE_COLORS]: {
      path: '/designer/colors',
      exact: true,
      title: 'Colors',
      topLevel: false,
      parentKey: MY_SITE_DESIGN,
   },

   [MY_SITE_COLORS_PREVIEW]: {
      path: '/designer/colors/preview-page',
      exact: true,
   },
   [MY_SITE_COMING_SOON]: {
      path: '/designer/coming-soon',
      exact: true,
      title: 'Coming soon page',
      topLevel: false,
      parentKey: MY_SITE_DESIGN,
   },
   [MY_SITE_LOGO]: {
      path: '/designer/logo',
      exact: true,
      title: 'Logo',
      topLevel: false,
      parentKey: MY_SITE_DESIGN,
   },
   [MY_SITE_HOMEPAGE]: {
      path: '/designer/homepage',
      exact: true,
      title: 'Homepage',
      topLevel: false,
      parentKey: MY_SITE_DESIGN,
   },
   [MY_SITE_ABOUT_ME]: {
      path: '/designer/homepage/about',
      exact: true,
      title: 'About',
      topLevel: false,
      parentKey: MY_SITE_HOMEPAGE,
   },
   [MY_SITE_HEADER]: {
      path: '/designer/homepage/header',
      exact: true,
      title: 'Header',
      topLevel: false,
      parentKey: MY_SITE_HOMEPAGE,
   },
   [MY_SITE_CUSTOM_PAGE]: {
      path: '/designer/custom-pages',
      exact: true,
      title: 'Custom pages',
      topLevel: false,
      parentKey: MY_SITE_DESIGN,
   },
   [MY_SITE_CREATE_NEW_CUSTOM_PAGE]: {
      path: '/designer/custom-pages/create-new',
      exact: true,
      title: 'Add new custom page',
      topLevel: false,
      parentKey: MY_SITE_CUSTOM_PAGE,
   },
   [MY_SITE_EDIT_CUSTOM_PAGE]: {
      path: '/designer/custom-pages/details/:id',
      exact: true,
      title: 'Custom page details',
      topLevel: false,
      parentKey: MY_SITE_CUSTOM_PAGE,
   },
   [MY_SITE_SETTINGS]: {
      path: '/settings',
      exact: true,
      title: 'Settings',
      topLevel: true,
      // parentKey: MY_SITE,
   },


   [MY_SITE_BLOCK_IP_WHITE_LIST]: {
      path: '/settings/blocked-ip-white-list',
      exact: true,
      title: 'IP whitelist',
      topLevel: false,
      parentKey: MY_SITE_SETTINGS,
   },
   [MY_SITE_BLOCK_IP_BLACK_LIST]: {
      path: '/settings/blocked-ip-black-list',
      exact: true,
      title: 'IP blacklist',
      topLevel: false,
      parentKey: MY_SITE_SETTINGS,
   },
   [MY_SITE_BLOCK_REGIONS]: {
      path: '/settings/blocked-regions',
      exact: true,
      title: 'Blocked regions',
      topLevel: false,
      parentKey: MY_SITE_SETTINGS,
   },
   [MY_SITE_BLOCK_MEMBERS]: {
      path: '/settings/blocked-members',
      exact: true,
      title: 'Blocked members',
      topLevel: false,
      parentKey: MY_SITE_SETTINGS,
   },
   [MY_SITE_SCHEDULED_CONTENT]: {
      path: '/settings/scheduled-content',
      exact: true,
      title: 'Scheduled content',
      topLevel: false,
      parentKey: MY_SITE_SETTINGS,
   },
   [MY_SITE_DOMAIN_REDIRECTS]: {
      path: '/settings/domain-redirects',
      exact: true,
      title: 'Domain redirects',
      topLevel: false,
      parentKey: MY_SITE_SETTINGS,
   },
   [MY_SITE_SEO]: {
      path: '/settings/seo',
      exact: true,
      title: 'Search engine optimization (SEO)',
      topLevel: false,
      parentKey: MY_SITE_SETTINGS,
   },
   [MY_SITE_TRACKING_CODE]: {
      path: '/settings/tracking-code',
      exact: true,
      title: 'Tracking code',
      topLevel: false,
      parentKey: MY_SITE_SETTINGS,
   },
   [MY_SITE_WATERMARK]: {
      path: '/settings/watermark',
      exact: true,
      title: 'Watermark',
      topLevel: false,
      parentKey: MY_SITE_SETTINGS,
   },
   [MY_SITE_CONTENT_CARDS]: {
      path: '/settings/content-cards',
      exact: true,
      title: 'Content Cards',
      topLevel: false,
      parentKey: MY_SITE_SETTINGS,
   },
   [MY_SITE_LEGAL_PAGES]: {
      path: '/settings/legal-pages',
      exact: true,
      title: 'Legal pages',
      topLevel: false,
      parentKey: MY_SITE_SETTINGS,
   },
   [MY_SITE_LEGAL_PAGES_TERMS_OF_USE]: {
      path: '/settings/legal-pages/terms-of-use',
      exact: true,
      title: 'Terms of use',
      topLevel: false,
      parentKey: MY_SITE_LEGAL_PAGES,
   },
   [MY_SITE_LEGAL_PAGES_PRIVACY_POLICY]: {
      path: '/settings/legal-pages/privacy-policy',
      exact: true,
      title: 'Privacy policy',
      topLevel: false,
      parentKey: MY_SITE_LEGAL_PAGES,
   },
   [MY_SITE_LEGAL_PAGES_2257_COMPLIANCE]: {
      path: '/settings/legal-pages/2257-compliance',
      exact: true,
      title: '2257 Compliance',
      topLevel: false,
      parentKey: MY_SITE_LEGAL_PAGES,
   },
   [MY_SITE_LEGAL_PAGES_DMCA]: {
      path: '/settings/legal-pages/complaints-and-content-removal',
      exact: true,
      title: 'Complaints & Content Removal',
      topLevel: false,
      parentKey: MY_SITE_LEGAL_PAGES,
   },
   [MY_SITE_LEGAL_PAGES_CCBILL]: {
      path: '/settings/legal-pages/ccbill',
      exact: true,
      title: 'CCBill',
      topLevel: false,
      parentKey: MY_SITE_LEGAL_PAGES,
   },
   [SINGULAR_OR_PLURAL_ADMINS]: {
      path: '/settings/singular-or-plural',
      exact: true,
      title: 'Singular or plural',
      topLevel: false,
      parentKey: MY_SITE_SETTINGS,
   },

   [TRANSACTIONS]: {
      path: '/transactions',
      title: 'Transactions',
      topLevel: true,
      exact: true,
   },

   [MY_SITE_OUTGOING_NOTIFICATIONS_AND_AUTO_TWEETS]: {
      path: '/settings/outgoing-notifications-and-auto-posts',
      exact: true,
      title: 'Outgoing notifications & Auto posts',
      topLevel: false,
      parentKey: MY_SITE_SETTINGS,
   },
   [MY_SITE_OUTGOING_NOTIFICATIONS_AND_AUTO_TWEETS_EDIT]: {
      path: '/settings/outgoing-notifications-and-auto-posts/edit/:slug',
      exact: true,
      title: 'Event',
      topLevel: false,
      parentKey: MY_SITE_OUTGOING_NOTIFICATIONS_AND_AUTO_TWEETS,
      goBackById: true,
   },

   [TRANSACTIONS_DETAILS]: {
      path: '/transactions/:id',
      topLevel: false,
      parentKey: TRANSACTIONS,
      exact: true,
      title: 'Transaction details',
   },
   [TRANSACTIONS_WALLET_ACTIVITY]: {
      path: '/transactions/wallet-activity',
      topLevel: true,
      exact: true,
      title: 'Transactions',
   },
   [TRANSACTIONS_WALLET_ACTIVITY_DETAILS]: {
      path: '/transactions/wallet-activity/:id',
      topLevel: false,
      parentKey: TRANSACTIONS_WALLET_ACTIVITY,
      exact: true,
      title: 'Wallet activity details',
   },
   [PAYOUT]: {
      path: '/payouts',
      exact: true,
      title: 'Payouts',
      topLevel: true,
   },

   [PAYOUT_STATISTICS]: {
      path: '/payouts/statistics',
      exact: true,
      title: 'Payouts',
      topLevel: true,
   },
   [PAYOUT_DETAILS]: {
      path: '/payouts/details/:id',
      exact: true,
      title: 'Payout details',
      topLevel: false,
      parentKey: PAYOUT,
   },
   [MY_DOMAIN_NAME]: {
      path: '/my-domain-name',
      exact: true,
      title: 'Site & domain name',
      topLevel: false,
      parentKey: MY_SITE_SETTINGS,
   },
   [UPLOADS]: {
      path: '/uploads',
      exact: true,
      title: 'Uploads',
      topLevel: true,
   },

   [TAGS]: {
      path: '/content-tags',
      exact: true,
      title: 'Content tags',
      topLevel: true,
      // parentKey: HOMEPAGE,
   },
   [COLLECTIONS]: {
      path: '/collections',
      exact: true,
      title: 'Collections',
      topLevel: true,
      // parentKey: VIDEOS,
   },
   [AFFILIATE_PROGRAM]: {
      path: '/affiliate-program',
      exact: true,
      title: 'My affiliate program',
      topLevel: true,
   },
   [MY_AFFILIATES]: {
      path: '/affiliate-program/my-affiliates',
      exact: true,
      title: 'My affiliate program',
      topLevel: true,
   },
   [REFERRALS]: {
      path: '/referrals',
      exact: true,
      title: 'Refer creators & earn',
      topLevel: true,
   },
   [MASS_MESSAGE]: {
      path: '/mass-message',
      exact: true,
      title: 'Mass message',
      topLevel: true,
   },
   [MANAGE_MAILING_LIST]: {
      path: '/mass-message/manage-mailing-list',
      exact: true,
      title: 'Manage mailing list',
      topLevel: false,
      parentKey: MASS_MESSAGE,
   },
   [SEND_MASS_MESSAGE]: {
      path: '/mass-message/send-mass-message',
      exact: true,
      title: 'Send mass message',
      topLevel: false,
      parentKey: MASS_MESSAGE,
   },
   [EDIT_MASS_MESSAGE]: {
      path: '/mass-message/edit-mass-message/:id',
      exact: true,
      title: 'Edit mass message',
      topLevel: false,
      parentKey: MASS_MESSAGE,
   },
   [MANAGE_EMAIL_SUBSCRIBERS]: {
      path: '/mass-message/manage-mailing-list/manage-email-subscribers',
      exact: true,
      title: 'Manage mailing list',
      topLevel: false,
      parentKey: MASS_MESSAGE,
   },
   [MANAGE_EMAIL_UNSUBSCRIBERS]: {
      path: '/mass-message/manage-mailing-list/manage-email-unsubscribers',
      exact: true,
      title: 'Manage mailing list',
      topLevel: false,
      parentKey: MASS_MESSAGE,
   },
   [PROMOTIONS]: {
      path: '/promotions',
      exact: true,
      title: 'Promotions',
      topLevel: true,
   },
   [PROMOTIONS_DETAILS]: {
      path: '/promotions/details/:id',
      exact: true,
      title: 'Promotion details',
      topLevel: false,
      parentKey: PROMOTIONS,
   },
   [PROMOTIONS_USED_PROMO_CODES]: {
      path: '/promotions/:id/details/used-promo-codes',
      exact: true,
      title: 'Used promo codes',
      topLevel: false,
      parentKey: PROMOTIONS_DETAILS,
   },
   [PROMOTIONS_CREATE]: {
      path: '/promotions/create',
      exact: true,
      title: 'Add new promotion',
      topLevel: false,
      parentKey: PROMOTIONS,
   },
   [LIVE_STREAM]: {
      path: '/livestream',
      exact: true,
      title: 'Livestream',
      topLevel: true,
   },
   [LIVE_STREAM_REORDER]: {
      path: '/livestream/reorder',
      exact: true,
      title: 'Livestream',
      topLevel: true,
   },
   [LIVE_STREAM_SCHEDULE]: {
      path: '/livestream/schedule',
      exact: true,
      title: 'Scheduled livestream',
      topLevel: false,
      parentKey: LIVE_STREAM,
   },
   [LIVE_STREAM_STUDIO]: {
      path: '/livestream/studio',
      exact: true,
      title: 'Studio',
      topLevel: false,
      parentKey: LIVE_STREAM,
   },
   [RECORDED_VIDEO]: {
      path: '/livestream/recorded/:id/edit',
      exact: true,
      title: 'Stream details',
      topLevel: false,
      parentKey: LIVE_STREAM,
   },
   [LIVE_STREAM_CREATE]: {
      path: '/livestream/create',
      exact: true,
      title: 'Create livestream',
      topLevel: false,
      parentKey: LIVE_STREAM,
   },

   [VAULT]: {
      path: '/vault',
      title: 'Vault',
      topLevel: true,
      exact: true,
   },
   [GET_VAULT_VIDEO]: {
      path: '/vault-video/:id',
      title: 'Vault video',
      topLevel: false,
      exact: true,
      parentKey: VAULT,
   },
   // [VAULT_REORDER]: {
   //    path: '/vault-video/:id',
   //    title: 'Vault video',
   //    topLevel: false,
   //    exact: true,
   //    parentKey: VAULT,
   // },

   [TWITTER_INTEGRATION]: {
      path: '/settings/twitter-integration',
      title: 'Twitter integration',
      exact: true,
      topLevel: false,
      parentKey: MY_SITE_SETTINGS,
   },
   [TWITTER_CALLBACK]: {
      path: '/twitter/callback',
      exact: true,
      topLevel: true,
   },
   [SIGN_IN_CANNY]: {
      path: '/signin-canny',
      exact: true,
      topLevel: true,
   },
   // GLOBAL STATISTICS
   [STATISTICS_REVENUE]: {
      path: '/statistics-revenue',
      title: 'Revenue',
      exact: true,
      topLevel: true,
   },
   [STATISTICS_ENGAGEMENT]: {
      path: '/statistics-engagement',
      title: 'Engagement',
      exact: true,
      topLevel: true,
   },
   [STATISTICS_ACQUISITION]: {
      path: '/statistics-acquisition',
      title: 'Acquisition',
      exact: true,
      topLevel: true,
   },
   [GLOBAL_ACTIVITY]: {
      path: '/activity',
      title: 'Activity',
      exact: true,
      topLevel: true,
   },
   [MY_SITE_CONTENT_CATEGORIES]: {
      path: '/settings/content-categories',
      exact: true,
      title: 'Content Categories',
      topLevel: false,
      parentKey: MY_SITE_SETTINGS,
   },
};
export default routes;
