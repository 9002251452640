import { submitForReview, submitLaunchSite } from 'admin/api/AuthApi';
import {
   openStepsModalCompleted,
   siteStatusChangeCompleted,
   setDialogCompleted,
   toggleIsFetching,
} from './actions';
import Router from 'admin/routes/router';
import { push } from "connected-react-router";
import { isNewPayoutVersion } from 'admin/utils/Helpers';

export const openStepsModalOperation = () => {
   return async dispatch => {
      dispatch(toggleIsFetching(true));
      dispatch(openStepsModalCompleted());
      setTimeout(() => {
         dispatch(toggleIsFetching(false));
      }, 400);
   }
}

export const submitForReviewOperation = () => {
   return async (dispatch) => {
      dispatch(setDialogCompleted({
         open: true,
         loading: false,
         type: 'review_submitted',
      }))
      try {
         await submitForReview();
         dispatch(siteStatusChangeCompleted('submitted_for_review'))
      } catch (error) {
      }
   };
};

export const submitForLaunchSiteOperation = () => {
   return async (dispatch) => {

      try {
         await submitLaunchSite();
         dispatch(setDialogCompleted({
            open: true,
            loading: false,
            type: 'congratulations',
         }))
      } catch (error) {
      }
   };
};

export const goToPagesOperation = (slug, isChangeModal) => {
   return async (dispatch, getState) => {
      switch (slug) {
         case 'legal_info':
            dispatch(push({
               pathname: Router.route('MY_ACCOUNT').getCompiledPath(),
               search: `?activeTab=legal_info&goTo=${ Router.route('HOMEPAGE').getCompiledPath() }`,
            }));
            break;
         case 'domain':
            dispatch(push({
               pathname: Router.route('MY_DOMAIN_NAME').getCompiledPath(),
               search: `goTo=${ Router.route('HOMEPAGE').getCompiledPath() }`,
            }))
            break;
         case 'payout_preferences':
            const {
               common: { authUser: { app_id }, siteInfo: { is_payout_method_selected, site_selected_payout } },
            } = getState();
            let pathname = '';
            if(isNewPayoutVersion(app_id)){ // New version
               if(Boolean(is_payout_method_selected)) {
                  pathname = Router.route('MY_ACCOUNT_PAYOUT_METHOD_EDIT').getCompiledPath();
               } else {
                  pathname = Router.route('MY_ACCOUNT_ADD_PAYOUT_METHODS').getCompiledPath();
               }
            } else {
               if(Boolean(site_selected_payout)) {
                  pathname = Router.route('MY_ACCOUNT_PAYOUT_METHOD').getCompiledPath();
               } else {
                  pathname = Router.route('MY_ACCOUNT_OTHER_PAYOUT_METHODS').getCompiledPath();
               }
            }
            dispatch(push({
               pathname: pathname,
               search: `goTo=${ Router.route('HOMEPAGE').getCompiledPath() }`,
            }))
            break;
         case 'content_story':
            dispatch(push({
               pathname: Router.route('STORIES').getCompiledPath(),
               search: `type=add&goTo=${ Router.route('HOMEPAGE').getCompiledPath() }`,
            }))
            break;
         case 'profile_picture':
            dispatch(push({
               pathname: Router.route('MY_ACCOUNT').getCompiledPath(),
               search: `?activeTab=public_profile&goTo=${ Router.route('HOMEPAGE').getCompiledPath() }`,
            }));
            break;
         case 'content_category':
            dispatch(push({
               pathname: Router.route('MY_SITE_CONTENT_CATEGORIES').getCompiledPath(),
            }));
            break;
         case 'twitter-integration':
            dispatch(push(Router.route('TWITTER_INTEGRATION').getCompiledPath()))
            break;
         case 'home':
            dispatch(push(Router.route('MY_SITE_HOMEPAGE').getCompiledPath()))
            break;
         case 'logo':
            dispatch(push(Router.route('MY_SITE_LOGO').getCompiledPath()))
            break;
         case 'colors':
            dispatch(push(Router.route('MY_SITE_COLORS').getCompiledPath()))
            break;
         case 'custom-page':
            dispatch(push(Router.route('MY_SITE_CUSTOM_PAGE').getCompiledPath()))
            break;
         default:
            break;
      }
      if(isChangeModal) {
         dispatch(openStepsModalCompleted())
      }
   };
};
