import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {

   // delete file
   [types.DELETE_START]: (state, action) => {
      return {
         ...state,
      }
   },
   [types.DELETE_COMPLETED]: (state, action) => {
      const  { payload: { id, type } } = action;
      if(type === 'Video'){
         let vaultVideosData = [...state.vaultVideosData.data];
         vaultVideosData = vaultVideosData.filter(item => item.id.toString() !== id.toString());
         return {
            ...state,
            vaultVideosData: {
               ...state.vaultVideosData,
               data: [...vaultVideosData],
            },
            isEmptyVideos: vaultVideosData.length === 0,
         }
      } else {
         let vaultImagesData = [...state.vaultImagesData.data];
         vaultImagesData = vaultImagesData.filter(item => item.id.toString() !== id.toString());
         return {
            ...state,
            vaultImagesData: {
               ...state.vaultImagesData,
               data: [...vaultImagesData],
            },
            isEmptyImages: vaultImagesData.length === 0,
         }
      }
   },
   [types.DELETE_FAILED]: (state, action) => {
      return {
         ...state,
      }
   },


   ////////////              Images

   // get images data
   [types.FETCH_IMAGES_REQUEST_START]: (state, action) => {
      return {
         ...state,
         isFetchingImages: true,
      }
   },
   [types.FETCH_IMAGES_REQUEST_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isFetchingImages: false,
         vaultImagesData: { ...payload },
         isEmptyImages: payload.data.length === 0,
         vaultImagesIsInited: true,
      }
   },

   [types.FETCH_IMAGES_REQUEST_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingImages: false,
      }
   },

   // get images data by filter
   [types.FETCH_IMAGES_BY_FILTER_START]: (state, action) => {
      return {
         ...state,
         isFetchingImagesByFilter: true,
      }
   },
   [types.FETCH_IMAGES_BY_FILTER_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isFetchingImagesByFilter: false,
         vaultImagesData: payload,
         isEmptyImagesByFilter: payload.data.length === 0,
      }
   },
   [types.FETCH_IMAGES_BY_FILTER_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingImagesByFilter: false,
      }
   },

   // get new images data
   [types.FETCH_NEW_IMAGES_REQUEST_START]: (state, action) => {
      return {
         ...state,
         isNewFetchingImages: true,
      }
   },
   [types.FETCH_NEW_IMAGES_REQUEST_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isNewFetchingImages: false,
         vaultImagesData: {
            ...payload,
            data: [
               ...state.vaultImagesData.data,
               ...payload.data,
            ],
         },
      }
   },
   [types.FETCH_NEW_IMAGES_REQUEST_FAILED]: (state, action) => {
      return {
         ...state,
         isNewFetchingImages: false,
      }
   },

   [types.CHANGE_VAULT_DATA]: (state, action) => {
      const  { payload: { data, status, byHash } } = action;
      let vaultImagesData = [...state.vaultImagesData.data];
      switch (status) {
         case 'loading':
            vaultImagesData = [...data, ...state.vaultImagesData.data];
            break;
         case 'update':
            vaultImagesData = [...state.vaultImagesData.data].map((i) => {
               let { ...elm } = i
               let key = 'id';
               if(byHash){
                  key = 'hash'
               }
               if(elm[key] === data[key]){
                  elm = data
               }
               return elm
            });
            break;
         case 'done':
            vaultImagesData = [...state.vaultImagesData.data].map((i) => {
               let { ...elm } = i
               if(elm.isFack && data[i.src]){
                  elm = data[i.src]
               }
               return elm
            });
            break;

         default:
            break;
      }
      return {
         ...state,
         vaultImagesData: {
            ...state.vaultImagesData,
            data: [...vaultImagesData],
         },
         isEmptyImages: vaultImagesData.length === 0,
      }
   },



   ////////                  Videos

   // get data
   [types.FETCH_VIDEOS_REQUEST_START]: (state, action) => {
      return {
         ...state,
         isFetchingVideos: true,
      }
   },
   [types.FETCH_VIDEOS_REQUEST_COMPLETED]: (state, action) => {
      const  { payload: { data, isInsideModal } } = action;
      let newData = data.data;
      let uploadingVideos = [...state.uploadingVideos];
      let uploadingVideosIds = uploadingVideos.map(item => item.id);
      let ids = [];
      newData.forEach(item => {
         if(uploadingVideosIds.includes(item.id) && typeof item.id === 'number') {
            ids.push(item.id);
         }
      });
      uploadingVideos = uploadingVideos.filter(item => !ids.includes(item.id));
      newData = [
         ...uploadingVideos,
         ...newData,
      ]
      return {
         ...state,
         isFetchingVideos: false,
         vaultVideosData: { ...data },
         isInsideModal: isInsideModal,
         isEmptyVideos: data.data.length === 0,
         vaultVideosIsInited: true,
      }
   },

   [types.FETCH_VIDEOS_REQUEST_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingVideos: false,
      }
   },

   // get data by filter
   [types.FETCH_VIDEOS_BY_FILTER_START]: (state, action) => {
      return {
         ...state,
         isFetchingVideosByFilter: true,
      }
   },
   [types.FETCH_VIDEOS_BY_FILTER_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isFetchingVideosByFilter: false,
         vaultVideosData: payload,
         isEmptyVideosByFilter: payload.data.length === 0,
      }
   },
   [types.FETCH_VIDEOS_BY_FILTER_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingVideosByFilter: false,
      }
   },

   // get new data
   [types.FETCH_NEW_VIDEOS_REQUEST_START]: (state, action) => {
      return {
         ...state,
         isNewFetchingVideos: true,
      }
   },
   [types.FETCH_NEW_VIDEOS_REQUEST_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isNewFetchingVideos: false,
         vaultVideosData: {
            ...payload,
            data: [
               ...state.vaultVideosData.data,
               ...payload.data,
            ],
         },
      }
   },
   [types.FETCH_NEW_VIDEOS_REQUEST_FAILED]: (state, action) => {
      return {
         ...state,
         isNewFetchingVideos: false,
      }
   },



   [types.REMOVE_FILE]: (state, action) => {
      const  { payload: { id } } = action;
      let vaultVideosData = [...state.vaultVideosData.data];
      let uploadingVideos = [...state.uploadingVideos];
      vaultVideosData = vaultVideosData.filter(item => item.id.toString() !== id.toString());
      uploadingVideos = uploadingVideos.filter(item => item.id.toString() !== id.toString());
      return {
         ...state,
         vaultVideosData: {
            ...state.vaultVideosData,
            data: [...vaultVideosData],
         },
         uploadingVideos: [...uploadingVideos],
         isEmptyVideos: vaultVideosData.length === 0,
      }
   },

   [types.ADD_VIDEOS]: (state, action) => {
      const { payload } = action;
      let data = [
         ...payload.vault,
         ...state.vaultVideosData.data,
      ];
      return {
         ...state,
         vaultVideosData: {
            ...state.vaultVideosData,
            data: data,
         },
         uploadingVideos: [
            ...state.uploadingVideos,
            ...payload.vault,
         ],
         isEmptyVideos: data.length === 0,
      }
   },

   [types.SET_INSIDE_MODAL]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         isInsideModal: payload,
      }
   },

   [types.UPDATE_VIDEO]: (state, action) => {
      const { payload: { id, data, byHash } } = action;
      const isInsideModal = state.isInsideModal;
      let oldData = [...state.vaultVideosData.data];
      let oldUploadingData = [...state.uploadingVideos];
      let key = 'id'
      if(byHash) key = 'hash'
      const candidate = oldData.find((video) => {
         return id === video[key];
      });
      const uploadingCandidate = oldUploadingData.find((video) => {
         return  video && id === video[key];
      });
      let newVideos = [...oldData];
      if(!isInsideModal) {
         if(candidate) {
            newVideos = oldData.map((video) => {
               if(id === video[key]) {
                  return {
                     ...video,
                     ...data,
                  }
               }
               return {
                  ...video,
               }
            });
         } else {
            newVideos = [data, ...oldData];
         }
      }
      let newUploadingVideos = null;
      if(uploadingCandidate) {
         let ids = [];
         newUploadingVideos = oldUploadingData.map((video) => {
            if(id === video[key]) {
               if(key === 'id' && typeof data.id === 'number'){
                  ids.push(data[key])
               }
               return {
                  ...video,
                  ...data,
                  uploadingStatus: data.video_optimization_status || data.uploadingStatus || video.uploadingStatus,
                  hash: data.hash || video.hash,
               }
            }
            return {
               ...video,
            }
         });
         newUploadingVideos = newUploadingVideos.filter(item => !ids.includes(item[key]))
      } else {
         newUploadingVideos = [data, ...oldUploadingData];
      }
      return {
         ...state,
         vaultVideosData: {
            ...state.vaultVideosData,
            data: newVideos,
         },
         uploadingVideos: newUploadingVideos,
      }
   },

   // all vault

   [types.FETCH_ALL_VAULT_REQUEST_START]: (state, action) => {
      return {
         ...state,
         isFetchingAllVault: true,
      }
   },
   [types.FETCH_ALL_VAULT_REQUEST_COMPLETED]: (state, action) => {
      const  { payload: { data, isInsideModal } } = action;
      return {
         ...state,
         isFetchingAllVault: false,
         allVaultData: { ...data },
         isInsideModal: isInsideModal,
         isEmptyAllVault: data?.data?.length === 0,
         allVaultIsInited: true,
      }
   },

   [types.FETCH_ALL_VAULT_REQUEST_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingAllVault: true,
      }
   },

   [types.FETCH_ALL_VAULT_BY_FILTER_START]: (state, action) => {
      return {
         ...state,
         isFetchingAllVaultByFilter: true,
      }
   },
   [types.FETCH_ALL_VAULT_BY_FILTER_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isFetchingAllVaultByFilter: false,
         allVaultData: { ...payload },
         isEmptyAllVaultByFilter: payload.data.length === 0,
      }
   },
   [types.FETCH_ALL_VAULT_BY_FILTER_FAILED]: (state, action) => {
      return {
         ...state,
         isEmptyAllVaultByFilter: true,
      }
   },
   [types.FETCH_NEW_VAULTS_REQUEST_START]: (state, action) => {
      return {
         ...state,
         isNewfetchingAllVaults: true,
      }
   },
   [types.FETCH_NEW_VAULTS_REQUEST_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isNewfetchingAllVaults: false,
         allVaultData: {
            ...payload,
            data: [
               ...state.allVaultData.data,
               ...payload.data,
            ],
         },
      }
   },
   [types.FETCH_NEW_VAULTS_REQUEST_FAILED]: (state, action) => {
      return {
         ...state,
         isNewfetchingAllVaults: false,
      }
   },
   [types.UPDATE_BUNDLE_STATE_IN_DATA]: (state, action) => {
      let { data: { id, attached_bundle_name, attached_bundle_id } } = action.payload

      let updatedImagesList = state.vaultImagesData.data.map(item => {
         if(item.id === id) {
            return {
               ...item,
               attached_bundle_name, attached_bundle_id,
            }
         }
         return item
      })

      let updatedVideosList = state.vaultVideosData.data.map(item => {
         if(item.id === id) {
            return {
               ...item,
               attached_bundle_name, attached_bundle_id,
            }
         }
         return item
      })

      return {
         ...state,
         vaultImagesData: {
            ...state.vaultImagesData,
            data: updatedImagesList,
         },
         vaultVideosData: {
            ...state.vaultVideosData,
            data: updatedVideosList,
         },
      }
   },



   [types.UPDATE_VAULT_MESSAGE_ACTION]: (state, action) => {
      const { ids, data, messageId, contentType } = action.payload;
      console.log('UPDATE_VAULT_MESSAGE_ACTION', action.payload);
      const isDeleteAll = action?.payload?.isDeleteAll;
      const resourceType = action?.payload?.resourceType;
      const messageData = action?.payload?.messageData;
      let newState = {}
      if((contentType && 'vault_image' === contentType && 'multiple_attachment' === resourceType && state?.vaultImagesData?.data) || ('photo_vault' === resourceType && state?.vaultImagesData?.data)){
         let images = [...state.vaultImagesData.data];
         // const ids = typeof id === 'object' ? id : [id]
         images = updateVaultMessagesState(images, data, messageId, ids, isDeleteAll, messageData)
         newState = {
            vaultImagesData: {
               ...state.vaultImagesData,
               data: images,
            },
         }
      } else if(state?.vaultVideosData?.data){
         let videos = [...state.vaultVideosData.data];
         videos = updateVaultMessagesState(videos, data, messageId, ids, isDeleteAll, messageData)
         newState = {
            vaultVideosData: {
               ...state.vaultVideosData,
               data: videos,
            },
         }
      }
      return {
         ...state,
         ...newState,
      }
   },

   [types.CLEAR_STATE]: (state, action) => {
      return {
         ...state,
         ...action.payload,
      }
   },
};
function updateVaultMessagesState(vaults, data, messageId, ids, isDeleteAll, messageData = null) {
   let result = [...vaults].map((el) => {
      let { ...vault } = el;
      if(ids.includes(vault.id)){
         let messages = null;
         if(vault.messages && !isDeleteAll){
            if(data){
               messages = [...vault.messages].map((message) => {
                  let { ...m } = message;
                  if(m.id === messageId){
                     m = {
                        ...m,
                        ...data,
                     }
                  }
                  return m;
               })
            } else {
               messages = [...vault.messages].filter(m => m.id !== messageId);
            }
         }
         vault.messages = messages;
         if(!messages?.length){
            vault.messages = null;
         }
      } else if(messageData && vault.messages?.length){
         const messages = [...vault.messages].map((message) => {
            let { ...m } = message;
            if(m.id === messageId){
               m = {
                  ...m,
                  ...messageData,
               }
            }
            return m;
         })
         vault.messages = messages;
      }
      return vault
   })

   return result;
}
export default createReducer(initialState)(reducersMap);
