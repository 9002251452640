import { createContext } from 'react';
const Context = createContext({
   onDeleteMessag: () => {},
   onUserTyping: () => {},
   groupChatTypersMessage: '',
   onLikeMessage: () =>  {},
   onSendMessage: () => {},
   onClickChat: () => {},
   onReadMessage: () => {},
   updateMessageUnlockDetails: () => {},
   converstionInfo: {},
   updateMessageData: () => {},
});
const Provider = Context.Provider
const Consumer = Context.Consumer
export {
   Provider,
   Consumer,
}
export default Context
