import * as types from './types';

const siteDetailsInitStart = () => ({
   type: types.SITE_DETAILS_INIT_START,
});

const siteDetailsInitCompleted = (payload) => ({
   type: types.SITE_DETAILS_INIT_COMPLETED,
   payload,
});

const siteDetailsInitFailed = () => ({
   type: types.SITE_DETAILS_INIT_FAILED,
});

const siteDetailsRefreshStart = () => ({
   type: types.SITE_DETAILS_REFRESH_START,
});

const resetCommonDetails = () => ({
   type: types.RESET_COMMON_DETAILS,
});

const collapseMenu = () => ({
   type: types.COLLAPSE_MENU,
});

const updateAuthUser = (data) => ({
   type: types.UPDATE_AUTH_USER,
   payload: data,
});

const screenResize = (screenWidth) => ({
   type: types.SCREEN_RESIZE,
   payload: {
      screenWidth,
   },
});
const setViewAsModal = (bool, url) => ({
   type: types.SET_VIEW_AS_MODAL,
   payload: { bool, url },
});
const setIsOnlineStream = (bool) => ({
   type: types.SET_IS_ONLINE_STREAM,
   payload: bool,
});
const updateLiveStreamCurrentSessionState = (data) => ({
   type: types.UPDATE_LIVE_STREAM_CURRENT_SESSION_STATE,
   payload: data,
});
const openOnlineMembersModal = (bool) => ({
   type: types.OPEN_ONLINE_MEMBERS_MODAL,
   payload: bool,
})
const updateSiteNameStart = () => ({
   type: types.UPDATE_SITE_LONG_NAME_START,
});

const updateSiteNameCompleted = (payload) => ({
   type: types.UPDATE_SITE_LONG_NAME_COMPLETED,
   payload,
});

const updateSiteNameFailed = (payload) => ({
   type: types.UPDATE_SITE_NAME_FAILED,
   payload,
});
const contentMetaInfo = (payload) => ({
   type: types.UPDATE_CONTENT_META_INFO,
   payload,
});
const setMassMessageLimit = (payload) => ({
   type: types.SET_MASS_MESSAGE_LIMIT,
   payload,
});
const updateIsOfflineModeCompleted = (payload) => ({
   type: types.UPDATE_IS_OFFLINE_MODE_COMPLETED,
   payload,
});
const updateIsOfflineModeStart = (payload) => ({
   type: types.UPDATE_IS_OFFLINE_MODE_START,
   payload,
});

const updateReceivedOrdersCountAction = payload => ({
   type: types.UPDATE_RECEIVED_ORDERS_COUNT,
   payload,
})

export {
   siteDetailsInitStart,
   resetCommonDetails,
   siteDetailsInitCompleted,
   siteDetailsInitFailed,
   collapseMenu,
   updateAuthUser,
   siteDetailsRefreshStart,
   screenResize,
   setViewAsModal,
   setIsOnlineStream,
   updateLiveStreamCurrentSessionState,
   openOnlineMembersModal,
   updateSiteNameStart,
   updateSiteNameCompleted,
   updateSiteNameFailed,
   contentMetaInfo,
   setMassMessageLimit,
   updateReceivedOrdersCountAction,
   updateIsOfflineModeCompleted,
   updateIsOfflineModeStart,
};
