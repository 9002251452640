import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import md5 from 'md5';
import './style.scss';
import Icon from 'common/components/elements/icons';

const ImgGallery = ({
   images,
   onClickFullScreen,
   showFullScreen,
   classNameIconDiv,
   clickCount,
   setClickCount,
   triggerImageLoad,
}) => {

   let numClicks = 0;
   let singleClickTimer = 0;
   const galleryRef = useRef(null);

   const handleImageLoad = (event) => {
      const src = event.target.src;
      const id = md5(src);
      if(document.getElementById(id) && !triggerImageLoad) {
         return;
      }
      const parentElement = event.target.parentElement;
      const newElement = document.createElement("div");
      newElement.id = id;
      newElement.style.backgroundImage = `url(${ event.target.src })`;
      newElement.style.width = '100%';
      newElement.style.height = '100%';
      newElement.style.filter = 'blur(30px)';
      newElement.style.backgroundRepeat = 'no-repeat';
      newElement.style.backgroundPosition = 'center';
      newElement.style.backgroundSize = 'cover';
      parentElement.insertBefore(newElement, event.target);
   };

   const toggleBodyClass = (add) => {
      if(add) {
         document.body.classList.add('photosets-fullscreen');
      } else {
         document.body.classList.remove('photosets-fullscreen');
      }
   };
   useEffect(() => {
      return () => {
         toggleBodyClass(false);
      };
   }, []);

   const handleFullscreenChange = (isFullScreenMode) => {
      if(isFullScreenMode){
         onClickFullScreen()
      }
      toggleBodyClass(isFullScreenMode)
   };

   let rightNewState = classNameIconDiv ? {
      renderRightNav: (onClick, disabled) => (
         <button
            type='button'
            className='image-gallery-right-nav !filter-none image-gallery-icon z-40'
            onClick={ (e) => {
               e.stopPropagation()
               e.preventDefault()
               e.stopPropagation()
               if(!disabled) {
                  onClick()
                  !!setClickCount && setClickCount(clickCount + 1)
               }
            }
            }
            aria-label='Next Slide'
         >
            <div className={ `${ classNameIconDiv } next-icon-classes ${ disabled ? '!hidden' : '' }` }>
               <Icon
                  name='arrow-right'
                  size='[20px]'
                  color='major-dark'
               />
            </div>
         </button>
      ),

   } : ''
   let leftNewState = classNameIconDiv ? {
      renderLeftNav: (onClick, disabled) => (
         <button
            type='button'
            className='image-gallery-left-nav px-4 !filter-none image-gallery-icon z-40'
            onClick={ (e) => {
               e.preventDefault()
               e.stopPropagation()
               if(!disabled) {
                  onClick()
                  !!setClickCount && setClickCount(clickCount - 1)
               }
            } }
            aria-label='Previous Slide'
         >
            <div className={ `${ classNameIconDiv } next-icon-classes ${ disabled ? '!hidden' : '' }` }>
               <Icon
                  name='arrow-left'
                  size='[20px]'
                  color='major-dark'
               />
            </div>
         </button>
      ),

   } : ''
   return (
      <>
         <ImageGallery
            ref={ galleryRef }
            items={ images }
            onImageLoad={ handleImageLoad }
            onScreenChange={ handleFullscreenChange }
            infinite={ false }
            showBullets={ false }
            showFullscreenButton={ showFullScreen }
            showPlayButton={ false }
            showThumbnails={ false }
            disableKeyDown
            showNav={ true }
            slideDuration={ 100 }
            additionalClass='app-image-gallery'
            useWindowKeyDown={ true }
            lazyLoad={ true }
            useBrowserFullscreen={ false }
            onClick={ (e) => {
               numClicks++;
               if(numClicks === 1) {
                  singleClickTimer = setTimeout(() => {
                     numClicks = 0;
                  }, 400);
               } else if(numClicks === 2) {
                  if(showFullScreen) {

                     clearTimeout(singleClickTimer);
                     numClicks = 0;
                     if(galleryRef && galleryRef.current) {
                        galleryRef.current.toggleFullScreen();
                     }
                  }
               }
            } }
            { ...rightNewState }
            { ...leftNewState }
         />

      </>
   )
}

ImgGallery.defaultProps = {
   onClickFullScreen: () => {},
   showFullScreen: true,
};

ImgGallery.propTypes = {
   images: PropTypes.array,
   onClickFullScreen: PropTypes.func,
   showFullScreen: PropTypes.bool,
   classNameIconDiv: PropTypes.string,
   clickCount: PropTypes.number,
   setClickCount: PropTypes.func,
   triggerImageLoad: PropTypes.bool,
};

export default React.memo(ImgGallery);
