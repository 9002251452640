import React from 'react';
import PropTypes from 'prop-types';
import CreateEditModal from 'common/components/modules/modal-wrapper';


const DeleteVoiceRecordConfirmModal = ({
   onCloseModal,
   action,
   status,
   isMobile,
}) => {
   const modalState = () => {
      let state = {
         icon: 'stop',
         title: 'Delete recording?',
         description: 'If you exit the chat now, you’ll lose the recorded voice message. Are you sure you want to exit?',
         cancelText: 'No, stay and keep recording',
         buttonText: 'Yes, exit and delete recording',
      }
      if('stop' === status){
         state = {
            icon: 'cancel',
            title: 'Discard changes?',
            description: 'Changes you made so far will not be saved',
            cancelText: 'Keep editing',
            buttonText: 'Discard changes',
         }
      }
      return state;
   }
   return (
      <CreateEditModal
         { ...modalState() }
         type='confirm'
         iconColor='error'
         descriptionColorClass='text-secondary dark:text-secondary-dark'
         action={ action }
         onCloseModal={ onCloseModal }
         nonPrimaryColor
         textColor='error'
         contentWidth='375px'
         isMobile={ isMobile }
         buttonClassName='mt-8 text-error'
         iconSize='2xl'
      />
   )
};
DeleteVoiceRecordConfirmModal.defaultProps = {
   onCloseModal: () => {},
   action: () => {},
};
DeleteVoiceRecordConfirmModal.propTypes = {
   action: PropTypes.func,
   onCloseModal: PropTypes.func,
   status: PropTypes.string,
   isMobile: PropTypes.bool,
};

export default DeleteVoiceRecordConfirmModal;
