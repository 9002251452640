import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {
   [types.FETCH_DETAILS]: (state, action) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isFetching: false,
         ...payload,
      }
   },
   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },
   [types.FETCH_MY_SITE_DETAILS]: (state, action) => {
      return {
         ...state,
         isFetchingSite: true,
      }
   },
   [types.FETCH_MY_SITE_DETAILS_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isFetchingSite: false,
         siteSettings: payload,
      }
   },
   [types.FETCH_MY_SITE_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingSite: false,
      }
   },

   [types.FETCH_MY_SITE_HEADER]: (state, action) => {
      return {
         ...state,
         isFetchingHeader: true,
         headerData: {},
      }
   },
   [types.FETCH_MY_SITE_HEADER_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         headerData: payload,
         isFetchingHeader: false,
      }
   },
   [types.FETCH_MY_SITE_HEADER_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingHeader: false,
      }
   },
   [types.UPDATE_MY_SITE_HEADER]: (state, action) => {
      return {
         ...state,
         // headerUpdateDisabeld: true,
         // headerData: {},
      }
   },
   [types.UPDATE_MY_SITE_HEADER_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         headerData: {
            ...state.headerData,
            ...payload,
         },
      }
   },
   [types.UPDATE_MY_SITE_HEADER_FAILED]: (state, action) => {
      return {
         ...state,
         // isFetchingHeader: false,
      }
   },

   //// about me
   [types.FETCH_ABOUT_ME_DETAILS_START]: (state, action) => {
      return {
         ...state,
         isFetchingAboutMe: action.payload,
      }
   },
   [types.FETCH_ABOUT_ME_DETAILS_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingAboutMe: false,
         aboutMeData: action.payload,
      }
   },
   [types.UPDATE_ABOUT_ME_DETAILS_START]: (state, action) => {
      return {
         ...state,
         afterUpdateAboutMe: false,
      }
   },
   [types.UPDATE_ABOUT_ME_DETAILS_COMPLETED]: (state, action) => {
      return {
         ...state,
         afterUpdateAboutMe: true,
         aboutMeData: {
            ...state.aboutMeData,
            ...action.payload,
         },
      }
   },

   ///////////////////////////////////

   [types.UPDATE_SETTINGS_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         settings: {
            ...state.settings,
            ...payload,
         },
      }
   },

   [types.SET_SETTINGS_MODAL]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         settingsModal: {
            ...state.settingsModal,
            ...payload,
         },
      }
   },
   [types.FETCH_CONTENT_CARDS_OPTIONS]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         contentCardsOptions: {
            ...state?.contentCardsOptions,
            ...payload,
         },
      }
   },
   [types.UPDATE_STATE_CONTENT_CARDS]: (state, { payload }) => {
      return {
         ...state,
         contentCardsOptions: {
            ...state?.contentCardsOptions,
            ...payload,
         },
      }
   },
   [types.SET_SETTINGS_MODAL_BY_ID]: (state, action) => {
      const  { buttonDisabled, id } = action.payload;
      return {
         ...state,
         settingsModalById: {
            ...state.settingsModalById,
            buttonDisabled,
            id,
         },
      }
   },
   [types.FETCH_TRACKING_CODE_START]: (state, { payload }) => {
      return {
         ...state,
         fetchingTrackingCode: payload,
      }
   },
   [types.FETCH_TRACKING_CODE_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         trackingCodeData: { ...state.trackingCodeData, ...payload },
         fetchingTrackingCode: false,
      }
   },

   [types.FETCH_SEO_START]: (state, { payload }) => {
      return {
         ...state,
         fetchingSEO: payload,
      }
   },
   [types.FETCH_SEO_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         initDataSEO: payload,
         fetchingSEO: false,
      }
   },
   [types.UPDATE_SEO_START]: (state, { payload }) => {
      return {
         ...state,
         isFetchingUpdateSEO: payload,
      }
   },
   [types.UPDATE_SEO_COMPLETED]: (state, { payload }) => {
      return {
         ...state,
         isFetchingUpdateSEO: false,
         initDataSEO: {
            ...state.initDataSEO,
            ...payload,
         },
      }
   },
   [types.UPDATE_SEO_FAILED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         errorMessageSEO: payload,
         isFetchingUpdateSEO: false,
      }
   },

   // Content categories
   [types.INIT_CONTENT_CATEGORIES_START]: (state) => {

      return {
         ...state,
         isFetchingAddedCategories: true,
      }
   },
   [types.INIT_CONTENT_CATEGORIES_COMPLETED]: (state, action) => {

      return {
         ...state,
         isFetchingAddedCategories: false,
         addedCategories: action.payload,
      }
   },
   [types.INIT_CONTENT_CATEGORIES_FAILED]: (state) => {

      return {
         ...state,
         isFetchingAddedCategories: false,
      }
   },
   [types.SET_IS_FETCHING_GET_ALL_CATEGORIES]: (state, action) => {

      return {
         ...state,
         isFetchingAllCategories: action.payload,
      }
   },
   [types.GET_ALL_CONTENT_CATEGORIES_COMPLETED]: (state, action) => {

      return {
         ...state,
         isFetchingAllCategories: false,
         allCategories: action.payload,
      }
   },
   [types.TOGGLE_FETCHING_ADD_REMOVE_CATEGORY_START]: (state, action) => {
      const { id, isFetching } = action.payload

      let newIds = []
      if(isFetching) {
         newIds = [...state.fetchingToggleAddRemoveIDs, id]
      } else {
         newIds = state.fetchingToggleAddRemoveIDs.filter(fetchingId => fetchingId !== id)
      }

      return {
         ...state,
         fetchingToggleAddRemoveIDs: newIds,
      }
   },
   [types.TOGGLE_ADD_REMOVE_CATEGORY_COMPLETED]: (state, action) => {
      const { category, isAdded } = action.payload;

      const newState = {}

      newState.fetchingToggleAddRemoveIDs = state.fetchingToggleAddRemoveIDs.filter(fetchingId => fetchingId !== category.id)

      if(isAdded) {
         newState.addedCategories = state.addedCategories.filter(item => item.id !== category.id)
      } else {
         newState.addedCategories = [...state.addedCategories, category]
      }

      newState.allCategories = state.allCategories.map(item => {
         if(item.id === category.id) {
            return {
               ...item,
               exists: !isAdded,
            }
         }
         return item
      })

      return {
         ...state,
         ...newState,
      }
   },
};

export default createReducer(initialState)(reducersMap);
