import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Step from './step';
import BottomContent from './bottom-content';
import {
   getTwitterRedirectUrl,
} from 'admin/api/AuthApi';
import toster from 'common/utils/toast';
import Icon from 'common/components/elements/icons';
import cx from 'classnames';

const RECOMMENDED_SETTINGS = [
   { type: 'home', title: 'Homepage', description: 'Customize your homepage' },
   { type: 'logo', title: 'Logo', description: 'Add your logo' },
   { type: 'colors', title: 'Colors', description: 'Choose a color theme for your site' },
   { type: 'custom-page', title: 'Custom pages', description: 'Create custom pages' },
]

const OnboardingStepper = ({
   className,
   steps,
   status,
   thumbsUpEmoji,
   onStepClick,
   isModal,
   onCloseModal,
   submitOnboardingStep,
   isFetchingRevew,
   // onClickPreview,
   twitterAccount,
}) => {
   const [fetchingConnectedTwitterAccount, setFetchingConnectedTwitterAccount] = useState(false);

   let buttonContentRef = {
      description: 'Complete the steps to submit for review',
      linkButtonText: 'More info on site approval',
      intercomClassName: 'intercom-more-info-on-site-approval',
   }
   switch (status) {
      case 'ready_to_submit_for_review':
         buttonContentRef = {
            linkButtonText: 'More info on site approval',
            isSubmit: true,
            buttonText: 'Submit for review',
            loading: isFetchingRevew,
            intercomClassName: 'intercom-more-info-on-site-approval',
         }
         break;
      case 'ready_for_approval':
         buttonContentRef = {
            linkButtonText: 'More info on site approval',
            isSubmit: true,
            buttonText: 'Submit for approval',
            intercomClassName: 'intercom-more-info-on-site-approval',
         }
         break;
      case 'ready_to_launch':
         buttonContentRef = {
            linkButtonText: 'More info on site approval',
            isSubmit: true,
            buttonText: 'Launch my site',
            intercomClassName: 'intercom-more-info-on-site-approval',
         }
         break;
      case 'submitted_for_review':
         buttonContentRef = {
            description: 'Your site is being reviewed by our team',
            linkButtonText: 'More info on site approval',
            intercomClassName: 'intercom-more-info-on-site-approval',
         }
         break;
      case 'additional':
         buttonContentRef = {
            description: 'Complete the additional steps before resubmitting your site for review',
            linkButtonText: 'More info on site approval',
            intercomClassName: 'intercom-more-info-on-site-approval',
         }
         break;
      case 'submitted_to_payment_processors':
         buttonContentRef = {
            description: 'Your site is being reviewed by payment processors',
            linkButtonText: 'More info on site approval',
            intercomClassName: 'intercom-more-info-on-site-approval',
         }
         break;
      case 'ams_reviewing':
         buttonContentRef = {
            ...buttonContentRef,
            description: 'Your site is being reviewed by our team',
         }
         break;

      default:
         break;
   }


   const onConnectToTwitterAccount = async () => {
      const referrerUrl = {
         route_name: 'HOMEPAGE',
         url: window.location.href,
      }
      setFetchingConnectedTwitterAccount(true);
      try {
         const { data } = await getTwitterRedirectUrl();
         const { url } = data;
         if(referrerUrl) {
            localStorage.setItem('twitter', JSON.stringify(referrerUrl))
         }
         window.location.replace(url);
      } catch (error) {
         toster.error(' Something went wrong');
         setFetchingConnectedTwitterAccount(false);
      }
   }

   if(isModal) {
      return (
         <div
            className='w-full flex flex-col items-center'
         >
            <div className='w-full flex flex-col py-1 rounded-lg lg:border border-divider overflow-hidden'>
               {
                  steps.map((value, index) => {
                     className = '';
                     if(value.slug){
                        className = 'onboarding-step-' + value.slug.replace('_', '-');
                     }
                     return (
                        <Step
                           key={ value.id }
                           step={ value }
                           onStepClick={ () => onStepClick(value.slug) }
                           isModal
                        />
                     )
                  })
               }
            </div>
            <BottomContent
               { ...buttonContentRef }
               submitOnboardingStep={ submitOnboardingStep }
               className='px-6 lg:px-0 !mb-0'
            />
         </div>
      )
   }

   return (
      <>
         <div
            className='flex flex-col items-center h-full bg-panel lg:bg-transparent px-4 lg:px-6 py-6 lg:py-8'>
            <div className={ `lg:w-[550px] w-full relative flex flex-col ${ className }` }>
               <Wrapper
                  description={ buttonContentRef.description || '' }
                  title='Required steps'
                  isModal={ false }
               >
                  <div className='flex flex-col overflow-hidden mt-4'>
                     {
                        steps.map((value, index) => {
                           className = '';
                           if(value.slug){
                              className = 'onboarding-step-' + value.slug.replace('_', '-');
                           }
                           return (
                              <Step
                                 key={ value.id }
                                 step={ value }
                                 onStepClick={ () => onStepClick(value.slug) }
                                 className={ className }
                              />
                           )
                        })
                     }
                  </div>

               </Wrapper>
               <BottomContent
                  { ...buttonContentRef }
                  submitOnboardingStep={ submitOnboardingStep }
               />
               <Wrapper
                  canClose
                  description='The following steps are not required for approval, but highly recommended'
                  title='Recommended settings'
               >
                  <div className='flex flex-col mt-4'>
                     <span className='text-major font-medium leading-[24px] mb-2 lg:mx-6 mx-2'>Design your site</span>
                     {
                        RECOMMENDED_SETTINGS.map((item, index) => {
                           return (
                              <Step
                                 key={ index.toString() }
                                 step={ {
                                    title: item.title,
                                    description: item.description,
                                 } }
                                 onStepClick={  () => {
                                    onStepClick(item.type)
                                 } }
                                 recommended
                                 recommendedStateIcon={ item.type }
                              />

                           )
                        })
                     }

                  </div>
                  <div className='mt-4 flex flex-col'>
                     <span className='text-major font-medium leading-[24px] mb-2 lg:mx-6 mx-2'>Connect twitter account</span>
                     <Step
                        step={ {
                           title: 'Twitter',
                           description: 'Connect your Twitter account to send Auto post',
                        } }
                        onStepClick={  () => {
                           if(twitterAccount?.username) {
                              onStepClick('twitter-integration');
                           } else {
                              onConnectToTwitterAccount()
                           }
                        } }
                        isConnectTwitter
                        twitterUserName={ twitterAccount?.username }
                        isFetching={ fetchingConnectedTwitterAccount }

                     />

                  </div>
               </Wrapper>
            </div>
         </div>
      </>
   );
};

const Wrapper = ({
   children,
   title,
   description,
   canClose,
   isModal,
   className = '',
}) => {
   const [isOpen, setIsOpen] = useState(true)
   return (
      <div
         className={ cx({
            [`flex flex-col rounded-lg bg-panel ${ className }`]: true,
            'lg:border border-divider lg:py-6': !isModal,
            'pt-6 px-4 ': isModal,
         }) }
      >
         <div
            role='presentation'
            onClick={ e => {
               if(canClose){
                  setIsOpen(!isOpen)
               }
            } }
            className={ cx({
               'flex w-full justify-between lg:px-6 px-2': true,
               'cursor-pointer ': Boolean(canClose),
            }) }
         >
            <span className='text-major panel-header'>{title}</span>
            {
               canClose && (
                  <div className='h-6 w-6 flex items-center justify-center'>
                     <Icon
                        name={ isOpen ? 'arrow-up' : 'arrow-down' }
                        size='xl'
                        color='secondary'
                     />
                  </div>
               )
            }
         </div>
         {
            isOpen && (
               <>
                  {
                     description && (
                        <span className='panel-description text-secondary mt-1 lg:mx-6 mx-2'>{description}</span>
                     )
                  }
                  {children && children}
               </>
            )
         }
      </div>
   )
}

Wrapper.propTypes = {
   children: PropTypes.any,
   title: PropTypes.string,
   canClose: PropTypes.bool,
   description: PropTypes.string,
   isModal: PropTypes.bool,
   className: PropTypes.string,
};
OnboardingStepper.propTypes = {

   className: PropTypes.string,
   steps: PropTypes.array,
   status: PropTypes.string,
   thumbsUpEmoji: PropTypes.string,
   onStepClick: PropTypes.func,
   onCloseModal: PropTypes.func,
   submitOnboardingStep: PropTypes.func,
   percentage: PropTypes.any,
   isModal: PropTypes.bool,
   isFetchingRevew: PropTypes.bool,
   onClickPreview: PropTypes.func,
   twitterAccount: PropTypes.object,
};
OnboardingStepper.defaultProps = {
   className: '',
   steps: [],
   onStepClick: () => {},
   onCloseModal: () => {},
};

export default React.memo(OnboardingStepper);
