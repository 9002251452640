export const isLocalhost = () => {
   return Boolean(
      window.location.hostname === 'localhost'
        // [::1] is the IPv6 localhost address.
        || window.location.hostname === '[::1]'
        // 127.0.0.1/8 is considered localhost for IPv4.
        || window.location.hostname.match(
           /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
        || window.location.hostname.match(
           /^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
   );
};
export const showIsVrCheckbox = (appId) => {
   if(isLocalhost() || 'dashboard.amstest.net' === window.location.hostname)  {
      return true;
   }
   const uuids = [
      'e049a82709704088982539ecf5dcc2d7',
      '5e129ae99ee2407e8149a73430c9c6a6',
      '7d22eb391b589e119f91db57ed077567',
   ];
   return uuids.includes(appId);
};

export const isFriskybussiness = appKey => {
   return '87a72e653bfb4a83833b77b97ff50d6f' === appKey;
}
export const isZephaniah = appKey => {
   return 'e5972f60a2a24f2697fed1af2cbd1706' === appKey;
}
export const isModelpov = appKey => {
   return '2854a8dfb46dbd0f745347091ba4dcf6' === appKey;
}
export const isThekandikjewel = appKey => {
   return 'c6f4eaf927b7450dbdb0ba0eda68137b' === appKey;
}
export const isThecaitlinclark = appKey => {
   return '75ffda1c715243a195038cbc6d4d1434' === appKey;
}
export const isThecontainer = appKey => {
   return 'fc5bccaf92a5436ab7665f69ec5d0a68' === appKey;
}
export const isCultofangel = appKey => {
   return '73f2a145c0dd46eb8048c803f46eaa3a' === appKey;
}
export const isFriskyfairyk = appKey => {
   return 'c24f6cf31a6a4017b9bde5b49b7e2b2e' === appKey;
}
export const isSusyballoons = appKey => {
   return '4712718ae11d44319ab4af3e59563b33' === appKey;
}
export const isMynudistjourney = appKey => {
   return '8a6cd1f840544ca3b64040903e95f98e' === appKey;
}
export const isBrookesballoon = appKey => {
   return '0909744f207d4302a9f0dc0ee8a7b0df' === appKey;
}
export const isDesivega = appKey => {
   return '68aacf2da3214f6396f4544933aa2f2f' === appKey;
}
export const isChristy = appKey => {
   return 'e16d2318a99e4c9c8a29a1aba0ccb8a3' === appKey;
}
export const isKitehkawasaki = appKey => {
   return '8cae3a41c58949a9a44be183bfbeb779' === appKey;
}

export const isNewPayoutVersion = appId => {

   return true;
   // if(isLocalhost() || 'dashboard.amstest.net' === window.location.hostname)  {
   //    return true;
   // }
   // const uuids = [
   //    '7d22eb391b589e119f91db57ed077567',
   //    'f36613c0bfef4840bde6a4b9f145913b',
   //    'bb499c7800c241d08dfddd832171705a',
   //    '3c4596add1e1460083d14815c182c8f0',
   //    '296c6c8fe81e4cc8be4a93996a96fd29',
   // ];
   // if(uuids.includes(appId)) {
   //    return true;
   // }
   // return localStorage.getItem('payoutMode') === 'new';

}
export const showPreviousHistory = extendedMode => {
   return true;
   // return Boolean(extendedMode);
}
