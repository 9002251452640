import React, { useState } from 'react';
import Icon from 'common/components/elements/icons';
import PropTypes from 'prop-types';
import AddNewPhotosetCard from './add-new-photoset-card';
import {
   sortableContainer,
   sortableElement,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import './style.scss';

const SortableItem = sortableElement(({
   cover,
   photo,
   removePhoto,
   makeCover,
   setActiveCard,
   lockedImageIsBlur,
   onClickUpdate,
}) => {
   return (
      <AddNewPhotosetCard
         cover={ cover }
         className='z-10 photos-card-width'
         // active={ photo.id === activeCard }
         active={ true }
         selectCard={ (id) => setActiveCard(id) }
         photo={ photo }
         lockedImageIsBlur={ lockedImageIsBlur }
         removePhoto={ removePhoto }
         makeCover={ () => makeCover(photo) }
         onClickUpdate={ onClickUpdate }
      />
   )
}
);

const SortableContainer = sortableContainer(({ children }) => {
   return <>{children}</>;
});

const AddNewPhotoset = ({
   photos,
   activeCard,
   setActiveCard,
   fileUpload,
   removePhoto,
   cover,
   makeCover,
   // gotToContinue,
   onSortPhotosEnd,
   photosCount,
   isEditebl,
   lockedImageIsBlur,
   onClickUpdate,
   // className,
}) => {

   const [inputValue, setInputValue] = useState('')

   let inputFile = null;

   function handleFileChange(e) {
      fileUpload(e);
   }
   function onSortEnd({ oldIndex, newIndex }){
      const newData = arrayMoveImmutable(photos, oldIndex, newIndex)
      if(oldIndex !== newIndex){
         onSortPhotosEnd(newData)
      }
   }

   let photosCountText = `${ photosCount } photos`
   if(photosCount === 0){
      photosCountText = ' No photos'
   }
   if(photosCount === 1) {
      photosCountText = `1 photo`
   }
   return (
      <div className='relative pb-5'>
         <div className='flex flex-col px-5 -mt-1'>
            <span
               style={ { lineHeight: '28px' } }
               className='text-secondary text-2xl'
            >
               {photosCountText} </span>
            {/* {
               photosCount < 3 && (
                  <span
                     style={ { lineHeight: '18px' } }
                     className='text-sm text-placeholder block my-1'>Add at least { 3 - photosCount} photo{photosCount < 2 && 's'} to publish</span>
               )
            } */}
         </div>
         <div className='w-full flex flex-wrap'>
            <SortableContainer
               helperClass='sortable-helper'
               useDragHandle
               axis='xy'
               onSortEnd={ onSortEnd }
            >
               <div className='flex flex-wrap w-full'>
                  <div className='relative photos-card-width overflow-hidden p-3  rounded-lg h-auto'>
                     <div className='aspect-ratio-padding' />

                     <div
                        className='absolute top-0 right-0 h-full w-full bg-grey-main hover:bg-grey-hover'
                        onClick={ () => {
                           inputFile.click();
                        } }
                        role='presentation'
                     >
                        <div className='relative items-center justify-center cursor-pointer flex  flex-col w-full h-full'>
                           <div className='text-center'>
                              <input
                                 type='file'
                                 hidden
                                 multiple
                                 value={ inputValue }
                                 ref={ (input) => { inputFile = input; } }
                                 onChange={ (e) => {
                                    handleFileChange(e);
                                    setInputValue('')
                                 } }
                                 accept='image/png, image/jpeg, image/jpg, image'

                              />
                              <Icon name='upload' size='2xl' color='major' />
                              <p className='mt-2 font-medium text-base text-major'>Add photos</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  {
                     photos.map((photo, index) => {
                        return (
                           <SortableItem
                              onSortEnd={ onSortEnd }
                              useDragHandle
                              shouldUseDragHandle={ true }
                              key={ `item-${ index }` }
                              index={ index }
                              photo={ photo }
                              cover={ cover && cover.id === photo.id }
                              setActiveCard={ (id) => setActiveCard(id, photo.id === activeCard) }
                              removePhoto={ removePhoto }
                              makeCover={ () => makeCover(photo) }
                              lockedImageIsBlur={ lockedImageIsBlur }
                              onClickUpdate={ onClickUpdate }
                           />
                        );
                     })
                  }
               </div>
            </SortableContainer>
         </div>
         <div className='h-10' />

      </div>
   );
};

AddNewPhotoset.propTypes = {
   photos: PropTypes.array,
   activeCard: PropTypes.any,
   setActiveCard: PropTypes.func,
   fileUpload: PropTypes.func,
   removePhoto: PropTypes.func,
   makeCover: PropTypes.func,
   // gotToContinue: PropTypes.func,
   onSortPhotosEnd: PropTypes.func,
   cover: PropTypes.any,
   photosCount: PropTypes.number,
   isEditebl: PropTypes.bool,
   lockedImageIsBlur: PropTypes.bool,
   onClickUpdate: PropTypes.func,
};

AddNewPhotoset.defaultProps = {
   photos: [],
   isEditebl: false,
   fileUpload: () => {},
};

export default React.memo(AddNewPhotoset);
