import React from 'react';
import { createRoot } from 'react-dom/client';
import store, { history } from "admin/state/store";
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Routes from 'admin/routes';
import bootsrap from 'admin/bootsrap';
import 'assets/css/index.scss';

// Styles start
import 'assets/fonts/icons/style.css';
import 'assets/css/video-style.scss';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { screenResize } from 'admin/state/modules/common/actions';
// Styles end

bootsrap();

window.addEventListener('resize', () => {
   store.dispatch(screenResize(window.innerWidth));
});

const app = document.getElementById('root');
const root = createRoot(app);


root.render(
   <Provider store={ store }>
      <ConnectedRouter history={ history }>
         <>
            <Routes />
         </>
      </ConnectedRouter>
   </Provider>);
