import {
   notificationsInit,
   getStoryCommentById,
   sendStoryComment,
   markAllAsSeenNotifications,
   markAllAsReadNotifications,
   getChatMessage,
} from 'admin/api/AuthApi';
import {
   sendCommentCompleted,
} from 'admin/state/modules/comments/actions';
import * as actions from "./actions";
import toast from "common/utils/toast";

// start Notifications
export const fetchNotificationsOperation = (query = []) => {
   return async (dispatch) => {
      dispatch(actions.fetchNotificationsRequest());
      try {
         let { data } =  await notificationsInit(query);
         dispatch(actions.fetchNotificationsCompleted({ data }))
      } catch (error) {
         if(error.response) {
            dispatch(actions.fetchNotificationsFailed(error.response))
         }
      }
   };
};

export const fetchNewNotificationsOperation = (query = []) => {
   return async (dispatch) => {
      dispatch(actions.fetchNewNotificationsRequest());
      try {
         let { data } =  await notificationsInit(query);
         dispatch(actions.fetchNewNotificationsCompleted(data))
      } catch (error) {
         if(error.response) {
            dispatch(actions.fetchNewNotificationsFailed(error.response))
         }
      }
   };
};

export const fetchNotificationsByFilterOperation = (query = []) => {
   return async (dispatch) => {
      dispatch(actions.fetchNotificationsByFilterRequest());
      try {
         let { data } =  await notificationsInit(query);
         dispatch(actions.fetchNotificationsByFilterCompleted(data))
      } catch (error) {
         if(error.response) {
            dispatch(actions.fetchNotificationsByFilterFailed(error.response))
         }
      }
   };
};

export const fetchReplyCommentOperation = ({ resourceId, commentId, type  }) => {
   return async (dispatch) => {
      dispatch(actions.fetchReplyCommentRequest());
      try {
         let { data } =  await getStoryCommentById(resourceId, commentId, type);
         dispatch(actions.fetchReplyCommentCompleted(data))
      } catch (error) {
         if(error?.response?.status === 404) {
            toast.error('This comment already deleted');
         }
         dispatch(actions.fetchReplyCommentFailed(error.response))
      }
   };
};

export const fetchReplyChatMessageOperation = (messageId) => {
   return async (dispatch) => {
      dispatch(actions.fetchReplyChatMessageRequest());
      try {
         let { data } =  await getChatMessage(messageId);
         dispatch(actions.fetchReplyChatMessageCompleted(data))
      } catch (error) {
         if(error?.response?.status === 404) {
            toast.error('This chat message already deleted');
         }
         dispatch(actions.fetchReplyChatMessageFailed())
      }
   };
};

export const sendCommentOperation = (data, replyMessage, resourceType, type) => {
   return async (dispatch) => {
      dispatch(actions.sendCommentRequest());
      try {
         const response = await sendStoryComment(data, resourceType);
         toast.info('Your comment has been posted!');
         dispatch(actions.sendCommentCompleted(response.data, replyMessage, type))
         dispatch(sendCommentCompleted(response.data))
      } catch (error) {
         dispatch(actions.sendCommentFailed(error))
      }
   };
};

export const markAllAsSeenNotificationsOperations = () => {
   return async (dispatch) => {
      try {
         await markAllAsSeenNotifications();
         dispatch(actions.markAllAsSeenNotificationsCompleted())
      } catch (error) {
      }
   };
};

export const markAllAsReadNotificationsOperations = (id = null) => {
   return async (dispatch) => {
      try {
         await markAllAsReadNotifications(id);
         dispatch(actions.markAllAsReadNotificationsCompleted(id))
      } catch (error) {
      }
   };
};


// end Notifications

