import * as types from "./types";

//get member data /
const fetchDetailsStart = () => ({
   type: types.FETCH_DETAILS_START,
});

const fetchDetailsCompleted = (data, ids, isEmptyFilters) => {
   return {
      type: types.FETCH_DETAILS_COMPLETED,
      payload: { membersData: data, ids, isEmptyFilters },
   }
};

const fetchDetailsFailed = (err) => ({
   type: types.FETCH_DETAILS_FAILED,
   payload: err,
});

// get next page /
const newFetchRequest = () => ({
   type: types.NEW_FETCH_REQUEST,
});

const newFetchRequestCompleted = (data) => ({
   type: types.NEW_FETCH_DETAILS_COMPLETED,
   payload: { membersData: data },
});

const newFetchRequestFailed = error => ({
   type: types.NEW_FETCH_DETAILS_FAILED,
   payload: {
      error,
   },
});

//get member /
const fetchMemberStart = () => ({
   type: types.FETCH_MEMBER_REQUEST,
});

const fetchMemberCompleted = (data) => ({
   type: types.FETCH_MEMBER_COMPLETED,
   payload: data,
});

const fetchMemberFailed = (err) => ({
   type: types.FETCH_MEMBER_FAILED,
   payload: err,
})

//get filtered members /
const filterMembers = (bool = true) => ({
   type: types.FILTER_MEMBERS_REQUEST,
   payload: bool,
});

const filterMembersCompleted = (data, idsToExcept) => ({
   type: types.FILTER_MEMBERS_COMPLETED,
   payload: { membersData: data, idsToExcept },
});

const filterMembersFailed = (err) => ({
   type: types.FILTER_MEMBERS_FAILED,
   payload: err,
})

//get member statistics /
const statisticsRequest = () => ({
   type: types.FETCH_STATISTICS_REQUEST,
});

const statisticsRequestCompleted = data => ({
   type: types.FETCH_STATISTICS_COMPLETED,
   payload: data,
});

const statisticsRequestFailed = error => ({
   type: types.FETCH_STATISTICS_FAILED,
   payload: {
      error,
   },
});

//get membership data /
const fetchMembershipsStart = () => ({
   type: types.FETCH_MEMBERSHIPS_REQUEST,
})
const fetchMembershipsCompleted = (data) => ({
   type: types.FETCH_MEMBERSHIPS_COMPLETED,
   payload: data,
})
const fetchMembershipsFailed = (error) => ({
   type: types.FETCH_MEMBERSHIPS_FAILED,
   payload: {
      error,
   },
})

//set free member to add id /
const setFreeMemberId = (id) => ({
   type: types.SET_FREE_MEMBER_TO_ADD_ID,
   payload: id,
})

//set free member to add membership /
const setFreeMemberMembership = (membership) => ({
   type: types.SET_FREE_MEMBER_TO_ADD_MEMBERSHIP,
   payload: membership,
})

// add free member
const addFreeMemberRequest = () => ({
   type: types.ADD_FREE_MEMBER_REQUEST,
})
const addFreeMemberCompleted = () => ({
   type: types.ADD_FREE_MEMBER_COMPLETED,
})
const addFreeMemberFailed = (error) => ({
   type: types.ADD_FREE_MEMBER_FAILED,
   payload: {
      error,
   },
})

//add new person
const addNewPersonRequest = () => ({
   type: types.ADD_NEW_PERSON_REQUEST,
})
const addNewPersonCompleted = (member) => ({
   type: types.ADD_NEW_PERSON_COMPLETED,
   payload: member,
})
const addNewPersonFailed = (errors) => ({
   type: types.ADD_NEW_PERSON_FAILED,
   payload: {
      errors,
   },
})
const updateMembersRequestCompleted = data => ({
   type: types.UPDATE_MEMBERS_COMPLETED,
   payload: data,
})
const updateMemberByUuid = (uuid, data) => ({
   type: types.UPDATE_MEMBERS_BY_UUID,
   payload: { uuid, data },
})
const updateMultipleMemberByUuids = (uuids, isSelectAll, data) => ({
   type: types.UPDATE_MULTIPLE_MEMBERS_BY_UUIDS,
   payload: { uuids, isSelectAll, data },
})
const updateMemberFailed = error => ({
   type: types.UPDATE_MEMBERS_FAILED,
   payload: error,
})
const setMemberUsernameModal = bool => ({
   type: types.SET_MEMBER_USERNAME_MODAL,
   payload: bool,
})

const removeTagToMember = (data) => ({
   type: types.REMOVE_TAG_TO_MEMBER,
   payload: data,
})
const changeTagToMember = (data) => ({
   type: types.CHANGE_TAG_TO_MEMBER,
   payload: data,
})


//get member wallet history /
const fetchMemberWalletHistoryStart = () => ({
   type: types.FETCH_MEMBER_WALLET_HISTORY_START,
});

const fetchMemberWalletHistoryCompleted = (data) => {
   return {
      type: types.FETCH_MEMBER_WALLET_HISTORY_COMPLETED,
      payload: data,
   }
};

const fetchMemberWalletHistoryFailed = (err) => ({
   type: types.FETCH_MEMBER_WALLET_HISTORY_FAILED,
   payload: err,
});

//get member wallet history /
const newFetchMemberWalletHistoryStart = () => ({
   type: types.NEW_FETCH_MEMBER_WALLET_HISTORY_START,
});

const newFetchMemberWalletHistoryCompleted = (data) => {
   return {
      type: types.NEW_FETCH_MEMBER_WALLET_HISTORY_COMPLETED,
      payload: data,
   }
};

const newFetchMemberWalletHistoryFailed = (err) => ({
   type: types.NEW_FETCH_MEMBER_WALLET_HISTORY_FAILED,
   payload: err,
});

//get member tags /
const fetchMemberTagsStart = () => ({
   type: types.FETCH_MEMBER_TAGS_START,
});

const fetchMemberTagsCompleted = (data) => {
   return {
      type: types.FETCH_MEMBER_TAGS_COMPLETED,
      payload: data,
   }
};

const fetchMemberTagsFailed = (err) => ({
   type: types.FETCH_MEMBER_TAGS_FAILED,
   payload: err,
});

const setActiveMemberData = (data) => ({
   type: types.SET_ACTIVE_MEMBER,
   payload: { data },
});

// add member note
const addNoteStart = () => ({
   type: types.ADD_NOTE_START,
});
const addNoteCompleted = (data) => ({
   type: types.ADD_NOTE_COMPLETED,
   payload: data,
});
const addNoteFailed = (err) => ({
   type: types.ADD_NOTE_FAILED,
   payload: err,
});
// delete member note
const deleteNoteStart = () => ({
   type: types.DELETE_NOTE_START,
});
const deleteNoteCompleted = (data) => ({
   type: types.DELETE_NOTE_COMPLETED,
   payload: data,
});
const deleteNoteFailed = (err) => ({
   type: types.DELETE_NOTE_FAILED,
   payload: err,
});
// update member note
const updateNoteStart = () => ({
   type: types.UPDATE_NOTE_START,
});
const updateNoteCompleted = (data) => ({
   type: types.UPDATE_NOTE_COMPLETED,
   payload: data,
});
const updateNoteFailed = (err) => ({
   type: types.UPDATE_NOTE_FAILED,
   payload: err,
});


export {
   fetchDetailsStart,
   fetchDetailsFailed,
   fetchDetailsCompleted,

   fetchMemberStart,
   fetchMemberCompleted,
   fetchMemberFailed,

   filterMembers,
   filterMembersCompleted,
   filterMembersFailed,

   statisticsRequest,
   statisticsRequestCompleted,
   statisticsRequestFailed,

   fetchMembershipsStart,
   fetchMembershipsCompleted,
   fetchMembershipsFailed,

   newFetchRequest,
   newFetchRequestCompleted,
   newFetchRequestFailed,

   setFreeMemberId,
   setFreeMemberMembership,

   addFreeMemberRequest,
   addFreeMemberCompleted,
   addFreeMemberFailed,

   addNewPersonRequest,
   addNewPersonCompleted,
   addNewPersonFailed,

   updateMembersRequestCompleted,
   updateMemberByUuid,
   updateMultipleMemberByUuids,
   updateMemberFailed,
   setMemberUsernameModal,

   removeTagToMember,
   changeTagToMember,

   fetchMemberWalletHistoryStart,
   fetchMemberWalletHistoryCompleted,
   fetchMemberWalletHistoryFailed,

   newFetchMemberWalletHistoryStart,
   newFetchMemberWalletHistoryCompleted,
   newFetchMemberWalletHistoryFailed,

   fetchMemberTagsStart,
   fetchMemberTagsCompleted,
   fetchMemberTagsFailed,

   setActiveMemberData,

   // notes
   addNoteStart,
   addNoteCompleted,
   addNoteFailed,
   deleteNoteStart,
   deleteNoteCompleted,
   deleteNoteFailed,
   updateNoteStart,
   updateNoteCompleted,
   updateNoteFailed,
};
