import { createSelector } from 'reselect'

const innerStateSelector = state => state.livestream

const scheduleDataSelector = createSelector(
   innerStateSelector,
   (state) => (state.scheduledData)
);
const currentDataSelector = createSelector(
   innerStateSelector,
   (state) => (state.currentData)
);
const savedDataSelector = createSelector(
   innerStateSelector,
   (state) => (state.savedData)
);
const isFetchingSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetching)
);
const recordedStreamsSelector = createSelector(
   innerStateSelector,
   (state) => (state.recordedStreams)
);
const isFetchingRecordFilterSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchingRecordFilter)
);
const isFetchingRecordSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchingRecord)
);
const isEmptyByFilterRecordSelector = createSelector(
   innerStateSelector,
   (state) => (state.isEmptyByFilterRecord)
);
const isEmptyByRecordSelector = createSelector(
   innerStateSelector,
   (state) => (state.isEmptyByRecord)
);
const isNewFetchingRecordsSelector = createSelector(
   innerStateSelector,
   (state) => (state.isNewFetchingRecords)
);
const isFetchingScheduleSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchingSchedule)
);
const isFetchingCreateScheduleSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchingCreateSchedule)
);
const updateErrorsDataSelector = createSelector(
   innerStateSelector,
   (state) => (state.updateErrorsData)
);
const isFetchingStartStreamSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchingStartStream)
);
const initDataSelector = createSelector(
   innerStateSelector,
   (state) => (state.initData)
);
const ivsSelector = createSelector(
   innerStateSelector,
   (state) => (state.ivs)
);
const openStreamStateModalSelector = createSelector(
   innerStateSelector,
   (state) => (state.openStreamStateModal)
);

const streamConversationSelector = createSelector(
   innerStateSelector,
   (state) => (state.streamConversation)
);

const isFetchingMessagesSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchingMessages)
);
const messageDataSelector = createSelector(
   innerStateSelector,
   (state) => (state.messageData)
);
const conversationSatetSelector = createSelector(
   innerStateSelector,
   (state) => (state.conversationSatet)
);
const isConnectedSelector = createSelector(
   innerStateSelector,
   (state) => (state.isConnected)
);
const onlineUsersSelector = createSelector(
   innerStateSelector,
   (state) => (state.onlineUsers)
);
const isFetchNewConversationMessagesSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchNewConversationMessages)
);
//////////////
const likedMemberByMessageSelector = createSelector(
   innerStateSelector,
   (state) => {
      let { likedMemberByMessage, onlineUsers } = state

      let data = likedMemberByMessage.data
      if(data) {
         data = [...data].map(i => {
            const { ...elm } = i
            elm.is_online = !!onlineUsers.find(user => user.uuid === elm.uuid)
            return elm
         })
      }
      return { ...likedMemberByMessage, data }
   }
);

const tipGoalAchievedModalSelector = createSelector(
   innerStateSelector,
   (state) => (state.tipGoalAchievedModal)
);
const tipGoalActionsAchievedModalSelector = createSelector(
   innerStateSelector,
   (state) => (state.tipGoalActionsAchievedModal)
);

const tipHistorySelector = createSelector(
   innerStateSelector,
   (state) => (state.tipHistory)
);
const activeViewersSelector = createSelector(
   innerStateSelector,
   (state) => (state.activeViewers)
);
const activeViewersFilterSelector = createSelector(
   innerStateSelector,
   (state) => (state.activeViewersFilter)
);
const deleteRecordButtonDisabledSelector = createSelector(
   innerStateSelector,
   (state) => (state.deleteRecordButtonDisabled)
);
const warningActivityModalSelector = createSelector(
   innerStateSelector,
   (state) => (state.warningActivityModal)
);
const isFetchingEndStreamSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchingEndStream)
);
const deleteUpcomingStateSelector = createSelector(
   innerStateSelector,
   (state) => (state.deleteUpcomingState)
);
const validationErrorsSelector = createSelector(
   innerStateSelector,
   (state) => (state.validationErrors)
);
const fetchCheckValidationSelector = createSelector(
   innerStateSelector,
   (state) => (state.fetchCheckValidation)
);
const fetchCreateLivestreamSelector = createSelector(
   innerStateSelector,
   (state) => (state.fetchCreateLivestream)
);

const unreadMessagesCountSelector = (state) => {
   let groupUnreadCount = 0
   let streamUnreadCount = 0
   const { groupConversation, unreadPrivateMessagesCount } = state.chat
   const { streamConversation } = state.livestream
   if(groupConversation &&  'number' === typeof groupConversation.unread_messages_count){
      groupUnreadCount = groupConversation.unread_messages_count
   }
   if(streamConversation &&  'number' === typeof streamConversation.unread_messages_count){
      streamUnreadCount = streamConversation.unread_messages_count
   }
   let count = unreadPrivateMessagesCount || 0;
   count = count + groupUnreadCount + streamUnreadCount;
   if(count > 100){
      count = '99+';
   }
   if(count < 0) count = 0;
   return count
};

const warningModalSelector = createSelector(
   innerStateSelector,
   state => state.warningModal
);


export {
   scheduleDataSelector,
   isFetchingSelector,
   recordedStreamsSelector,
   isFetchingRecordFilterSelector,
   isEmptyByFilterRecordSelector,
   isFetchingRecordSelector,
   isEmptyByRecordSelector,
   isNewFetchingRecordsSelector,
   isFetchingScheduleSelector,
   isFetchingCreateScheduleSelector,
   updateErrorsDataSelector,
   isFetchingStartStreamSelector,

   initDataSelector,
   ivsSelector,
   openStreamStateModalSelector,
   streamConversationSelector,

   messageDataSelector,
   isFetchingMessagesSelector,
   conversationSatetSelector,
   isConnectedSelector,
   onlineUsersSelector,
   likedMemberByMessageSelector,
   isFetchNewConversationMessagesSelector,
   currentDataSelector,
   tipGoalAchievedModalSelector,
   tipGoalActionsAchievedModalSelector,
   tipHistorySelector,
   activeViewersSelector,
   activeViewersFilterSelector,
   deleteRecordButtonDisabledSelector,
   warningActivityModalSelector,

   isFetchingEndStreamSelector,
   deleteUpcomingStateSelector,
   unreadMessagesCountSelector,
   savedDataSelector,
   validationErrorsSelector,
   fetchCheckValidationSelector,
   fetchCreateLivestreamSelector,
   warningModalSelector,
}
