import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
   deleteVault,
   cancelUploadOperation,

   fetchVaultVideosOperation,
   getFilterVideosByOperation,
   getNewVaultVideosOperation,

   fetchVaultImagesOperation,
   getFilterImagesByOperation,
   getNewVaultImagesOperation,
   fetchAllVaultOperation,
   getFilterAllVaultByOperation,
   getNewVaultsOperation,
} from 'admin/state/modules/vault/operations';
import { startUploadOperation } from 'admin/state/modules/vault/operations';
import {
   // videos
   vaultVideosDataSelector,
   isEmptyVideosSelector,
   isFetchingVideosSelector,
   isFetchingVideosByFilterSelector,
   isEmptyVideosByFilterSelector,
   isNewFetchingVideosSelector,
   // images
   vaultImagesDataSelector,
   isEmptyImagesSelector,
   isFetchingImagesSelector,
   isFetchingImagesByFilterSelector,
   isEmptyImagesByFilterSelector,
   isNewFetchingImagesSelector,
   allVaultDataSelector,
   isFetchingAllVaultSelector,
   isEmptyAllVaultSelector,
   isEmptyAllVaultByFilterSelector,
   isFetchingAllVaultByFilterSelector,
   isNewfetchingAllVaultsSelector,
   vaultsIntedStateSelector,
} from 'admin/state/modules/vault/selectors';
import {
   uploadPhotos,
   updateVideo,
   setInsideModalAction,
   deleteCompleted,
   addVideos as addVideosAction,
   updateBundleStateInData,
   updateVaultMessageAction,
   clearStateAction,
} from 'admin/state/modules/vault/actions';
import PullRefresh from 'admin/utils/PullRefresh.js';
import ModalUrlHelper from 'common/utils/ModalUrlHelper.js';
import QueryParams from 'admin/utils/QueryParams.js'
import PullToRefresh from 'pulltorefreshjs';
import toast from 'common/utils/toast';
import { screenWidthSelector, siteInfoSelector } from 'admin/state/modules/common/selectors';
import Vault from 'admin/views/vault';
import DeleteModal from 'admin/views/vault/modalDelete'
import { makeRandomString } from 'common/utils/utils';
import { uploadPhotosetsImage, createVault, checkVaultByHash,
   // , getVaultById
} from 'admin/api/AuthApi';
import { cancelUplaod } from 'admin/utils/VaultVideoUpload'
import { getImageFileHash, getVideoFileHash } from 'common/utils/utils'
import {
   mediaDownloadButtonSelector,
} from 'admin/state/modules/resources/selectors';
import { downloadMediaOperation } from 'admin/state/modules/resources/operations';
import ConfirmModal from 'common/components/modules/modal-wrapper';
import { generateUriSegment } from 'common/utils/mediaRouteHelper';
import Router from 'admin/routes/router';
import { getAllowedVideoExtentions } from 'common/utils/utils';
class StoriesContainer extends Component {
   static propTypes = {
      type: PropTypes.string,
      data: PropTypes.object,
      uploadPhotos: PropTypes.func,
      deleteVault: PropTypes.func,
      removeFile: PropTypes.func,
      screenWidth: PropTypes.number,
      isInsideModal: PropTypes.bool,
      handleSelectCard: PropTypes.func,
      updateVideo: PropTypes.func,
      startUpload: PropTypes.func,
      selected: PropTypes.array,

      // videos
      fetchVideosData: PropTypes.func,
      getFilterVideosBy: PropTypes.func,
      getNewVaultVideos: PropTypes.func,
      setInsideModal: PropTypes.func,
      vaultVideosData: PropTypes.object,
      isFetchingVideos: PropTypes.bool,
      isEmptyVideos: PropTypes.bool,
      isEmptyVideosByFilter: PropTypes.bool,
      isFetchingVideosByFilter: PropTypes.bool,
      isNewFetchingVideos: PropTypes.bool,
      mediaDownloadButton: PropTypes.bool,
      downloadFile: PropTypes.func,
      // images
      fetchImagesData: PropTypes.func,
      getFilterImagesBy: PropTypes.func,
      getNewVaultImages: PropTypes.func,
      vaultImagesData: PropTypes.object,
      isEmptyImages: PropTypes.bool,
      isFetchingImages: PropTypes.bool,
      isEmptyImagesByFilter: PropTypes.bool,
      isFetchingImagesByFilter: PropTypes.bool,
      isNewFetchingImages: PropTypes.bool,
      deleteCompleted: PropTypes.func,
      addVideosAction: PropTypes.func,
      // all vault
      showNewStyles: PropTypes.bool,
      allVaultData: PropTypes.array,
      isFetchingAllVault: PropTypes.bool,
      isEmptyAllVault: PropTypes.bool,
      isEmptyAllVaultByFilter: PropTypes.bool,
      isFetchingAllVaultByFilter: PropTypes.bool,
      fetchAllVault: PropTypes.func,
      getFilterAllVault: PropTypes.func,
      isNewfetchingAllVaults: PropTypes.bool,
      getNewVaults: PropTypes.func,
      prevetInsideModalDiscard: PropTypes.func,
      // updateBundleStateInData: PropTypes.func,
      bundleId: PropTypes.any,
      contentType: PropTypes.string,
      onDeleteMessag: PropTypes.func,
      updateMessageUnlockDetails: PropTypes.func,
      updateVaultMessageAction: PropTypes.func,
      conversationId: PropTypes.any,

      isMultipleSelect: PropTypes.bool,

      isMultipleAttachments: PropTypes.bool,
      siteInfo: PropTypes.object,
      vaultsIntedState: PropTypes.object,
      clearState: PropTypes.func,

      isMaxAttachmentCountReached: PropTypes.bool,
   };

   constructor(props) {
      super(props);
      this.state = {
         vaultData: [],
         pageType: props.type ? props.type : 'image',
         onCloseOptionModal: true,
         openDeleteModal: false,
         deletedId: null,
         deletedType: 'delete',
         errors: {},
         isUploadVideoState: false,
         uploadingProgress: 0,
         compressionStatus: 'none',
         video: {},
         isCompleted: false,
         id: null,
         videoID: null,
         removingVideo: {},
         hashInfoState: [],
         warningModal: {
            isOpen: false,
            description: null,
            ids: null,
            multiple_bundles: null,
         },
      };
      this.filter = {
         count: 20,
         page: 1,
         type: 'image',
      };
      this.fileUploader = React.createRef();
      this.alreadyFetched = false
   }

   componentDidMount() {
      const {
         type,
         isInsideModal,
         fetchImagesData,
         fetchVideosData,
         getFilterVideosBy,
         getFilterImagesBy,
         fetchAllVault,
         conversationId,
         vaultsIntedState: {
            vaultVideosIsInited,
            vaultImagesIsInited,
            allVaultIsInited,
         },
      } = this.props;
      if(isInsideModal){
         let idByType = `#select-from-${ type === 'image' ? 'photo' : 'video' }_vaults`
         if(type === 'all_vault') {
            idByType = '#select-from-vaults'
         }
         let element = document.querySelector(idByType)
         if(element) element.addEventListener('scroll', this.handleScroll);
      } else {
         window.addEventListener('scroll', this.handleScroll);
      }
      window.addEventListener('popstate', this.onPropsState);
      let pageType = 'image';
      if(isInsideModal){
         pageType = type;
         if(conversationId){
            this.filter = {
               ...this.filter,
               conversation: conversationId,
            }
         }
      }
      PullToRefresh.init({
         ...PullRefresh.init(),
         onRefresh: (done) => {
            this.filter = { ...this.filter, page: 1, type: pageType };
            const param = this.generateQuery()
            if(param){
               if(pageType === 'video') {
                  getFilterVideosBy(param)
               } else {
                  getFilterImagesBy(param)
               }
               done();
            } else {
               if(pageType === 'video') {
                  getFilterVideosBy()
               } else {
                  getFilterImagesBy()
               }
               done();
            }
            window.isPullRefresh  = false
         },
      });
      let params = QueryParams.getParsedSearchParams();
      this.filter = { ...this.filter, type: pageType };
      if(params.type && !isInsideModal) {
         pageType = params.type;
         this.filter = { ...this.filter, type: pageType };
         this.setState({ pageType: pageType });
      }
      if(type === 'video' && isInsideModal) {
         this.filter = { ...this.filter, status: 'completed' };
      }
      const param = this.generateQuery();
      if(pageType === 'video'){
         if(!vaultVideosIsInited) {
            fetchVideosData(param, isInsideModal);
         }
      } else {
         if(pageType === 'all_vault') {
            if(!allVaultIsInited) {
               fetchAllVault(param)
            }
         } else {
            if(!vaultImagesIsInited) {
               fetchImagesData(param);
            }
         }
      }
      window.addEventListener('content-update', this.bindSocketEvents);
   }

   componentWillUnmount() {
      const {
         type,
         clearState,
         isInsideModal,
         setInsideModal,
         isMultipleAttachments,
      } = this.props

      window.isPullRefresh  = false
      window.removeEventListener('content-update', this.bindSocketEvents);
      PullRefresh.destroyAll();
      setInsideModal(false);

      if(!isMultipleAttachments) {
         clearState({
            vaultVideosIsInited: false,
            vaultImagesIsInited: false,
            allVaultIsInited: false,
         })
      }

      if(isInsideModal){
         let elementId = type === 'all_vault' ? '#select-from-vaults' : `#select-from-${ type === 'image' ? 'photo' : 'video' }_vaults`
         let element = document.querySelector(elementId)
         if(element) element.removeEventListener('scroll', this.handleScroll);
      } else {
         window.removeEventListener('scroll', this.handleScroll);
      }
   }

   componentDidUpdate(){
      const { isFetchingAllVault, prevetInsideModalDiscard, isInsideModal } = this.props

      if(!!prevetInsideModalDiscard && isInsideModal) {
         prevetInsideModalDiscard(isFetchingAllVault)
      }
   }


   bindSocketEvents = e => {
      const { detail: { payload } } = e
      const video = payload
      const { updateVideo } = this.props;
      const { id, socket_emit_type } = video;
      if('vault_upload' === socket_emit_type) {
         updateVideo(+id, { ...video, id: +video.id });
      }
   }

   onFileChange = async e =>  {
      const { uploadPhotos } = this.props;
      const targetFiles = e.target.files
      let res = [];
      let filesData = [];
      let fackData = [];
      let hashs = []
      for(let index = 0; index < Object.values(targetFiles).length; index++) {
         const file = Object.values(targetFiles)[index];
         const type = file.name.split('.');
         const validFormat = ['jpeg', 'jpg', 'png'];
         const isValid = validFormat.indexOf(type[type.length - 1].toLowerCase()) !== -1 && type.length > 1;
         if(isValid) {
            await getImageFileHash(file).then(
               function(result) {
                  fackData.push({ uploadingStatus: 'loading', id: makeRandomString(8), isFack: true, hash: result, file });
                  filesData.push(file);
                  hashs.push(result)
               },
               function(err) {
               });
         } else {
            toast.error('You may upload only photo files');
         }
      }
      uploadPhotos(fackData, 'loading')
      const { data } = await checkVaultByHash('image', [...hashs])
      let sortItems = data.reduce((acc, curr) => {
         let value =  Object.values(curr)[0]
         let hash =  Object.keys(curr)[0]
         if(value) {
            const { deleteCompleted, vaultImagesData } = this.props
            if(vaultImagesData && vaultImagesData.data && vaultImagesData.data.find(elm => elm.id === value.id)) {
               deleteCompleted(value.id, 'image')
            }
            uploadPhotos({ ...value }, 'update', hash)
         } else {
            acc.push(curr)
         }
         return acc
      }, []);

      for(let index = 0; index < sortItems.length; index++) {
         const element = sortItems[index];
         let hash =  Object.keys(element)[0]
         let current = fackData.find(el => el.hash === hash) || {}
         const data = new FormData();
         data.append('file', current.file || {});

         try {
            const { data: { path } } =  await uploadPhotosetsImage(data);
            uploadPhotos({ ...current, src: path }, 'update')
            const photo = {
               hash: hash,
               type: 'image',
               src: path,
            }
            res.push(photo)

         } catch (error) {
            uploadPhotos({ ...current,  uploadingStatus: 'error' }, 'update')
         }
      }
      if(res.length !== 0){
         try {
            const { data } = await createVault({ data: res })
            uploadPhotos(data, 'done')

         } catch (error) {

         }
      }

      // this.fileUploader.current.value = '';
   }

   handleFileChange = () => {
      this.fileUploader.current.click();
   };

   onPropsState = () => {
      ModalUrlHelper.resetAction();
   }

   handleScroll = (e) => {
      const { isInsideModal } = this.props
      const { scrollHeight, clientHeight, scrollTop } = !!isInsideModal ? e.target : document.documentElement;
      if(((scrollHeight - scrollTop) - clientHeight < 100)) {
         this.nextPage()
      }
   }

   nextPage = async () => {
      const {
         getNewVaultVideos,
         getNewVaultImages,
         isNewFetchingVideos,
         isNewFetchingImages,
         vaultVideosData: {
            current_page: currentVideosPage,
            last_page: videosPages,
         },
         vaultImagesData: {
            current_page: currentImagesPage,
            last_page: imagesPages,
         },
         allVaultData: {
            current_page: currentAllVaultsPage,
            last_page: AllVaultsPages,
         },
         getNewVaults,
         isNewfetchingAllVaults,
      } = this.props;
      const { pageType } = this.state;
      let query = [];
      if(pageType === 'video') {
         if(currentVideosPage < videosPages && !isNewFetchingVideos) {
            this.filter = { ...this.filter, page: currentVideosPage + 1 };
            query = this.generateQuery();
            await getNewVaultVideos(query);
         } else {
            return
         }
      } else {
         if(pageType === 'all_vault') {
            if(currentAllVaultsPage < AllVaultsPages && !isNewfetchingAllVaults) {
               this.filter = { ...this.filter, page: currentAllVaultsPage + 1 };
               query = this.generateQuery();
               await getNewVaults(query);
            } else {
               return
            }
         } else {
            if(currentImagesPage < imagesPages && !isNewFetchingImages) {
               this.filter = { ...this.filter, page: currentImagesPage + 1 };
               query = this.generateQuery();
               await getNewVaultImages(query);
            } else {
               return
            }
         }
      }
   }

   onFilterChange = (value) => {
      const { getFilterVideosBy, getFilterImagesBy, getFilterAllVault } = this.props;
      const { pageType } = this.state;
      this.filter = {
         ...this.filter,
         sortBy: value,
         page: 1,
      }
      const param = this.generateQuery();
      if(pageType === 'video'){
         getFilterVideosBy(param)
      } else {
         if(pageType === 'all_vault') {
            getFilterAllVault(param)
         } else {
            getFilterImagesBy(param)
         }
      }
   };

   onFilterByType = (type) => {
      const { getFilterAllVault } = this.props;
      let filteredTypes = ''
      if(type?.length > 1) {
         filteredTypes = 'all_vault'
      }
      this.filter = {
         ...this.filter,
         type: !!filteredTypes ? filteredTypes : type,
         page: 1,
      }
      const param = this.generateQuery();
      getFilterAllVault(param)
   }

   generateQuery = () => {
      const query = [];
      Object.keys(this.filter).forEach((key) => {
         if(key){
            if(this.filter[key]) {
               key !== 'count' && key !== 'page' && key !== 'sortBy' && key !== 'conversation' ? query.push(`query[]=${ key }=${ this.filter[key] }`) : query.push(`${ key }=${ this.filter[key] }`);
            }
         }
      });
      return query;
   };

   // openDeleteConfirmModal = (bool, id, type) => {
   //    this.setState({ openDeleteModal: bool, deletedId: id, deletedType: type ? type : 'delete' })
   // }

   openDeleteConfirmModal  = (id = null, open = false, type) => {

      this.setState({
         ...this.state,
         openDeleteModal: open,
         deletedType: type ? type : 'delete',
         deletedId: id,
      })

   }

   onDeleteVault = async (id, isModal) => {
      const { deleteVault, removeFile, vaultImagesData, vaultVideosData } = this.props;
      const { pageType, deletedType, removingVideo: { data, status } } = this.state;
      if(deletedType === 'delete') {
         let type = pageType === 'image' ? 'Photo' : 'Video';
         deleteVault(id, type, (error) => {
            this.setState({
               // ...this.state,
               warningModal: {
                  isOpen: true,
                  description: error.message,
                  ids: error.bundle_ids,
                  multiple_bundles: error.multiple_bundles,
               },
            })
            console.log('error', error);

         });
         if(isModal){
            this.handleCloseModalByUrl()
         }
         this.setState({ onCloseOptionModal: true });
         this.handleCloseModalByUrl()
      } else {
         if(status && ('uploading' ===  status || 'pending' ===  status)) {
            cancelUplaod(data, () => removeFile(data.id, status))
         } else {
            removeFile(data.id, status)
         }
      }
      this.openDeleteConfirmModal(null, false)
      if(pageType === 'video'){
         if(vaultVideosData.data && vaultVideosData.data.length < 10){
            this.nextPage();
         }
      } else {
         if(vaultImagesData.data && vaultImagesData.data.length < 10){
            this.nextPage();
         }
      }
   }

   handleCloseModalByUrl = () => {
      this.setState({ openModalByUrl: false }, () => {
         ModalUrlHelper.resetAction();
      })
   }

   onTabChange = (tab) => {
      const { pageType } = this.state;
      const {
         fetchVideosData,
         fetchImagesData,
         isInsideModal,
         vaultsIntedState: {
            vaultVideosIsInited,
            vaultImagesIsInited,
         },
      } = this.props;

      if(pageType !== tab) {
         QueryParams.setQueryParam('type', tab);
         this.filter = { ...this.filter, page: 1, type: tab };
         this.setState({ pageType: tab });
         const param = this.generateQuery();
         if(tab === 'video'){
            if(!vaultVideosIsInited) {
               fetchVideosData(param, isInsideModal);
            }
         } else {
            if(!vaultImagesIsInited) {
               fetchImagesData(param)
            }
         }
      }
   }



   // video upload

   handleVideoFileChange = async (e) => {
      const allowedExtentions = getAllowedVideoExtentions();
      const files = e.target.files;
      const filtered = [];
      const hashs = [];
      Array.from(files).forEach((file) => {
         const { type } = file;
         if(allowedExtentions.indexOf(type.toLowerCase()) !== -1) {
            let hash = getVideoFileHash(file)
            hashs.push(hash);
            filtered.push({ [hash]: file });
         }
      });
      const { data } = await checkVaultByHash('video', [...hashs])
      let sortItems = data.reduce((acc, curr) => {
         let value =  Object.values(curr)[0]
         let hash =  Object.keys(curr)[0]
         if(value) {
            const { addVideosAction, updateVideo } = this.props
            addVideosAction({ vault: [{ uploadingStatus: 'pending', hash, id: hash, type: 'video' }] })
            setTimeout(() => {
               const { deleteCompleted, vaultVideosData } = this.props
               if(vaultVideosData && vaultVideosData.data && !!vaultVideosData.data.find(elm => elm.id === value.id)){
                  deleteCompleted(value.id, 'Video')
               }
               updateVideo(hash, value, true)
            }, 300)
         } else {
            let item = filtered.find(element => element[hash]) || {}

            acc.push({ file: item[hash], hash })
         }
         return acc
      }, []);
      this.fileUploader.current.value = '';
      if(0 === filtered.length) {
         toast.error('You may upload only video files')
         return;
      }
      if(sortItems.length > 0){
         const { startUpload } = this.props;
         startUpload(sortItems);
      }
   }

   render() {
      const {
         screenWidth,
         isInsideModal,
         handleSelectCard,
         selected,

         // images
         vaultImagesData,
         isEmptyImages,
         isFetchingImages,
         isEmptyImagesByFilter,
         isFetchingImagesByFilter,
         isNewFetchingImages,

         // videos
         vaultVideosData,
         isFetchingVideos,
         isEmptyVideos,
         isEmptyVideosByFilter,
         isFetchingVideosByFilter,
         isNewFetchingVideos,
         downloadFile,
         mediaDownloadButton,

         // all vault
         showNewStyles,
         allVaultData,
         isFetchingAllVault,
         isEmptyAllVault,
         isEmptyAllVaultByFilter,
         isFetchingAllVaultByFilter,
         isNewfetchingAllVaults,
         bundleId,
         contentType,
         onDeleteMessag,
         updateVaultMessageAction,
         updateMessageUnlockDetails,
         isMultipleSelect,
         isMultipleAttachments,
         siteInfo,
         isMaxAttachmentCountReached,
      } = this.props;
      const {
         deletedId,
         openDeleteModal,
         pageType,
         warningModal,
      } = this.state;

      let allowedExtentions = [
         'image/png',
         'image/jpeg',
         'image/jpg',
      ];
      if(pageType === 'video'){
         allowedExtentions = getAllowedVideoExtentions();
      }
      const accept = allowedExtentions.join(',');
      return (
         <div className='h-full'>
            <input
               type='file'
               ref={ this.fileUploader }
               className='hidden'
               multiple
               onChange={ pageType === 'image' ? this.onFileChange : this.handleVideoFileChange }
               accept={  pageType === 'image' ? `${ accept }/*` : `${ accept }`  }
            />
            <Vault
               isInsideModal={ isInsideModal }
               pageType={ pageType }
               desktopType={ screenWidth > 701 ? 'desktop' : 'mobile' }
               handleAddNewVault={ this.handleFileChange }
               onFilterChange={ this.onFilterChange }
               openDeleteConfirmModal={ (id, open, openWarning) => {
                  this.openDeleteConfirmModal(id, open, 'delete')
               } }
               onTabChange={ this.onTabChange }
               removeFile={ (data, status) => {
                  this.setState({ removingVideo: { data, status } });
                  this.openDeleteConfirmModal(data.id, true, false, 'cancel')
               } }
               onSelectCard={ handleSelectCard }
               selected={ selected }
               // images
               vaultImagesData={ vaultImagesData && vaultImagesData.data }
               isEmptyImages={ isEmptyImages }
               isFetchingImages={ isFetchingImages }
               isEmptyImagesByFilter={ isEmptyImagesByFilter }
               isFetchingImagesByFilter={ isFetchingImagesByFilter }
               isNewFetchingImages={ isNewFetchingImages }
               // videos
               vaultVideosData={ vaultVideosData && vaultVideosData.data }
               isFetchingVideos={ isFetchingVideos }
               isEmptyVideos={ isEmptyVideos }
               isEmptyVideosByFilter={ isEmptyVideosByFilter }
               isFetchingVideosByFilter={ isFetchingVideosByFilter }
               isNewFetchingVideos={ isNewFetchingVideos }
               downloadOriginalFile={ downloadFile }
               isDownloading={ mediaDownloadButton }
               // all vault
               showNewStyles={ showNewStyles }
               allVaultData={ allVaultData }
               isFetchingAllVault={ isFetchingAllVault }
               isEmptyAllVault={ isEmptyAllVault }
               isEmptyAllVaultByFilter={ isEmptyAllVaultByFilter }
               isFetchingAllVaultByFilter={ isFetchingAllVaultByFilter }
               onFilterByType={ this.onFilterByType }
               isNewfetchingAllVaults={ isNewfetchingAllVaults }
               screenWidth={ screenWidth }
               bundleId={ bundleId }
               contentType={ contentType }
               onDeleteMessag={ onDeleteMessag }
               updateVaultMessageAction={ updateVaultMessageAction }
               updateMessageUnlockDetails={ updateMessageUnlockDetails }
               isMultipleSelect={ isMultipleSelect }
               isMultipleAttachments={ isMultipleAttachments }
               isDemoMode={ siteInfo?.site_status === 'demo_mode' }
               isMaxAttachmentCountReached={ isMaxAttachmentCountReached }
            />
            {
               openDeleteModal && (
                  <DeleteModal
                     onCloseModal={ () => this.openDeleteConfirmModal() }
                     deleteVault={ () => this.onDeleteVault(deletedId) }
                     isMobile={ screenWidth < 701 }
                     isCloseAction
                     type={ pageType === 'image' ? 'photo' : 'video' }
                  />
               )
            }
            {
               warningModal.isOpen &&
               <ConfirmModal
                  onCloseModal={ () => {
                     this.setState({
                        ...this.state,
                        isDeleteModal: false,
                        warningModal: {
                           isOpen: false,
                           description: null,
                           ids: null,
                           multiple_bundles: null,
                        },
                     })
                  } }
                  isCloseAction
                  type='confirm'
                  icon='warning'
                  withoutCloseButton
                  iconColor='warning'
                  title='Content connected to bundle'
                  description={ warningModal?.description }
                  cancelText='Ok'
                  buttonText={ `Edit bundle${ warningModal?.multiple_bundles ? 's' : '' }` }
                  buttonIconName='go-to'
                  buttonClassName='mt-8'
                  action={ () => {
                     this.setState({
                        ...this.state,
                        isDeleteModal: false,
                        warningModal: {
                           isOpen: false,
                           description: null,
                           ids: null,
                           multiple_bundles: null,
                        },
                     })
                     if(warningModal?.multiple_bundles){
                        window.open(`${ Router.route('STORE').getCompiledPath() }?q={"type":"content_bundle_item"}`)
                     } else {
                        console.log(warningModal.id?.[0])
                        window.open(Router.route('STORE_EDIT_BUNDLE').getCompiledPath({ id: generateUriSegment(warningModal.ids?.[0]) }))
                     }
                  } }
               />
            }
         </div>
      )
   }
}

const mapStateToProps = state => ({
   screenWidth: screenWidthSelector(state),
   // videos
   vaultVideosData: vaultVideosDataSelector(state),
   isFetchingVideos: isFetchingVideosSelector(state),
   isEmptyVideos: isEmptyVideosSelector(state),
   isEmptyVideosByFilter: isEmptyVideosByFilterSelector(state),
   isFetchingVideosByFilter: isFetchingVideosByFilterSelector(state),
   isNewFetchingVideos: isNewFetchingVideosSelector(state),
   mediaDownloadButton: mediaDownloadButtonSelector(state),

   // images
   vaultImagesData: vaultImagesDataSelector(state),
   isFetchingImages: isFetchingImagesSelector(state),
   isEmptyImages: isEmptyImagesSelector(state),
   isEmptyImagesByFilter: isEmptyImagesByFilterSelector(state),
   isFetchingImagesByFilter: isFetchingImagesByFilterSelector(state),
   isNewFetchingImages: isNewFetchingImagesSelector(state),

   // all vault
   allVaultData: allVaultDataSelector(state),
   isFetchingAllVault: isFetchingAllVaultSelector(state),
   isEmptyAllVault: isEmptyAllVaultSelector(state),
   isEmptyAllVaultByFilter: isEmptyAllVaultByFilterSelector(state),
   isFetchingAllVaultByFilter: isFetchingAllVaultByFilterSelector(state),
   isNewfetchingAllVaults: isNewfetchingAllVaultsSelector(state),
   siteInfo: siteInfoSelector(state),
   vaultsIntedState: vaultsIntedStateSelector(state),
});

const mapDispatchToProps = dispatch => ({
   deleteVault: (id, type, callBack) => dispatch(deleteVault(id, type, callBack)),
   // images
   fetchImagesData: (params, type) => dispatch(fetchVaultImagesOperation(params, type)),
   getFilterImagesBy: params => dispatch(getFilterImagesByOperation(params)),
   getNewVaultImages: (params) => dispatch(getNewVaultImagesOperation(params)),
   uploadPhotos: (data, status, byHash) => dispatch(uploadPhotos(data, status, byHash)),
   // videos
   fetchVideosData: (params, isInsideModal) => dispatch(fetchVaultVideosOperation(params, isInsideModal)),
   getFilterVideosBy: params => dispatch(getFilterVideosByOperation(params)),
   getNewVaultVideos: (params) => dispatch(getNewVaultVideosOperation(params)),
   removeFile: (id, status) => dispatch(cancelUploadOperation(id, status)),
   startUpload: (files) => dispatch(startUploadOperation(files)),
   updateVideo: (id, data, byHash) => dispatch(updateVideo(id, data, byHash)),
   setInsideModal: (isInsideModal) => dispatch(setInsideModalAction(isInsideModal)),
   deleteCompleted: (data, type) => dispatch(deleteCompleted(data, type)),
   addVideosAction: (data) => dispatch(addVideosAction(data)),
   downloadFile: (id) => dispatch(downloadMediaOperation(id, 'vault', 'video')),
   // all vault
   fetchAllVault: params => dispatch(fetchAllVaultOperation(params)),
   getFilterAllVault: params => dispatch(getFilterAllVaultByOperation(params)),
   getNewVaults: params => dispatch(getNewVaultsOperation(params)),
   updateBundleStateInData: (data, bool) => dispatch(updateBundleStateInData(data, bool)),
   updateVaultMessageAction: data => dispatch(updateVaultMessageAction(data)),
   clearState: data => dispatch(clearStateAction(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(StoriesContainer);
