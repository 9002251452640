import React from 'react';
import PropTypes from 'prop-types';
// import Button from 'common/components/elements/buttons/primary'
import Icon from 'common/components/elements/icons'
import MediaChip from 'common/components/modules/media-chip'
import { BlurhashCanvas } from "react-blurhash";
import { Link } from 'react-router-dom';
// import IconLabel from 'common/components/elements/icon-label';
import './style.scss';
import cx from "classnames";
import Button from 'common/components/elements/buttons/primary';
import TruncateText from 'common/components/elements/truncate-text';
import DateFormatter from 'common/utils/DateFormatter';


const blurhash = require('blurhash');

const MessageWrapper = ({
   children,
   unlockButton,
   wrapperClassNames,
   openAttachmentsModal,
   classNames,
}) => {
   return (
      <div className={ wrapperClassNames } role='presentation' onClick={ openAttachmentsModal }>
         <div className={ classNames }>
            {children}
         </div>
         {
            unlockButton
         }
      </div>
   )
}

const MultipleAttachmentMessage = ({
   data,
   isAdmin,
   type,
   contentLink,
   onStream,
   closeChat,
   restFunction,
   // isMobile,
   paymentButtonLoading,
   width,
   adminMessage,
   attachments,
   isMiniChat,
   onClickAttachment,
   onRemoveMedia,
   isGroup,
   isParent,
}) => {

   const openAttachmentsModal = (e) => {
      console.log('openAttachmentsModal');
      e.stopPropagation()
      !!onClickAttachment && onClickAttachment(e)
   }

   const wrapperClassNames = cx({
      'flex gap-2': true,
      'hover:cursor-pointer': !!onClickAttachment,
      'flex-col': !isAdmin,
   })

   const classNames = cx({
      'flex gap-2': true,
      'hover:cursor-pointer': !!onClickAttachment && isAdmin,
   })

   const staticProps = {
      isAdmin,
      adminMessage,
      paymentButtonLoading,
      onStream,
      unlockDetails: data,
      width: window.innerWidth < 445 ? '110px' : '143px',
      onRemoveMedia: (content, index) => onRemoveMedia(content, index),
      isUnlocked: data?.is_unlocked,
      isMessage: true,
      contentLink,
      closeChat,
   }



   const unlockButton = (
      !isAdmin && !isGroup && ('is_unlocked_for_view' in data ? !data?.is_unlocked_for_view : !data?.is_unlocked) && !!data?.unlock_price && !!data?.attachments?.length &&
      <Button
         text={ `Unlock for ${ data?.unlock_price } USD` }
         textSize='small'
         iconName={ !paymentButtonLoading ? 'unlocked-new' : '' }
         iconPosition='left'
         fontIconSize='base'
         primaryColor
         classNames='w-auto h-full !h-9 px-3'
         padding='none'
         onClick={ (e) => {
            e.stopPropagation()
            console.log('Unlock')
            onStream()
         } }
         borderRadius='large'
         isLoading={ paymentButtonLoading }
      />
   )

   const messageWrapperStaticProps = {
      unlockButton,
      wrapperClassNames,
      openAttachmentsModal,
      classNames,
   }

   if(isParent) {

      if(attachments.length === 1) {

         return (
            <MessageWrapper
               { ...messageWrapperStaticProps }
            >
               {
                  attachments.map((content, index) => {
                     return (
                        <MessageElement
                           key={ index }
                           index={ index }
                           content={ content }
                           isParent
                           { ...staticProps }
                        />
                     )
                  }
                  )
               }
            </MessageWrapper>
         )
      }

      if(attachments.length > 1) {

         return (
            <MessageWrapper
               { ...messageWrapperStaticProps }
            >
               {
                  attachments.slice(0, isMiniChat ? 1 : 2).map((content, index) => {
                     return (
                        <MessageElement
                           key={ index }
                           content={ content }
                           index={ index }
                           contentCountLeft={ attachments.length - (isMiniChat ? 1 : 2) }
                           isParent
                           hideCount={ !isMiniChat && index === 0 }
                           { ...staticProps }
                        />
                     )
                  })
               }
            </MessageWrapper>
         )
      }
   }

   if(attachments.length < 3) {

      return (
         <MessageWrapper
            { ...messageWrapperStaticProps }
         >
            {
               attachments.map((content, index) => {
                  return (
                     <MessageElement
                        key={ index }
                        content={ content }
                        index={ index }
                        { ...staticProps }
                        width={ attachments.length === 1 ?  (window.innerWidth < 445 ? '210px' : '250px') : staticProps.width }
                     />
                  )
               }
               )
            }
         </MessageWrapper>
      )
   }

   if(attachments.length >= 3) {

      if(isMiniChat || (!isAdmin) || (window?.innerWidth > 1280 && window?.innerWidth < 1380) || (window?.innerWidth < 530)) {

         return (
            <MessageWrapper
               { ...messageWrapperStaticProps }
            >
               {
                  attachments.slice(0, 2).map((content, index) => {
                     return (
                        <MessageElement
                           key={ index }
                           content={ content }
                           index={ index }
                           contentCountLeft={ attachments.length - 2 }
                           lastMediaIndex={ 1 }
                           { ...staticProps }
                        />
                     )
                  })
               }
            </MessageWrapper>
         )

      }

      return (
         <MessageWrapper
            { ...messageWrapperStaticProps }
         >
            {
               attachments.slice(0, 3).map((content, index) => {
                  return (
                     <MessageElement
                        key={ index }
                        content={ content }
                        index={ index }
                        contentCountLeft={ attachments.length - 3 }
                        lastMediaIndex={ 2 }
                        { ...staticProps }
                     />
                  )
               })
            }
         </MessageWrapper>
      )
   }
};


export const MessageElement = ({
   content,
   width,
   isAdmin,
   unlockDetails,
   adminMessage,
   onStream,
   paymentButtonLoading,
   contentCountLeft,
   index,
   lastMediaIndex,
   isUnlocked,
   onRemoveMedia,
   title,
   onClickItem,
   hasDetailsPage,
   isMessage,
   siteInfo,
   contentStatus,
   isParent,
   contentLink,
   closeChat,
   hideCount,
}) => {

   let cover = content?.cover || '';
   let isVideo = 'video_unlock' === content?.type;
   let hash = null
   let style = {}
   const isBlockForMember = !isAdmin && !(unlockDetails.is_unlocked || !unlockDetails.unlock_price || !!cover)

   const isMediaBlurApplied = (
      (content?.resource_type === 'video' || content?.resource_type === 'photoset') && content?.locked_image_is_blur
   ) ||
    (
       (content?.resource_type === 'video_vault' || content?.resource_type === 'photo_vault') && unlockDetails?.locked_poster_is_blur
    )

   const showBlurhash = !isAdmin && isMediaBlurApplied && !!content?.blurhash && contentStatus !== 'locked_free_content' && isBlockForMember && !content?.cover

   // console.log({
   //    isBlockForMember,
   //    isMediaBlurApplied,
   //    showBlurhash,
   // });

   let contentStyles = {}

   // let contentIcon = 'vault-new'

   // switch (content?.resource_type) {
   //    case 'video_vault':
   //    case 'upload_video':
   //       contentIcon = 'video-camera'
   //       break;
   //    case 'photoset':
   //       contentIcon = 'photosets'
   //       break;

   //    default:
   //       break;
   // }

   if(!!content?.cover && !showBlurhash) {
      cover = content.cover;
      if(!!content?.cover_thumb) {
         cover = content.cover_thumb;
      }
      style = {
         ...style,
         backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0) 27.86%), url(${ cover }`,
      }

      if(isAdmin  || !isBlockForMember) {
         style = {
            ...style,
            filter: 'blur(10px)',
         }

      }
   }

   if(showBlurhash) {
      const { result } =  blurhash.isBlurhashValid(content.blurhash)
      if(result) {
         hash = content.blurhash
      } else {
         hash = 'UBF6UmB._3.S?^4TIARj00H?IUVsrX%#%Mxu'
      }

   }

   if(!!cover && (!isBlockForMember || (!isAdmin && (!content?.locked_image_is_blur || !unlockDetails.locked_poster_is_blur)))) {
      style = {
         ...style,
         filter: 'blur(10px)',
      }

   }

   if(((!hideCount && isParent && contentCountLeft > 0) || (!!index && index === lastMediaIndex)) && !!contentCountLeft) {

      contentStyles = {
         background: `linear-gradient(0deg, #041527, #041527), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`,
         opacity: '0.6',
      }
   }

   if(isUnlocked && isAdmin && !isMessage) {

      contentStyles = {
         background: `rgba(0, 0, 0)`,
         opacity: '0.6',
      }
   }


   const view = <>
      <div
         className={ cx({
            'flex flex-col': true,
            [`cursor-pointer`]: hasDetailsPage,
         }) }
         style={ {
            width,
            // height: '80px',
            // aspectRatio: '16 / 9',
         } }
      >
         {/* {
      title &&
         // <div className={ cx({
         //    'bg-panel dark:bg-panel-dark relative bottom-0 z-[10] h-6 p-1 rounded-b-lg border-b border-x border-solid border-divider dark:border-divider-dark': true,
         // }) } >
         //    <TruncateText
         //       className=''
         //       text={ title }
         //       textClass={ `font-bold text-xs text-major dark:text-major-dark leading-4 w-full text-center` }
         //       textSize='20px'
         //       fontWeight='700'
         //       // width='calc(100% - 24px)'
         //    />
         // </div>
         <div className='flex flex-col'>
            <div className='w-full flex items-center gap-2'>
               {
                  Boolean(false)  &&
                  <Icon
                     name={ `pin` }
                     color={ `warning` }
                     darkColor={ `warning-dark` }
                     className={ `h-5 w-5 min-h-[20px] min-w-[16px] rotate-[35deg]` }
                     size={ `xl` }
                     data-tooltip-content={ 'Pinned' }
                     data-tooltip-id={ 'ams-top-tooltip' }
                  />
               }
               <TruncateText
                  text={ title }
                  textClass='text-xl font-bold text-major dark:text-major-dark title dark:shadow-none'
                  textSize='20px'
                  fontWeight='700'
               />
            </div>
            <span className='panel-description leading-4 text-placeholder dark:text-placeholder-dark font-normal description mb-1 text-left'>
               {
                  ['video', 'photoset'].includes(content?.resource_type) && siteInfo?.content_card_settings?.timestamp ? (content?.resource_type === 'video' ? ' Video • ' : 'Photoset • ') : ''}
               {
                  ['video', 'photoset'].includes(content?.resource_type) && siteInfo?.content_card_settings?.timestamp &&  DateFormatter.getDate(content?.publish_date)
               }
            </span>
         </div>
   } */}
         <div
            style={ {
               width,
               // height: '80px',
               aspectRatio: '16 / 9',
            } }
            className={ cx({
               'flex flex-col items-center justify-center relative overflow-hidden **aspect-ratio-padding z-1': true,
               // [`rounded-t-lg`]: !!title,
               [`rounded-lg`]: true,
               // [`cursor-pointer`]: !!onClickItem,
               // 'min-w-49': isAdmin,
            }) }
            role='presentation'
            onClick={ (e) => {
               // e.stopPropagation()
               console.log('MessageElement onClickItem');
               if(!hasDetailsPage) {
                  return
               }
               !!onClickItem && onClickItem()
            } }
         >
            {
               !isAdmin && !isMessage && unlockDetails?.allow_download && (
                  <div
                     data-tooltip-content={ isBlockForMember ? 'Download after unlock' : 'Download allowed' }
                     data-tooltip-id='ams-top-tooltip'
                     className='flex h-6 w-6 absolute left-1 top-1 items-center justify-center bg-panel dark:bg-panel-dark rounded-full z-[11] cursor-pointer'
                  >
                     <Icon
                        color='major'
                        darkcolor='major-dark'
                        name='download'
                     />
                  </div>
               )
            }
            {
               isAdmin && !isMessage && !(!!index && index === lastMediaIndex) &&  !isUnlocked && !!onRemoveMedia && (
                  <div
                     className={ cx({
                        'media-item-remove absolute w-5 h-5 top-1 right-1 flex rounded-lg items-center justify-center z-10 cursor-pointer': true,
                        // 'opacity-50 cursor-not-allowed': startSendContent,
                        // 'cursor-pointer': !startSendContent,
                     }) }
                     role='presentation'
                     onClick={ (e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        onRemoveMedia(content, index)
                     } }
                     data-tooltip-id='ams-top-tooltip'
                     data-tooltip-content='Unsend'
                  >
                     <Icon
                        name='cancel'
                        size='xl'
                        color='major-dark'
                     />
                  </div>

               )
            }

            {
               !!cover && (!isBlockForMember || (!isAdmin && (!content?.locked_image_is_blur || !unlockDetails.locked_poster_is_blur))) && (
                  <img src={ cover } alt='' className='w-full h-full object-contain object-center absolute inset-0 z-1' />
               )
            }
            <div
               style={ {
                  ...style,
               } }
               className='bg-cover bg-center bg-no-repeat w-full h-full absolute inset-0 z-0'
            >
               {
                  showBlurhash && !!hash && (
                     <BlurhashCanvas
                        hash={ hash }
                        punch={ 1 }
                        className='h-full w-full rounded-lg'
                     />
                  )
               }
            </div>
            <MediaChip
               type={ isVideo ? 'video' : 'photoset' }
               className='m-1 z-2 absolute bottom-0 left-0'
               countOrDuration={ isVideo ? content?.video_duration : content?.photos_count }
               name=''
               photoIcon='photosets'
            />
            {/* {
               adminMessage && (
               )
            } */}
            {/* {
               !adminMessage && (
                  <MediaChip
                     type={ isVideo ? 'video' : 'photoset' }
                     className='m-1 z-2 absolute top-0 right-0'
                     countOrDuration={ isVideo ? content?.video_duration : content?.photos_count }
                     name={ isVideo ? 'video' : 'photo' }
                     photoIcon='photosets'
                     textClassName={ `${ isParent ? `!text-xs !leading-4` : `` }` }
                  />
               )
            } */}
            <div
               className={ `absolute w-full flex items-center justify-center h-full z-2 ` }
               style={ { ...contentStyles } }
            >

               {/* {
            !(isParent && contentCountLeft > 0) && isAdmin && isMessage && !(!!index && index === lastMediaIndex) &&
            <Icon
               name={ contentIcon }
               color='secondary'
               darkColor='secondary-dark'
               size={ `lg` }
            />
         } */}

               {
                  (((isParent && contentCountLeft > 0) || !(!!index && index === lastMediaIndex))) && !isAdmin && showBlurhash && isBlockForMember &&
                  <Icon
                     size={ isMessage ? '[32px]' : '[80px]' }
                     name='lock'
                     className={ `${ isMessage ? 'text-[32px]' : 'text-[80px]' } text-[#FFFFFF99] dark:text-[#FFFFFF99] ` }
                     darkColor='#FFFFFF99'
                     style={ {
                        opacity: '.9',
                     } }
                  />
               }

            </div>
            {
               ((!hideCount && isParent && contentCountLeft > 0) || (!!index && index === lastMediaIndex)) && !!contentCountLeft &&
               <span className='text-major-dark z-[5]'>
                  { '+' + contentCountLeft}
               </span>
            }
            {
               isAdmin &&  !isMessage && isUnlocked &&
               <div className='flex z-[5] items-center justify-center gap-1'>
                  <Icon
                     name={ 'unlocked-new' }
                     color='success'
                     darkColor='success-dark'
                     size={ `base` }
                  />
                  <p className='text-sm text-success dark:text-success-dark leading-[18px]'>
                     Unlocked
                  </p>
               </div>
            }
         </div>
         {
            title &&
            // <div className={ cx({
            //    'bg-panel dark:bg-panel-dark relative bottom-0 z-[10] h-6 p-1 rounded-b-lg border-b border-x border-solid border-divider dark:border-divider-dark': true,
            // }) } >
            //    <TruncateText
            //       className=''
            //       text={ title }
            //       textClass={ `font-bold text-xs text-major dark:text-major-dark leading-4 w-full text-center` }
            //       textSize='20px'
            //       fontWeight='700'
            //       // width='calc(100% - 24px)'
            //    />
            // </div>
            <div className='flex flex-col mt-2 gap-0.5'>
               <div className='w-full flex items-center gap-1.5'>
                  {
                     Boolean(false)  &&
                     <Icon
                        name={ `pin` }
                        color={ `warning` }
                        darkColor={ `warning-dark` }
                        className={ `h-5 w-5 min-h-[20px] min-w-[16px] rotate-[35deg] mt-0.5` }
                        size={ `xl` }
                        data-tooltip-content={ 'Pinned' }
                        data-tooltip-id={ 'ams-top-tooltip' }
                     />
                  }
                  <TruncateText
                     text={ title }
                     textClass='text-xl leading-6 font-bold text-major dark:text-major-dark title dark:shadow-none'
                     textSize='20px'
                     fontWeight='700'
                  />
               </div>
               {
                  !!content?.publish_date &&
                  <span className='panel-description leading-4 text-placeholder dark:text-placeholder-dark font-normal description text-left'>
                     {
                        ['video', 'photoset'].includes(content?.resource_type) && siteInfo?.content_card_settings?.timestamp ? (content?.resource_type === 'video' ? ' Video • ' : 'Photoset • ') : ''}
                     {
                        ['video', 'photoset'].includes(content?.resource_type) && siteInfo?.content_card_settings?.timestamp &&  DateFormatter.getDate(content?.publish_date)
                     }
                  </span>
               }
            </div>
         }
      </div>
   </>


   if(!isMessage && !!(contentLink())) {
      return   <Link
         onClick={ () => {
            if(closeChat) {
               closeChat()
            }

         } }
         to={ contentLink() } >
         {view}
      </Link>
   } else {
      return  view
   }

}


MultipleAttachmentMessage.propTypes = {
   data: PropTypes.object,
   isAdmin: PropTypes.bool,
   type: PropTypes.string,
   onStream: PropTypes.func,
   closeChat: PropTypes.func,
   paymentButtonLoading: PropTypes.bool,
   width: PropTypes.string,
   contentLink: PropTypes.any,
   restFunction: PropTypes.any,
   adminMessage: PropTypes.bool,
   attachments: PropTypes.array,
   isMiniChat: PropTypes.bool,
   onClickAttachment: PropTypes.func,
   onRemoveMedia: PropTypes.func,
   isGroup: PropTypes.bool,
   isParent: PropTypes.bool,
};

MessageElement.propTypes = {
   content: PropTypes.object,
   width: PropTypes.any,
   isAdmin: PropTypes.bool,
   unlockDetails: PropTypes.object,
   adminMessage: PropTypes.bool,
   onStream: PropTypes.func,
   paymentButtonLoading: PropTypes.bool,
   contentCountLeft: PropTypes.number,
   index: PropTypes.number,
   lastMediaIndex: PropTypes.number,
   isUnlocked: PropTypes.bool,
   onRemoveMedia: PropTypes.func,
   title: PropTypes.string,
   onClickItem: PropTypes.func,
   hasDetailsPage: PropTypes.bool,
   isMessage: PropTypes.bool,
   siteInfo: PropTypes.object,
   contentStatus: PropTypes.string,
   isParent: PropTypes.bool,
   contentLink: PropTypes.func,
   closeChat: PropTypes.func,
   hideCount: PropTypes.bool,
};

MessageWrapper.propTypes = {
   children: PropTypes.any,
   unlockButton: PropTypes.any,
   wrapperClassNames: PropTypes.string,
   openAttachmentsModal: PropTypes.func,
   classNames: PropTypes.string,
};

MultipleAttachmentMessage.defaultProps = {
   data: {},
   width: '250px',
   isParent: false,
};

export default MultipleAttachmentMessage;
