import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import Icon from 'common/components/elements/icons';
import Modal from 'common/components/modules/modal';
import { isMobileAndTablet } from 'common/utils/utils';
import Loader from 'common/components/elements/loader/circle';
import Card from 'admin/views/uploading/card';
import DateFormatter from 'common/utils/DateFormatter';
import { Link } from 'react-router-dom';
import Router from 'admin/routes/router';
import './style.scss'
import CheckBox from 'common/components/elements/inputs/checkbox';
import cx from 'classnames'
import RadioLabel from 'common/components/elements/inputs/radio-label';
import ChatMessageContentState from 'admin/views/media-modules/chat-message-state';
import ContentPreviewIntoModal from 'common/components/modules/content-preview-into-modal';

const VaultCard = ({
   desktopType,
   openDeleteConfirmModal,
   isChecked,
   selectCard,
   removeFile,
   isInsideModal,
   data = {},
   isDownloading,
   downloadOriginalFile,
   showNewStyles,
   disabled,
   isMultipleSelect,
   onDeleteMessag,
   updateVaultMessageAction,
   updateMessageUnlockDetails,
   isDemoMode,
}) => {
   const [openOptionsModal, setOpenOptionsModal] = useState(false);
   const [isOpenFullScreenModal, setIsOpenFullScreenModal] = useState(false);
   const [previewModal, setPreviewModal] = useState({
      isOpen: false,
      data: {},
   })
   const { id } = data

   function openFullScreenModal(bool) {
      setIsOpenFullScreenModal(bool)
   }

   function closeOnClickOutside(e) {
      if(!e.target.closest(`.vault-${ id }`)) {
         setOpenOptionsModal(false);
      }
   }

   useEffect(() => {
      window.addEventListener('click', closeOnClickOutside, true);
      return () => {
         window.removeEventListener('click', closeOnClickOutside, true);
      };
   // eslint-disable-next-line
   }, []);

   const previewModalView = (
      previewModal.isOpen &&
         <ContentPreviewIntoModal
            type={ data.type === 'video' ? data.type : 'photoset' }
            isMobile={ desktopType === 'mobile' }
            data={ previewModal.data }
            closeModal={ (e) => {
               e.stopPropagation()
               e.preventDefault()
               setPreviewModal({ isOpen: false, data: {} })
            } }
         />
   )


   if(data.type === 'video'){
      let time = 0
      let percentage = data.video_optimization_percentage || data.percentage
      let status = data.video_optimization_status || data.uploadingStatus
      if(data.video_optimization_status === 'completed') {
         status = 'ready'
      } else if(data.video_optimization_status === 'mediainfo_error' || data.video_optimization_status === 'optimization_error' || data.video_optimization_status === 'other_error') {
         status = 'error'
      }
      const { video_duration } = data
      if(video_duration){
         time = DateFormatter.getSecondsConversion(video_duration);
      }
      const view = (
         <>
            <div className='aspect-ratio-padding' />
            <div className={ `absolute group/card top-0 left-0 text-major-dark w-full h-full overflow-hidden rounded-lg` } >
               <Card
                  posterImage={  data.video_poster_full_src }
                  onCancel={ () => { removeFile(data, status) } }
                  percentage={ percentage?.toString() }
                  uploadingStatus={ status }
                  type={ data.type }
                  isShowCancel={ status !== 'ready' }
                  className='h-full z-10 relative'
                  disabled={ disabled }
               >
                  {
                     isInsideModal &&
                     <div className='absolute top-2 left-2 z-[4]'>
                        {
                           isMultipleSelect ?
                              <CheckBox
                                 isChecked={ isChecked }
                                 onChange={ () => { } }
                                 disabled={ disabled }
                                 className='w-6 h-6 '
                                 name={ `vault_checkbox_${ data.id }` }
                              />
                              :
                              <RadioLabel
                                 checked={ isChecked }
                                 className={ '!p-0 ' }
                                 radioParentClassName='ml-0'
                                 disabled={ disabled }
                                 labelClassName='!w-5 !h-5'
                                 id={ `vault_radio_${ data.id }` }
                              />
                        }
                     </div>
                  }
                  {
                     time && (
                        <div
                           className='absolute top-0 left-0 rounded-lg card-shadow p-2 w-full flex justify-between items-center'
                           style={ {
                              background: 'linear-gradient(0deg,rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%)',
                           } }
                        >
                           <div></div>
                           <div className='vault_videoCard__time font-medium self-end rounded flex items-center h-6'>
                              <div className='h-5 w-5 flex items-center justify-center'>
                                 <Icon name='play-default' color='major-dark' size='xl' />
                              </div>
                              <span className='ml-1 text-sm'>{time}</span>
                           </div>
                        </div>
                     )
                  }
                  {
                     isInsideModal &&  data?.messages  && data?.messages?.length > 0 && (
                        <ChatMessageContentState
                           chatMessages={ data.messages }
                           onDeleteMessag={ onDeleteMessag }
                           updateMessage={ (messageId, isDeleteAll, resourceType, messageData, contentType) => updateVaultMessageAction({ ids: [id], data: null, messageId: messageId, isDeleteAll: isDeleteAll, resourceType: resourceType, messageData, contentType }) }
                           path={ data.src }
                           hash={ data.hash }
                           updateMessageUnlockDetails={ updateMessageUnlockDetails }
                           onClickPreview={ () => {
                              setPreviewModal({
                                 isOpen: true,
                                 data: {
                                    posterSrc: data.video_poster_full_src,
                                    duration: data.video_duration,
                                    hlsManifest: data.video_hls_manifest,
                                    isPreview: !data.video_hls_manifest && !!data.video_poster_full_src,
                                    src: data.video_hls_manifest || data.video_system_video_preview_job_id || data.custom_preview_video_full_src,
                                    isVaultVideo: true,
                                 },
                              })
                           } }
                           resourceId={ data?.id }
                        />
                     )
                  }
                  {
                     !data?.messages?.length && isInsideModal &&
                     <div className='hidden absolute top-0 left-0 w-full h-full group-hover/card:!flex justify-center items-center bg-major-60 dark:bg-grey-hover-dark-60'>
                        <Button
                           text='Preview'
                           iconName='play-default'
                           textColor='divider'
                           iconPosition='left'
                           textSize='small'
                           backgroundColor='transparent'
                           classNames='!w-fit !h-9 border border-grey-main items-center'
                           borderRadius='large'
                           padding='0 px-3.5 py-2'
                           textClassName='mr-0 !leading-[18px]'
                           onClick={ (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setPreviewModal({
                                 isOpen: true,
                                 data: {
                                    posterSrc: data.video_poster_full_src,
                                    duration: data.video_duration,
                                    hlsManifest: data.video_hls_manifest,
                                    isPreview: !data.video_hls_manifest && !!data.video_poster_full_src,
                                    src: data.video_hls_manifest || data.video_system_video_preview_job_id || data.custom_preview_video_full_src,
                                    isVaultVideo: true,
                                 },
                              })
                           } }
                        />
                     </div>
                  }
                  {
                     !isInsideModal && (
                        <div className='absolute bottom-0 right-0 rounded-lg card-shadow p-2 flex z-10'>
                           <div className='w-full h-full flex flex-end'>
                              <div
                                 className={ `h-6 w-6 vault-${ id } flex justify-center items-center cursor-pointer` }
                              >
                                 <Button
                                    onClick={ (e) => {
                                       e.stopPropagation();
                                       e.preventDefault();
                                       setOpenOptionsModal(!openOptionsModal);
                                    } }
                                    backgroundColor='transparent'
                                    fontIconColor='major-dark'
                                    iconName='optons-horizontal'
                                    padding='none'
                                 />
                              </div>
                           </div>
                        </div>
                     )
                  }
                  {
                     openOptionsModal && (
                        <OptionsModal
                           closeModal={ () => setOpenOptionsModal(false) }
                           openDeleteConfirmModal={ (e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              openDeleteConfirmModal();
                              setTimeout(() => {
                                 setOpenOptionsModal(false)
                              }, 100)
                           } }
                           desktopType={ desktopType }
                           pageType={ data.type }
                           id={ id }
                           isDownloading={ isDownloading }
                           downloadOriginalFile={ downloadOriginalFile }
                           isDemoMode={ isDemoMode }
                        />
                     )
                  }
               </Card>
            </div>
            {previewModalView}
         </>
      )
      if(isInsideModal && status !== 'error'){
         return (
            <div
               role='presentation'
               onClick={ (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  if(!disabled) {
                     selectCard(id, !isChecked)
                  }
               } }
               className={ cx({
                  'h-full w-full relative cursor-pointer': true,
                  '!cursor-default': disabled,
               }) }
               data-tooltip-content='This content has already been added to a different bundle'
               data-tooltip-id={ disabled ? 'ams-top-tooltip' : null }
            >
               {view}
            </div>
         )
      } else {
         return (
            <div className={ `h-full w-full relative ${ status === 'ready' ? 'cursor-pointer' : '' }` }>
               {
                  status === 'ready' && (
                     <Link to={ `${ Router.route('GET_VAULT_VIDEO').getCompiledPath({ id }) }` } >
                        {view}
                     </Link>

                  )
               }
               {
                  status !== 'ready' && (
                     view
                  )
               }
            </div>
         )
      }
   } else {
      let status = data.uploadingStatus
      if(!data.uploadingStatus  && id) {
         status = 'complete'
      }
      switch (status) {
         case 'error':
            return (
               <div
                  className={ `relative h-full w-full bg-placeholder border border-divider rounded-label flex items-center justify-center ` }>
                  <div className='aspect-ratio-padding' />
                  <div
                     onClick={ (e) => e.stopPropagation() }
                     role='presentation'
                     className='absolute top-0 right-0 m-2 flex items-center'
                  >
                     <Button
                        textColor='major-dark'
                        backgroundColor='transparent'
                        iconName='cancel'
                        fontIconColor='secondary'
                        padding='none'
                        onClick={ () => removeFile(id, status)  }
                        borderRadius='large'
                        textSize='base'
                     />
                  </div>
                  <div className='flex flex-col items-center'>
                     <Icon name='warning' color='secondary' size='5xl' />
                     <p className='mt-5 text-error font-bold'>Error</p>
                     <p className='text-major text-sm'>Try again or contact support</p>
                  </div>
                  {previewModalView}
               </div>
            );
         case 'loading':
            return (
               <div
                  className={ `relative bg-placeholder w-full h-full rounded-label flex items-center justify-center` }>
                  <div className='aspect-ratio-padding' />
                  <div
                     onClick={ (e) => e.stopPropagation() }
                     role='presentation'
                     className='absolute top-0 right-0 m-2 flex items-center'
                  >
                     <Button
                        textColor='major-dark'
                        backgroundColor='transparent'
                        iconName='cancel'
                        fontIconColor='major-dark'
                        padding='none'
                        onClick={ () => {} }
                        borderRadius='large'
                        textSize='base'
                     />
                  </div>
                  <Loader color='major-dark' />
                  {previewModalView}
               </div>
            );
         case 'complete':
            return (
               <>
                  <CompleteCard
                     isInsideModal={ isInsideModal }
                     selectCard={ selectCard }
                     openFullScreenModal={ openFullScreenModal }
                     id={ id }
                     isChecked={ isChecked }
                     src={ data.image_thumb_src }
                     pageType={ data.type }
                     setOpenOptionsModal={ setOpenOptionsModal }
                     openOptionsModal={ openOptionsModal }
                     openDeleteConfirmModal={ openDeleteConfirmModal }
                     desktopType={ desktopType }
                     isOpenFullScreenModal={ isOpenFullScreenModal }
                     isDownloading={ isDownloading }
                     downloadOriginalFile={ downloadOriginalFile }
                     showNewStyles={ showNewStyles }
                     disabled={ disabled }
                     chatMessages={ data.messages }
                     onDeleteMessag={ onDeleteMessag }
                     updateVaultMessageAction={ updateVaultMessageAction }
                     path={ data.src }
                     hash={ data.hash }
                     updateMessageUnlockDetails={ updateMessageUnlockDetails }
                     setPreviewModal={ setPreviewModal }
                     isDemoMode={ isDemoMode }
                  />
                  {
                     isOpenFullScreenModal && (
                        <FullScreenModal
                           src={ data.image_full_src }
                           setOpenOptionsModal={ setOpenOptionsModal }
                           openOptionsModal={ openOptionsModal }
                           openFullScreenModal={ openFullScreenModal }
                           openDeleteConfirmModal={ openDeleteConfirmModal }
                           desktopType={ desktopType }
                           pageType={ data.type }
                           id={ id }
                           isDemoMode={ isDemoMode }
                        />
                     )
                  }
                  {previewModalView}
               </>
            );
         default:
            return null;
      }
   }
}

export const CompleteCard = ({
   isInsideModal,
   selectCard,
   openFullScreenModal,
   id,
   isChecked,
   src,
   setOpenOptionsModal,
   openOptionsModal,
   openDeleteConfirmModal,
   desktopType,
   isFullScreen,
   pageType,
   isDownloading,
   downloadOriginalFile,
   showNewStyles,
   disabled,
   chatMessages,
   onDeleteMessag,
   updateVaultMessageAction,
   path,
   updateMessageUnlockDetails,
   hash,
   setPreviewModal,
   isDemoMode,
}) => {

   return (
      <div
         role='presentation'
         onClick={ () => isInsideModal ? !disabled && selectCard(id, !isChecked) : isFullScreen ? () => {} : openFullScreenModal(true) }
         className={ `h-full w-full group/card relative ${ isFullScreen ? '' : 'cursor-pointer' } ${ disabled ? '!cursor-default' : '' }` }
         data-tooltip-content='This content has already been added to a different bundle'
         data-tooltip-id={ disabled ? 'ams-top-tooltip' : null }
      >
         <div className='aspect-ratio-padding' />
         <div className={ `absolute top-0 left-0 text-major-dark w-full h-full overflow-hidden ${ isFullScreen ? '' : 'rounded-lg' }` } >
            <div
               style={ {
                  background: `linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0) 19.7%), linear-gradient(180deg, rgba(0, 0, 0, 0) 62.09%, rgba(0, 0, 0, 0.65) 100%), url(${ src })`,
                  filter: 'blur(24px)',
                  zIndex: '-1',
               } }
               className=' bg-cover bg-center w-full h-full'
            />
            <img src={ src } alt='card' className='h-full w-full rounded-t-lg object-contain object-center absolute top-0 left-0' />
            <div className='absolute top-0 left-0 w-full rounded-lg h-full card-shadow **p-2 flex flex-col group/card justify-between z-[3]'>
               {
                  isInsideModal && chatMessages && chatMessages?.length > 0 && (
                     <ChatMessageContentState
                        chatMessages={ chatMessages }
                        onDeleteMessag={ onDeleteMessag }
                        updateMessage={ (messageId, isDeleteAll, resourceType, messageData, contentType) => updateVaultMessageAction({ ids: [id], data: null, messageId: messageId, isDeleteAll: isDeleteAll, resourceType: resourceType, messageData, contentType }) }
                        path={ path }
                        hash={ hash }
                        updateMessageUnlockDetails={ updateMessageUnlockDetails }
                        contentType='vault_image'
                        onClickPreview={ () => {
                           setPreviewModal({
                              isOpen: true,
                              data: {
                                 photos: [src],
                              },
                           })
                        } }
                        resourceId={ id }
                     />
                  )
               }
               {
                  !chatMessages?.length && isInsideModal &&
                  <div className='hidden absolute top-0 left-0 w-full h-full group-hover/card:!flex justify-center items-center bg-major-60 dark:bg-grey-hover-dark-60'>
                     <Button
                        text='Preview'
                        iconName='play-default'
                        textColor='divider'
                        iconPosition='left'
                        textSize='small'
                        backgroundColor='transparent'
                        classNames='!w-fit !h-9 border border-grey-main items-center'
                        borderRadius='large'
                        padding='0 px-3.5 py-2'
                        textClassName='mr-0 !leading-[18px]'
                        onClick={ (e) => {
                           e.preventDefault();
                           e.stopPropagation();
                           setPreviewModal({
                              isOpen: true,
                              data: {
                                 photos: [src],
                              },
                           })
                        } }
                     />
                  </div>
               }
               {
                  isInsideModal  && (
                     <div
                        className='w-full p-2 pb-3'
                        style={ {
                           background: 'linear-gradient(0deg,rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%)',
                        } }
                     >
                        <CheckBox
                           isChecked={ isChecked }
                           onChange={ () => {} }
                           onClick={ (e) => {
                              e.stopPropagation()
                              e.preventDefault()
                           } }
                           classNames={ `${ disabled ? '!cursor-default' : 'z-[3]' }` }
                           disabled={ disabled }
                        />
                     </div>
                  )
               }
               {
                  isFullScreen && (
                     <div className='flex items-center justify-end'>
                        <div
                           onClick={ (e) => {
                              e.stopPropagation()
                              e.preventDefault()
                           } }
                           role='presentation'
                           className=' m-2 w-6 flex items-center'
                        >
                           <Button
                              textColor='major-dark'
                              backgroundColor='transparent'
                              iconName='cancel'
                              fontIconColor='major-dark'
                              padding='none'
                              onClick={ () => openFullScreenModal(false) }
                              borderRadius='large'
                              textSize='base'
                           />
                        </div>
                     </div>
                  )
               }
            </div>
            {
               !isInsideModal && (
                  <div className='absolute bottom-0 right-0 rounded-lg card-shadow p-2 flex z-10'>
                     <div className='w-full h-full flex flex-end'>
                        <div
                           className={ `${ isFullScreen ? 'h-11 w-11 full-screen-option' : 'h-6 w-6 vault-' + id } flex justify-center items-center cursor-pointer` }
                        >
                           <Button
                              onClick={ (e) => {
                                 e.stopPropagation();
                                 setOpenOptionsModal(!openOptionsModal);
                              } }
                              backgroundColor='transparent'
                              fontIconColor='major-dark'
                              iconName='optons-horizontal'
                              padding='none'
                           />
                        </div>
                     </div>
                  </div>
               )
            }
            {
               openOptionsModal && (
                  <OptionsModal
                     closeModal={ () => setOpenOptionsModal(false) }
                     openDeleteConfirmModal={ (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        openDeleteConfirmModal();
                        setTimeout(() => {
                           setOpenOptionsModal(false)
                        }, 100)
                     } }
                     desktopType={ desktopType }
                     pageType={ pageType }
                     id={ id }
                     isDownloading={ isDownloading }
                     downloadOriginalFile={ downloadOriginalFile }
                     isDemoMode={ isDemoMode }
                  />
               )
            }
         </div>
      </div>
   )
}

const FullScreenModal = ({
   src,
   setOpenOptionsModal,
   openOptionsModal,
   openFullScreenModal,
   openDeleteConfirmModal,
   desktopType,
   id,
   pageType,
   isDemoMode,
}) => {
   return (
      <Modal
         fullScreenMode={ isMobileAndTablet() }
         contentPosition='full-screen'
         onClose={ () => openFullScreenModal(false) }
         className='h-screen'
      >
         <CompleteCard
            src={ src }
            isFullScreen={ true }
            setOpenOptionsModal={ setOpenOptionsModal }
            openOptionsModal={ openOptionsModal }
            openFullScreenModal={ openFullScreenModal }
            openDeleteConfirmModal={ openDeleteConfirmModal }
            desktopType={ desktopType }
            id={ id }
            pageType={ pageType }
            setPreviewModal={ () => {} }
            isDemoMode={ isDemoMode }
         />

      </Modal>
   )

}


const OptionsModal = ({
   /* eslint-disable */
   desktopType,
   closeModal,
   openDeleteConfirmModal,
   id,
   pageType,
   isDownloading,
   downloadOriginalFile,
   isDemoMode
   /* eslint-enable */
}) => {
   const view = (
      <div
         className={ `shadow dark:shadow-dark vault-${ id } flex flex-col w-full bg-panel items-center justify-between px-2 ${ desktopType === 'mobile' ? '' : 'border border-divider rounded-lg' }` }>
         {
            pageType === 'video'  && (
               <div className='flex w-full h-10 items-center'>
                  <Button
                     text='Download original file'
                     textSize='base'
                     textColor='major'
                     classNames='h-full w-full'
                     iconName='download'
                     fontIconColor='secondary'
                     iconPosition='left'
                     alignment='end'
                     padding='none'
                     backgroundColor='transparent'
                     fontIconSize='base'
                     onClick={ (e) => {
                        e.preventDefault();
                        downloadOriginalFile(id)
                     } }
                     disabled={ isDownloading }
                  />
               </div>
            )
         }
         <div className='flex w-full h-10 items-center'>
            <Button
               text={ `Delete ${ pageType === 'video' ? 'video' : 'photo' }` }
               onClick={ openDeleteConfirmModal }
               textSize='base'
               classNames='h-full w-full'
               backgroundColor='transparent'
               textColor='error'
               padding='none'
               iconName='delete'
               fontIconColor='error'
               iconPosition='left'
               alignment='end'
               fontIconSize='base'
               disabled={ isDemoMode }
               dataToolTipContent={ !!isDemoMode ? 'Not available in demo' : '' }
            />
         </div>

      </div>
   )
   if(desktopType === 'mobile'){
      return (
         <Modal
            onClose={ closeModal }
            roundedModal='lg'
         >
            {view}
         </Modal>
      );
   } else {
      return (
         <div
            style={ { bottom: '24px', right: '10px' } }
            className='absolute z-30 bottom-0'>
            {view}
         </div>
      )
   }

}

CompleteCard.propTypes = {
   selectCard: PropTypes.func,
   openFullScreenModal: PropTypes.func,
   setOpenOptionsModal: PropTypes.func,
   openDeleteConfirmModal: PropTypes.func,
   src: PropTypes.string,
   desktopType: PropTypes.string,
   pageType: PropTypes.string,
   isInsideModal: PropTypes.bool,
   openOptionsModal: PropTypes.bool,
   isChecked: PropTypes.bool,
   isFullScreen: PropTypes.bool,
   id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
   ]),
   isDownloading: PropTypes.bool,
   downloadOriginalFile: PropTypes.func,
   showNewStyles: PropTypes.bool,
   disabled: PropTypes.bool,
   chatMessages: PropTypes.array,
   onDeleteMessag: PropTypes.func,
   updateVaultMessageAction: PropTypes.func,
   path: PropTypes.string,
   hash: PropTypes.string,
   updateMessageUnlockDetails: PropTypes.func,
   setPreviewModal: PropTypes.func,
   isDemoMode: PropTypes.bool,
}

FullScreenModal.propTypes = {
   openFullScreenModal: PropTypes.func,
   setOpenOptionsModal: PropTypes.func,
   openDeleteConfirmModal: PropTypes.func,
   src: PropTypes.string,
   desktopType: PropTypes.string,
   pageType: PropTypes.string,
   openOptionsModal: PropTypes.bool,
   id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
   ]),
   isDemoMode: PropTypes.bool,
}

VaultCard.propTypes = {
   desktopType: PropTypes.string,
   openDeleteConfirmModal: PropTypes.func,
   selectCard: PropTypes.func,
   removeFile: PropTypes.func,
   data: PropTypes.object,
   isChecked: PropTypes.bool,
   isInsideModal: PropTypes.bool,
   isDownloading: PropTypes.bool,
   downloadOriginalFile: PropTypes.func,
   showNewStyles: PropTypes.bool,
   disabled: PropTypes.bool,
   isMultipleSelect: PropTypes.bool,
   onDeleteMessag: PropTypes.func,
   updateVaultMessageAction: PropTypes.func,
   updateMessageUnlockDetails: PropTypes.func,
   isDemoMode: PropTypes.bool,
}

VaultCard.defaultProps = {
   showNewStyles: false,
   disabled: false,
   isMultipleSelect: false,
   isDemoMode: false,
}

export default VaultCard;
