import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImagesContent from './images';
import VideosContent from './videos';
import TabSwitcher, { Tab, TabPanel } from 'common/components/modules/tabs-switcher';
import './style.scss'
import VaultContent from './all-vault';


function Vault({
   isInsideModal,
   onFilterChange,
   desktopType,
   openDeleteConfirmModal,
   pageType,
   onTabChange,
   handleAddNewVault,
   removeFile,
   onSelectCard,
   selected,
   // images
   vaultImagesData,
   isEmptyImages,
   isFetchingImages,
   isFetchingImagesByFilter,
   isNewFetchingImages,
   // videos
   vaultVideosData,
   isFetchingVideos,
   isEmptyVideos,
   isFetchingVideosByFilter,
   isNewFetchingVideos,
   isDownloading,
   downloadOriginalFile,
   // all vault
   showNewStyles,
   allVaultData,
   isFetchingAllVault,
   isEmptyAllVault,
   isEmptyAllVaultByFilter,
   isFetchingAllVaultByFilter,
   onFilterByType,
   isNewfetchingAllVaults,
   screenWidth,
   bundleId,
   contentType,
   onDeleteMessag,
   updateVaultMessageAction,
   updateMessageUnlockDetails,
   isMultipleSelect,
   isMultipleAttachments,
   isDemoMode,
   isMaxAttachmentCountReached,
}) {

   const [selectedCards, setSelectedCard] = useState(selected || []);
   const [selectedCardsSrc, setSelectedCardSrc] = useState([]);
   const [filters, setFilters] = useState({
      name: 'Newest',
      value: 'date_added:desc',
   });

   // all_vault
   const [selectedIds, setSelectedIds] = useState(selected || [])
   const [selectedItems, setSelectedItems] = useState([])

   // useEffect(() => {
   //    if(pageType === 'all_vault') {
   //       if(selected && selected.length !== 0) {
   //          let newData = allVaultData?.data?.filter(i => selected.includes(i.id))
   //          setSelectedItems(newData)
   //          onSelectCard(newData)
   //       }
   //    }
   // // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [allVaultData])

   useEffect(() => {
      if(selected && selected.length !== 0) {
         let data = pageType === 'video' ? vaultVideosData : vaultImagesData;
         let newData = data?.filter(i => selected.includes(i.id))
         setSelectedCardSrc(newData)
      }
   }, [vaultImagesData, vaultVideosData, pageType, selected])

   function onSimpleFilter(value, name){
      setFilters({
         name,
         value,
      })
      onFilterChange(value)
   }

   function selectCard(bool, current) {
      const { id } = current
      let newData = [];
      let newSrcData = [];
      if('video' === pageType && !isMultipleSelect){
         newData = [id]
         newSrcData = [current]
      } else {
         newData =  [...selectedCards];
         newSrcData = [...selectedCardsSrc];
         if(bool) {
            newData = isMaxAttachmentCountReached ? newData : [...newData, id];
            newSrcData = isMaxAttachmentCountReached ? newSrcData : [...newSrcData, current];
         } else {

            newData =  newData.filter(item => item !== id);
            newSrcData =   newSrcData.filter(item => item.id !== current.id);
         }
      }
      setSelectedCard(newData);
      setSelectedCardSrc(newSrcData);
      if(isMultipleAttachments) {
         // console.log({ selectedCards, selectedCardsSrc, bool, current });
         onSelectCard([current]);
      } else {
         onSelectCard(newSrcData);
      }
   }

   //all_vault
   const onChangeSelected = (isChecked, item) => {
      let newIds = selectedIds
      let newCards = selectedItems
      if(isChecked) {
         newCards = newCards.filter(el => el.id !== item.id);
         newIds = newIds.filter(id => id !== item.id)
      } else {
         newCards = [...newCards, item]
         newIds = [...newIds, item.id]
      }
      setSelectedIds(newIds);
      setSelectedItems(newCards);
      onSelectCard(newCards);
   }

   return (
      <div className='vault-page h-full'>
         {
            isInsideModal ? (
               pageType === 'video' ? (
                  <VideosContent
                     isInsideModal={ isInsideModal }
                     data={ vaultVideosData }
                     isFetching={ isFetchingVideos }
                     isEmpty={ isEmptyVideos }
                     isFetchingByFilter={ isFetchingVideosByFilter }
                     isNewFetching={ isNewFetchingVideos }
                     desktopType={ desktopType }
                     openDeleteConfirmModal={ openDeleteConfirmModal }
                     removeFile={ removeFile }
                     selectedCards={ selectedCards }
                     selectCard={ selectCard }
                     filters={ filters }
                     handleAddNewVault={ handleAddNewVault }
                     pageType={ pageType }
                     onSimpleFilter={ onSimpleFilter }
                     isDownloading={ isDownloading }
                     downloadOriginalFile={ downloadOriginalFile }
                     contentType={ contentType }
                     screenWidth={ screenWidth }
                     onDeleteMessag={ onDeleteMessag }
                     updateVaultMessageAction={ updateVaultMessageAction }
                     updateMessageUnlockDetails={ updateMessageUnlockDetails }
                     isMultipleSelect={ isMultipleSelect }
                     isMaxAttachmentCountReached={ isMaxAttachmentCountReached }
                  />
               ) : (
                  pageType === 'all_vault' ?
                     <VaultContent
                        isInsideModal={ isInsideModal }
                        data={ allVaultData }
                        isFetchingByFilter={ isFetchingAllVaultByFilter }
                        isFetching={ isFetchingAllVault }
                        isEmpty={ isEmptyAllVault }
                        isEmptyAllVaultByFilter={ isEmptyAllVaultByFilter }
                        desktopType={ desktopType }
                        selectedCards={ selectedIds }
                        filters={ filters }
                        onSimpleFilter={ onSimpleFilter }
                        showNewStyles={ showNewStyles }
                        onFilterByType={ onFilterByType }
                        onChangeSelected={ onChangeSelected }
                        isNewfetchingAllVaults={ isNewfetchingAllVaults }
                        selected={ selected }
                        setSelectedItems={ setSelectedItems }
                        onSelectCard={ onSelectCard }
                        screenWidth={ screenWidth }
                        bundleId={ bundleId }
                        onDeleteMessag={ onDeleteMessag }
                        updateVaultMessageAction={ updateVaultMessageAction }
                        updateMessageUnlockDetails={ updateMessageUnlockDetails }
                        contentType={ contentType }
                        isMaxAttachmentCountReached={ isMaxAttachmentCountReached }
                     />
                     :
                     <ImagesContent
                        isInsideModal={ isInsideModal }
                        data={ vaultImagesData }
                        isFetchingByFilter={ isFetchingImagesByFilter }
                        isFetching={ isFetchingImages }
                        isEmpty={ isEmptyImages }
                        isNewFetching={ isNewFetchingImages }
                        desktopType={ desktopType }
                        openDeleteConfirmModal={ openDeleteConfirmModal }
                        removeFile={ removeFile }
                        selectedCards={ selectedCards }
                        selectCard={ selectCard }
                        filters={ filters }
                        handleAddNewVault={ handleAddNewVault }
                        onSimpleFilter={ onSimpleFilter }
                        contentType={ contentType }
                        screenWidth={ screenWidth }
                        onDeleteMessag={ onDeleteMessag }
                        updateVaultMessageAction={ updateVaultMessageAction }
                        updateMessageUnlockDetails={ updateMessageUnlockDetails }
                        isMaxAttachmentCountReached={ isMaxAttachmentCountReached }
                     />
               )
            ) : (
               <TabSwitcher
                  initialActiveTab={ pageType }
                  parentOnChangeCallback={ (tab, e) => {
                     onTabChange(tab)
                     const elm = document.querySelector('.tabs')
                     const item = e.target.getBoundingClientRect()
                     let left = (item.left - item.width)
                     elm.scrollBy(left, 0)
                  }  }
               >
                  <div className='w-full bg-panel tabs pr-4'>
                     <div className='flex mr-4 max-tabs-width'>
                        <Tab id='image' classNames='p-3 font-normal' name='Photos' />
                        <Tab id='video' classNames='p-3 font-normal' name='Videos' />

                     </div>
                  </div>
                  <TabPanel whenActive={ pageType }>
                     {
                        pageType === 'video' ? (
                           <VideosContent
                              isInsideModal={ isInsideModal }
                              data={ vaultVideosData }
                              isFetching={ isFetchingVideos }
                              isEmpty={ isEmptyVideos }
                              isFetchingByFilter={ isFetchingVideosByFilter }
                              isNewFetching={ isNewFetchingVideos }
                              desktopType={ desktopType }
                              openDeleteConfirmModal={ openDeleteConfirmModal }
                              removeFile={ removeFile }
                              selectedCards={ selectedCards }
                              selectCard={ selectCard }
                              filters={ filters }
                              handleAddNewVault={ handleAddNewVault }
                              pageType={ pageType }
                              onSimpleFilter={ onSimpleFilter }
                              isDownloading={ isDownloading }
                              downloadOriginalFile={ downloadOriginalFile }
                              updateMessageUnlockDetails={ updateMessageUnlockDetails }
                              isDemoMode={ isDemoMode }
                           />
                        ) : (
                           <ImagesContent
                              isInsideModal={ isInsideModal }
                              data={ vaultImagesData }
                              isFetchingByFilter={ isFetchingImagesByFilter }
                              isFetching={ isFetchingImages }
                              isEmpty={ isEmptyImages }
                              isNewFetching={ isNewFetchingImages }
                              desktopType={ desktopType }
                              openDeleteConfirmModal={ openDeleteConfirmModal }
                              removeFile={ removeFile }
                              selectedCards={ selectedCards }
                              selectCard={ selectCard }
                              filters={ filters }
                              handleAddNewVault={ handleAddNewVault }
                              onSimpleFilter={ onSimpleFilter }
                              updateMessageUnlockDetails={ updateMessageUnlockDetails }
                              isDemoMode={ isDemoMode }
                           />
                        )
                     }
                  </TabPanel>
               </TabSwitcher>
            )
         }
      </div>
   )
}

Vault.propTypes = {
   isInsideModal: PropTypes.bool,
   onFilterChange: PropTypes.func,
   onTabChange: PropTypes.func,
   handleAddNewVault: PropTypes.func,
   removeFile: PropTypes.func,
   desktopType: PropTypes.string,
   pageType: PropTypes.string,
   openDeleteConfirmModal: PropTypes.func,
   onSelectCard: PropTypes.func,
   selected: PropTypes.array,
   // images
   vaultImagesData: PropTypes.array,
   isEmptyImages: PropTypes.bool,
   isFetchingImages: PropTypes.bool,
   isFetchingImagesByFilter: PropTypes.bool,
   isNewFetchingImages: PropTypes.bool,
   // videos
   vaultVideosData: PropTypes.array,
   isFetchingVideos: PropTypes.bool,
   isEmptyVideos: PropTypes.bool,
   isFetchingVideosByFilter: PropTypes.bool,
   isNewFetchingVideos: PropTypes.bool,
   isDownloading: PropTypes.bool,
   downloadOriginalFile: PropTypes.func,
   // all vault
   showNewStyles: PropTypes.bool,
   allVaultData: PropTypes.array,
   isFetchingAllVault: PropTypes.bool,
   isEmptyAllVault: PropTypes.bool,
   isEmptyAllVaultByFilter: PropTypes.bool,
   isFetchingAllVaultByFilter: PropTypes.bool,
   onFilterByType: PropTypes.func,
   isNewfetchingAllVaults: PropTypes.bool,
   screenWidth: PropTypes.number,
   bundleId: PropTypes.any,
   contentType: PropTypes.string,
   updateVaultMessageAction: PropTypes.func,
   onDeleteMessag: PropTypes.func,
   updateMessageUnlockDetails: PropTypes.func,
   isMultipleSelect: PropTypes.bool,
   isMultipleAttachments: PropTypes.bool,
   isDemoMode: PropTypes.bool,
   isMaxAttachmentCountReached: PropTypes.bool,
}

Vault.defaultProps = {
   showNewStyles: false,
   bundleId: null,
   contentType: '',
}


export default Vault;
