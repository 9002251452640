const initialState = {
   isFetching: true,
   settings: {},
   siteSettings: {},
   isFetchingSite: true,
   statistic: {},
   headerData: {},
   isFetchingHeader: true,
   contentCardsOptions: {},

   //about me
   aboutMeData: {},
   isFetchingAboutMe: true,
   afterUpdateAboutMe: false,

   /////////////////////
   settingsModal: {
      isOpen: false,
      type: null,
      buttonDisabled: false,
   },

   settingsModalById: {
      buttonDisabled: false,
      id: null,
   },
   fetchingTrackingCode: true,
   trackingCodeData: {},

   initDataSEO: {},
   fetchingSEO: true,
   isFetchingUpdateSEO: false,
   errorMessageSEO: {},

   // Content categories
   isFetchingAddedCategories: true,
   addedCategories: [],
   isFetchingAllCategories: true,
   allCategories: [],
   fetchingToggleAddRemoveIDs: [],
};

export default initialState;
