import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {

   [types.FETCH_DETAILS]: (state, action) => {
      return {
         ...state,
         isFetching: true,
         newStore: {},
         newItemErrorMessage: {},
         newItemButtonDisabled: false,
         updateError: {},
         isEmptyByFilter: false,
      }
   },

   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const  { data, fetchByFilter } = action.payload;
      if(fetchByFilter) {
         return {
            ...state,
            isFetching: false,
            store: data,
            isEmptyByFilter: !data?.data?.length,
         }
      }
      return {
         ...state,
         isFetching: false,
         store: data,
         isEmpty: data.data.length === 0,
      }
   },

   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   // next page
   [types.FETCH_NEW_STORE]: (state, action) => {
      return {
         ...state,
         isNewFetching: true,
      }
   },

   [types.FETCH_NEW_STORE_COMPLETED]: (state, action) => {
      const  { payload } = action;
      const res = payload.data;
      return {
         ...state,
         isNewFetching: false,
         store: {
            ...res,
            data: [...state.store.data, ...res.data],
         },
      }
   },

   [types.FETCH_NEW_STORE_FAILED]: (state, action) => {
      return {
         ...state,
         isNewFetching: false,
      }
   },

   // by filter
   [types.FETCH_FILTER_STORE]: (state, action) => {
      return {
         ...state,
         isFilterFetching: true,
      }
   },

   [types.FETCH_FILTER_STORE_COMPLETED]: (state, action) => {
      const  { payload } = action;
      const res = payload.data;
      return {
         ...state,
         store: {
            ...payload,
            data: res,
         },
         isEmptyByFilter: res.length === 0,
      }
   },

   [types.AFTER_FILTER_COMPLETED]: (state) => {
      return {
         ...state,
         isFilterFetching: false,
      }
   },

   [types.FETCH_FILTER_STORE_FAILED]: (state, action) => {
      return {
         ...state,
         isFilterFetching: false,
      }
   },
   // get by id

   [types.FETCH_STORE_BY_ID]: (state, action) => {
      return {
         ...state,
         isFetchingResources: true,
      }
   },
   [types.FETCH_STORE_BY_ID_COMPLETED]: (state, action) => {
      let  { payload } = action;
      let newImgArray = [...JSON.parse((payload && payload?.image_full_src) && payload?.image_full_src)]
      let prefixArray = [...JSON.parse((payload && payload?.image_paths) && payload?.image_paths)]

      if(newImgArray.length) {
         newImgArray = newImgArray.map((val, index) => ({
            id: (new Date().getTime() + Math.random()).toString(),
            path: val,
            cover: payload.cover_image_index === index,
            path_prefix: prefixArray?.[index],
         }))
      }

      return {
         ...state,
         isFetching: false,
         activeStore: {
            ...payload,
            auto_count_down_after_sale: !!payload.auto_count_down_after_sale,
            send_email_out_of_stock: !!payload.send_email_out_of_stock,
            track_quantity: !!payload.track_quantity,
            newImgArray: [...newImgArray],
         },
         initialActiveStore: {
            ...payload,
            auto_count_down_after_sale: !!payload.auto_count_down_after_sale,
            send_email_out_of_stock: !!payload.send_email_out_of_stock,
            track_quantity: !!payload.track_quantity,
            newImgArray: [...newImgArray],
         },
         storeImg: {
            ...state.storeImg,
            loading: false,
         },
         isInited: !!state?.store?.data?.length,
      }
   },
   [types.FETCH_STORE_BY_ID_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingResources: false,
      }
   },

   [types.UPDATE_STORE_LIST]: (state, action) => {
      const { payload } = action
      let store = {}

      if(state?.store?.data?.length) {
         const updatedData = state.store.data.map(it => {
            if(it.id === payload.id){
               return payload
            }
            return it
         })
         store = {
            ...state.store,
            data: updatedData,
         }
      }

      return {
         ...state,
         updateError: {},
         store,
         isFetching: false,
         isInited: !!store?.data?.length,
      }
   },
   [types.UPDATE_FAILED]: (state, action) => {
      return {
         ...state,
         updateError: {
            ...state.updateError,
            ...action.payload.data,
         },
      }
   },
   [types.CHANGE_STORE_DETAILS_ERRORS]: (state, action) => {
      let { payload } = action
      if(Object.keys(payload)?.length) {
         return {
            ...state,
            updateError: {
               ...state.updateError,
               ...payload,
            },
         }
      }
      return {
         ...state,
         updateError: {
            ...payload,
         },
      }
   },

   // create new store item

   [types.CHANGE_LOADING_STATE]: (state, action) => {
      return {
         ...state,
         isFetchingResources: action.payload,
      }
   },

   [types.CREATE_NEW_STORE]: (state, action) => {
      return {
         ...state,
         newItemButtonDisabled: true,
         newItemErrorMessage: {},
      }
   },
   [types.CREATE_NEW_STORE_COMPLETED]: (state, action) => {
      return {
         ...state,
         newItemButtonDisabled: false,
         newStore: {},
         isInited: false,
      }
   },
   [types.CREATE_NEW_STORE_FAILED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         newItemButtonDisabled: false,
         newItemErrorMessage: payload,
      }
   },
   [types.SAVE_NEW_STORE_DATA]: (state, action) => {
      const { payload: { data } } = action
      let newData = {
         ...state.newStore,
         ...data,
      }

      return {
         ...state,
         newStore: {
            ...newData,
         },
      }
   },

   [types.FETCH_RESOURCES]: (state, action) => {
      return {
         ...state,
         isFetchingResources: true,
      }
   },
   [types.FETCH_RESOURCES_COMPLETED]: (state, action) => {
      const { payload } = action
      let defaulstData = [];
      let customData = [];
      if(payload.collections) {
         payload.collections.forEach(i => {
            if(!!i.system_slug){
               defaulstData.push(i)
            } else {
               customData.push(i)
            }
         })
      }
      if(!!payload.owners) {
         payload.owners = payload.owners.map(element => {
            const i = {}
            i.value = element.uuid
            i.img = element.compressed_avatar_src
            i.display_name = element.screen_name
            return i
         });
      }
      return {
         ...state,
         ...payload,
         isFetchingResources: false,
         defaultCollections: defaulstData,
         customCollections: customData,
      }
   },
   [types.FETCH_RESOURCES_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingResources: false,
      }
   },

   // get data by id

   [types.FETCH_COLLECTION_BY_ID]: (state, action) => {
      return {
         ...state,
      }
   },
   [types.FETCH_COLLECTION_BY_ID_COMPLETED]: (state, action) => {
      const { payload } = action

      return {
         ...state,
         activeCollection: payload.data,
      }
   },
   [types.FETCH_COLLECTION_BY_ID_FAILED]: (state, action) => {
      return {
         ...state,
      }
   },

   // create category
   [types.CREATE_CATEGORY]: (state, action) => {
      return {
         ...state,
         categoryActionError: null,
      }
   },
   [types.CREATE_CATEGORY_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         customCollections: [payload.data, ...state.customCollections],
         categoryActionError: null,
      }
   },
   [types.CREATE_CATEGORY_FAILED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         categoryActionError: payload.data,
      }
   },

   // delete category
   [types.DELETE_CATEGORY]: (state, action) => {
      return {
         ...state,
         categoryActionError: null,
      }
   },
   [types.DELETE_CATEGORY_COMPLETED]: (state, action) => {
      const { payload } = action
      const newData = [...state.customCollections].filter(i => i.id !== payload)
      return {
         ...state,
         customCollections: newData,
         categoryActionError: null,
      }
   },
   [types.DELETE_CATEGORY_FAILED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         categoryActionError: payload.data,
      }
   },

   // update category

   [types.UPDATE_CATEGORY]: (state, action) => {
      return {
         ...state,
         categoryActionError: null,
      }
   },
   [types.UPDATE_CATEGORY_COMPLETED]: (state, action) => {
      const { payload } = action
      let item = state.collections.find(i => i.id === payload.id)
      if(item === undefined) {
         item = state.customCollections.find(i => i.id === payload.id)
      }
      let newData = []
      if(!!item.system_slug){
         newData = [...state.defaultCollections].map(i => {
            const { ...element } = i
            if(element.id === payload.id){
               element.name = payload.name
               return element
            }
            return element
         })
         return {
            ...state,
            defaultCollections: newData,
            categoryActionError: null,
         }
      } else {
         newData = [...state.customCollections].map(i => {
            const { ...element } = i
            if(element.id === payload.id){
               element.name = payload.name
               return element
            }
            return element
         })
         return {
            ...state,
            customCollections: newData,
            categoryActionError: null,
         }
      }

   },
   [types.UPDATE_CATEGORY_FAILED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         categoryActionError: payload.data,
      }
   },
   [types.SET_STORE_IMG]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         storeImg: {
            ...state.storeImg,
            ...payload,
         },
      }
   },
   [types.SET_DELETE_MODAL]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         openDeleteModal: {
            ...state.openDeleteModal,
            ...payload,
         },
      }
   },
   [types.DELETE_STORE_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         store: {
            ...state.store,
            data: [...state.store.data].filter(i => i.id !== payload),
         },
      }
   },
   [types.SET_NEW_BUTTON_DISABLED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         newItemButtonDisabled: payload,
      }
   },

   [types.OPEN_SHIPPING_ZONE_MODAL]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         shippingZoneModal: payload,
      }
   },
   [types.FETCH_DATA_BY_FILTERS]: (state) => {
      return {
         ...state,
         isFetching: false,
      }
   },
   [types.FETCH_STORE_CATEGORIES]: (state, action) => {
      return {
         ...state,
         categoriesList: action.payload,
      }
   },
   [types.CHANGE_CHECKED_SHIPPING_ZONES]: (state, action) => {
      return {
         ...state,
         activeStore: {
            ...state.activeStore,
            shipping_zones: action.payload,
         },
      }
   },
   [types.CHANGE_STORE_DETAILS_DATA]: (state, action) => {
      let { payload } = action

      return {
         ...state,
         activeStore: {
            ...state.activeStore,
            ...payload,
         },
      }
   },
   [types.ADD_OR_REMOVE_IMAGE]: (state, action) => {
      let { payload } = action
      let cover_image

      payload?.forEach((item, index) => {
         if(item?.cover) {
            cover_image = index
         }
      })

      return {
         ...state,
         activeStore: {
            ...state.activeStore,
            newImgArray: [...payload],
            cover_image_index: cover_image,
         },
      }
   },
   [types.GET_STORE_VALIDATION_NUMBERS]: (state, action) => {
      return {
         ...state,
         storeValidationNumbers: action.payload,
      }
   },
   [types.SAVE_STORE_SCROLL_POSITION_AND_FILTERS]: (state, action) => {
      return {
         ...state,
         scrollPositonAndFilters: {
            scrollPosition: action.payload.scrollPosition,
            filters: action.payload.filters,
         },
      }
   },
   [types.FETCH_ORDER_REQUESTS_START]: (state) => {
      return {
         ...state,
         orderRequests: {
            ...state.orderRequests,
            loading: true,
         },
      }
   },
   [types.FETCH_ORDER_REQUESTS_COMPLETED]: (state, action) => {
      const { payload: { data, hasOrderRequestsForms } } = action
      return {
         ...state,
         orderRequests: {
            ...state.orderRequests,
            loading: false,
            data: data,
            isEmpty: data?.data?.length === 0,
            hasOrderRequestsForms,
         },
      }
   },
   [types.FETCH_ORDER_REQUESTS_BY_FILTER_START]: (state) => {
      return {
         ...state,
         orderRequests: {
            ...state.orderRequests,
            isFilterFetching: true,
         },
      }
   },
   [types.FETCH_ORDER_REQUESTS_BY_FILTER_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         orderRequests: {
            ...state.orderRequests,
            data: payload,
            isEmptyByFilter: payload?.data?.length === 0,
            isFilterFetching: false,
            loading: false,
         },
      }
   },
   [types.FETCH_ORDER_REQUESTS_BY_FILTER_FAIL]: (state) => {
      return {
         ...state,
         orderRequests: {
            ...state.orderRequests,
            isFilterFetching: false,
         },
      }
   },
   [types.FETCH_NEW_ORDER_REQUESTS_START]: (state) => {
      return {
         ...state,
         orderRequests: {
            ...state.orderRequests,
            isfetchingNewData: true,
         },
      }
   },
   [types.FETCH_NEW_ORDER_REQUESTS_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         orderRequests: {
            ...state.orderRequests,
            data: {
               ...state.orderRequests.data,
               ...payload,
               data: [
                  ...state.orderRequests.data.data,
                  ...payload.data,
               ],
            },
            isfetchingNewData: false,
         },
      }
   },
   [types.FETCH_NEW_ORDER_REQUESTS_FAIL]: (state) => {
      return {
         ...state,
         orderRequests: {
            ...state.orderRequests,
            isfetchingNewData: false,
         },
      }
   },

   [types.FETCH_ORDER_REQUEST_BY_ID_START]: (state) => {
      return {
         ...state,
         activeOrderRequestPageLoading: true,
      }
   },
   [types.FETCH_ORDER_REQUEST_BY_ID_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         activeOrderRequest: {
            ...payload,
            order_statuses: payload?.statuses.reverse(),
            shipping_info: { ...payload?.shipping_info },
            order_details: { ...payload?.order_details },
            item_details: { ...payload?.item_details },
            notes: payload?.notes?.reverse()?.map(it => {
               return {
                  ...it,
                  note: it.description,
               }
            }),
         },
         activeOrderRequestPageLoading: false,
      }
   },
   [types.FETCH_ORDER_REQUEST_BY_ID_FAIL]: (state) => {
      return {
         ...state,
         activeOrderRequestPageLoading: true,
      }
   },
   [types.UPDATE_ACTIVE_ORDER_REQUEST]: (state, action) => {
      return {
         ...state,
         activeOrderRequest: action.payload,
         activeOrderRequestPageLoading: !Object.keys(action?.payload).length,
      }
   },
   [types.UPDATE_ORDER_REQUESTS_DATA]: (state, action) => {
      const { payload } = action

      let requests = state?.orderRequests?.data?.data || []
      let activeOrderRequest = state?.activeOrderRequest?.order_details?.id === payload?.id ? state?.activeOrderRequest : null
      let isEmpty = state?.orderRequests?.isEmpty

      console.log(state?.orderRequests?.isEmpty, requests);

      if(!!requests?.length || state?.orderRequests?.isEmpty) {
         if(payload?.product_status_name === 'received' && !payload?.isUpdate) {
            let newOrder =  {
               order: {
                  id: payload?.id,
                  seen: false,
                  date: payload?.updated_at,
               },
               member: {
                  id: payload?.member_id,
                  avatar: payload?.member_avatar,
                  email: payload?.member_email,
                  name: payload?.member_username,
                  is_deleted: payload?.is_member_deleted,
               },
               transaction: {
                  id: null,
                  date: payload?.product_status_created_at,
                  status: null,
                  price: payload?.payload?.total_price,
               },
               webhook: null,
               product: {
                  category: payload?.product_collection_name,
                  name: payload?.name,
                  date: payload?.product_status_created_at,
                  status: payload?.product_status_name,
               },
            }

            requests = [
               newOrder,
               ...requests,
            ]
            isEmpty = false

         } else if(payload?.product_status_name !== 'received') {
            let index = state?.orderRequests?.data?.data?.findIndex(it => it.order.id === payload?.id)
            if(index !== -1) {
               requests[index] = {
                  ...requests[index],
                  transaction: {
                     ...requests[index].transaction,
                     date: payload?.product_status_created_at,
                  },
                  order: {
                     ...requests[index].order,
                  },
                  product: {
                     ...requests[index].product,
                     status: payload?.product_status_name,
                     date: payload?.product_status_created_at,
                  },
               }
            }
         }
      }

      if(!!activeOrderRequest) {
         activeOrderRequest = {
            ...activeOrderRequest,
            order_statuses: [
               {
                  created_at: payload?.product_status_created_at,
                  id: payload?.product_status_id,
                  is_current: !!payload?.product_status_is_current,
                  is_member: !!payload?.product_status_is_member,
                  name: payload?.product_status_name,
                  order_request_payment_id: payload?.id,
                  updated_at: payload?.product_status_created_at,
               },
               ...activeOrderRequest?.order_statuses,
            ],
         }
      }

      return {
         ...state,
         activeOrderRequest: activeOrderRequest ?? state?.activeOrderRequest,
         orderRequests: {
            ...state.orderRequests,
            isEmpty,
            data: {
               ...state.orderRequests.data,
               data: requests,
            },
         },
      }
   },
   [types.UPDATE_ORDER_REQUESTS_LIST_DATA]: (state, action) => {
      const { payload } = action

      let requests = state?.orderRequests?.data?.data || []

      if(!!requests?.length) {
         const index = requests.findIndex(it => it?.order?.id === payload?.order_details?.id)

         if(!!payload?.isChangeOnlySeenStatus) {
            requests[index] = {
               ...requests[index],
               order: {
                  ...requests[index].order,
                  seen: true,
               },
            }
         } else {
            const status = payload?.order_statuses?.[0]
            requests[index] = {
               ...requests[index],
               transaction: {
                  ...requests[index].transaction,
                  date: status?.created_at,
               },
               order: {
                  ...requests[index].order,
                  seen: true,
               },
               product: {
                  ...requests[index].product,
                  status: status?.name,
                  date: status?.created_at,
               },
            }
         }

      }

      return {
         ...state,
         orderRequests: {
            ...state.orderRequests,
            data: {
               ...state.orderRequests.data,
               data: requests,
            },
         },
      }
   },



   [types.FETCH_ORDER_REQUEST_FORMS]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         orderRequestForms: {
            ...state.orderRequestForms,
            loading: false,
            isEmpty: !!payload?.data?.length,
            data: payload,
         },
      }
   },
   [types.FETCH_NEW_ORDER_REQUEST_FORMS_START]: (state) => {
      return {
         ...state,
         orderRequestForms: {
            ...state.orderRequestForms,
            isfetchingNewData: true,
         },
      }
   },
   [types.FETCH_NEW_ORDER_REQUEST_FORMS_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         orderRequestForms: {
            ...state.orderRequestForms,
            data: {
               ...state.orderRequestForms.data,
               ...payload,
               data: [
                  ...state.orderRequestForms.data.data,
                  ...payload.data,
               ],
            },
            isfetchingNewData: false,
         },
      }
   },
   [types.FETCH_NEW_ORDER_REQUEST_FORMS_FAIL]: (state) => {
      return {
         ...state,
         orderRequestForms: {
            ...state.orderRequestForms,
            isfetchingNewData: false,
         },
      }
   },
   [types.DELETE_ORDER_REQUEST_FORM]: (state, action) => {
      const { payload } = action
      let newData = state.orderRequestForms.data.data.filter(item => item.id !== payload)
      return {
         ...state,
         orderRequestForms: {
            ...state.orderRequestForms,
            data: {
               ...state.orderRequestForms.data,
               data: newData,
            },
            isEmpty: !!newData.length,
         },
      }
   },

   //content bundle
   [types.FETCH_RESOURCES_FOR_NEW_BUNDLE]: (state) => {
      return {
         ...state,
         isFetchingResources: true,
      }
   },
   [types.CHANGE_NEW_BUNDLE_DATA]: (state, action) => {
      let { payload } = action
      let newData = { ...state.newBundle, ...payload }

      if(payload.hasOwnProperty('is_future_publish_enabled')) {
         if(!payload.is_future_publish_enabled) {
            delete newData.publish_date
         }
      }

      return {
         ...state,
         newBundle: {
            ...newData,
         },
      }
   },
   [types.FETCH_RESOURCES_FOR_BUNDLE_COMPLETED]: (state, action) => {
      let { payload: {
         newData, type,
      } } = action

      if(type === 'create') {
         return {
            ...state,
            newBundle: {
               ...newData,
            },
            initialBundleState: {
               ...newData,
            },
            isFetchingResources: false,
         }
      }

      return {
         ...state,
         activBundle: {
            ...newData,
         },
         initalActiveBundelState: {
            ...newData,
         },
         isFetchingResources: false,
         isInited: !!state?.store?.data?.length,
      }
   },
   [types.CHANGE_NEW_BUNDLE_ERRORS]: (state, action) => {
      let { payload } = action
      return {
         ...state,
         newBundleErrors: payload,
      }
   },
   [types.CLEAR_NEW_BUNDLE_DATA]: (state) => {
      return {
         ...state,
         newBundle: state?.initialBundleState,
         initialBundleState: state?.initialBundleState,
         isInited: false,
      }
   },
   [types.CHANGE_ACCESS_DATA]: (state, action) => {
      let { payload } = action
      return {
         ...state,
         newBundle: {
            ...state.newBundle,
            ...payload,
         },
      }
   },
   [types.SET_IMAGE_LOADER]: (state, action) => {
      const { payload } = action

      return {
         ...state,
         imageLoader: payload,
      }
   },

   //edit bundle
   [types.SET_ACTIVE_BUNDLE_ERRORS]: (state, action) => {
      const { payload } = action

      return {
         ...state,
         bundlesErrors: payload,
      }
   },
   [types.CLEAR_ACTIVE_BUNDLE_DATA]: (state) => {
      return {
         ...state,
         activBundle: { description: '' },
         initalActiveBundelState: { description: '' },
      }
   },
   [types.CHANGE_ACTIVE_BUNDLE_ACCESSES]: (state, action) => {
      const { payload } = action

      return {
         ...state,
         activBundle: { ...payload },
      }
   },
   [types.CHANGE_ACTIVE_BUNDLE_DATA]: (state, action) => {
      const { payload } = action
      let newData = { ...state.activBundle, ...payload }

      if(payload.hasOwnProperty('is_future_publish_enabled')) {
         if(!payload.is_future_publish_enabled) {
            delete newData.publish_date
         }
      }
      return {
         ...state,
         activBundle: { ...newData },
      }
   },
   [types.PIN_OR_UNPIN_BUNDLE_ITEMS]: (state, action) => {
      const { payload: { id, is_pinned } } = action
      let newStoreList = state?.store?.data ? state?.store?.data : []
      let itemIndex = state?.store?.data?.findIndex(item => id === item.id)

      if(itemIndex !== -1) {
         let storeItem = state?.store?.data?.[itemIndex]
         storeItem = { ...storeItem, is_pinned: !is_pinned }
         if(is_pinned) {
            newStoreList = newStoreList.map(item => {
               if(item.id === storeItem.id) {
                  return storeItem
               }
               return item
            })
            newStoreList = newStoreList.sort((a, b) => Number(b?.is_pinned) - Number(a?.is_pinned))
         } else {
            newStoreList.splice(itemIndex, 1)
            newStoreList.unshift(storeItem)
         }

         return {
            ...state,
            store: {
               ...state?.store,
               data: [...newStoreList],
            },
         }
      }
      return {
         ...state,
      }
   },
   [types.DELETE_STORE_BUNDLE_ITEM]: (state) => {
      return {
         ...state,
         isInited: false,
      }
   },

   //reorder
   [types.REORDER_STORE]: (state, action) => {
      return {
         ...state,
         store: {
            ...state.store,
            data: action.payload,
         },
      }
   },
   [types.CHANGE_INITED_STATE]: (state, action) => {
      return {
         ...state,
         isInited: action.payload,
      }
   },
   //reorder end


};

export default createReducer(initialState)(reducersMap);
