export const FETCH_DETAILS_START = "promotions/FETCH_DETAILS_START";
export const FETCH_DETAILS_COMPLETED = "promotions/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "promotions/FETCH_DETAILS_FAILED";

export const FETCH_DETAILS_BY_FILTER_START = "promotions/FETCH_DETAILS_BY_FILTER_START";
export const FETCH_DETAILS_BY_FILTER_COMPLETED = "promotions/FETCH_DETAILS_BY_FILTER_COMPLETED";
export const FETCH_DETAILS_BY_FILTER_FAILED = "promotions/FETCH_DETAILS_BY_FILTER_FAILED";
export const NEW_FETCH_REQUEST = "promotions/NEW_FETCH_REQUEST";
export const NEW_FETCH_DETAILS_COMPLETED = "promotions/NEW_FETCH_DETAILS_COMPLETED";
export const NEW_FETCH_REQUEST_FAILED = "promotions/NEW_FETCH_REQUEST_FAILED";
export const UPDATE_FILTERS_LIST = "promotions/UPDATE_FILTERS_LIST";

export const FETCH_BY_ID_START = "promotions/FETCH_BY_ID_START";
export const FETCH_BY_ID_COMPLETED = "promotions/FETCH_BY_ID_COMPLETED";
export const FETCH_BY_ID_FAILED = "promotions/FETCH_BY_ID_FAILED";
export const UPDATE_CREATE_DATA = "promotions/UPDATE_CREATE_DATA";
export const ADD_NEW_START = "promotions/ADD_NEW_START";
export const ADD_NEW_FAILED = "promotions/ADD_NEW_FAILED";
export const UPDATE_CREATE_ERRORS = "promotions/UPDATE_CREATE_ERRORS";

export const UPDATE_EDIT_DATA = "promotions/UPDATE_EDIT_DATA";
export const EDIT_PROMOTION_START = "promotions/EDIT_PROMOTION_START";
export const EDIT_PROMOTION_COMPLETED = "promotions/EDIT_PROMOTION_COMPLETED";
export const EDIT_PROMOTION_FAILED = "promotions/EDIT_PROMOTION_FAILED";
export const UPDATE_EDIT_ERRORS = "promotions/UPDATE_EDIT_ERRORS";

export const DELETE_PROMOTION_COMPLETED = "promotions/DELETE_PROMOTION_COMPLETED";
export const EXPIRE_PROMOTION_COMPLETED = "promotions/EXPIRE_PROMOTION_COMPLETED";
export const ACTIVATE_PROMOTION_COMPLETED = "promotions/ACTIVATE_PROMOTION_COMPLETED";

export const ACTION_WITH_PROMOTION_START = "promotions/ACTION_WITH_PROMOTION_START";
export const ACTION_WITH_PROMOTION_FAILED = "promotions/ACTION_WITH_PROMOTION_FAILED";
export const DUPLICATE_PROMOTION_COMPLETED = "promotions/DUPLICATE_PROMOTION_COMPLETED";

export const UPDATE_DUPLICATE_ERRORS = "promotions/UPDATE_DUPLICATE_ERRORS";

export const UPDATE_ACTIONS_ERRORS = "promotions/UPDATE_ACTIONS_ERRORS";

export const INIT_PROMOTIONS_FOR_FILTER_START = "promotions/INIT_PROMOTIONS_FOR_FILTER_START";
export const INIT_PROMOTIONS_FOR_FILTER_COMPLETED = "promotions/INIT_PROMOTIONS_FOR_FILTER_COMPLETED";
export const INIT_PROMOTIONS_FOR_FILTER_FAILED = "promotions/INIT_PROMOTIONS_FOR_FILTER_FAILED";
export const INIT_INFO_START = "promotions/INIT_INFO_START";
