export const SET_STREAM_INFO = "livestream/SET_STREAM_INFO";
export const FETCH_INDEX_REQUEST = "livestream/FETCH_INDEX_REQUEST";
export const FETCH_NEX_RECORDS_REQUEST = "livestream/FETCH_NEX_RECORDS_REQUEST";
export const FETCH_NEX_RECORDS_COMPLETED = "livestream/FETCH_NEX_RECORDS_COMPLETED";
export const UPDATE_DATA_COMPLETED = "livestream/UPDATE_DATA_COMPLETED";
export const FETCH_LIVE_STREAM_MESSAGES = "livestream/FETCH_LIVE_STREAM_MESSAGES";
export const FETCH_LIVE_STREAM_MESSAGES_COMPLETED = "livestream/FETCH_LIVE_STREAM_MESSAGES_COMPLETED";
export const SET_LIVE_STREAM_CONVERSATION_STATE = "livestream/SET_LIVE_STREAM_CONVERSATION_STATE";
export const UPDATE_MESSAGE = "livestream/UPDATE_MESSAGE";
export const ADD_MESSAGE = "livestream/ADD_MESSAGE";
export const SET_TYPER_ACTION = "livestream/SET_TYPER_ACTION";
export const DELETE_MESSAGE_ACTION = "livestream/DELETE_MESSAGE_ACTION";
export const LIKE_MESSAGE_ACTION = "livestream/LIKE_MESSAGE_ACTION";
export const SET_IS_CONNECTED_ACTION = "livestream/SET_IS_CONNECTED_ACTION";
export const SET_ONLINE_USERS_ACTION = "livestream/SET_ONLINE_USERS_ACTION";
///////////////////////////////////////////////////////////////
export const FETCH_LIKED_MEMBER_BY_MESSSAGE_REQUEST = 'livestream/FETCH_LIKED_MEMBER_BY_MESSSAGE_REQUEST';
export const FETCH_LIKED_MEMBER_BY_MESSSAGE_COMPLETED = 'livestream/FETCH_LIKED_MEMBER_BY_MESSSAGE_COMPLETED';
export const FETCH_LIKED_MEMBER_BY_MESSSAGE_FAILED = 'livestream/FETCH_LIKED_MEMBER_BY_MESSSAGE_FAILED';
///
export const FETCH_NEW_MESSAGES_REQUEST = 'livestream/FETCH_NEW_MESSAGES_REQUEST';
export const FETCH_NEW_MESSAGES_COMPLETED = 'livestream/FETCH_NEW_MESSAGES_COMPLETED';
export const FETCH_NEW_MESSAGES_FAILED = 'livestream/FETCH_NEW_MESSAGES_FAILED';

export const SET_STREAM_CONVERASTION_INFO = 'livestream/SET_STREAM_CONVERASTION_INFO';
export const UPDATE_CURRENT_DATA = 'livestream/UPDATE_CURRENT_DATA';
export const SET_TIP_GOALL_ACHIEVED_MODAL = 'livestream/SET_TIP_GOALL_ACHIEVED_MODAL';
export const SET_TIP_ACTIONS_GOALL_ACHIEVED_MODAL = 'livestream/SET_TIP_ACTIONS_GOALL_ACHIEVED_MODAL';
export const SET_TIP_HISTORY_ACTION = 'livestream/SET_TIP_HISTORY_ACTION';
export const SET_ACTIVE_VIEWER_STATES = 'livestream/SET_ACTIVE_VIEWER_STATES';
export const ACTIVE_VIEWER_FILTER = 'livestream/ACTIVE_VIEWER_FILTER';
export const ACTIVE_VIEWER_CLEAR_FILTER = 'livestream/ACTIVE_VIEWER_CLEAR_FILTER';
export const CREATE_OR_UPDATE_RECORD_STREAM = 'livestream/CREATE_OR_UPDATE_RECORD_STREAM';
export const SET_DELET_RECORD_BUTTON_LOADING = 'livestream/SET_DELET_RECORD_BUTTON_LOADING';
export const SET_WARNING_ACTIVITY_MODAL = 'livestream/SET_WARNING_ACTIVITY_MODAL';
export const UPDATE_TIP_ACTION_DATA = 'livestream/UPDATE_TIP_ACTION_DATA';
export const SET_DELETE_UPCOMING_STATE = 'livestream/SET_DELETE_UPCOMING_STATE';
export const SET_DELETE_UPCOMING_COMPLETED = 'livestream/SET_DELETE_UPCOMING_COMPLETED';
export const UPDATE_SAVED_DATA = 'livestream/UPDATE_SAVED_DATA';
export const SET_VALIDATION_ERROR = 'livestream/SET_VALIDATION_ERROR';
export const FETCH_CHECK_VALISATION_REQUEST = 'livestream/FETCH_CHECK_VALISATION_REQUEST';
export const FETCH_CREATE_LIVESTREAM_REQUEST = 'livestream/FETCH_CREATE_LIVESTREAM_REQUEST';

//reorder
export const REORDER_RECORDED_STREAMS = 'livestream/REORDER_RECORDED_STREAMS'

export const PIN_RECORDED_STREAM_COMPLETED = 'livestream/PIN_RECORDED_STREAM_COMPLETED'
export const SET_WARNING_MODAL = 'livestream/SET_WARNING_MODAL'

export const UPDATE_LIVESTREAM_MESSAGES_BY_ID = 'livestream/UPDATE_LIVESTREAM_MESSAGES_BY_ID'
