import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import Icon from 'common/components/elements/icons';
import Loader from 'common/components/elements/loader/circle';
import MediaChip from 'common/components/modules/media-chip';
import cx from 'classnames';
// import ChatSellingPriceInput from './price-input';
import WarningModal from '../../content-warning-modal';
import LocalStorage from 'sites/utils/LocalStorage';
import CheckBox from 'common/components/elements/inputs/checkbox-label';
import '../style.scss'
import ConfirmModal from 'common/components/modules/modal-wrapper';
import Input from 'common/components/elements/inputs/inputs';

const ChatSellingContent = ({
   prevFiles,
   removeFile,
   uploadFile,
   isCanUploadNew,
   type,
   count,
   onClickClose,
   sendButtonDisabled,
   isMobile,
   isBlurPoster,
   onSavePrice,
   price,
   isGroup,
   startSendContent,
   maxPrice,
   allowDownload,
   isAdmin,
   isUpdateState,
   isMassMessage,
   onCustomAction,
   isMultipleAttachments,
   attachments,
   attachmentsForShow,
   onRemoveMedia,
   // setUnlockPrice,
   isMaxAttachmentCountReached,
}) => {
   const myRef = useRef(null);
   const containerRef = useRef(null)
   // const [unlockPrice, setUnlockPrice] = useState('');
   const [value, setValue] = useState('');
   const [activeState, setActiveState] =  useState('setContent');
   const [error, setError] =  useState(null);
   const [showWarningModal, setShowWarningModal] =  useState({
      isOpen: false,
      isShowRemoveBtn: false,
   });
   let buttonState = {
      padding: 'none',
      textColor: 'secondary dark:text-secondary-dark',
      borderRadius: 'large',
      textSize: 'small',
   }
   const [initialModalState, setInitialModalState] = useState({
      price: value,
      isBlurPoster,
   })

   if(startSendContent) {
      buttonState = {
         ...buttonState,
         classNames: ' opacity-50 cursor-default',
      }
   }
   useEffect(() => {
      if(myRef && myRef.current && 'setPrice' === activeState){
         myRef.current.autofocus = true
         myRef.current.click()
         myRef.current.focus()
         setInitialModalState({
            price: value,
            isBlurPoster,
         })
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [activeState, myRef])
   useEffect(() => {
      // setUnlockPrice(+price || '')
      if(price){
         setValue(+price || '')
      }
   }, [price])

   const savePriceAmount = (isRemove) => {
      if(isRemove) {
         // setUnlockPrice(0)
         setActiveState('setContent')
         setValue('')
         onSavePrice({ price: '' })
      } else {
         let price = +value;
         let invalidPrice = price < 0.25 || price > maxPrice || price === ''
         if(!invalidPrice){
            const dontShow = +LocalStorage.getItem('dont_show_chat_coast_warning_modal')
            if(price <= 4 && !dontShow) {
               setShowWarningModal({
                  ...showWarningModal,
                  isOpen: true,
               })
            } else {
               savePrice()
            }

         } else {
            setError(`Price must be between 0.25 and ${ maxPrice } USD`)
         }
      }
   }

   const savePrice = () => {
      setActiveState('setContent')
      onSavePrice({ price: value })
      if(isMassMessage && !!onCustomAction) {
         onCustomAction('unlock_price', String(value))
      }
   }
   const isLoadPhoto = () => {
      if('photo' === type || 'photo_vault' === type){
         const asd = prevFiles.find(el => 'uploading' === el.status);
         return Boolean(asd)
      }
      return false
   }

   let video = {}
   if('upload_video' === type) {
      video = {
         src: prevFiles[0].video_poster_full_src,
         status: prevFiles[0].video_optimization_status || prevFiles[0].uploadingStatus,
         duration: prevFiles[0].video_duration,
         percentage: prevFiles[0].video_optimization_status !== 'optimizing' ? prevFiles[0].percentage : prevFiles[0].video_optimization_percentage,
      }
   }

   const handleMouseDown = useCallback((e) => {
      const ele = containerRef.current;
      if(!ele) {
         return;
      }
      const startPos = {
         left: ele.scrollLeft,
         top: ele.scrollTop,
         x: e.clientX,
         y: e.clientY,
      };

      const handleMouseMove = (e) => {
         const dx = e.clientX - startPos.x;
         const dy = e.clientY - startPos.y;
         ele.scrollTop = startPos.top - dy;
         ele.scrollLeft = startPos.left - dx;
         updateCursor(ele);
      };

      const handleMouseUp = () => {
         document.removeEventListener('mousemove', handleMouseMove);
         document.removeEventListener('mouseup', handleMouseUp);
         resetCursor(ele);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
   }, []);

   const handleTouchStart = useCallback((e) => {
      const ele = containerRef.current;
      if(!ele) {
         return;
      }
      const touch = e.touches[0];
      const startPos = {
         left: ele.scrollLeft,
         top: ele.scrollTop,
         x: touch.clientX,
         y: touch.clientY,
      };

      const handleTouchMove = (e) => {
         const touch = e.touches[0];
         const dx = touch.clientX - startPos.x;
         const dy = touch.clientY - startPos.y;
         ele.scrollTop = startPos.top - dy;
         ele.scrollLeft = startPos.left - dx;
         updateCursor(ele);
      };

      const handleTouchEnd = () => {
         document.removeEventListener('touchmove', handleTouchMove);
         document.removeEventListener('touchend', handleTouchEnd);
         resetCursor(ele);
      };

      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd);
   }, []);

   const updateCursor = (ele) => {
      ele.style.cursor = 'grabbing';
      ele.style.userSelect = 'none';
   };

   const resetCursor = (ele) => {
      ele.style.cursor = 'default';
      ele.style.removeProperty('user-select');
   };

   const buttons = (
      <>
         <div>
            {
               !('setPrice' === activeState && value) &&
                  <Button
                     { ...buttonState }
                     backgroundColor='transparent'
                     textMarginX='0'
                     text='Cancel'
                     disabled={ startSendContent || isLoadPhoto() }
                     onClick={ () => {
                        if('setContent' === activeState) {
                           onClickClose()
                        } else {
                           setError(null)
                           let price = value < 0.25 ? '' : value
                           setValue(price)
                           setActiveState('setContent')
                        }
                     } }
                  />
            }
         </div>

         {
            isAdmin && 'setContent' !== activeState && (
               <div className='h-9'>
                  <Button
                     classNames='h-full px-3'
                     padding='none'
                     textColor='secondary dark:text-secondary-dark'
                     borderRadius='large'
                     textSize='base'
                     text={ 'setContent' === activeState ? 'Send' : 'Save' }
                     primaryColor
                     onClick={ () => savePriceAmount() }
                     disabled={ 'setContent' === activeState ?  sendButtonDisabled : (!!error  && value === '') }
                  />
               </div>

            )
         }
      </>
   )
   return (
      <>
         {
            showWarningModal?.isOpen && (
               <WarningModal
                  isMobile={ isMobile }
                  info='Items that cost less than 4.00 USD can be purchased only using the <span class="font-medium">Wallet.</span>'
                  actionText='Ok'
                  isCheckbox
                  action={ () => {
                     setShowWarningModal({
                        ...showWarningModal,
                        isOpen: false,
                     })
                     savePrice()
                  } }
                  closeModal={ () => {
                     setShowWarningModal({
                        ...showWarningModal,
                        isOpen: false,
                     })
                  } }
                  showNewStyles
                  hideCloseButton
                  iconColor='action'
                  iconName='wallet'
               />

            )
         }
         <div
            className='flex flex-col max-w-full bg-panel dark:bg-panel-dark send-message-container'
         >
            {
               isAdmin &&  'setPrice' === activeState &&
               <ConfirmModal
                  title='Set price to unlock'
                  type='confirm'
                  icon='money'
                  iconColor='action'
                  buttonClassName='mt-8'
                  descriptionColorClass='font-normal'
                  contentWidth='375px'
                  buttonText='Save'
                  action={ () => {
                     savePriceAmount()
                  } }
                  cancelButtonAction={ () => {
                     if('setContent' === activeState) {
                        onClickClose()
                        onSavePrice({ initialModalState })
                     } else {
                        setError(null)
                        setValue(initialModalState?.price)
                        onSavePrice({ isBlurPoster: initialModalState?.isBlurPoster })
                        setActiveState('setContent')
                     }
                  } }
                  isPreventDeafault={ false }
                  isMobile={ isMobile }
                  secondButtonTitle={ !initialModalState?.price ? '' : 'Remove price' }
                  secondButtonAction={ () => {
                     savePriceAmount(true)
                  } }
               >
                  <div className=''>
                     <Input
                        inputRef={ myRef }
                        rightText='USD'
                        name='unlockPrice'
                        pattern='d\*'
                        type='number'
                        onChange={ (name, value) => {
                           let maxCherecters = maxPrice >= 100 ? 3 : 2
                           if(value.includes('.')) {
                              const test = value.split('.')
                              if(maxPrice >= 100){
                                 maxCherecters = test[0] && test[0].length < 4 ? test[0].length + 3 : 6
                              } else {
                                 maxCherecters = test[0] && test[0].length < 3 ? test[0].length + 3 : 5
                              }
                           }
                           if(value.length > maxCherecters) {
                              return
                           }
                           if(error) {
                              setError(null)
                           }
                           setValue(value)
                        } }
                        value={ value }
                        className={ 'my-4 !flex !items-center !justify-center' }
                        onInputFocus={ () => {
                           setError(null)
                        }  }
                        errorMessage={ error }
                        isPriceInput
                     />
                     <CheckBox
                        label='Blur poster image'
                        name='blur_poster_image'
                        checked={ isBlurPoster }
                        onChange={ () => {
                           onSavePrice({ isBlurPoster: !isBlurPoster })
                        } }
                        textColor='secondary dark:text-secondary-dark'
                        padding='none'
                        disabled={ startSendContent }
                        className={ cx({
                           'opacity-50 cursor-default': startSendContent,
                           'cursor-pointer': !startSendContent,
                        }) }
                        labelClassName='mx-auto !w-[fit-content]'
                        textSize='base'
                     />
                  </div>
               </ConfirmModal >
            }
            {
               'setContent' === activeState && (
                  <>
                     {
                        !isMultipleAttachments && (type && (type.includes('video')  || 'photoset' === type)) && (
                           <>
                              <MediaCard
                                 removeFile={ e => removeFile(e, null, prevFiles[0] ? prevFiles[0].uploadingStatus : null) }
                                 src={ 'upload_video' === type ? video.src : prevFiles[0] }
                                 type={ type }
                                 count={ 'upload_video' === type ? video.duration : count }
                                 status={ 'upload_video' === type ? video.status : 'completed' }
                                 percentage={ video?.percentage?.toString()  }
                                 startSendContent={ startSendContent }
                                 title={ video?.title || '' }
                              />
                           </>
                        )
                     }
                     {
                        isMultipleAttachments && attachmentsForShow.length > 0 && (
                           <div id='multiple_attachments' className='min-h-[80px] max-h-[100px] w-full flex overflow-x-auto ams-custom-scrool'>
                              <label
                                 className={ cx({
                                    'border border-divider dark:border-divider-dark flex justify-center items-center mr-4 chat-upload-image bg-panel dark:bg-panel-dark rounded-lg': true,
                                    'opacity-50 !cursor-default': startSendContent || isMaxAttachmentCountReached,
                                    'cursor-pointer': !startSendContent,
                                 }) }
                                 htmlFor='chat_image_several'
                                 data-tooltip-content={ isMaxAttachmentCountReached ? 'Limit of 20 attachments has been reached' : '' }
                                 data-tooltip-id='ams-top-tooltip'
                              >
                                 <div className='w-6 h-6 flex items-center'>
                                    <Icon
                                       name='add-photos'
                                       size='2xl'
                                       color='secondary'
                                       darkColor='secondary-dark'
                                    />
                                 </div>
                                 <input
                                    id='chat_image_several'
                                    type='file'
                                    hidden
                                    multiple
                                    disabled={ isMaxAttachmentCountReached }
                                    onChange={ ('photo_vault' === type || startSendContent) ? null : e => {
                                       uploadFile(e);
                                    } }
                                    accept='image/png, image/jpeg, image/jpg, image'
                                 />
                              </label>
                              {
                                 attachmentsForShow.map((media, index) => {

                                    const getSrc = (item) => {
                                       if(item?.type === 'photo') {
                                          return media?.prevFiles[0]?.src
                                       }
                                       if(['video', 'upload_video', 'photoset', 'video_vault', 'photo_vault'].includes(item?.type) &&  item?.status === 'completed') {
                                          return item?.full_src
                                       }
                                       else {
                                          return null
                                       }
                                    }

                                    const getCount = (item) => {
                                       if(item?.type === 'photoset') {
                                          return item?.count
                                       }
                                       if(['video', 'upload_video', 'photoset', 'video_vault'].includes(item?.type)) {
                                          return item?.count
                                       }
                                    }

                                    const getType = (item) => {
                                       if(['photo', 'video', 'upload_video', 'photoset', 'video_vault', 'photo_vault'].includes(item?.type)) {
                                          return item?.type
                                       }
                                    }

                                    const getStatus = (item) => {
                                       if(item?.type === 'photo') {
                                          return item?.status === 'uploading' ? 'uploading' : 'completed'
                                       }
                                       if(item?.type === 'video') {
                                          return item?.video_optimization_status || item?.uploadingStatus || item?.status
                                       }
                                       if(['photo', 'video', 'upload_video', 'photoset', 'video_vault', 'photo_vault'].includes(item?.type)) {
                                          return item?.status
                                       }
                                    }

                                    return <>
                                       <MediaCard
                                          key={ index }
                                          removeFile={ () => onRemoveMedia(media, index) }
                                          src={ getSrc(media) }
                                          type={ getType(media) }
                                          count={ getCount(media) }
                                          status={ getStatus(media) }
                                          percentage={ !getSrc(media) ? media?.percentage?.toString() : 0  }
                                          startSendContent={ startSendContent }
                                          isMultipleAttachments
                                          media={ media }
                                          dontCancel={ getStatus(media) !== 'completed'  && getType(media) === 'photo' }
                                          title={ media?.title || '' }
                                       />
                                    </>
                                 })
                              }
                           </div>
                        )
                     }
                     {
                        !isMultipleAttachments &&  ('photo' === type || 'photo_vault' === type) && (
                           <div
                              ref={ containerRef }
                              className='flex items-center overflow-x-auto show-scroll ams-custom-scrool'
                              role='presentation'
                              onMouseDown={ handleMouseDown }
                              onTouchStart={ handleTouchStart }
                           >
                              {
                                 isCanUploadNew && 'photo' === type  &&  (
                                    <label
                                       className={ cx({
                                          'border border-divider dark:border-divider-dark flex justify-center items-center mr-4 chat-upload-image bg-panel dark:bg-panel-dark rounded-lg': true,
                                          'opacity-50 !cursor-default': startSendContent || isMaxAttachmentCountReached,
                                          'cursor-pointer': !startSendContent,
                                       }) }
                                       htmlFor='chat_image_several'
                                       data-tooltip-content={ isMaxAttachmentCountReached ? 'Limit of 20 attachments has been reached' : '' }
                                       data-tooltip-id='ams-top-tooltip'
                                    >
                                       <div className='w-6 h-6 flex items-center'>
                                          <Icon
                                             name='add-photos'
                                             size='2xl'
                                             color='secondary'
                                             darkColor='secondary-dark'
                                          />
                                       </div>
                                       <input
                                          id='chat_image_several'
                                          type='file'
                                          hidden
                                          multiple
                                          disabled={ isMaxAttachmentCountReached }
                                          onChange={ ('photo_vault' === type || startSendContent) ? null : e => {
                                             uploadFile(e);
                                          } }
                                          accept='image/png, image/jpeg, image/jpg, image'
                                       />
                                    </label>
                                 )
                              }
                              {
                                 isCanUploadNew && 'photo_vault' === type  &&  (
                                    <div
                                       className={ cx({
                                          'border flex justify-center items-center mr-4 chat-upload-image bg-panel dark:bg-panel-dark rounded-lg': true,
                                          'opacity-50 !cursor-default': startSendContent || isMaxAttachmentCountReached,
                                          'cursor-pointer': !startSendContent,
                                       }) }
                                       role='presentation'
                                       onClick={ (startSendContent || isMaxAttachmentCountReached)  ? null : () => {
                                          uploadFile();
                                       } }
                                       htmlFor='chat_image_several'
                                       data-tooltip-content={ isMaxAttachmentCountReached ? 'Limit of 20 attachments has been reached' : '' }
                                       data-tooltip-id='ams-top-tooltip'
                                    >
                                       <div className='w-6 h-6 flex items-center'>
                                          <Icon
                                             name='add-photos'
                                             size='2xl'
                                             color='secondary'
                                             darkColor='secondary-dark'
                                          />
                                       </div>
                                    </div>
                                 )
                              }

                              {
                                 !isMultipleAttachments && prevFiles  && prevFiles.map((prevFile, index) => {
                                    let src = prevFile
                                    let status = 'completed'
                                    if(typeof prevFile !== 'string'){
                                       src = prevFile.src
                                       status = prevFile.status
                                    }
                                    return (
                                       <MediaCard
                                          key={ index.toString() }
                                          removeFile={ e => removeFile(e, index) }
                                          status={ status }
                                          src={ src }
                                          type={ type }
                                          count={ 10 }
                                          startSendContent={ startSendContent }
                                          dontCancel={ status === 'uploading' }
                                          title={ prevFile?.title || '' }
                                       />
                                    );
                                 })}
                           </div>

                        )
                     }
                     {
                        isAdmin && !isGroup &&
                        <div className='flex justify-between gap-3 mt-3 flex-wrap'>
                           <PriceContent
                              onClick={ () => {
                                 setActiveState('setPrice')
                              } }
                              buttonState={ buttonState }
                              price={ +value }
                              startSendContent={ startSendContent }
                           />
                           <CheckBox
                              label='Allow downloads'
                              name={ isMassMessage ? 'allow_download_mass_message' : 'allow_download' }
                              checked={ allowDownload }
                              onChange={ (name, value) => onSavePrice({ 'allow_download': value }) }
                              textColor='secondary dark:text-secondary-dark'
                              padding='none'
                              disabled={ startSendContent }
                              labelClassName={ cx({
                                 '!w-fit': true,
                                 'opacity-50': startSendContent,
                              }) }
                              className={ `${ startSendContent ? '!cursor-default' : '' }` }
                           />
                        </div>
                     }
                  </>
               )
            }

            <div className={ `flex justify-between py-3 pb-4 ${ (isGroup || !isAdmin) ? 'mt-4' : '' }` }>
               {
                  'setPrice' === activeState && value ?
                     <>
                        <div className=''>
                           <Button
                              { ...buttonState }
                              disabled={ startSendContent }
                              backgroundColor='transparent'
                              text='Remove price'
                              onClick={ () => savePriceAmount(true) }
                           />
                        </div>
                        <div className='flex h-9 items-center'>
                           {buttons}
                        </div>
                     </>
                     : buttons
               }
            </div>
         </div>
      </>
   )
};

const MediaCard = ({
   type,
   status = 'completed',
   src,
   removeFile,
   count,
   percentage,
   startSendContent,
   dontCancel,
   isMultipleAttachments,
   title,
}) => {
   const icons = {
      photoset: 'photosets',
      video: 'video-camera',
      photo_vault: 'vault-new',
      video_vault: 'vault-new',
   }

   const tooltips = {
      photoset: title,
      video: title,
      photo_vault: 'Vault image',
      video_vault: 'Vault video',
   }

   const isComplited = src && ['completed', 'ready'].includes(status)
   return (
      <div
         className={ cx({
            'media-item relative rounded-lg flex items-center justify-center !overflow-hidden bg-no-repeat bg-cover bg-center mr-2 aspect-video min-w-[143px] ': true,
            'max-w-[143px]': type && (type.includes('video') || 'photoset' === type),
            'bg-placeholder-12 dark:text-placeholder-dark-12': !!status,
         }) }
         data-tooltip-id='ams-top-tooltip'
         data-tooltip-content={ tooltips?.[type] || null }
      >
         {
            !dontCancel &&
               <div
                  className={ cx({
                     'media-item-remove absolute w-5 h-5 top-0 right-0 flex rounded-lg items-center justify-center z-10': true,
                     'opacity-50 cursor-default': startSendContent,
                     'cursor-pointer': !startSendContent,
                  }) }
                  role='presentation'
                  onClick={ startSendContent ? null : (e) => removeFile(e) }
                  data-tooltip-id='ams-top-tooltip'
                  data-tooltip-content='Remove'
               >
                  <Icon
                     name='cancel'
                     size='xl'
                     color='major-dark'
                  />
               </div>
         }
         {
            isComplited &&
            <div className='w-full h-full'>
               <div
                  className='w-full h-full absolute top-0  rounded-lg z-2 flex items-center justify-center bg-major-20'
                  style={ {
                     // background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.0525) 0%, rgba(0, 0, 0, 0.1855) 0%, rgba(0, 0, 0, 0) 27.86%), linear-gradient(180deg, rgba(0, 0, 0, 0) 62.09%, rgba(0, 0, 0, 0.2275) 100%), url(.jpg)',
                  } }
               >
                  <Icon
                     name={ icons?.[type] }
                     color='major-dark'
                     size='xl'
                  />
               </div>
               <img
                  src={ src }
                  alt=''
                  className={
                     cx({
                        'w-full h-full object-cover absolute blur': true,
                     })
                  }
                  style={ {
                     background: "linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0) 27.86%),linear-gradient(180deg, rgba(0, 0, 0, 0) 62.09%, rgba(0, 0, 0, 0.65) 100%)",
                  } }
               />
               <img
                  src={ src }
                  alt=''
                  className={
                     cx({
                        'w-full h-full object-contain sortable-image absolute': true,
                     })
                  }
               />
            </div>
         }
         <div className='flex flex-col items-center'>
            {
               ('uploading' === status || 'optimizing' === status) && (
                  <Loader size='6' color='major-dark' className='border-[2px]' />
               )
            }
            {
               ('in_optimization_queue' === status || 'pending' === status) && (
                  <>
                     <div className='h-8 w-8 flex items-center justify-center' >
                        <Icon
                           name='clock'
                           size='3xl'
                           color='secondary dark:text-secondary-dark'
                        />
                     </div>
                     <span className='text-secondary dark:text-secondary-dark text-sm mt-2 text-center'>{'pending' === status ? 'Upload will start soon' : 'In optimization queue'}</span>
                  </>
               )
            }
            {
               !!percentage && 'in_optimization_queue' !== status  && (
                  <span className='text-secondary dark:text-secondary-dark text-sm mt-1'>{status === 'optimizing' ? `Optimizing(${ percentage }%)` :  `${ percentage }%` }</span>
               )
            }
         </div>
         {
            type && (type === 'photoset' || type.includes('video')) && isComplited &&
            <div
               className='w-full absolute bottom-0 left-0 gap-1 z-10'
               style={ {
                  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%)',
               } }
            >
               <MediaChip
                  countOrDuration={ count }
                  type={ type }
                  className={ cx({
                     'p-1': true,
                  }) }
                  name=''
                  photoIcon='photosets'
                  isBlurBackground={ false }
               />
            </div>
         }
      </div>
   )
}

const PriceContent = ({
   buttonState,
   onClick,
   price,
   startSendContent,
}) => {
   const isPriceNullable = price === 0
   return (
      <div className='flex items-center'>
         {
            price !== 0 &&
               <div className='flex-1 flex items-center mr-3'>
                  <span className='text-placeholder dark:text-placeholder-dark leading-tighti ml-2px'> Price: <span className='text-major dark:text-major-dark'>{price} USD</span></span>
               </div>
         }
         <div className='mx-auto'>
            <Button
               classNames='h-5'
               lineHeight='5'
               { ...buttonState }
               disabled={ startSendContent }
               backgroundColor='transparent'
               primaryColor
               textMarginX='0'
               textClassName={ isPriceNullable ? 'ml-2' : 'mr-2' }
               iconPosition={ isPriceNullable ? 'left' : 'right' }
               fontIconSize={  'base' }
               text={ isPriceNullable ? 'Set price to unlock' : 'Edit' }
               iconName={ isPriceNullable ? 'money' : 'pencil' }
               onClick={ onClick }
            />
         </div>

      </div>
   )
}

ChatSellingContent.propTypes = {
   prevFiles: PropTypes.any,
   removeFile: PropTypes.func,
   uploadFile: PropTypes.func,
   // onSendMessage: PropTypes.func,
   onClickClose: PropTypes.func,
   isCanUploadNew: PropTypes.bool,
   sendButtonDisabled: PropTypes.bool,
   defaultPrice: PropTypes.any,
   type: PropTypes.string,
   onSavePrice: PropTypes.func,
   price: PropTypes.any,
   count: PropTypes.any,
   isMobile: PropTypes.bool,
   isBlurPoster: PropTypes.bool,
   isGroup: PropTypes.bool,
   startSendContent: PropTypes.bool,
   allowDownload: PropTypes.bool,
   isAdmin: PropTypes.bool,
   maxPrice: PropTypes.number,
   isUpdateState: PropTypes.bool,
   isMassMessage: PropTypes.bool,
   onCustomAction: PropTypes.func,
   attachments: PropTypes.array,
   attachmentsForShow: PropTypes.array,
   isMultipleAttachments: PropTypes.bool,
   onRemoveMedia: PropTypes.func,
   isMaxAttachmentCountReached: PropTypes.bool,
};
MediaCard.propTypes = {
   removeFile: PropTypes.func,
   type: PropTypes.string,
   status: PropTypes.string,
   src: PropTypes.any,
   count: PropTypes.any,
   percentage: PropTypes.number,
   startSendContent: PropTypes.bool,
   dontCancel: PropTypes.bool,
   isMultipleAttachments: PropTypes.bool,
   title: PropTypes.string,
};
PriceContent.propTypes = {
   buttonState: PropTypes.object,
   onClick: PropTypes.func,
   price: PropTypes.number,
   startSendContent: PropTypes.bool,
};

export default ChatSellingContent;
