import React from 'react';
import PropTypes from 'prop-types';
import Card from '../card';
import Button from 'common/components/elements/buttons/primary';
import CircleLoader from 'common/components/elements/loader/circle';
import EmptyState from 'common/components/modules/empty-states';
import Filter from 'common/components/responsive/filter';
import '../style.scss'
import cx from 'classnames'
import { createPortal } from 'react-dom';
import Intercom from 'common/utils/Intercom';
import { ARTICLE_ID_ON_THE_VAULT } from 'common/utils/intercom-articles';

const ImagesContent = ({
   isInsideModal,
   data,
   isFetchingByFilter,
   desktopType,
   isNewFetching,
   openDeleteConfirmModal,
   removeFile,
   selectedCards,
   selectCard,
   filters,
   handleAddNewVault,
   onSimpleFilter,
   isFetching,
   isEmpty,
   goToReorder,
   contentType,
   screenWidth,
   onDeleteMessag,
   updateVaultMessageAction,
   updateMessageUnlockDetails,
   isDemoMode,
}) => {

   const filtersIntoModal = ['store', 'chat'].includes(contentType) && screenWidth > 1024

   const view = <div
      className={ cx({
         'vault-search-sorting flex': true,
         '!px-6': filtersIntoModal,
         'pb-6': filtersIntoModal && screenWidth > 1025,
      }) }
      id='photo_vaults-filter-content'
   >
      <div className='w-[fit-content] z-20'>
         <Filter
            name='Sorting'
            data={ [
               { name: 'Newest', value: 'date_added:desc' },
               { name: 'Oldest', value: 'date_added:asc' },
            ] }
            desktopType={ desktopType }
            className=''
            onFilterChange={ (value, name) => onSimpleFilter(value, name) }
            activeSelectedValue={ {
               name: filters.value === 'date_added:desc' ? '' : filters.name,
               value: filters.value,
            } }
            height='10'
            contentStyles={ {
               left: '0',
               top: '44px',
               width: '200px',
            } }
         />
      </div>
   </div>

   if(isFetching){
      return (
         <div className='empty-height flex flex-col h-full'>
            <div className='flex flex-col h-full items-center justify-center'>
               <CircleLoader color='blue' />
            </div>
         </div>
      )
   }
   if(isEmpty){
      return (
         <div className='empty-height flex h-full items-center'>
            <EmptyState
               icon='vault-v2'
               noContentText='You don’t have any photos your vault'
               buttonText={ isInsideModal ? null : 'Add new photos' }
               className='h-full'
               onAdd={ isInsideModal ? null : handleAddNewVault }
               linkButtonText='More info on the Vault'
               linkButtonIcon='attention-outline'
               onLinkClick={ () => Intercom.showArticle(ARTICLE_ID_ON_THE_VAULT) }
               dontAddIntercomClassName
               mainButtonDisabled={ isDemoMode }
               buttonDisabled={ isDemoMode }
            />
         </div>
      )
   }
   return (
      <div className='vault'>
         <div className={
            cx({
               'vault-header': true,
               'py-2': isInsideModal,
               'p-6 pt-8  flex flex-col w-full gap-2': !isInsideModal,
               '!pt-6 !px-4': desktopType === 'mobile',
               '!py-0': filtersIntoModal,
            })
         }
         >
            {
               !isInsideModal && (
                  <div
                     className={
                        cx({
                           ' flex flex-col-reverse lg:flex-row w-full justify-between lg:items-center gap-2': true,
                        })
                     }
                  >
                     <div
                        className={
                           cx({
                              ' flex  gap-2 flex-row': true,
                              'flex-col': desktopType === 'mobile',
                           })
                        }>
                        <Button
                           text='Add new photos'
                           backgroundColor='action'
                           iconName='add'
                           iconPosition='left'
                           borderRadius='large'
                           onClick={ handleAddNewVault }
                           classNames='px-5 py-2.5 !w-[fit-content]'
                           textMarginX='0'
                           textClassName='ml-2'
                           disabled={ isDemoMode }
                           dataToolTipContent={ isDemoMode ? 'Not available in demo' : '' }
                        />
                     </div>
                     <div className='vault-more-info '>
                        <Button
                           text='More info on the Vault'
                           textSize='small'
                           iconName='attention-outline'
                           fontIconSize='base'
                           iconPosition='left'
                           backgroundColor='transparent'
                           padding='none'
                           classNames='h-full px-[14px] py-2 !w-[fit-content]'
                           textMarginX='0'
                           textClassName='ml-2'
                           primaryColor
                           onClick={ () => Intercom.showArticle(ARTICLE_ID_ON_THE_VAULT) }
                        />
                     </div>
                  </div>
               )
            }
            {
               filtersIntoModal &&  !!document?.getElementById('photo_vaults-filter-content') ?
                  createPortal(view, document?.getElementById('photo_vaults-filter-content'))
                  : view
            }
         </div>
         <div className={ cx({
            'vault-content h-full': true,
         }) }>
            <div className='w-full px-6 max-[700px]:px-4 pb-3 grid grid-cols-1 sm:grid-cols-2 **lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-4 gap-4 select-none cards-grid'>
               {
                  !isFetchingByFilter && data && data.map((item, index) => {
                     let isChecked = false;
                     if(selectedCards.includes(item.id)) {
                        isChecked = true
                     }
                     return (
                        <div
                           className='vault-card-width'
                           key={ item.id ? item.id.toString() : item.hash }
                        >
                           <Card
                              data={ item }
                              desktopType={ desktopType }
                              openDeleteConfirmModal={ () => openDeleteConfirmModal(item.id, true, !!item.attached_bundle_id, item.attached_bundle_name, item.attached_bundle_id) }
                              selectCard={ (_, bool) => selectCard(bool, item) }
                              isChecked={ isChecked }
                              isInsideModal={ isInsideModal }
                              removeFile={ removeFile }
                              onDeleteMessag={ onDeleteMessag }
                              updateVaultMessageAction={ updateVaultMessageAction }
                              updateMessageUnlockDetails={ updateMessageUnlockDetails }
                              isDemoMode={ isDemoMode }
                           />
                        </div>
                     )
                  })
               }
            </div>
            {
               isNewFetching && !isFetchingByFilter &&
                  <div
                     className='flex w-full h-20 justify-center items-center'>
                     <CircleLoader />
                  </div>
            }
            {
               !isNewFetching && isFetchingByFilter &&
               <div
                  className='flex !h-[50vh] w-full justify-center items-center'>
                  <CircleLoader  />
               </div>
            }
         </div>
      </div>
   )
}

ImagesContent.propTypes = {
   isInsideModal: PropTypes.bool,
   data: PropTypes.array,
   removeFile: PropTypes.func,
   isFetchingByFilter: PropTypes.bool,
   desktopType: PropTypes.string,
   isNewFetching: PropTypes.bool,
   openDeleteConfirmModal: PropTypes.func,
   selectedCards: PropTypes.array,
   selectCard: PropTypes.func,
   filters: PropTypes.object,
   handleAddNewVault: PropTypes.func,
   onSimpleFilter: PropTypes.func,
   isEmpty: PropTypes.bool,
   isFetching: PropTypes.bool,
   goToReorder: PropTypes.func,
   screenWidth: PropTypes.number,
   contentType: PropTypes.string,
   onDeleteMessag: PropTypes.func,
   updateVaultMessageAction: PropTypes.func,
   updateMessageUnlockDetails: PropTypes.func,
   isDemoMode: PropTypes.bool,
}

export default ImagesContent;
