import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import cx from 'classnames';
import { EditPriceButtons, EditPriceContent } from './price';
import WarningModal from 'common/components/modules/chat/content-warning-modal';
import Waveform from 'common/components/modules/audio-player';
import GenerateColor from 'common/utils/generateTextColor.js'
import { getDuration } from 'common/utils/audioRecording';
import toast from 'common/utils/toast';
import TruncateText from 'common/components/elements/truncate-text';
import Icon from 'common/components/elements/icons';


const VoiceMessage = ({
   isMobile,
   maxPrice = 100,
   onStop,
   onCancel,
   duration,
   audioAsblob,
   audioSrc,
   onSend,
   disabledSendTo,
   withoutPricingContnet,
   defaultVoicePrice,
   onOpenMicSettings,
   withoutMicSettings,
   isLoadingSendBtn,
   replyMessage,
   isAdmin,
   showNickname,
   canelReplyMessage,
   authUser,
}) => {
   const [status, setStatus] = useState('progress');
   const [error, setError] = useState(null);
   const [priceDetails, setPriceDetails] = useState({
      price: defaultVoicePrice,
      make_default_price_for_voice_messages: Boolean(defaultVoicePrice),
   })
   const [showWarningModal, setShowWarningModal] =  useState(false);
   // const [price, setPrice] =  useState();
   const [isOpenEditContent, setIsOpenEditContent] =  useState(false);

   const leavChatConversation = (e) => {
      const data = e?.detail.payload;
      const audio_recorded_status = data?.audio_recorded_status;
      switch (audio_recorded_status) {
         case 'start':
            onStop();
            break;
         case 'stop':
            // onStop();
            break;
         case 'limit_reached':
            onStop()
            setStatus('complited');
            break;
         default:
            onCancel(true);
            break;
      }
   }
   useEffect(() => {
      window.addEventListener('leav-chat-conversation', leavChatConversation)
      return () => {
         window.removeEventListener('leav-chat-conversation', leavChatConversation)

      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const handleSave = () => {
      let val = priceDetails?.price;
      let invalidPrice = val < 0.25 || val > maxPrice || val === ''

      if(!invalidPrice){
         const dontShow = +localStorage.getItem('dont_show_chat_coast_warning_modal')
         if(val <= 4 && !dontShow) {
            setShowWarningModal(true)
         } else {
            savePrice()
         }
      } else {
         setError(`Price must be between 0.25 and ${ maxPrice } USD`)
      }
   }

   const savePrice = () => {
      setIsOpenEditContent(false);
   }


   const onClickPriceButton = (action, data) => {
      switch (action) {
         case 'set':
            setIsOpenEditContent(true);
            break;
         case 'save':
            handleSave();
            break;
         case 'cancel':
            setIsOpenEditContent(false);
            setPriceDetails({
               price: null,
               make_default_price_for_voice_messages: false,
            });
            setError(null)
            break;
         case 'closeModal':
            setError(null)
            setIsOpenEditContent(false);
            setPriceDetails({
               price: data?.price,
               make_default_price_for_voice_messages: data?.makeDefaultPrice,
            });
            break;

         default:
            break;
      }
   }


   const getColorForVoiceMessage = () => {
      let color = '#ffffff';
      let opasitiHex = '#ffffff';
      let name = 'major-dark';
      const textColor = GenerateColor.adaptColor(window.skinPrimaryColor)
      switch (textColor) {
         case 'major-dark':
            color = '#E0E0E0';
            opasitiHex = '#e0e0e01f';
            name = 'major-dark';
            break;
         case 'major':
            color = '#667085';
            opasitiHex = '#6670851f';
            name = 'secondary';
            break;
         default:
      }
      return { hex: color, name, opasitiHex: opasitiHex }
   }

   let waveformColor = {};
   if(!isOpenEditContent && audioSrc){
      waveformColor = getColorForVoiceMessage();
   }
   return (
      <div
         className={ cx({
            'shadow dark:shadow-dark flex bg-panel dark:bg-panel-dark dark:border-divider-dark border-divider border-t !p-4 min-h-[79px] relative bottom-1.5 z-20': true,
            'justify-between py-6': 'progress' === status,
            'flex-col py-4': 'complited' === status,
            'flex-col': replyMessage &&  replyMessage.parentMessageId,
         }) }
         id='chat-input-field'
      >
         {
            replyMessage &&  replyMessage.parentMessageId &&
            <div
               className={ cx({
                  'flex flex-col gap-1.5 pb-3': true,
               }) }
            >
               <div className='flex gap-2 items-center'>
                  <p className='text-sm font-normal leading-[18px] text-secondary dark:text-secondary-dark flex gap-1'>
                     Replying to
                     <TruncateText
                        className='flex text-secondary dark:text-secondary-dark'
                        text={ isAdmin || showNickname ? replyMessage?.user?.nickname ||  replyMessage?.user?.username || 'your message' : replyMessage?.user?.uuid === authUser?.uuid ? 'your message' : replyMessage.user.username }
                        textClass='text-xm font-semibold'
                        textSize='14px'
                        fontWeight='600'
                        width='200px'
                     />
                  </p>
                  <Icon
                     name='cancel'
                     onClick={ canelReplyMessage }
                     color='secondary'
                     className='cursor-pointer'
                  />
               </div>
               {
                  !['text_message'].includes(replyMessage.type) && !!replyMessage.type && (
                     <div className='flex max-w-250 text-secondary dark:text-secondary-dark items-start'>
                        <span className='text-xs '>{replyMessage.type === 'voice_message' ? 'Voice message' : 'Content'}!</span>
                     </div>
                  )
               }
               {
                  replyMessage.text && 'voice_message' !== replyMessage.type  && (
                     <div className='max-w-[307px] w-fit bg-placeholder-12 dark:bg-placeholder-12 py-0.5 px-1 rounded-lg'>
                        <TruncateText
                           className='flex text-secondary dark:text-secondary-dark !h-4'
                           text={ ['mass_message'].includes(replyMessage.type) ? replyMessage?.text?.subject : replyMessage?.text  }
                           textClass='text-xs !leading-4'
                           textSize='12px'
                           fontWeight='400'
                           width='fit-content'
                        />
                     </div>
                  )
               }
            </div>
         }
         {
            showWarningModal && (
               <WarningModal
                  isMobile={ isMobile }
                  info='Items that cost less than 4.00 USD can be purchased only using the wallet'
                  actionText='Ok'
                  isCheckbox
                  action={ () => {
                     setShowWarningModal(false)
                     savePrice()
                  } }
                  closeModal={ () => {
                     setShowWarningModal(false)
                  } }
                  showNewStyles
                  hideCloseButton
                  iconColor='action'
                  iconName='wallet'
               />

            )
         }
         {
            'complited' === status && (
               <>
                  <div
                     className={ cx({
                        'flex justify-between items-center gap-4': true,
                     }) }
                  >
                     {
                        !withoutPricingContnet && isOpenEditContent &&
                           <>
                              <EditPriceContent
                                 maxPrice={ maxPrice }
                                 error={ error }
                                 handleSave={ handleSave }
                                 price={ priceDetails?.price }
                                 makeDefaultPrice={ priceDetails?.make_default_price_for_voice_messages }
                                 setPriceDetails={ (name, value) => {
                                    setPriceDetails({
                                       ...priceDetails,
                                       [name]: value,
                                    }) } }
                                 setError={ value => setError(value) }
                                 isMobile={ isMobile }
                                 onClick={ onClickPriceButton }
                              />
                           </>
                     }
                     {
                        !isOpenEditContent &&
                           <>
                              <IconButton
                                 iconName='delete'
                                 fontIconColor='error dark:!text-error-dark'
                                 onClick={ () => onCancel(true, () => {
                                    toast.info('Voice message has been deleted');
                                 }) }
                                 fontIconSize='doubleLarge'
                              />
                              {
                                 audioSrc &&
                                    <div className='w-[270px] skin-primary-background px-3 py-2 rounded-full'>
                                       <Waveform
                                          type={ audioAsblob?.type }
                                          duration={ duration }
                                          url={ audioSrc }
                                          textColor={ waveformColor?.name }
                                          colorState={
                                             {
                                                waveColor: waveformColor.opasitiHex,
                                                progressColor: waveformColor.hex,
                                             }
                                          }
                                       />
                                    </div>
                              }
                              <IconButton
                                 primaryColor
                                 fontIconSize='doubleLarge'
                                 iconName='send'
                                 onClick={ _ => onSend(withoutPricingContnet ? {
                                    price: 0,
                                    make_default_price_for_voice_messages: false,
                                 } : priceDetails) }
                                 disabled={ disabledSendTo }
                                 isLoadingSendBtn={ isLoadingSendBtn }
                              />
                           </>
                     }
                  </div>
                  {
                     !withoutPricingContnet && (
                        <EditPriceButtons
                           onClick={ onClickPriceButton }
                           price={ priceDetails?.price }
                           isOpenEditContent={ isOpenEditContent }
                        />
                     )
                  }
               </>
            )
         }
         {
            'progress' === status &&
            <div className='flex flex-col gap-3 items-center w-full'>
               <div className='flex items-center justify-between w-full'>
                  <div className='flex items-center'>
                     <div className='w-2.5 h-2.5 min-w-[10px] rounded-full bg-error dark:bg-error-dark mr-2' />
                     <span className='text-major dark:text-major-dark text-base leading-[20px] select-none w-14 '>{getDuration(duration, true)}</span>
                  </div>
                  <IconButton
                     iconName='stop'
                     fontIconColor='error dark:!text-error-dark'
                     onClick={ _ => {
                        onStop()
                        setStatus('complited')
                     } }
                     fontIconSize='doubleLarge'
                     className={ withoutMicSettings ? '' : 'mr-[47px]' }
                  />
                  {
                     !withoutMicSettings &&
                     <IconButton
                        iconName='settings'
                        fontIconColor='secondary dark:!text-secondary-dark'
                        onClick={ () => onOpenMicSettings() }
                        // className=''
                        fontIconSize='extraLarge'
                     />
                  }
               </div>
               <Button
                  text='Cancel'
                  textMarginX='0'
                  padding={ 0 }
                  textColor='secondary'
                  classNames='!w-[fit-content] px-3.5 py-2.5'
                  backgroundColor='transparent'
                  textSize='small'
                  onClick={ () => onCancel(false) }
               />
            </div>
         }

      </div>
   )
};

const IconButton = ({
   iconName,
   fontIconColor,
   primaryColor,
   onClick,
   disabled,
   fontIconSize = 'large',
   className = '',
   isLoadingSendBtn,
}) => {
   return (
      <div className={ `flex h-6 w-6 ${ className || '' }` }>
         <Button
            onClick={ onClick }
            iconName={ isLoadingSendBtn ? '' : iconName }
            textMarginX='0'
            padding={ 0 }
            fontIconColor={ fontIconColor }
            backgroundColor='transparent'
            fontIconSize={ fontIconSize }
            primaryColor={ primaryColor }
            disabled={ disabled }
            classNames={ `${ !disabled ? '' : 'opacity-50' } h-full` }
            loaderClassName='!border-[1px] !w-4 !h-4'
            isLoading={ isLoadingSendBtn }

         />
      </div>
   )
}

VoiceMessage.propTypes = {
   isMobile: PropTypes.bool,
   onStop: PropTypes.func,
   maxPrice: PropTypes.any,
   onCancel: PropTypes.func,
   duration: PropTypes.any,
   audioAsblob: PropTypes.object,
   audioSrc: PropTypes.string,
   onSend: PropTypes.func,
   disabledSendTo: PropTypes.bool,
   withoutPricingContnet: PropTypes.bool,
   defaultVoicePrice: PropTypes.number,
   onOpenMicSettings: PropTypes.func,
   withoutMicSettings: PropTypes.bool,
   isLoadingSendBtn: PropTypes.bool,
   replyMessage: PropTypes.object,
   isAdmin: PropTypes.bool,
   showNickname: PropTypes.bool,
   canelReplyMessage: PropTypes.func,
   authUser: PropTypes.object,
};
IconButton.propTypes = {
   iconName: PropTypes.string,
   fontIconColor: PropTypes.string,
   onClick: PropTypes.func,
   primaryColor: PropTypes.bool,
   disabled: PropTypes.bool,
   fontIconSize: PropTypes.string,
   className: PropTypes.string,
   isLoadingSendBtn: PropTypes.bool,
};
VoiceMessage.defaultProps = {
   isLoadingSendBtn: false,
}


export default VoiceMessage
