import React, { useState, useEffect } from 'react';
import Button from 'common/components/elements/buttons/primary';
import Input from 'common/components/elements/inputs/inputs';
import Modal from 'common/components/modules/modal';
import PropTypes from 'prop-types';
import ConfirmDialog from 'common/components/modules/confirm-dialog';
import IconLabel from 'common/components/elements/icon-label';


const UpdateUserName = ({
   isMobile,
   username,
   errorMessage,
   onSaveUserName,
   closeModal,
   maxLimit,
}) => {
   const [name, setName] = useState(username)
   const [loading, setLoading] = useState(false)
   const [animate, setAnimate] = useState('up')
   const [hideText, setHideText] = useState(!!errorMessage)
   const [showDiscardModal, setShowDiscardModal] = useState(false)
   const [allowToClose, setAllowToClose] = useState(true)
   let timeout = null

   useEffect(() => {
      setName(username)
   }, [username]);

   useEffect(() => {
      return () => {
         clearTimeout(timeout)
         setAllowToClose(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const onClose = () => {
      if(username !== name?.trim()) {
         setAllowToClose(false)
         setShowDiscardModal(true)
         return
      }
      if(isMobile) {
         setAnimate('down')
         timeout = setTimeout(() => {
            setAnimate('up')
            allowToClose && closeModal()
         }, 800)
      } else {
         allowToClose && closeModal()
      }
   }

   function closeOnClickOutside(e) {
      if(e.target.closest('.update-member')) {
         if(!hideText) {
            setHideText(false)
         }
      }
   }

   useEffect(() => {
      window.addEventListener('click', closeOnClickOutside, true);
      return () => {
         window.removeEventListener('click', closeOnClickOutside, true);
      };
      // eslint-disable-next-line
   }, [hideText]);

   return (
      (
         <Modal
            contentPosition={ isMobile ? 'bottom' : 'center' }
            onClose={ onClose }
            contentWidth={ isMobile ? '100%' : '375px' }
            animate={ isMobile ? animate : null }
            isCloseAction={ false }
         >

            {
               !showDiscardModal ? (
                  <div className='update-member'>
                     <div className='flex h-14 items-center px-4'>
                        <div className='w-6 h-6 flex items-center justify-cente mr-4'>
                           <Button
                              iconName='close'
                              fontIconColor='secondary'
                              darkIconColor='secondary-dark'
                              backgroundColor='transparent'
                              onClick={ () => onClose() }
                           />
                        </div>
                     </div>
                     <div className='flex items-center justify-center' >
                        <IconLabel
                           className='w-12 h-12'
                           name='pencil'
                           size='3xl'
                           color='action'
                        />
                     </div>
                     <span className='leading-8 mt-3 flex items-center justify-center text-major dark:text-major-dark text-xl font-semibold'>Edit username</span>

                     <div className='flex px-5 mt-4'>
                        <Input
                           label='Username'
                           name='username'
                           value={ name }
                           onChange={ (name, value) => {
                              if(maxLimit) {
                                 if(maxLimit < value?.length) {
                                    return
                                 }
                                 setName(value)
                                 return
                              }
                              setName(value)
                           } }
                           placeholder='Enter username'
                           errorMessage={ errorMessage }
                           autoComplete='off'
                           description={ maxLimit ? `${ name?.length || 0 } of ${ maxLimit } characters` : '' }
                           descriptionPosition='right'
                           descriptionFontWeight={ 'normal' }
                           labelFontWeight={ 'medium' }
                           labelFontSize='sm'
                           height={ '10' }
                           hideDescriptionWhenError={ !loading && hideText }
                           onInputFocus={ () => {
                              if(!!errorMessage) {
                                 setHideText(false)
                              }
                           } }
                        />
                     </div>
                     <div className='h-9 w-auto px-5 flex items-center justify-center mb-6 mt-8'>
                        <Button
                           text='Save changes'
                           borderRadius='large'
                           onClick={ () => {
                              setLoading(true)
                              onSaveUserName({ username: name }, () => setLoading(false))
                              if(!!errorMessage || !hideText) {
                                 setHideText(true)
                              }
                           }
                           }
                           disabled={ name?.length < 3 || (username === name?.trim()) }
                           primaryColor
                           isLoading={ loading }
                           className=''
                           lineHeight='leading-5'
                           padding={ 2 }
                           textSize='small'
                        />
                     </div>
                  </div>
               ) : (
                  <ConfirmDialog
                     modalInfo={ {
                        title: `Discard changes?`,
                        actionText: 'Discard changes',
                        description: 'Changes you made so far will not be saved',
                        action: () => {
                           closeModal()
                        },
                     } }
                     closeConfirmDialog={ () => {
                        setShowDiscardModal(false)
                     }  }
                     cancelText='Keep editing'
                     iconName='cancel'
                     showNewStyles
                  />
               )
            }
         </Modal>
      )
   )
}

UpdateUserName.defaultProps = {
   maxLimit: 30,
};

UpdateUserName.propTypes = {
   isMobile: PropTypes.bool,
   username: PropTypes.string,
   errorMessage: PropTypes.string,
   onSaveUserName: PropTypes.func,
   closeModal: PropTypes.func,
   maxLimit: PropTypes.number,
}

export default UpdateUserName


