import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import Icon from 'common/components/elements/icons';

function ConfirmDialog({ modalInfo, closeConfirmDialog, isLoading, cancelText, iconName, showNewStyles, isRedesigned, sendMessage, changeSendType, descriptionClassName }) {
   let icon = null
   switch (iconName) {
      case 'retry':
         icon = 'retry'
         break;
      case 'block':
         icon = 'block'
         break;
      case 'delete':
         icon = 'delete'
         break;
      case 'cancel':
         icon = 'cancel'
         break;

      default:
         icon = null
         break;
   }

   return (
      <div className='flex flex-col bg-panel dark:bg-panel-dark p-6 pt-6 items-center text-center'>
         {
            !!icon && !isRedesigned && <div className='bg-error-12 dark:bg-error-dark-12 h-10 w-10 flex items-center justify-center rounded-full mb-3'>
               <Icon name={ icon } size='2xl' color={ 'error' } darkColor='error-dark' />
            </div>
         }
         {
            isRedesigned
            &&
            <>
               <div className='h-10 w-10 mx-auto flex items-center justify-center mb-3 rounded-full bg-error-12 dark:bg-error-dark-12'>
                  <Icon
                     name={ 'cancel' }
                     size={ 'xl' }
                     color='error'
                     darkColor='error-dark'
                  />
               </div>
               {/* <p className='text-xl leading-5 text-[#041527] font-semibold mt-3'>Discard changes?</p> */}
            </>

         }
         {
            modalInfo.title && (
               <span className={ `text-major text-xl mb5 font-bold dark:text-major-dark` }>{modalInfo.title}</span>
            )
         }
         {
            modalInfo.description &&
            <span className={ `${ showNewStyles ? 'mx-12' : '' } text-secondary leading-6 dark:text-secondary-dark  mt-4 ${ descriptionClassName }` }>{ modalInfo.description }</span>
         }
         {
            isRedesigned && !!changeSendType &&
            <div className='h-9' style={ { marginTop: '32px' } }>
               <Button
                  text={ 'Save as draft' }
                  primaryColor
                  borderRadius='large'
                  backgroundColor='transparent'
                  textSize='small'
                  onClick={ () => {
                     changeSendType('draft', () => {
                        sendMessage()
                     });
                  } }
                  padding='none'
                  classNames='h-full w-[116px]'
               />
            </div>
         }
         <div className='h-9' style={ { marginTop: isRedesigned ? !changeSendType ? '24px' : '12px' : '32px' } }>
            <Button
               text={ modalInfo.actionText }
               borderRadius='large'
               backgroundColor='transparent'
               textColor='error dark:text-error-dark'
               textSize={ 'small' }
               onClick={ modalInfo.action }
               padding='none'
               classNames='h-full w-full'
               isLoading={ isLoading }
               loaderColor='blue'
               lineHeight='leading-5'
            />
         </div>
         <div className='h-9 mt-3'>
            <Button
               text={ cancelText }
               borderRadius='large'
               backgroundColor='transparent'
               textColor='secondary dark:text-secondary-dark '
               textSize={ 'small' }
               onClick={ () => closeConfirmDialog() }
               padding='none'
               classNames='h-full w-full'
               lineHeight='leading-5'
            />
         </div>
      </div>
   );
}

ConfirmDialog.propTypes = {
   modalInfo: PropTypes.object,
   closeConfirmDialog: PropTypes.func,
   isLoading: PropTypes.bool,
   cancelText: PropTypes.string,
   iconName: PropTypes.string,
   showNewStyles: PropTypes.bool,
   isRedesigned: PropTypes.bool,
   sendMessage: PropTypes.func,
   changeSendType: PropTypes.func,
   descriptionClassName: PropTypes.string,
};

ConfirmDialog.defaultProps = {
   cancelText: 'Cancel',
   isRedesigned: false,
};

export default ConfirmDialog;
