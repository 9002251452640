import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Icon from 'common/components/elements/icons';
import NumberFormatter from 'admin/utils/NumberFormatter'
import moment from 'moment';
import { Link } from 'react-router-dom';
import Router from 'admin/routes/router';
import Button from 'common/components/elements/buttons/primary';
import Modal from 'common/components/modules/modal';
import DateFormatter from 'common/utils/DateFormatter';
import { generateUriSegment } from 'common/utils/mediaRouteHelper';
import { getProtocol, makeTweetableUrl } from 'common/utils/utils.js';
import MediaChip from 'common/components/modules/media-chip';
import { CopyContentLink } from 'admin/utils/CopyContentLink';
import TruncateText from 'common/components/elements/truncate-text';
import { getNickName } from 'common/utils/helper';
import cx from 'classnames';
import CheckBox from 'common/components/elements/inputs/checkbox';
import RadioLabel from 'common/components/elements/inputs/radio-label';
import ChatMessageContentState from 'admin/views/media-modules/chat-message-state';
import ContentPreviewIntoModal from '../content-preview-into-modal';

const VideoCard = ({
   data: {
      poster_src: src,
      duration,
      views_count: viewsCount,
      likes_count: likesCount,
      comments_count: commentsCount,
      title,
      is_published: isPublished,
      is_future_publish_enabled: isPublishEnabled,
      publish_date: scheduledDate,
      access,
      id,
      stream_access,
      who_can_stream,
      total_tip,
      poster,
      messages,
      hls_manifest: hlsManifest,
      ends_in,
      custom_preview_video_full_src,
      system_preview_video_full_src,
   } = {},
   points = false,
   openDeleteConfirmModal,
   desktopType,
   downloadOriginalFile,
   isDownloading,
   isInsideModal,
   onSelectThisVideo,
   isSelected,
   isBulkEdit,
   isBulkEditSelected,
   onSelectVideos,
   onClickDetailsLink,
   siteUrl,
   onClickOpenTips,
   onClickSetId,
   routeName,
   type,
   isProgress,
   hasLivestreamError,
   showNewStyles,
   selectedItemsIds,
   showCheckboxes,
   disabled,
   pinVideo,
   isPinned,
   isMultipleSelect,
   onDeleteMessag,
   updateMessage,
   contentType,
   isDemoMode,
   updateMessageUnlockDetails,
   isMultipleAttachments,
}) => {
   const [openOptionsModal, setOpenOptionsModal] = useState(false)
   const [previewModal, setPreviewModal] = useState({
      isOpen: false,
      data: {},
   })

   const numberFormatter = new NumberFormatter();
   let calculateTimeLeft = () => DateFormatter.calculateTimeLeft(ends_in)
   const [timeLeft, setTimeLeft] = useState(ends_in ? calculateTimeLeft() : null);


   let statusName = 'scheduled'
   if(!isPublishEnabled) {
      statusName = !isPublished ?  'unpublished' : 'published'
   }
   const status = {
      published: {
         iconName: 'public',
         color: 'placeholder dark:text-placeholder-dark',
         text: 'Published',
      },
      unpublished: {
         iconName: 'unpublish',
         color: 'placeholder dark:text-placeholder-dark',
         text: 'Unpublished',
      },
      scheduled: {
         iconName: 'clock',
         color: 'placeholder dark:text-placeholder-dark',
         text: 'Scheduled',
         date: scheduledDate,
      },
   }
   function closeOnClickOutside(e) {
      if(!e.target.closest(`.video-${ id }`)) {
         setOpenOptionsModal(false);
      }
   }

   useEffect(() => {
      window.addEventListener('click', closeOnClickOutside, true);
      return () => {
         window.removeEventListener('click', closeOnClickOutside, true);
      };
   // eslint-disable-next-line
   }, []);

   useEffect(() => {
      let timer
      if(timeLeft !== null) {
         timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
         }, 1000);
      }

      return () => clearInterval(timer);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [timeLeft]);

   let tweetUrl = null;
   if(statusName !== 'unpublished') {
      let pagename = 'videos';
      if('livestream' === type){
         pagename = 'recorded-streams';
      }
      const url = `${ getProtocol() }${ siteUrl }/${ pagename }/${ id }`;
      tweetUrl = makeTweetableUrl(url, `via ${ getNickName() }`);
   }
   const recordedProgress = isProgress && 'livestream' === type;
   const recordedstreamHasError = hasLivestreamError && 'livestream' === type;

   const onClickShare = () => {
      let pagename = 'video';
      if('livestream' === type){
         pagename = 'recorded-stream';
      }
      CopyContentLink(siteUrl, id, pagename);
   }

   const isHasDuration = () => {
      return !['scheduled', 'unpublished']?.includes(statusName) && Boolean(ends_in)
   }

   return (
      <>
         <div
            className='adminDashboard__videoCard rounded-lg relative overflow-hidden group/card'
            data-tooltip-content='This content has already been added to a different bundle'
            data-tooltip-id={ disabled ? 'ams-top-tooltip' : null }
         >
            {
               (recordedProgress || recordedstreamHasError) && (
                  <>
                     <div
                        className='w-full relative overflow-hidden'
                        style={ {
                           height: 'calc(100% - 48px)',
                        } }
                     >
                        <div
                           style={ {
                              filter: 'blur(24px)',
                              backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0.28) 0%, rgba(0, 0, 0, 0) 24.2%), linear-gradient(180.82deg, rgba(0, 0, 0, 0) 67.2%, rgba(0, 0, 0, 0.46) 99.3%)',
                              zIndex: '-1',
                           } }
                           className={ `bg-cover bg-center cursor-pointer video-card-padding rounded-t-lg ${ statusName === 'published' ? '' : 'non-published-bg z-2 bg-major-60 dark:bg-grey-hover-dark-60' }` } />
                        <div className=' absolute top-0 left-0 w-full h-full z-1 flex items-center justify-center'>
                           {
                              recordedProgress && (
                                 <span className='text-xl font-medium text-major dark:text-major-dark'>Progressing...</span>
                              )
                           }
                           {
                              recordedstreamHasError && !recordedProgress && (
                                 <span className='text-xl font-medium text-error dark:text-error-dark'>Error</span>
                              )
                           }

                        </div>

                     </div>
                  </>
               )
            }
            {
               (!recordedstreamHasError && !recordedProgress) && (
                  <Link
                     onClick={ (isInsideModal || isBulkEdit) ? (e) => {
                        if(onClickDetailsLink) {
                           onClickDetailsLink()
                        }
                        e.preventDefault()
                        e.stopPropagation()
                        isInsideModal ? !disabled && onSelectThisVideo() : onSelectVideos(!isBulkEditSelected)
                     } : () => {
                        if(onClickDetailsLink){
                           onClickDetailsLink()
                        }
                     } }
                     to={ Router.route(routeName).getCompiledPath({ id: generateUriSegment(id, title) }) }
                     className='text-major-dark block overflow-hidden'

                  >
                     <div
                        className={ cx({
                           'w-full relative group/card': true,
                           '!cursor-default': disabled,
                        }) }
                        style={ {
                           height: 'calc(100% - 48px)',
                        } }
                     >
                        <div
                           style={ {
                              filter: 'blur(24px)',
                              backgroundImage: `url(${ src })`,
                              zIndex: '-1',
                           } }
                           className={ `bg-cover bg-center cursor-pointer video-card-padding rounded-t-lg ${ statusName === 'published' ? '' : 'non-published-bg z-2 bg-major-60 dark:bg-grey-hover-dark-60' }` } />
                        {
                           (statusName !== 'published' || !!timeLeft) && (
                              <div className='non-published-bg z-2 bg-major-60 dark:bg-grey-hover-dark-60 rounded-t-lg absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center'>
                                 <Icon
                                    name={ isHasDuration() ? 'clock' : status[statusName].iconName }
                                    color='major-dark'
                                    size='2xl'
                                 />
                                 <span className='mt-1 text-major-dark text-lg leading-7 font-semibold select-none'>
                                    {isHasDuration() ? 'Ends in' :  status[statusName].text } { status[statusName].time }
                                 </span>
                                 {
                                    (!!status[statusName].date || isHasDuration()) && statusName !== 'unpublished' && (
                                       <span className=' text-major-dark block mt-1'>
                                          {
                                             'scheduled' !== statusName && isHasDuration() ?
                                                timeLeft
                                                :
                                                <>
                                                   {DateFormatter.convertToUserTz(status[statusName].date, 'MMM DD, YYYY hh:mm A')} ({DateFormatter.getZoonFullName()})
                                                </>
                                          }
                                       </span>
                                    )
                                 }
                              </div>
                           )
                        }
                        {
                           poster && (
                              <img src={ src } alt='video card' className='rounded-t-lg w-full object-contain h-full absolute top-0 left-0' />

                           )
                        }
                        {
                           !poster && (
                              <div className='rounded-t-lg w-full object-contain h-full absolute top-0 left-0 bg-panel-dark' />
                           )
                        }
                        {
                           (isInsideModal || isBulkEdit) &&
                                 (
                                    isMultipleSelect ?
                                       <CheckBox
                                          isChecked={ selectedItemsIds.includes(id) || isBulkEditSelected || isSelected }
                                          onChange={ () => { } }
                                          disabled={ disabled }
                                          className={ `w-6 h-6` }
                                          classNames={ `!z-10 absolute top-1 left-1 ${ disabled ? '!cursor-default' : '' }` }
                                       />
                                       :
                                       <RadioLabel
                                          checked={  selectedItemsIds.includes(id) || isSelected  }
                                          className={ `!z-10 !p-0 absolute top-2 left-2 ${ disabled ? '!cursor-default' : '' } ` }
                                          radioParentClassName='ml-0'
                                          disabled={ disabled }
                                          labelClassName='!w-5 !h-5'
                                       />
                                 )
                        }
                        {
                           messages  && messages?.length > 0 && (
                              <ChatMessageContentState
                                 chatMessages={ messages }
                                 onDeleteMessag={ onDeleteMessag }
                                 updateMessage={ (messageId, isDeleteAll) => updateMessage({ ids: [id], data: null, messageId: messageId, isDeleteAll: isDeleteAll }) }
                                 contentType='video'
                                 onClickPreview={ () => {
                                    setPreviewModal({
                                       isOpen: true,
                                       data: {
                                          posterSrc: src,
                                          title,
                                          id,
                                          src: hlsManifest || system_preview_video_full_src || custom_preview_video_full_src,
                                       },
                                    })
                                 } }
                                 updateMessageUnlockDetails={ updateMessageUnlockDetails }
                                 resourceId={ id }
                              />
                           )
                        }
                        {
                           !messages?.length && (contentType === 'chat' || isMultipleAttachments) && isInsideModal &&
                              <div className='hidden absolute top-0 left-0 w-full h-full group-hover/card:!flex justify-center items-center bg-major-60 dark:bg-grey-hover-dark-60 !z-[3]'>
                                 <Button
                                    text='Preview'
                                    iconName='play-default'
                                    textColor='divider'
                                    textSize='small'
                                    iconPosition='left'
                                    backgroundColor='transparent'
                                    classNames='!w-fit !h-9 border border-grey-main items-center'
                                    borderRadius='large'
                                    textClassName='mr-0 !leading-[18px]'
                                    padding='0 px-3.5 py-2'
                                    onClick={ (e) => {
                                       e.preventDefault();
                                       e.stopPropagation();
                                       setPreviewModal({
                                          isOpen: true,
                                          data: {
                                             posterSrc: src,
                                             title,
                                             id,
                                             src: hlsManifest || system_preview_video_full_src || custom_preview_video_full_src,
                                          },
                                       })
                                    } }
                                 />
                              </div>
                        }
                        <div className='absolute top-0 left-0 w-full rounded-t-lg video-top-content-height **p-2 flex flex-col justify-between overflow-hidden'>
                           <div
                              className={ cx({
                                 [`flex justify-between p-2 pb-3`]: true,
                                 '!justify-end': isInsideModal || isBulkEdit,
                              })
                              }
                              style={ {
                                 background: 'linear-gradient(0deg,rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%)',
                              } }
                           >
                              {
                                 !isInsideModal && statusName !== 'unpublished' && !isBulkEdit && (
                                    <span className='text-major-dark text-xs' >{ moment(DateFormatter.convertToUserTz(scheduledDate)).fromNow()}</span>
                                 )
                              }
                              <MediaChip
                                 countOrDuration={ duration }
                                 type='video'
                              />
                           </div>
                           <div className='self-start flex w-full pt-3 p-2'
                              style={ {
                                 background: 'linear-gradient(180deg,rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%)',
                              } }
                           >
                              <div className='flex items-center'>
                                 <Icon name='views' color='major-dark' size='xl' />
                                 <p className='ml-1 text-sm leading-5'>{ numberFormatter.withCommas(viewsCount) }</p>
                              </div>
                              <div className='flex items-center ml-3'>
                                 <Icon name='heart-filled' color='major-dark' size='xl' />
                                 <p className='ml-1 text-sm leading-5'>{ numberFormatter.withCommas(likesCount) }</p>
                              </div>
                              <div className='flex items-center ml-3'>
                                 <Icon name='comment' color='major-dark' size='xl' />
                                 <p className='ml-1 text-sm leading-5'>{ numberFormatter.withCommas(commentsCount) }</p>
                              </div>
                              <div style={ { minWidth: '80px' } } className='pl-3 **m-auto text-sm'>
                                 <Button
                                    text={ `${ numberFormatter.withCommas(total_tip) } USD` }
                                    textColor='major-dark'
                                    backgroundColor='transparent'
                                    textSize='small'
                                    iconName='tip'
                                    iconPosition='left'
                                    fontIconColor='major-dark'
                                    fontIconSize='extraLarge'
                                    fontWeight='300'
                                    padding='none'
                                    lineHeight='leading-5'
                                    classNames={ disabled ? '!cursor-default' : '' }
                                    onClick={ (e) => {
                                       if(disabled) {
                                          return
                                       }
                                       e.preventDefault()
                                       e.stopPropagation()
                                       onClickSetId(id)
                                       onClickOpenTips()
                                    } }
                                    waveActive={ false }
                                    ignoreHover
                                 />
                              </div>
                              {/* )
                              } */}
                           </div>
                        </div>

                     </div>
                  </Link>

               )
            }

            <div className='videoCard__bottom pb-4 bg-panel'>
               <div className='flex justify-between items-center'>
                  <div className='flex items-center w-full'>
                     {
                        isPinned &&
                           <Icon
                              name={ `pin` }
                              color={ `warning` }
                              darkColor={ `warning-dark` }
                              className={ `h-5 w-5 min-h-[20px] min-w-[16px] rotate-[35deg] mr-2` }
                              size={ `xl` }
                              data-tooltip-content={ 'Pinned' }
                              data-tooltip-id={ 'ams-top-tooltip' }
                           />
                     }
                     <TruncateText
                        className='pr-1'
                        text={ title }
                        textClass={ `font-bold text-xl text-major dark:text-major-dark leading-6 ${ disabled ? '!text-disabled' : '' }` }
                        textSize='20px'
                        fontWeight='700'
                        width={ points ? 'calc(100% - 28px)' : '100%' }
                     />
                  </div>
                  {points && (
                     <div
                        className={ `h-6 w-6 flex justify-center items-center video-${ id } cursor-pointer` }
                     >
                        <Button
                           onClick={ () => setOpenOptionsModal(!openOptionsModal) }
                           backgroundColor='transparent'
                           fontIconColor={ disabled ? 'disabled' : 'secondary' }
                           darkIconColor='secondary-dark'
                           iconName='optons-horizontal'
                        />
                     </div>
                  )}
               </div>
            </div>
         </div>
         {
            openOptionsModal && (
               <OptionsModal
                  closeModal={ () => setOpenOptionsModal(false) }
                  openDeleteConfirmModal={ () => {
                     openDeleteConfirmModal();
                     setTimeout(() => {
                        setOpenOptionsModal(false)

                     }, 100)
                  } }
                  desktopType={ desktopType }
                  id={ id }
                  title={ title }
                  downloadOriginalFile={ downloadOriginalFile }
                  isDownloading={ isDownloading }
                  onClickDetailsLink={ onClickDetailsLink }
                  tweetUrl={ tweetUrl }
                  routeName={ routeName }
                  type={ type }
                  recordedstreamHasError={ recordedstreamHasError }
                  onClickShare={ () => {
                     onClickShare();
                     setOpenOptionsModal(false)
                  } }
                  recordedProgress={ recordedProgress }
                  pinVideo={ (id, bool) => {
                     pinVideo(id, bool)
                     setOpenOptionsModal(false)
                  } }
                  isPinned={ isPinned }
                  isDemoMode={ isDemoMode }
               />
            )
         }
         {
            previewModal.isOpen &&
            <ContentPreviewIntoModal
               type='video'
               isMobile={ desktopType === 'mobile' }
               data={ previewModal.data }
               closeModal={ () => {
                  setPreviewModal({ isOpen: false, data: {} })
               } }
            />
         }
      </>
   );
};

const CardButtomContent = ({
   access,
   title,
   className = '',
}) => {
   return (
      <div
         className={ `cursor-pointer flex items-center justify-center ${ className }` }
         data-tooltip-content={ access.text }
         data-tooltip-id='ams-bottom-tooltip'
      >
         {
            title && (
               <span className='text-secondary dark:text-secondary-dark text-sm font-medium select-none mr-1'>{title}</span>
            )
         }
         <div className='flex items-center justify-center w-5 h-5'>
            <Icon
               name={ access.icon  }
               color={ access.color }
               size='lg'
            />
         </div>
      </div>
   )
}

const OptionsModal = ({
   /* eslint-disable */
   desktopType,
   closeModal,
   openDeleteConfirmModal,
   downloadOriginalFile,
   id,
   title,
   isDownloading,
   onClickDetailsLink,
   tweetUrl,
   routeName,
   type,
   recordedstreamHasError,
   onClickShare,
   recordedProgress,
   pinVideo,
   isPinned,
   isDemoMode
   /* eslint-enable */
}) => {

   const view = (
      <div
         className={ `shadow dark:shadow-dark video-${ id } flex flex-col w-full bg-panel dark:bg-panel-dark py-2 items-center justify-between px-5 ${ desktopType === 'mobile' ? '' : 'border dark:border-divider-dark border-divider rounded-lg' }` }>
         {/* <div
            className='flex w-full h-12 items-center justify-between'>
            <Link
               to={ Router.route('VIDEOS_SHOW').getCompiledPath({ id: generateUriSegment(id, title) }) }
               className='w-full '
               onClick={ !!onClickDetailsLink ? _ => onClickDetailsLink() : null }
            >
               <Button
                  text='Edit'
                  textSize='base'
                  textColor='major dark:text-major-dark'
                  classNames='h-full w-full'
                  iconName='pencil'
                  fontIconColor='secondary'
                  darkIconcolor='secondary-dark'
                  iconPosition='left'
                  alignment='end'
                  padding='none'
                  backgroundColor='transparent'
                  fontIconSize='base'
                  onClick={ () => {} }
               />
            </Link>
         </div> */}

         {
            !recordedstreamHasError && !recordedProgress && (
               <>
                  <div
                     className='flex w-full h-12 items-center justify-between'>
                     <Link
                        to={ Router.route(routeName).getCompiledPath({ id: generateUriSegment(id, title) }) }
                        className='w-full '
                        onClick={ !!onClickDetailsLink ? _ => onClickDetailsLink() : null }
                     >
                        <Button
                           text='Edit'
                           textSize='base'
                           textColor='major dark:text-major-dark'
                           classNames='h-full w-full'
                           iconName='pencil'
                           fontIconColor='secondary'
                           darkIconcolor='secondary-dark'
                           iconPosition='left'
                           alignment='end'
                           padding='none'
                           backgroundColor='transparent'
                           fontIconSize='base'
                           onClick={ () => {} }
                        />
                     </Link>
                  </div>
                  <div className='flex w-full h-12 items-center justify-between'>
                     <Button
                        text='Copy sharing link'
                        textSize='base'
                        textColor='major dark:text-major-dark'
                        classNames='h-full w-full'
                        iconName='copy'
                        fontIconColor='secondary'
                        darkIconcolor='secondary-dark'
                        iconPosition='left'
                        alignment='end'
                        padding='none'
                        backgroundColor='transparent'
                        fontIconSize='base'
                        onClick={ () => onClickShare() }
                     />
                  </div>
               </>
            )
         }
         {
            'video' === type &&  !!tweetUrl && (
               <div className='flex w-full h-12 items-center justify-between'>
                  <a
                     href={ tweetUrl }
                     target='_blank'
                     rel='noreferrer'
                     className='h-full w-full'
                     onClick={ (e) => {
                        window.open(
                           tweetUrl,
                           "twitterwindow",
                           "height=450, width=550, toolbar=0, location=0, menubar=0, directories=0,scrollbars=0"
                        );
                        e.preventDefault();
                        e.stopPropagation();
                     } }
                  >
                     <Button
                        text='Tweet this'
                        textSize='base'
                        textColor='major dark:text-major-dark'
                        classNames='h-full w-full'
                        iconName='twitter'
                        fontIconColor='secondary'
                        darkIconcolor='secondary-dark'
                        iconPosition='left'
                        alignment='end'
                        padding='none'
                        backgroundColor='transparent'
                        fontIconSize='base'
                        onClick={ () => {} }
                        disabled={ isDownloading }
                     />
                  </a>
               </div>
            )
         }
         <div className='flex w-full h-12 items-center justify-between'>
            <Button
               text={ isPinned ? `Unpin this  ${ type === 'video' ? `video` : `stream` }` : `Pin this ${ type === 'video' ? `video` : `stream` }` }
               textSize='base'
               textColor='major dark:text-major-dark'
               classNames='h-full w-full intercom-pin-content'
               iconName={ isPinned ? 'unpin' : 'pin' }
               fontIconColor='secondary'
               darkIconcolor='secondary-dark'
               iconPosition='left'
               alignment='end'
               padding='none'
               backgroundColor='transparent'
               fontIconSize='base'
               onClick={ () => pinVideo(id, !!isPinned) }
               disabled={ isDownloading }
            />
         </div>
         <div className='flex w-full h-12 items-center justify-between'>
            <Button
               text='Download original file'
               textSize='base'
               textColor='major dark:text-major-dark'
               classNames='h-full w-full'
               iconName='download'
               fontIconColor='secondary'
               darkIconcolor='secondary-dark'
               iconPosition='left'
               alignment='end'
               padding='none'
               backgroundColor='transparent'
               fontIconSize='base'
               onClick={ () => downloadOriginalFile(id) }
               disabled={ isDownloading }
            />
         </div>
         <div
            className='flex w-full h-12 items-center justify-between'>
            <Button
               text={ `Delete this ${ type }` }
               onClick={ openDeleteConfirmModal }
               iconName='delete'
               textSize='base'
               fontIconSize='base'
               iconPosition='left'
               classNames='h-full w-full'
               backgroundColor='transparent'
               fontIconColor='error'
               darkIconColor='error-dark'
               textColor='error dark:text-error-dark'
               padding='none'
               alignment='end'
               disabled={ isDemoMode }
               dataToolTipContent={ !!isDemoMode ? 'Not available in demo' : '' }
            />
         </div>
      </div>
   )
   if(desktopType === 'mobile'){
      return (
         <Modal
            onClose={ closeModal }
            roundedModal='lg'
         >
            {view}
         </Modal>
      );
   } else {
      return (
         <div
            style={ { bottom: '-40px', right: '10px' } }
            className='absolute z-30 bottom-0'>
            {view}
         </div>
      )
   }

}

VideoCard.propTypes = {
   data: PropTypes.object,
   points: PropTypes.bool,
   desktopType: PropTypes.string,
   openDeleteConfirmModal: PropTypes.func,
   downloadOriginalFile: PropTypes.func,
   isDownloading: PropTypes.bool,
   isInsideModal: PropTypes.bool,
   onSelectThisVideo: PropTypes.func,
   isSelected: PropTypes.bool,
   isBulkEdit: PropTypes.bool,
   onSelectVideos: PropTypes.func,
   isBulkEditSelected: PropTypes.bool,
   onClickDetailsLink: PropTypes.func,
   siteUrl: PropTypes.string,
   onClickOpenTips: PropTypes.func,
   onClickSetId: PropTypes.func,
   routeName: PropTypes.string,
   type: PropTypes.string,
   hasLivestreamError: PropTypes.bool,
   isProgress: PropTypes.bool,
   showNewStyles: PropTypes.bool,
   showCheckboxes: PropTypes.bool,
   selectedItemsIds: PropTypes.array,
   disabled: PropTypes.bool,
   pinVideo: PropTypes.func,
   isPinned: PropTypes.bool,
   isMultipleSelect: PropTypes.bool,
   onDeleteMessag: PropTypes.func,
   updateMessage: PropTypes.func,
   contentType: PropTypes.string,
   isDemoMode: PropTypes.bool,
   updateMessageUnlockDetails: PropTypes.func,
   isMultipleAttachments: PropTypes.bool,
};
CardButtomContent.propTypes = {
   title: PropTypes.string,
   access: PropTypes.object,
   className: PropTypes.string,
};

VideoCard.defaultProps = {
   routeName: 'VIDEOS_SHOW',
   type: 'video',
   isProgress: false,
   hasLivestreamError: false,
   showNewStyles: false,
   showCheckboxes: false,
   selectedItemsIds: [],
   disabled: false,
   isMultipleSelect: true,
};

export default React.memo(VideoCard);
