
export const FETCH_REQUEST_START = "photoset/FETCH_REQUEST_START";
export const FETCH_REQUEST = "photoset/FETCH_REQUEST";
export const FETCH_DETAILS_COMPLETED = "photoset/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "photoset/FETCH_DETAILS_FAILED";

export const FETCH_NEW_REQUEST_START = 'photoset/FETCH_NEW_REQUEST_START';
export const FETCH_NEW_COMPLETED = 'photoset/FETCH_NEW_COMPLETED';
export const FETCH_NEW_FAILED = 'photoset/FETCH_NEW_FAILED';

export const FETCH_BY_FILTER_REQUEST_START = 'photoset/FETCH_BY_FILTER_REQUEST_START';
export const FETCH_BY_FILTER_COMPLETED = 'photoset/FETCH_BY_FILTER_COMPLETED';
export const FETCH_BY_FILTER_FAILED = 'photoset/FETCH_BY_FILTER_FAILED';

export const FETCH_BY_ID_REQUEST_START = 'photoset/FETCH_BY_ID_REQUEST_START';
export const FETCH_BY_ID_COMPLETED = 'photoset/FETCH_BY_ID_COMPLETED';
export const FETCH_BY_ID_FAILED = 'photoset/FETCH_BY_ID_FAILED';

export const UPDATE_REQUEST_START = 'photoset/UPDATE_REQUEST_START';
export const DELETE_REQUEST_START = 'photoset/DELETE_REQUEST_START';
export const UPDATE_COMPLETED = 'photoset/UPDATE_COMPLETED';
export const UPDATE_FAILED = 'photoset/UPDATE_FAILED';

export const UPLOAD_PHOTOS_START = 'photoset/UPLOAD_PHOTOS_START';
export const UPLOAD_PHOTOS_FAILED = 'photoset/UPLOAD_PHOTOS_FAILED';
export const UPLOAD_PHOTOS_COMPLETED = 'photoset/UPLOAD_PHOTOS_COMPLETED';
export const REMOVE_UPLOAD_PHOTOS_COMPLETED = 'photoset/REMOVE_UPLOAD_PHOTOS_COMPLETED';
export const DELETE_PHOTOS_COMPLETED = 'photoset/DELETE_PHOTOS_COMPLETED';
export const REMOVE_DELETED_PHOTOS_COMPLETED = 'photoset/REMOVE_DELETED_PHOTOS_COMPLETED';
export const SAVE_PHOTOS_COMPLETED = 'photoset/SAVE_PHOTOS_COMPLETED';

export const SELECT_COVER_COMPLETED = 'photoset/SELECT_COVER_COMPLETED';
export const SAVE_NEW_PHOTOSETS_COMPLETED = 'photoset/SAVE_NEW_PHOTOSETS_COMPLETED';
export const FETCH_PHOTOS_COMPLETED = 'photoset/FETCH_PHOTOS_COMPLETED';
export const CUSTOM_PRICES_COMPLETED = 'photoset/CUSTOM_PRICES_COMPLETED';
export const CUSTOM_PRICES_START = 'photoset/CUSTOM_PRICES_START';
export const FETCH_STATS_COMPLETED = 'photoset/FETCH_STATS_COMPLETED';
export const FETCH_STATS_START = 'photoset/FETCH_STATS_START';

export const VALIDATE_STEPS_START = 'photoset/VALIDATE_STEPS_START';
export const VALIDATE_STEPS_COMPLETED = 'photoset/VALIDATE_STEPS_COMPLETED';
export const VALIDATE_STEPS_FAILED = 'photoset/VALIDATE_STEPS_FAILED';

export const DELETE_COMPLETED = 'photoset/DELETE_COMPLETED';
export const SET_ACTIVE_TAB = 'photoset/SET_ACTIVE_TAB';
export const SET_STATUS_MODAL = 'photoset/SET_STATUS_MODAL';
export const CLEAR_ERRORE = 'photoset/CLEAR_ERRORE';
export const SET_BUTTON_DISABLED = 'photoset/SET_BUTTON_DISABLED';
export const CLEAR_DATA_ACTION = 'photoset/CLEAR_DATA_ACTION';

export const UPLOAD_FAKE_PHOTOS = 'photoset/UPLOAD_FAKE_PHOTOS';
export const CLEAR_STATE = 'photoset/CLEAR_STATE';
export const SET_FILTERS = 'photoset/SET_FILTERS';

export const DISTORY_PHOTOSETS_REQUEST = 'photoset/DISTORY_PHOTOSETS_REQUEST';
export const DISTORY_PHOTOSETS_COMPLETED = 'photoset/DISTORY_PHOTOSETS_COMPLETED';
export const SET_BULK_IDS = 'photoset/SET_BULK_IDS';
export const MULTIPLE_UPDATE_ACTION = 'photoset/MULTIPLE_UPDATE_ACTION';
export const BULK_PHOTOSETS_REQUEST = 'photoset/BULK_PHOTOSETS_REQUEST';
export const BULK_PHOTOSETS_COMLITED = 'photoset/BULK_PHOTOSETS_COMLITED';
export const UPDATE_PUBLISH_DATA = 'photoset/UPDATE_PUBLISH_DATA'
export const CLEAR_BULK_ERROR = 'photoset/CLEAR_BULK_ERROR'
export const SET_EDIT_TITLE_MODAL = 'photoset/CLEAR_BULK_ERROR'

export const REORDER_PHOTOSET = 'photoset/REORDER_PHOTOSET'
export const CHANGE_INITED_STATE = 'photoset/CHANGE_INITED_STATE'

export const UPDATE_BUNDLE_STATE_IN_DATA = 'photoset/UPDATE_BUNDLE_STATE_IN_DATA'
export const PIN_PHOTOSET_COMPLETED = 'photoset/PIN_PHOTOSET_COMPLETED'

export const RESET_ACTIVE_DATA = 'photoset/RESET_ACTIVE_DATA'
export const SET_WARNING_MODAL = 'videos/SET_WARNING_MODAL'
export const UPDATE_MESSAGE_ACTION = 'photoset/UPDATE_MESSAGE_ACTION'
export const CHANGE_LOADING_STATE = 'photoset/CHANGE_LOADING_STATE'
