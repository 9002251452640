import * as types from "./types";

const fetchRequest = (key) => ({
   type: types.FETCH_REQUEST_START,
   payload: key,
});

const fetchRequestCompleted = (data, emptyFilters, key) => ({
   type: types.FETCH_DETAILS_COMPLETED,
   payload: { data, emptyFilters, key },
});

const fetchRequestFailed = error => ({
   type: types.FETCH_DETAILS_FAILED,
   payload: {
      error,
   },
});

const fetchNewRequest = (key) => ({
   type: types.FETCH_NEW_REQUEST_START,
   payload: { key },
});

const fetchNewCompleted = (data, key) => ({
   type: types.FETCH_NEW_COMPLETED,
   payload: { data, key },
});
const fetchNewFailed = (error) => ({
   type: types.FETCH_NEW_FAILED,
   payload: {
      error,
   },
});

const fetchByFilterRequest = (key) => ({
   type: types.FETCH_BY_FILTER_REQUEST_START,
   payload: { key },
});

const fetchByFilterCompleted = (data, key) => ({
   type: types.FETCH_BY_FILTER_COMPLETED,
   payload: { data, key },
});
const fetchByFilterFailed = (error) => ({
   type: types.FETCH_BY_FILTER_FAILED,
   payload: {
      error,
   },
});

const fetchByIdRequest = () => ({
   type: types.FETCH_BY_ID_REQUEST_START,
});

const fetchByIdCompleted = data => ({
   type: types.FETCH_BY_ID_COMPLETED,
   payload: data,
});
const fetchByIdFailed = (error) => ({
   type: types.FETCH_BY_ID_FAILED,
   payload: {
      error,
   },
});

const updatePhotosetsRequest = bool => ({
   type: types.UPDATE_REQUEST_START,
   payload: bool,
});

const deleteRequest = () => ({
   type: types.DELETE_REQUEST_START,
});

const updatePhotosetsCompleted = data => ({
   type: types.UPDATE_COMPLETED,
   payload: data,
});

const updatePhotosetsFailed = (error) => ({
   type: types.UPDATE_FAILED,
   payload: error,
});

const uploadPhotosRequest = (data, name) => ({
   type: types.UPLOAD_PHOTOS_START,
   payload: { data, name },
});
const uploadPhotosFailed = data => ({
   type: types.UPLOAD_PHOTOS_FAILED,
   payload: data,
});

const uploadPhotosCompleted = (data, name) => ({
   type: types.UPLOAD_PHOTOS_COMPLETED,
   payload: { data, name },
});
const removeUploadPhotosCompleted = data => ({
   type: types.REMOVE_UPLOAD_PHOTOS_COMPLETED,
   payload: data,
});


const deletePhotosCompleted = data => ({
   type: types.DELETE_PHOTOS_COMPLETED,
   payload: data,
});
const removeDeletedPhotosCompleted = data => ({
   type: types.REMOVE_DELETED_PHOTOS_COMPLETED,
   payload: data,
});
const savePhotosCompleted = data => ({
   type: types.SAVE_PHOTOS_COMPLETED,
   payload: data,
});

const selectCoverPhotoCompleted = data => ({
   type: types.SELECT_COVER_COMPLETED,
   payload: data,
});

const saveNewPhotosetsCompleted = data => ({
   type: types.SAVE_NEW_PHOTOSETS_COMPLETED,
   payload: data,
});

const fetchPhotosCompleted = data => ({
   type: types.FETCH_PHOTOS_COMPLETED,
   payload: data,
});

const customPricesCompleted = data => ({
   type: types.CUSTOM_PRICES_COMPLETED,
   payload: data,
});
const customPricesRequest = data => ({
   type: types.CUSTOM_PRICES_START,
   payload: data,
});
const fetchStatsRequestCompleted = data => ({
   type: types.FETCH_STATS_COMPLETED,
   payload: data,
});
const fetchStatsRequestStart = () => ({
   type: types.FETCH_STATS_START,
});


const validateStepsRequestStart = () => ({
   type: types.VALIDATE_STEPS_START,

});
const validateStepsRequestCompleted = data => ({
   type: types.VALIDATE_STEPS_COMPLETED,
   payload: data,
});
const validateStepsRequestFailed = (error) => ({
   type: types.VALIDATE_STEPS_FAILED,
   payload: error,
});
const deleteCompleted = (id, emptyFilterParams) => ({
   type: types.DELETE_COMPLETED,
   payload: { id, emptyFilterParams },
});
const setActiveTab = (error) => ({
   type: types.SET_ACTIVE_TAB,
   payload: error,
});
const setStatusModal = bool => ({
   type: types.SET_STATUS_MODAL,
   payload: bool,
});
const clearErrors = bool => ({
   type: types.CLEAR_ERRORE,
   payload: bool,
});
const setButtonDisabled = data => ({
   type: types.SET_BUTTON_DISABLED,
   payload: data,
});
const clearDataAction = data => ({
   type: types.CLEAR_DATA_ACTION,
   payload: data,
});
const uploadFakePhotosAction = (data, status, callBack) => ({
   type: types.UPLOAD_FAKE_PHOTOS,
   payload: { data, status, callBack },
});
const clearState = payload => ({
   type: types.CLEAR_STATE,
   payload,
});
const setFilterData = data => ({
   type: types.SET_FILTERS,
   payload: data,
});
const destroyRequest = bool => ({
   type: types.DISTORY_PHOTOSETS_REQUEST,
   payload: bool,
});
const destroyCompleted = ids => ({
   type: types.DISTORY_PHOTOSETS_COMPLETED,
   payload: ids,
});
const setBulkIds = ids => ({
   type: types.SET_BULK_IDS,
   payload: ids,
});
const bulkPhotosetsCompleted = ids => ({
   type: types.BULK_PHOTOSETS_COMLITED,
   payload: ids,
});
const bulkPhotosetsRequest = ids => ({
   type: types.BULK_PHOTOSETS_REQUEST,
   payload: ids,
});
const multipleUpdateAction = data => ({
   type: types.MULTIPLE_UPDATE_ACTION,
   payload: data,
});

const updatePublishData = (data) => ({
   type: types.UPDATE_PUBLISH_DATA,
   payload: data,
})
const clearBulkErrorAction = (data) => {
   return {
      type: types.CLEAR_BULK_ERROR,
      payload: data,
   };
}
const setEditTitelMode = bool => ({
   type: types.SET_EDIT_TITLE_MODAL,
   payload: bool,
});

//reorder
const reorderPhotoset = (data) => ({
   type: types.REORDER_PHOTOSET,
   payload: data,
})
const changeInitedState = () => ({
   type: types.CHANGE_INITED_STATE,
})


const updateBundleStateInData = (data, isDetailsPage) => {
   return ({
      type: types.UPDATE_BUNDLE_STATE_IN_DATA,
      payload: { data, isDetailsPage },
   })
}
const pinPhotosetCompleted = (data) => ({
   type: types.PIN_PHOTOSET_COMPLETED,
   payload: data,
});

const resetActiveDataAction = () => ({
   type: types.RESET_ACTIVE_DATA,
})
const setWarningModal = (data) => {
   return ({
      type: types.SET_WARNING_MODAL,
      payload: data,
   })
}
const updateMessageAction = (data) => {
   return ({
      type: types.UPDATE_MESSAGE_ACTION,
      payload: data,
   })

}
const changeLoadingStateAction = (payload) => ({
   type: types.CHANGE_LOADING_STATE,
   payload,
})


export {
   fetchRequest,
   fetchRequestCompleted,
   fetchRequestFailed,

   fetchNewRequest,
   fetchNewCompleted,
   fetchNewFailed,

   fetchByFilterRequest,
   fetchByFilterCompleted,
   fetchByFilterFailed,

   fetchByIdRequest,
   fetchByIdCompleted,
   fetchByIdFailed,

   updatePhotosetsRequest,
   deleteRequest,
   updatePhotosetsCompleted,
   updatePhotosetsFailed,

   uploadPhotosRequest,
   uploadPhotosCompleted,
   removeUploadPhotosCompleted,
   deletePhotosCompleted,
   removeDeletedPhotosCompleted,
   savePhotosCompleted,

   selectCoverPhotoCompleted,
   saveNewPhotosetsCompleted,
   fetchPhotosCompleted,
   customPricesCompleted,
   customPricesRequest,
   fetchStatsRequestStart,
   fetchStatsRequestCompleted,

   validateStepsRequestStart,
   validateStepsRequestCompleted,
   validateStepsRequestFailed,

   deleteCompleted,
   setActiveTab,
   uploadPhotosFailed,
   setStatusModal,
   clearErrors,
   setButtonDisabled,
   clearDataAction,

   uploadFakePhotosAction,
   clearState,
   setFilterData,
   destroyRequest,
   destroyCompleted,
   setBulkIds,
   bulkPhotosetsCompleted,
   bulkPhotosetsRequest,
   multipleUpdateAction,
   updatePublishData,
   clearBulkErrorAction,
   setEditTitelMode,

   reorderPhotoset,
   changeInitedState,

   updateBundleStateInData,
   pinPhotosetCompleted,
   resetActiveDataAction,
   setWarningModal,
   updateMessageAction,

   changeLoadingStateAction,
};
