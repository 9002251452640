/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import ResponsiveModal from 'common/components/modules/responsive-modal';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import Search from 'common/components/elements/inputs/search';
import TagCard from '../tag-card';
// import intercomMoreInfo, { MORE_INFO_ON_MEMBER_TAGS } from 'admin/utils/IntercomMoreInfo';
import CircleLoader from 'common/components/elements/loader/circle';
import Icon from 'common/components/elements/icons'
import EmptyState from 'common/components/modules/empty-states';
import QueryParams from 'admin/utils/QueryParams';
import IconLabel from 'common/components/elements/icon-label';

import './style.scss';

const AddMemberTags = ({
   isBulkEdit,
   isMemberTags,
   onClose,
   tagsCount,
   tags,
   isMobile,
   isFetchingMembersTags,
   isEmptyMembersTags,
   height,
   manageMembersTags,
   onAddTagsToMember,
   isLoadingAddTagsToMemberButton,
   selectedItem,
   optionValues,
   createNewTag,
   memberCount,
   tagsOfCurrentMember,
}) => {
   const [selectedTags, setSelectedTags] = useState([]);
   const [changedTags, setChangedTags] = useState([]);
   // eslint-disable-next-line no-unused-vars
   const [addTags, setAddTags] = useState([]);
   const [searchValue, setSearchValue] = useState('');
   // eslint-disable-next-line no-unused-vars
   const [filteredTags, setFilteredTags] = useState([...tags]);
   const [isExistTag, setIsExistTag] = useState(false);
   const [isLoading, setIsLoading] = useState(true);
   let showTagsTimeout

   const onSelectCards = (bool, id) => {
      if(bool){
         setSelectedTags([...selectedTags, id])
      } else {
         setSelectedTags(old => [...old].filter(i => i !== id))
      }
      let isSelected = changedTags.find(i => i === id);
      if(!!isSelected) {
         setChangedTags(old => [...old].filter(i => i !== id))
      } else {
         setChangedTags(old => [...old, id])
      }
      if(!selectedItem?.member_tags?.tags_ids?.find(item => item === id)) {
         if(!!isSelected) {
            setAddTags(old => [...old].filter(i => i !== id))
         } else {
            setAddTags(old => [...old, id])
         }
      }
   }

   useEffect(() => {
      if(tagsOfCurrentMember){
         let ids = [];
         tagsOfCurrentMember?.forEach((tag) => {
            ids = [...ids, tag.id]
         })
         setSelectedTags(ids)
      }
      // console.log()
      // if(!!tags?.length) {
      //    let memberTagsIds = selectedTags;
      //    let filtered = tags
      //    // .filter(item => item.rule === null)
      //    if(isMemberTags) {
      //       memberTagsIds = tags?.map(item => item.id);
      //    } else {
      //       filtered = tags
      //       // ?.filter(el => !el.rule)
      //    }
      //    setFilteredTags(filtered)
      //    setSelectedTags([...memberTagsIds]);
      // }
   }, [])

   useEffect(() => {
      QueryParams.addQueryParam('manage', 'tag-member')
      return () => {
         QueryParams.removeQueryParam('manage')
         tagsOfCurrentMember = null
         clearTimeout(showTagsTimeout)
      }
   }, [])

   const onSearchChange = (value) => {
      setSearchValue(value);

      let newdataList = [];

      // eslint-disable-next-line array-callback-return
      newdataList = [...tags].filter(item => {
         if(item?.name && item.name.toLowerCase().search(value.toLowerCase()) !== -1) {
            return item
         }

      })
      let existingTag = tags.find(item => item.name?.toLowerCase() === value?.toLowerCase());
      setIsExistTag(!!existingTag);
      setFilteredTags(newdataList)
   }

   showTagsTimeout = setTimeout(() => {
      setIsLoading(false)
   }, 500);

   return (
      <>
         <ResponsiveModal
            // headerTitle={ isMemberTags ? `Member tags • ${ tagsCount }` : `Tag member${ !!isBulkEdit ? 's' : '' }` }
            headerLeftIconAction={ onClose }
            onClose={ () => {} }
            headerLeftIconName='close'
            width='375px'
            height={ `${ isMobile ? 'calc(100vh - 128px) !important' : '684px' }` }
            footerButtonText='Save changes'
            withoutFooter
            contentClass='p-6 pt-0 bg-panel sm:rounded-xlg'
            isHelpIcon={ false }
            contentPosition={ isMobile ? 'bottom' : 'responsive'  }
            headerClassName={ 'pl-2' }
         >
            <div className='member-tags-modal h-full flex flex-col items-center'>
               <IconLabel
                  className='w-10 h-10'
                  name='tag'
                  size='2xl'
                  color='action'
               />
               <span className='leading-8 mt-3 mb-6 flex items-center justify-center text-major text-xl font-semibold select-none' style={ {
                  lineHeight: '30px',
               } }>Add or remove tags</span>
               {
                  isLoading ? (
                     <div className='flex items-center justify-center h-[400px] '>
                        <CircleLoader color='blue' />
                     </div>
                  ) : (
                     <div className='h-full flex flex-col' style={ { height: isMobile ? `${ window?.innerHeight < 680 ? '480px' : '490px' }` : '500px' } }>
                        <div
                           className='flex flex-col justify-between gap-1'
                           style={ { height: isMobile ? 'calc(100% - 40px)' : '100%' } }
                        >
                           {
                              isEmptyMembersTags ? (
                                 <div className='pt-6 pb-4 items-center'>
                                    <p className='text-base text-center leading-relaxed text-secondary font-normal'>
                                       You don’t have any tags in your members tag list
                                    </p>
                                 </div>
                              ) : (
                                 <div className='member-tags-modal-content'>
                                    <div className='mb-2'>
                                       <Search
                                          value={ searchValue }
                                          onChange={ (value) => {
                                             if(value.length < 26) {
                                                onSearchChange(value)
                                             }
                                          } }
                                          placeholder={ `Search tags` }
                                          height='h-10'
                                          maxLength={ 25 }
                                          isDebounce
                                          version='2'
                                          clearIcon='xl'
                                       />
                                    </div>
                                    {
                                       !!searchValue && searchValue.length > 0 && !isExistTag && (
                                          <div
                                             className='flex items-center mb-1 cursor-pointer gap-2 h-9'
                                             role='presentation'
                                             onClick={ () => createNewTag(searchValue) }
                                          >
                                             <div className='flex items-center justify-center w-5 h-5 flex-none'>
                                                <Icon
                                                   name='tag'
                                                   size='base'
                                                   color='action'
                                                />
                                             </div>
                                             <div className={ `flex  flex-wrap hover:underline text-action ` }>
                                                <div className='h-5 flex items-center text-sm justify-between' style={ { lineHeight: '16px' } }>
                                                   <span className='font-medium flex-none leading-5'>Create new member tag:</span>
                                                </div>
                                                <span className={ `font-semibold flex items-center text-sm ml-1` }>{`“${ searchValue }”`}</span>
                                             </div>
                                          </div>
                                       )
                                    }
                                    {
                                       !!tags?.length && filteredTags.length > 0 ? (
                                          <div
                                             className='h-full tags overflow-auto tags-scrollbar ams-custom-scrool'
                                             style={ { maxHeight: !!searchValue && searchValue.length > 1 && !isExistTag ? 'calc(100%)' : '258px' } }
                                          >
                                             {
                                                filteredTags?.map(el => {
                                                   // const isSelected = (tagsOfCurrentMember?.map(it => it.id).includes(el.id) && !selectedTags.includes(el.id) && !changedTags.includes(el.id))
                                                   //                      || (changedTags.includes(el.id) && selectedTags.includes(el.id))
                                                   //                      || selectedTags.includes(el.id);
                                                   const isSelected = [...selectedTags].includes(el.id)
                                                   let rule = optionValues?.find(rl => rl.value ===  el.rule);
                                                   return (
                                                      <div
                                                         key={ el.id }
                                                         className='h-auto mt-2'
                                                      >
                                                         <TagCard
                                                            rule={ rule?.display_name }
                                                            tagIcon={ el.emoji }
                                                            isMobile={ isMobile }
                                                            name={ el.name }
                                                            id={ el.id }
                                                            onSelectCard={ (bool) => {
                                                               onSelectCards(bool, el.id)
                                                            } }
                                                            isSelected={ isSelected }
                                                            tagMembersCount={ el.tag_members_count }
                                                            isInsideModal
                                                         />
                                                      </div>
                                                   )
                                                })
                                             }
                                          </div>
                                       ) : (
                                          <div className='flex items-center h-full'>
                                             <EmptyState
                                                type={ 'search' }
                                                result={ searchValue }
                                             />
                                          </div>
                                       )
                                    }
                                 </div>
                              )
                           }
                           <div className='flex flex-col justify-center items-center mb-8' style={ {
                              // marginTop: '20px',
                           } }>
                              <div className='mt-6 w-52'>
                                 <Button
                                    text='Manage member tags'
                                    onClick={ manageMembersTags }
                                    iconName='go-to'
                                    iconPosition='left'
                                    borderRadius='large'
                                    backgroundColor='transparent'
                                    textColor='action'
                                    textSize='small'
                                    fontIconSize='extraLarge'
                                    fontIconColor='action'
                                    padding='0'
                                    waveColor='rgba(33, 150, 243, 0.8)'
                                    fontWeight='medium'
                                    primaryColor
                                 />
                              </div>
                              <div className='mt-3 w-60'>
                                 <Button
                                    text='More info on member tags'
                                    onClick={ () => window?.Intercom('showArticle', 6624105) }
                                    iconName='attention-outline'
                                    iconPosition='left'
                                    borderRadius='large'
                                    backgroundColor='transparent'
                                    textColor='action'
                                    textSize='small'
                                    fontIconSize='extraLarge'
                                    fontIconColor='action'
                                    padding='0'
                                    waveColor='rgba(33, 150, 243, 0.8)'
                                    fontWeight='medium'
                                    primaryColor
                                    classNames='intercom-more-info-on-member-tags'
                                 />
                              </div>
                           </div>
                        </div>
                        {
                           !isEmptyMembersTags && (
                              <div className='tag-member-button'>
                                 <Button
                                    // text={ isMemberTags ? 'Done' : `Tag ${ memberCount ? memberCount : '' } member${ memberCount ? (memberCount === 1 ? '' : 's') : '' }` }
                                    text={ isMemberTags ? 'Done' : `Save changes` }
                                    primaryColor
                                    borderRadius='large'
                                    textSize={ 'base' }
                                    disabled={ changedTags.length === 0 || isLoadingAddTagsToMemberButton }
                                    isLoading={ isLoadingAddTagsToMemberButton }
                                    onClick={ () => {

                                       // console.log('selectedItem?.id, changedTags, selectedTags', selectedItem?.id, changedTags, selectedTags)
                                       // console.log(tagsOfCurrentMember);
                                       // const selectedIds=selectedTags.filter(tag=>)
                                       onAddTagsToMember(selectedItem?.id, changedTags, selectedTags)
                                    } }
                                 />
                              </div>
                           )
                        }
                     </div>
                  )
               }
            </div>
         </ResponsiveModal>
      </>
   )
}

AddMemberTags.propTypes = {
   onClose: PropTypes.func,
   tags: PropTypes.array,
   selectedItem: PropTypes.object,
   isMobile: PropTypes.bool,
   isFetchingMembersTags: PropTypes.bool,
   isEmptyMembersTags: PropTypes.bool,
   height: PropTypes.number,
   manageMembersTags: PropTypes.func,
   onAddTagsToMember: PropTypes.func,
   isLoadingAddTagsToMemberButton: PropTypes.bool,
   optionValues: PropTypes.array,
   createNewTag: PropTypes.func,
   isBulkEdit: PropTypes.bool,
   isMemberTags: PropTypes.bool,
   tagsCount: PropTypes.number,
   memberCount: PropTypes.number,
   tagsOfCurrentMember: PropTypes.any,
}

export default AddMemberTags
