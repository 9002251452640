import React from 'react'
import PropTypes from 'prop-types'
// import Icon from 'common/components/elements/icons';
import ChatItem from 'common/components/modules/chat/list-item';
import Button from 'common/components/elements/buttons/primary';
import ChatItemLoading from 'common/components/modules/chat/list-loading';
import IntercomMoreInfo, { CONTACT_SUPPORT } from 'sites/utils/IntercomMoreInfo';
import './style.scss'

function NotificationsList({
   list,
   onClickChat,
   buttonText,
   onClickButton,
   isMobile,
   isFetchConversation,
   isFetchNewConversation,
   onScrollList,
   onlyTwoConvestaion,
   showButton,
   showHelpIcon,
   authUser,
   adminUuid,
   isAdmin,
}) {
   return (
      <div
         role='presentation'
         // onClick={ (e) => e.stopPropagation() }
         // style={ {
         //    maxHeight: window?.innerWidth > 1024 ? `${ `${ window?.innerHeight - document.querySelector('.page__header')?.clientHeight }px` }` : 'auto',
         // } }
         className={ `h-full w-full flex flex-col relative notification-list-content bg-panel dark:bg-panel-dark
         ${ !isMobile ? 'rounded-xlg' : '' }` }

      >
         <div className='flex justify-between border-b dark:border-divider-dark border-divider' style={ { padding: '12px 18px 12px 20px' } }>
            <span className='text-xl leading-6 font-bold text-major dark:text-major-dark'>Chat</span>
            {
               showHelpIcon && (
                  <div className='h-6 w-6'>
                     <Button
                        fontIconSize='doubleLarge'
                        backgroundColor='transparent'
                        onClick={ () => IntercomMoreInfo(CONTACT_SUPPORT) }
                        classNames='h-full'
                        iconName='help'
                        fontIconColor='major'
                        darkIconcolor='major-dark'
                        padding='none'
                     />
                  </div>

               )
            }
         </div>
         <div
            onScroll={ onScrollList }
            className='flex-1 overflow-y-auto ams-custom-scrool'>
            {
               !isFetchConversation && (
                  <>
                     {
                        list && list.map(item => {
                           if(!item) return null
                           return (
                              <ChatItem
                                 key={ item && item.chat_room_id }
                                 chat={ item }
                                 isGroup={ !!item.isGroup }
                                 onClick={ () => onClickChat(item) }
                                 authUserUuid={ authUser.uuid }
                                 showMembersCount={ showButton }
                                 hiddeBorder={ (list.length === 1 && !showButton) }
                                 adminUuid={ adminUuid }
                                 isAdmin={ isAdmin }
                              />
                           )
                        })
                     }
                     {
                        isFetchNewConversation && (
                           <ChatItemLoading count={ 3 } />
                        )
                     }
                  </>
               )
            }
            {
               isFetchConversation && (
                  <ChatItemLoading count={ onlyTwoConvestaion ? 2 : 6 } />
               )
            }
         </div >
         {
            !isFetchConversation  && showButton && (
               <div className='see-all-conversations-rectangle'>

                  <Button
                     text={ buttonText }
                     primaryColor
                     backgroundColor='transparent'
                     classNames='dark:hover:!bg-transparent'
                     onClick={ onClickButton }
                  />

               </div>
            )
         }
      </div>
   )
}

NotificationsList.propTypes = {
   list: PropTypes.array,
   onClickChat: PropTypes.func,
   onClickButton: PropTypes.func,
   buttonText: PropTypes.string,
   isMobile: PropTypes.bool,
   isFetchConversation: PropTypes.bool,
   isFetchNewConversation: PropTypes.bool,
   onlyTwoConvestaion: PropTypes.bool,
   showButton: PropTypes.bool,
   onScrollList: PropTypes.func,
   showHelpIcon: PropTypes.bool,
   authUser: PropTypes.object,
   adminUuid: PropTypes.string,
   isAdmin: PropTypes.bool,
}
NotificationsList.defaultProps = {
   buttonText: 'See all conversations',
   onClickButton: () => {},
   onClickChat: () => {},
   onScrollList: () => {},
   onlyTwoConvestaion: false,
   showButton: true,
   showHelpIcon: true,
   authUser: {},
}
export default NotificationsList

