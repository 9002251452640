import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { screenWidthSelector } from 'admin/state/modules/common/selectors';
import {
   descktopUserListSelector,
   showMiniChatSelector,
   openDesctopDialogueSelector,
   chatByUserSelector,
   activeConverstaionSelector,
   groupConversationSelector,
   initedConverstionMessagesSelector,
   memberByUuidSelector,
} from 'admin/state/modules/chat/selectors';
import {
   setOpenDesktopDialogueAction,
   desktopChatDialogueCompleted,
   setChatConversationAction,
   setBlockMemberAction,
   updateConverstionAction,
   updateUnreamPmMessageCountAction,
   updateConversationMessagesByIdAction,
} from 'admin/state/modules/chat/actions';
import MiniChat from 'admin/views/chat/mini-chat'
import { currentLocationPathSelector } from 'admin/state/modules/router/selectors';
import { authUserSelector, isOnlineStreamSelector } from 'admin/state/modules/common/selectors';

import { fetchConversationMessagesOperation } from 'admin/state/modules/chat/operations';
import ChatConversationMessagesContainer  from 'admin/containers/pages/chat/index/dialogue';
import Router, { getCurrentRoute } from 'admin/routes/router';
import ChatHepler from 'common/utils/chat';
import Modal from 'common/components/modules/modal';
import ConfirmDialog from 'common/components/modules/confirm-dialog';
import QueryParams from 'admin/utils/QueryParams';
import { cancelUplaod } from 'admin/utils/VaultVideoUpload'
import { push } from 'connected-react-router';
import {
   setMemberUsernameModal,
   updateMemberByUuid,
   removeTagToMember,
   changeTagToMember,
   updateMembersRequestCompleted,
   setActiveMemberData,
   updateMemberFailed,
} from 'admin/state/modules/members/actions';
import {
   addFreeMemberOperation,
   updateMembersOperation,
   expireFreeMemberShipOperation,
   fetchStatisticsData,
   memberFetchDetailsOperation,
} from 'admin/state/modules/members/operations';
import ChooseMembership from 'admin/views/members/add-free-member/choose-membership';
import AddMemberTags from 'admin/views/member-tags/add-member-tags';
import MuteMember from 'admin/views/chat/modules/mute-block-member';
import UpdateUserName from 'common/components/modules/user-info/update-username';
import {
   muteMember,
   unMuteMember,
   updateMember,
   addTagsToMembers,
   disableMultipleMemberAllNotifications,
   enableMultipleMemberAllNotifications,
   initMembersTags,
   addMembersTag,
   getMember,
} from 'admin/api/AuthApi';
import intercomMoreInfo, { CLIENT_REQUEST_CONTACT_SUPPORT_ABOUT_MEMBER } from 'admin/utils/IntercomMoreInfo';
import toast from 'common/utils/toast';
import { ExpireFreeMembershipModal } from 'admin/views/chat/modules/expire-free-membership';
import AddMemberTag from 'admin/views/member-tags/add-member-tag';
import { addNewTagCompleted } from 'admin/state/modules/member-tags/actions';
import { activeMemberSelector, updateErrorsSelector } from 'admin/state/modules/members/selectors';
import DeleteVoiceRecordConfirmModal from 'common/components/modules/chat/delete-voice-confirm';
import { audioRecorder } from 'common/utils/audioRecording';
import AddEditNicknameModal from 'admin/views/members/add-edit-nickname';
import { updateNotificationsByMemberIdAction } from 'admin/state/modules/notifications/actions';

class MiniChatContainer extends Component {
   static propTypes = {
      chatList: PropTypes.any,
      userList: PropTypes.any,
      screenWidth: PropTypes.number,
      showMiniChat: PropTypes.bool,
      openDesctopDialogue: PropTypes.bool,
      fetchMessages: PropTypes.func,
      activeConverstaion: PropTypes.object,
      openDialogue: PropTypes.func,
      groupConversation: PropTypes.object,
      locationPath: PropTypes.string,
      onDeleteMessag: PropTypes.func,
      onUserTyping: PropTypes.func,
      onLikeMessage: PropTypes.func,
      onSendMessage: PropTypes.func,
      onReadMessage: PropTypes.func,
      initedConverstionMessages: PropTypes.object,
      converstionInfo: PropTypes.object,
      setChatConversation: PropTypes.func,
      setOpenDesktopDialogue: PropTypes.func,
      onSetMemberOption: PropTypes.func,
      onUnMuteMember: PropTypes.func,
      changeTagToMemberDt: PropTypes.func,
      updateMemberByUuid: PropTypes.func,
      addFreeMemberOperation: PropTypes.func,
      goTo: PropTypes.func,
      blockMemberAction: PropTypes.func,
      updateMembersAction: PropTypes.func,
      showOnMobile: PropTypes.bool,
      expireFreeMemberShip: PropTypes.func,
      fetchStatisticsData: PropTypes.func,
      memberFetchDetails: PropTypes.func,
      addNewTagCompleted: PropTypes.func,
      setActiveMember: PropTypes.func,
      activeMember: PropTypes.object,
      // updateConverstion: PropTypes.func,
      // updateMUnreamPmMessageCount: PropTypes.func,
      updateMembers: PropTypes.func,
      updateConversationMessagesById: PropTypes.func,
      updateNotificationsByMemberId: PropTypes.func,
      updateErrors: PropTypes.object,
      updateMemberFailedAction: PropTypes.func,
      updateMessageUnlockDetails: PropTypes.func,
      updateMessageData: PropTypes.func,
   }
   state = {
      openKeepModal: false,
      removeUploadVideo: false,
      openFreeMemberShipModal: false,
      animate: 'up',
      warningMessage: null,
      selectedMembership: null,
      addCustomTagOpen: false,
      isLoadingAddTagsToMemberButton: false,
      height: null,
      memberOptionModal: {
         type: null,
         isLoading: false,
         isOpen: false,
      },
      isOpenUsernameModal: false,
      updateErrors: {},
      isOpenExpireFreeMembershipConfirmModal: false,
      newTagModalOpen: false,
      showCheckbox: false,
      errors: {},
      newTagName: '',
      isButtonLoading: false,
      deleteRecordModal: {
         isOpen: false,
         data: {},
         action: '',
      },
      isOpenNicknameModal: false,
   }
   unsubscribeFromHistory = ''
   timeout = null
   event = null

   optionValues = [
      {
         value: 'high_roller',
         display_name: 'Total spent is more than 1000 USD',
      },
      {
         value: 'king',
         display_name: 'Total spent 500 - 1000 USD',
      },
      {
         value: 'prince',
         display_name: 'Total spent 250 - 500 USD',
      },
      {
         value: 'duke',
         display_name: 'Total spent 100 - 250 USD',
      },
   ];

   leavChatConversationDispatchEvent = (payload) => {
      const leavChatConversation = new CustomEvent('leav-chat-conversation', {
         detail: {
            payload,
         },
      })
      window.dispatchEvent(leavChatConversation);
   }

   componentDidMount() {
      const height = window.innerHeight;
      this.setState({ height })
      this.getMembersTags();
   }

   componentWillUnmount() {
      clearTimeout(this.timeout)
   }

   onCloseDeleteRecordModal = () => {
      this.setState({
         deleteRecordModal: {
            isOpen: false,
            data: {},
            action: '',
         },
      })
   }

   onDeleteRecord = () => {
      const { data, action } = this.state.deleteRecordModal;
      this.leavChatConversationDispatchEvent();
      if(!!audioRecorder) {
         audioRecorder.stopStream()
      }
      switch (action) {
         case 'handleClickOutside':
            const { event, bool } = data;
            this.handleClickOutside(event, bool);
            break;
         case 'onClickChatItem':
            this.onClickChatItem(data.data);
            break;

         default:
            break;
      }
      this.onCloseDeleteRecordModal();
   }
   handleClickOutside = async (event, bool = false) => {
      const {
         screenWidth,
         setOpenDesktopDialogue,
         locationPath,
      } = this.props;
      const oldlocationPath = locationPath
      if(screenWidth < 1025){
         return
      }
      const modal = document.querySelector('#modals-root')
      const miniChat = document.querySelector('#mini-chat')
      const optionTooltip = document.querySelector('#chat_options_menu_tooltip')
      if(miniChat && miniChat.contains(event.target)) {
         return
      }
      if(optionTooltip && optionTooltip.contains(event.target)) {
         return
      }

      if(QueryParams.getParam('upload_video') &&  'start' === QueryParams.getParam('upload_video')){
         this.setState({
            openKeepModal: true,
         })
         this.event = event
      } else {
         if(modal && modal.contains(event.target) && !bool) {
            return
         }
         if(window.chatVoiceMessageRecorded){
            this.setState({
               deleteRecordModal: {
                  isOpen: true,
                  data: {
                     event, bool: true,
                  },
                  action: 'handleClickOutside',
               },
            })
            return
         }

         if('start' === QueryParams.getParam('upload_video')) {
            this.setState({
               removeUploadVideo: true,
            })
            QueryParams.removeQueryParam('upload_video')
         }
         this.timeout = setTimeout(() => {
            const { locationPath } = this.props
            if(oldlocationPath !== locationPath) {
               return
            }
            if(QueryParams.getParam('conversation') && typeof +QueryParams.getParam('conversation') === 'number'){
               QueryParams.removeQueryParam('conversation')
            }
            setOpenDesktopDialogue(false)
            event.stopPropagation();
         }, 200)

      }
   }

   closeAskModal = () => {
      this.setState({
         openKeepModal: false,
      })
      this.event = null
   }
   askleavConversation = () => {
      const { activeConverstaion, setChatConversation } = this.props
      const videoInfo = activeConverstaion.content ? activeConverstaion.content.videoUpload : { uploadInfo: {} }
      cancelUplaod(videoInfo || {}, () => {
         QueryParams.removeQueryParam('upload_video')
         this.handleClickOutside(this.event, true)
         this.event = null

         setChatConversation({
            ...activeConverstaion,
            content: {},
         })
         let ollContents = window.currentConversationContent ? JSON.parse(window.currentConversationContent) : {}
         delete ollContents[activeConverstaion.chat_room_id]
         window.currentConversationContent = JSON.stringify(ollContents)
         this.closeAskModal()
      })

   }

   onClickChatItem = async (data) => {
      const {
         fetchMessages,
         openDialogue,
         groupConversation,

         onReadMessage,
         initedConverstionMessages,
         setChatConversation,
         // updateConverstion,
         // updateMUnreamPmMessageCount,
      } = this.props;

      ChatHepler.inputState(false)
      if(window.chatVoiceMessageRecorded){
         this.setState({
            deleteRecordModal: {
               isOpen: true,
               data: {
                  data,
               },
               action: 'onClickChatItem',
            },
         })
         return
      }

      const id = data.chat_room_id

      // if(id) {
      //    updateConverstion({ conversationId: id, data: { unread_messages_count: 0 } })
      //    updateMUnreamPmMessageCount(-data?.unread_messages_count);
      // }

      if(id && initedConverstionMessages && !initedConverstionMessages[id]) {
         await fetchMessages(data, groupConversation, {}, () => {
            openDialogue(data, true)
            ChatHepler.syncDialogueScroll(null, data.chat_room_id)
            ChatHepler.inputState()
            const id = data.chat_room_id
            if(id && data.unread_messages_count && data.unread_messages_count > 0) {
               onReadMessage(id)
            }
         })
      } else {
      // const dialogue = document.querySelector(`#messages-content-${ activeConverstaion.chat_room_id }`)
      // if(window.scrolledPosition && dialogue) {
      //    window.scrolledPosition[activeConverstaion.chat_room_id] = dialogue ? dialogue.scrollTop : null
      // }
         let ollContents = window.currentConversationContent ? JSON.parse(window.currentConversationContent) : {}
         let content = {
            content: ollContents[data.chat_room_id],
         }
         await setChatConversation({ ...data, ...content })
         await openDialogue(data, true)
         ChatHepler.syncDialogueScroll(null, data.chat_room_id)
         ChatHepler.inputState()
         const id = data.chat_room_id
         if(id && data.unread_messages_count && data.unread_messages_count > 0) {
            onReadMessage(id)
         }
      }
      if(QueryParams.getParsedSearchParams().conversation !== data.chat_room_id) {
         QueryParams.addQueryParam('conversation', data.chat_room_id)
      }
   }



   // START member options functional

   setSelectedMembership = (membership) => {
      this.setState({
         selectedMembership: membership,
      })
   }

   openCloseMemberShipModal = (bool) => {
      this.setState({
         openFreeMemberShipModal: bool,
         animate: bool ? 'up' : 'down',
      })
   }

   openAddCustomTagModal = (bool) => {
      const { setActiveMember, activeConverstaion } = this.props;

      getMember(activeConverstaion?.member?.id)
         .then((res) => {
            setActiveMember(res?.data)
         })
         .catch(err => console.log(err))

      let animate = 'down';
      if(!!bool) {
         animate = 'up'
      }
      this.setState({ addCustomTagOpen: !this.state.addCustomTagOpen, animate: animate })
   }

   generateTagsQuery = () => {
      const query = [];
      Object.keys(this.tagsFilter).forEach((key) => {
         if(key){
            if(this.tagsFilter[key]) {
               key !== 'count' && key !== 'page' ? query.push(`query[]=${ key }=${ this.tagsFilter[key] }`) : query.push(`${ key }=${ this.tagsFilter[key] }`);
            }
         }
      });
      return query;
   };

   getMembersTags = () => {
      this.tagsFilter = {
         ...this.tagsFilter,
         count: 'all',
      }
      const tagsQuery = this.generateTagsQuery();
      this.setState({ isFetchingMembersTags: true })
      initMembersTags(tagsQuery)
         .then((res) => {
            this.setState({
               isFetchingMembersTags: false,
               membersTags: [...res?.data],
               isEmptyMembersTags: [...res?.data].length === 0,
            })
         })
         .catch(err => {
            this.setState({ isFetchingMembersTags: false })
         })
   }

   onAddTagsToMember = (selectedItem, selectedTags, addTagsId) => {
      const { changeTagToMemberDt } = this.props;
      const { membersTags } = this.state;
      this.setState({ isLoadingAddTagsToMemberButton: true })
      let data = {
         member_ids: [selectedItem],
         tag_ids: [...selectedTags],
      }
      addTagsToMembers(data)
         .then((res) => {
            let addTags = membersTags?.filter(el => addTagsId.includes(el.id));
            let data = {
               members_ids: [selectedItem],
               tag_ids: [...selectedTags],
               memberTagIds: addTagsId,
               allTags: membersTags,
               membersTag: addTags,
            }
            changeTagToMemberDt(data);

            this.handleChangeConversationMember(data);
            this.setState({ isLoadingAddTagsToMemberButton: false, addCustomTagOpen: false })
            toast.success('Your changes have been saved');
            this.getMembersTags()
         })
         .catch((err) => {
            this.setState({ isLoadingAddTagsToMemberButton: false })
         })
   }

   handleChangeConversationMember = ({ tag_ids, addTags }) => {
      const { activeConverstaion, setChatConversation } = this.props;
      let member = activeConverstaion?.member;
      if(!!Object.keys(member).length) {
         let memberTags = [];
         member.member_tags.forEach(el => {
            if(!tag_ids.includes(el.id)) {
               memberTags = [...memberTags, el];
            }
         });
         memberTags = [
            ...memberTags,
            ...addTags,
         ];
         member =  {
            ...member,
            member_tags: [...memberTags],
         }
      }
      setChatConversation({ ...activeConverstaion, member: { ...member } })
   }

   openNewTagModal = (tagName) => {
      QueryParams.pushQueryParam('new-tag-name', tagName)

      this.setState({
         name: tagName,
      })
      this.setState({
         addCustomTagOpen: !this.state.addCustomTagOpen,
      })
      setTimeout(() => {
         this.showAddTagModal()
      }, 250);
   }


   onSetMemberOption = type => {
      switch (type) {
         case 'unmute':
            this.onUnMuteMember()
            break;
         case 'unblock':
            this.onBlockMember()
            break;
         case 'enable_notifications':
            this.onEnableMemberAllNotifications()
            break;
         case 'help':
            intercomMoreInfo(CLIENT_REQUEST_CONTACT_SUPPORT_ABOUT_MEMBER)
            break;
         case 'expire_free_membership':
            this.openCloseExpireFreeMembershipModal(true)
            break;
         default:
            this.setState({
               memberOptionModal: {
                  type,
                  isOpen: !!type,
               },
            })
            break;
      }
   }

   onMuteMember = async (mutedPeriod, memberUuid) => {
      const { memberOptionModal } = this.state
      const { activeConverstaion } = this.props;
      let member = activeConverstaion?.member;
      this.setState({
         memberOptionModal: {
            ...memberOptionModal,
            isLoading: true,
         },
      })
      try {
         await muteMember(memberUuid, { mute_period_in_seconds: mutedPeriod })
         const { updateMemberByUuid } = this.props
         updateMemberByUuid(member.uuid, { muted_since: Date.now() })
         toast.remove(`${ member.nickname || member.username } has been muted`)
         this.setState({
            memberOptionModal: {
               ...memberOptionModal,
               isLoading: false,
               type: null,
               isOpen: false,
            },
         })
      } catch (error) {
         this.setState({
            memberOptionModal: {
               ...memberOptionModal,
               isLoading: false,
            },
         })
      }
   }

   onDisableMemberAllNotifications = async (disabledPeriod, memberUuid) => {
      const { memberOptionModal } = this.state
      const { activeConverstaion } = this.props;
      let member = activeConverstaion?.member;
      this.setState({
         memberOptionModal: {
            ...memberOptionModal,
            isLoading: true,
         },
      })
      try {
         await disableMultipleMemberAllNotifications({ uuids: [memberUuid], notifications_disable_period_in_seconds: disabledPeriod })
         const { updateMemberByUuid, setChatConversation } = this.props
         updateMemberByUuid(member.uuid, { notifications_disabled_since: Date.now() })
         setChatConversation({ ...activeConverstaion, member: { ...activeConverstaion?.member, notifications_disabled_since: Date.now() } })
         toast.remove('Notifications have been disabled')
         this.setState({
            memberOptionModal: {
               ...memberOptionModal,
               isLoading: false,
               type: null,
               isOpen: false,
            },
         })
      } catch (error) {
         this.setState({
            memberOptionModal: {
               ...memberOptionModal,
               isLoading: false,
            },
         })
      }
   }

   onEnableMemberAllNotifications = async () => {
      const { memberOptionModal } = this.state
      const { activeConverstaion } = this.props;
      let member = activeConverstaion?.member;
      this.setState({
         memberOptionModal: {
            ...memberOptionModal,
            isLoading: true,
         },
      })
      try {
         await enableMultipleMemberAllNotifications({ uuids: [member.uuid] })
         const { updateMemberByUuid, setChatConversation } = this.props
         updateMemberByUuid(member.uuid, { notifications_disabled_since: null })
         setChatConversation({ ...activeConverstaion, member: { ...activeConverstaion?.member, notifications_disabled_since: null } })
         toast.remove('Notifications have been enabled')
         this.setState({
            memberOptionModal: {
               ...memberOptionModal,
               isLoading: false,
            },
         })
      } catch (error) {
         this.setState({
            memberOptionModal: {
               ...memberOptionModal,
               isLoading: false,
            },
         })
      }
   }


   onBlockMember = async () => {
      const { memberOptionModal } = this.state
      const { activeConverstaion, blockMemberAction } = this.props
      let member = activeConverstaion?.member;
      const banned = member && !member.banned ? 1 : 0
      if(banned) {
         this.setState({
            memberOptionModal: {
               ...memberOptionModal,
               isLoading: true,
               isOpen: false,
            },
         })
      }
      try {
         await updateMember(member.uuid, { banned }, 'uuid');
         const { updateMemberByUuid } = this.props
         updateMemberByUuid(member.uuid, { banned })
         toast.remove(`${ member.nickname || member.username } has been ${ !banned ? 'unblocked' : 'blocked' }`)
         blockMemberAction({ banned, uuid: member.uuid })
         if(banned){
            this.setState({
               memberOptionModal: {
                  ...memberOptionModal,
                  isLoading: false,
                  type: null,
                  isOpen: false,
               },
            })
         }
      } catch (error) {
         this.setState({
            memberOptionModal: {
               ...memberOptionModal,
               isLoading: false,
            },
         })
      }
   }
   onUnMuteMember = async () => {
      const { memberOptionModal } = this.state
      const { activeConverstaion } = this.props;
      let member = activeConverstaion?.member;
      this.setState({
         memberOptionModal: {
            ...memberOptionModal,
            isLoading: true,
         },
      })
      try {
         await unMuteMember(member.uuid)
         const { updateMemberByUuid } = this.props
         updateMemberByUuid(member.uuid, { muted_since: null })
         toast.remove(`${ member.nickname || member.username } has been unmuted`)
         this.setState({
            memberOptionModal: {
               ...memberOptionModal,
               isLoading: false,
            },
         })
      } catch (error) {
         this.setState({
            memberOptionModal: {
               ...memberOptionModal,
               isLoading: false,
            },
         })
      }
   }

   setMemberUsernameModal = (bool) => {
      this.setState({ isOpenUsernameModal: bool, updateErrors: {} })
   }

   updateMembers = async (id, data, callBack) => {
      const { updateMembersAction, setChatConversation, activeConverstaion } = this.props;
      try {
         await updateMember(id, data)
         updateMembersAction({ id, data });
         setChatConversation({
            ...activeConverstaion,
            username: data.username,
            member: {
               ...activeConverstaion?.member,
               username: data.username,
            },
         })
         toast.success('Username has been updated')
         if(callBack) {
            callBack();
         }
         this.setState({ isOpenUsernameModal: false, updateErrors: {} })
      } catch (err) {
         if(callBack){
            callBack()
         }
         if(err && err.response) {
            this.setState({  updateErrors: err.response.data })
         }
      }
   }

   openCloseExpireFreeMembershipModal = (bool) => {
      this.setState({ isOpenExpireFreeMembershipConfirmModal: bool })
   }

   addFreeMember = () => {
      const { activeConverstaion, addFreeMemberOperation, memberFetchDetails, fetchStatisticsData } = this.props;
      const { selectedMembership } = this.state;
      let member = activeConverstaion?.member;
      if(selectedMembership && member?.id) {
         const data = {
            membership_period: selectedMembership,
         }
         addFreeMemberOperation(member?.id, data, () => {
            this.setState({
               warningMessage: null,
               isOpenExpireFreeMembershipConfirmModal: false,
            })
            memberFetchDetails(member?.id)
            fetchStatisticsData();
            this.openCloseMemberShipModal(false)
         });
      }
   }

   onExpireFreeMemberShip = () => {
      const { expireFreeMemberShip, activeConverstaion, setChatConversation, fetchStatisticsData } = this.props;
      expireFreeMemberShip(activeConverstaion?.member.id, (data) => {
         this.setState({ warningMessage: null })
         fetchStatisticsData()
         setChatConversation({
            ...activeConverstaion,
            member: {
               ...activeConverstaion?.member,
               ...data,
            } });
      })
   }

   // END member options functional

   changeCheckbox = (bool) => {
      this.setState({
         showCheckbox: bool,
      })
   }

   showAddTagModal = () => {
      this.setState({
         newTagModalOpen: !this.state.newTagModalOpen,
      })
   }

   onCloseNewTagModal = () => {
      QueryParams.removeQueryParam('new_tag_name');
      this.setState({
         newTagModalOpen: false,
         newTagName: '',
      });
      this.setState({ errors: {} })

   }

   createNewTag = (data) => {
      const { addNewTagCompleted } = this.props;
      this.setState({ isButtonLoading: true })
      addMembersTag(data)
         .then((res) =>  {
            addNewTagCompleted(res?.data);
            QueryParams.removeQueryParam('new_tag_name');
            this.setState({ isButtonLoading: false, newTagModalOpen: false, newTagName: '', addCustomTagOpen: false });
            toast.info('Member tag has been created');
            this.getMembersTags();
            if(!res?.data?.rule) {
               this.createAndAddNewTag(res?.data)
            }
            this.setState(prev => {
               return {
                  ...prev,
                  addCustomTagOpen: true,
               }
            })
         })
         .catch(err => {
            this.setState({ isButtonLoading: false });
            if(err?.response?.data) {
               let errors = err?.response?.data;
               this.setState({ errors: { ...errors } });
               if(errors?.emoji?.[0]) {
                  toast.error(errors?.emoji?.[0]);
                  return;
               }
            }
         })
   }

   createAndAddNewTag = (data) => {
      const { changeTagToMemberDt, activeMember } = this.props;
      const { membersTags } = this.state;
      const { id } = data
      const { id: memberId, member_tags } = activeMember
      let memberTagData = {
         member_ids: [memberId],
         tag_ids: [id],
      }

      addTagsToMembers(memberTagData).then((res) => {
         let newData = {
            members_ids: [memberId], //+
            tag_ids: [id], //+
            memberTagIds: member_tags?.map(it => it?.id),
            membersTag: member_tags,
            allTags: membersTags,
            addDataAfterCreate: data,
         }
         changeTagToMemberDt(newData)
         this.getMembersTags()

      }).catch(err => console.log(err))
   }

   openEditNickname = (id) => {

      this.setState({
         isOpenNicknameModal: true,
      })
   }

   closeEditNickname = () => {

      this.setState({
         isOpenNicknameModal: false,
      })
   }

   render(){

      const {
         chatList,
         userList,
         screenWidth,
         showMiniChat,
         openDesctopDialogue,
         activeConverstaion,
         groupConversation,
         locationPath,
         onDeleteMessag,
         onUserTyping,
         onLikeMessage,
         onSendMessage,
         converstionInfo,
         onReadMessage,
         onSetMemberOption,
         onUnMuteMember,
         goTo,
         // memberByUuid,
         // authUser,
         setOpenDesktopDialogue,

         // isOnlineStream,
         showOnMobile,
         updateMembers,
         setChatConversation,
         updateConversationMessagesById,
         updateNotificationsByMemberId,
         updateErrors,
         updateMemberFailedAction,
         updateMessageUnlockDetails,
         updateMessageData,
      } = this.props;
      const {
         openKeepModal,
         removeUploadVideo,
         openFreeMemberShipModal,
         animate,
         warningMessage,
         selectedMembership,
         addCustomTagOpen,
         membersTags,
         memberOptionModal,
         height,
         isLoadingAddTagsToMemberButton,
         selectedCount,
         isFetchingMembersTags,
         isEmptyMembersTags,
         isOpenUsernameModal,
         // updateErrors,
         isOpenExpireFreeMembershipConfirmModal,
         newTagModalOpen,
         showCheckbox,
         errors,
         newTagName,
         isButtonLoading,
         deleteRecordModal,
         isOpenNicknameModal,
      } = this.state

      if(!!Router.route('CHAT').match(locationPath)) {
         return null;
      }
      if((screenWidth < 1025 || !showMiniChat) && (!showOnMobile)){
         return null;
      }

      if(showOnMobile) {
         return (
            <ChatConversationMessagesContainer
               onUserTyping={ () => {
                  onUserTyping() } }
               onLikeMessage={ (messageId, isLiked) => {
                  const data = { messageId, isLiked }
                  onLikeMessage(data)
               } }
               onDeleteMessag={ (id, callBack, tooltipText, showTooltip) => {
                  const data = { messageId: id }
                  onDeleteMessag(data, callBack, tooltipText, showTooltip);
               } }
               sendMessage={ onSendMessage }
               converstionInfo={ converstionInfo }
               onUnMuteMember={ onUnMuteMember }
               groupMembersCount={ groupConversation.members_count }
               onReadMessage={ (data) => {
                  const id = data.chat_room_id
                  if(id && data.unread_messages_count && data.unread_messages_count > 0) {
                     onReadMessage(id)
                  }
               } }
               fetchMemberByUuid={ null }
               onClickUnnblockMember={ () => onSetMemberOption('unblock') }
               removeUploadVideo={ removeUploadVideo }
               updateMessageUnlockDetails={ updateMessageUnlockDetails }
               updateMessageData={ updateMessageData }
            />
         );
      }
      return (
         <>
            {
               openKeepModal && (
                  <Modal contentPosition='center' onClose={ this.closeAskModal }>
                     <ConfirmDialog
                        modalInfo={ {
                           description: 'Changes you made so far will not be saved',
                           actionText: 'Discard changes',
                           action: () =>  this.askleavConversation(false),
                        } }
                        closeConfirmDialog={ this.closeAskModal }
                        cancelText='Keep editing'
                        iconName='retry'
                     />
                  </Modal>

               )
            }
            <MiniChat
               pathname={ locationPath }
               handleClickOutside={ this.handleClickOutside }
               handleClearTimeout={ () => {
                  clearTimeout(this.timeout)
               } }
               userList={ userList }
               chatList={ chatList }
               openDesctopDialogue={ openDesctopDialogue }
               openDialogue={ (data) => this.onClickChatItem(data) }
               currentConverstionId={ activeConverstaion && activeConverstaion.chat_room_id }
               activeConverstaion={ activeConverstaion }
               dialogue={
                  <ChatConversationMessagesContainer
                     onUserTyping={ () => {
                        onUserTyping() } }
                     onLikeMessage={ (messageId, isLiked) => {
                        const data = { messageId, isLiked }
                        onLikeMessage(data)
                     } }
                     onDeleteMessag={ (id, callBack, tooltipText, showTooltip) => {
                        const data = { messageId: id }
                        onDeleteMessag(data, callBack, tooltipText, showTooltip);
                     } }
                     sendMessage={ onSendMessage }
                     converstionInfo={ converstionInfo || {} }
                     onUnMuteMember={ onUnMuteMember }
                     groupMembersCount={ groupConversation.members_count }
                     onReadMessage={ (data) => {
                        const id = data.chat_room_id
                        if(id && data.unread_messages_count && data.unread_messages_count > 0) {
                           onReadMessage(id)
                        }
                     } }
                     fetchMemberByUuid={ null }
                     onClickUnnblockMember={ () => onSetMemberOption('unblock') }
                     removeUploadVideo={ removeUploadVideo }
                     isMiniChat={ true }
                     updateMessageUnlockDetails={ updateMessageUnlockDetails }
                     updateMessageData={ updateMessageData }
                  />
               }
               onSetMemberOption={ this.onSetMemberOption }
               openEditUsernameModal={ () => this.setMemberUsernameModal(true) }
               giveFreeMembership={ () => this.openCloseMemberShipModal(true) }
               setMemberOptionModal={ (type) => {
                  this.onSetMemberOption(type)
               } }
               openCustomTagModal={ (bool) => {
                  this.openAddCustomTagModal(true)
               } }
               isDisabledNotifications={ !!activeConverstaion?.member?.notifications_disabled_since  }
               handleCloseChat={ () => setOpenDesktopDialogue(false) }
               onOpenEditNickname={ this.openEditNickname }
               updateMessageUnlockDetails={ updateMessageUnlockDetails }
               goToMemberProfile={ (id) => goTo(Router.route('MEMBERS_SHOW').getCompiledPath({ id })) }
            />
            {
               isOpenUsernameModal && (
                  <UpdateUserName
                     username={ activeConverstaion?.member?.username }
                     onSaveUserName={ (data, callBack) => this.updateMembers(activeConverstaion?.member.id, data, callBack) }
                     errorMessage={ updateErrors?.username ? updateErrors.username[0] : null }
                     isMobile={ screenWidth < 701 }
                     closeModal={ () => this.setMemberUsernameModal(false) }
                  />
               )
            }
            {
               isOpenExpireFreeMembershipConfirmModal && (
                  <ExpireFreeMembershipModal
                     isMobile={ screenWidth < 701 }
                     expireFreeMemberShip={ () => {
                        this.openCloseExpireFreeMembershipModal(false)
                        this.onExpireFreeMemberShip()
                     } }
                     onClose={ () => this.openCloseExpireFreeMembershipModal(false) }
                  />
               )
            }
            {
               openFreeMemberShipModal && (
                  <Modal
                     contentPosition={ screenWidth < 700 ? 'bottom' : 'center'  }
                     onClose={ () => this.openCloseMemberShipModal(false) }
                     contentWidth={ screenWidth < 700 ? '100%' : '375px' }
                     animate={ screenWidth < 700 ? animate : null }
                     isCloseAction={ false }
                  >
                     <ChooseMembership
                        warningMessage={ warningMessage }
                        avatar={ activeConverstaion?.member?.avatar_compressed_src }
                        username={ activeConverstaion?.member?.nickname || activeConverstaion?.member?.username }
                        memberships={ [7, 14, 30, 60, 90] }
                        selectedMembership={ selectedMembership }
                        setSelectedMembership={ this.setSelectedMembership }
                        addFreeMember={ this.addFreeMember }
                        onclose={ () => this.openCloseMemberShipModal(false) }
                     />
                  </Modal>
               )
            }
            {
               memberOptionModal.isOpen && (
                  <Modal
                     contentPosition={ screenWidth < 700 ? 'bottom' : 'center' }
                     onClose={ () => this.onSetMemberOption(null) }
                     isCloseAction={ false }
                     contentWidth={ ('block' === memberOptionModal.type || screenWidth < 700) ? null : '375px' }
                     roundedModal='xlg'
                  >
                     {
                        'block' === memberOptionModal.type && (
                           <ConfirmDialog
                              modalInfo={ {
                                 title: `Block ${ activeConverstaion?.member?.nickname || activeConverstaion?.member.username }?`,
                                 description: `Are you sure you want to block ${ activeConverstaion?.member?.nickname || activeConverstaion?.member.username }? This member will be shown an error page instead of your site.`,
                                 actionText: 'Yes, block this member',
                                 action: () =>  this.onBlockMember() }  }
                              closeConfirmDialog={ () => {
                                 this.onSetMemberOption(null)
                              }  }
                              disabled={ memberOptionModal.isLoading }
                              iconName='block'
                           />
                        )
                     }
                     {
                        ('mute' === memberOptionModal.type || 'disable_notifications' === memberOptionModal.type) && (
                           <MuteMember
                              isMobile={ screenWidth < 700 }
                              onCloseModal={ () => this.onSetMemberOption(null) }
                              onMuteTimeChange={ (time) => {
                                 if('mute' === memberOptionModal.type) {
                                    this.onMuteMember(time, activeConverstaion?.member.uuid)
                                 } else {
                                    this.onDisableMemberAllNotifications(time, activeConverstaion?.member.uuid)
                                 }
                              } }
                              muteTime={ activeConverstaion?.member.muted_period_in_seconds }
                              username={ activeConverstaion?.member?.nickname || activeConverstaion?.member.username }
                              disabled={ memberOptionModal.isLoading }
                              isMute={ 'mute' === memberOptionModal.type }
                           />
                        )
                     }
                  </Modal>
               )
            }
            {
               addCustomTagOpen && (
                  <AddMemberTags
                     onClose={ () => this.openAddCustomTagModal(false)  }
                     tags={ membersTags }
                     isMobile={ screenWidth < 700 }
                     memberCount={ selectedCount }
                     isFetchingMembersTags={ isFetchingMembersTags }
                     isEmptyMembersTags={ isEmptyMembersTags }
                     height={ height }
                     manageMembersTags={ () => goTo(Router.route('MEMBER_TAGS').getCompiledPath()) }
                     onAddTagsToMember={ this.onAddTagsToMember }
                     isLoadingAddTagsToMemberButton={ isLoadingAddTagsToMemberButton }
                     selectedItem={ activeConverstaion?.member }
                     optionValues={ this.optionValues }
                     createNewTag={ this.openNewTagModal }
                     tagsOfCurrentMember={ activeConverstaion?.member?.member_tags || [] }
                  />
               )
            }
            {
               newTagModalOpen && (
                  <AddMemberTag
                     showCheckbox={ showCheckbox }
                     setShowCheckBox={ (bool) => this.changeCheckbox(bool) }
                     onSaveTag={ this.createNewTag }
                     onCloseModal={ this.onCloseNewTagModal }
                     screenWidth={ screenWidth }
                     optionValues={ this.optionValues }
                     isButtonLoading={ isButtonLoading }
                     name={ newTagName }
                     errors={ errors }
                  />
               )
            }
            {
               deleteRecordModal?.isOpen && (
                  <DeleteVoiceRecordConfirmModal
                     action={ () => {
                        this.onDeleteRecord()
                     } }
                     onCloseModal={ this.onCloseDeleteRecordModal }
                     status={ window.chatVoiceMessageRecorded }
                     isMobile={ screenWidth < 1025 }
                  />
               )
            }
            {
               isOpenNicknameModal && (
                  <AddEditNicknameModal
                     username={ activeConverstaion?.member?.username }
                     nickname={ activeConverstaion?.member?.nickname }
                     onSaveNickname={ (data, callBack, hideToast) => {
                        updateMembers(activeConverstaion?.member?.id, data, callBack, hideToast)
                        QueryParams.removeQueryParam('manage')
                     } }
                     errorMessage={ updateErrors?.nickname ? updateErrors.nickname[0] : null }
                     isMobile={ screenWidth < 701 }
                     closeModal={ () => {
                        this.closeEditNickname()
                        updateMemberFailedAction({})
                        QueryParams.removeQueryParam('manage')
                     } }
                     onChangeSuccess={ async (data) => {
                        await setChatConversation({
                           ...activeConverstaion,
                           member: {
                              ...activeConverstaion?.member,
                              nickname: data?.nickname,
                           },
                           member_nickname: data?.nickname,
                        })
                        await updateConversationMessagesById({
                           member_nickname: data?.nickname,
                           activeConversation: activeConverstaion,
                           username: activeConverstaion?.username || groupConversation?.member?.username,
                        })
                        await updateNotificationsByMemberId({
                           member_nickname: data?.nickname,
                           username: activeConverstaion?.member?.username,
                        })
                        if(Router.route('MEMBERS_SHOW').getCompiledPath({ id: activeConverstaion?.member?.id }) === window?.location.pathname) {
                           const currentRoute = getCurrentRoute(locationPath);
                           currentRoute.setTitle(data?.nickname || activeConverstaion?.member?.username)
                        }
                     } }
                  />
               )
            }
         </>
      );
   }

}

const mapStateToProps = (state) => {
   return {
      screenWidth: screenWidthSelector(state),
      chatList: chatByUserSelector(state),
      userList: descktopUserListSelector(state),
      showMiniChat: showMiniChatSelector(state),
      openDesctopDialogue: openDesctopDialogueSelector(state),
      chatByUser: chatByUserSelector(state),
      activeConverstaion: activeConverstaionSelector(state),
      groupConversation: groupConversationSelector(state),
      locationPath: currentLocationPathSelector(state),
      initedConverstionMessages: initedConverstionMessagesSelector(state),
      memberByUuid: memberByUuidSelector(state),
      authUser: authUserSelector(state),
      isOnlineStream: isOnlineStreamSelector(state),
      activeMember: activeMemberSelector(state),
      updateErrors: updateErrorsSelector(state),
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      setOpenDesktopDialogue: data => dispatch(setOpenDesktopDialogueAction(data)),
      openDialogue: (data, bool) => dispatch(desktopChatDialogueCompleted(data, bool)),
      fetchMessages: (chat, group, member, callback) => dispatch(fetchConversationMessagesOperation(chat, group, member, callback, false)),
      setChatConversation: (data) => dispatch(setChatConversationAction(data)),
      setMemberUsernameModal: bool => { dispatch(setMemberUsernameModal(bool)) },
      goTo: (location, search = '') => {
         dispatch(push({
            search: search,
            pathname: location,
         }))
      },
      addFreeMemberOperation: (id, data, callBack) => { dispatch(addFreeMemberOperation(id, data, callBack)) },
      changeTagToMemberDt: (data) => { dispatch(changeTagToMember(data)) },
      updateMembers: (id, data, callBack, hideToast) => {
         dispatch(updateMembersOperation(data, id, callBack, hideToast))
      },
      updateMemberByUuid: (uuid, data) => dispatch(updateMemberByUuid(uuid, data)),
      updateMembersAction: (uuid, data) => dispatch(updateMembersRequestCompleted(uuid, data)),
      blockMemberAction: info => dispatch(setBlockMemberAction(info)),
      removeTagToMember: (data) => dispatch(removeTagToMember(data)),
      expireFreeMemberShip: (id, callBack) => {
         dispatch(expireFreeMemberShipOperation(id, callBack))
      },
      fetchStatisticsData: params => dispatch(fetchStatisticsData(params)),
      memberFetchDetails: (id) => dispatch(memberFetchDetailsOperation(id)),
      addNewTagCompleted: (data) => dispatch(addNewTagCompleted(data)),
      setActiveMember: data => {
         dispatch(setActiveMemberData(data))
      },
      updateConverstion: data => dispatch(updateConverstionAction(data)),
      updateMUnreamPmMessageCount: (count) => dispatch(updateUnreamPmMessageCountAction(count)),
      updateConversationMessagesById: (payload) => dispatch(updateConversationMessagesByIdAction(payload)),
      updateNotificationsByMemberId: (payload) => dispatch(updateNotificationsByMemberIdAction(payload)),
      updateMemberFailedAction: (payload) => dispatch(updateMemberFailed(payload)),
   }
}


export default connect(mapStateToProps, mapDispatchToProps)(MiniChatContainer);
