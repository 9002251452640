import * as types from "./types";

export const fetchIndexRequest = (data) => {
   return {
      type: types.FETCH_INDEX_REQUEST,
      payload: data,
   }
}
export const updateCurrentData = (data, initiator) => {
   return {
      type: types.UPDATE_CURRENT_DATA,
      payload: { data, initiator },
   }
}
export const fetchNextRecordsRequest = bool => {
   return {
      type: types.FETCH_NEX_RECORDS_REQUEST,
      payload: bool,
   }
}
export const fetchNextRecordsCompleted = (data) => {
   return {
      type: types.FETCH_NEX_RECORDS_COMPLETED,
      payload: data,
   }
}
export const updateDataCompleted = (type, data) => {
   return {
      type: types.UPDATE_DATA_COMPLETED,
      payload: { data, type },
   }
}
export const fetchLiveStreamMessagesRequest = bool => {
   return {
      type: types.FETCH_LIVE_STREAM_MESSAGES,
      payload: bool,
   }
}
export const fetchLiveStreamMessagesRequestCompleted = data => {
   return {
      type: types.FETCH_LIVE_STREAM_MESSAGES_COMPLETED,
      payload: data,
   }
}
export const setLiveStreamConversationState = data => {
   return {
      type: types.SET_LIVE_STREAM_CONVERSATION_STATE,
      payload: data,
   }
}
export const addNewMessage = data => {
   return {
      type: types.ADD_MESSAGE,
      payload: data,
   }
}
export const updateMessage = data => {
   return {
      type: types.UPDATE_MESSAGE,
      payload: data,
   }
}
export const setTyperAction = (typers) => ({
   type: types.SET_TYPER_ACTION,
   payload: { typers },
});

export const deleteMessageAction = (id) => ({
   type: types.DELETE_MESSAGE_ACTION,
   payload: id,
});

export const likeMessageAction = data => ({
   type: types.LIKE_MESSAGE_ACTION,
   payload: data,
});

export const setIsConnectedAction = bool => ({
   type: types.SET_IS_CONNECTED_ACTION,
   payload: bool,
});

export const setOnlineUsersAction = (data, action) => ({
   type: types.SET_ONLINE_USERS_ACTION,
   payload: { data, action },
});


///
export const fetchLikedMemberByMessageRequest = bool => ({
   type: types.FETCH_LIKED_MEMBER_BY_MESSSAGE_REQUEST,
   payload: bool,
});
export const fetchLikedMemberByMessageCompleted = data => ({
   type: types.FETCH_LIKED_MEMBER_BY_MESSSAGE_COMPLETED,
   payload: data,
});
export const fetchLikedMemberByMessageFailed = error => ({
   type: types.FETCH_LIKED_MEMBER_BY_MESSSAGE_FAILED,
   payload: error,
});

///
export const fetchNewMessagesRequest = () => ({
   type: types.FETCH_NEW_MESSAGES_REQUEST,
});
export const fetchNewMessagesCompleted = data => ({
   type: types.FETCH_NEW_MESSAGES_COMPLETED,
   payload: data,
});
export const fetchNewMessagesFailed = error => ({
   type: types.FETCH_NEW_MESSAGES_FAILED,
   payload: error,
});

export const setStreamConversationInfo = data => ({
   type: types.SET_STREAM_CONVERASTION_INFO,
   payload: data,
});
export const seTipGoalAchievedModal = bool => ({
   type: types.SET_TIP_GOALL_ACHIEVED_MODAL,
   payload: bool,
});
export const updateTipActionData = bool => ({
   type: types.UPDATE_TIP_ACTION_DATA,
   payload: bool,
});
export const seTipActionsGoalAchievedModal = bool => ({
   type: types.SET_TIP_ACTIONS_GOALL_ACHIEVED_MODAL,
   payload: bool,
});
export const setTipHistoryAction = data => ({
   type: types.SET_TIP_HISTORY_ACTION,
   payload: data,
});
export const setActiveViewerStates = (data) => ({
   type: types.SET_ACTIVE_VIEWER_STATES,
   payload: data,
});
export const activeViewerFilter = (filter) => ({
   type: types.ACTIVE_VIEWER_FILTER,
   payload: filter,
});
export const clearActiveViewerFilter = () => ({
   type: types.ACTIVE_VIEWER_CLEAR_FILTER,
});
export const createOrUpdateRecordStream = data => ({
   type: types.CREATE_OR_UPDATE_RECORD_STREAM,
   payload: data,
});
export const setDeletButtonLoadingState = (bool, id) => ({
   type: types.SET_DELET_RECORD_BUTTON_LOADING,
   payload: { bool, id },
});
export const warningActivityModal = (state) => ({
   type: types.SET_WARNING_ACTIVITY_MODAL,
   payload: state,
});
export const setDeleteUpcomingState = (state) => ({
   type: types.SET_DELETE_UPCOMING_STATE,
   payload: state,
});
export const setDeleteUpcomingCompleted = (data) => ({
   type: types.SET_DELETE_UPCOMING_COMPLETED,
   payload: data,
});
export const updateSavedData = (data) => ({
   type: types.UPDATE_SAVED_DATA,
   payload: data,
});
export const setValidationError = (data) => ({
   type: types.SET_VALIDATION_ERROR,
   payload: data,
});
export const fetchCheckValidationRequest = (bool) => ({
   type: types.FETCH_CHECK_VALISATION_REQUEST,
   payload: bool,
});
export const fetchCreateLivestreamRequest = (bool) => ({
   type: types.FETCH_CREATE_LIVESTREAM_REQUEST,
   payload: bool,
});

//reorder
export const reorderRecordedStreams = (data) => ({
   type: types.REORDER_RECORDED_STREAMS,
   payload: data,
})

export const pinRecordedStreamCompleted = (data) => ({
   type: types.PIN_RECORDED_STREAM_COMPLETED,
   payload: data,
})
export const setWarningModal = (data) => {
   return ({
      type: types.SET_WARNING_MODAL,
      payload: data,
   })
}
export const updateLivestreamMessagesByIdAction = (payload) => {
   return ({
      type: types.UPDATE_LIVESTREAM_MESSAGES_BY_ID,
      payload,
   })
}
