export const FETCH_DETAILS_START = "members/FETCH_DETAILS_START";
export const FETCH_DETAILS_COMPLETED = "members/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "members/FETCH_DETAILS_FAILED";

export const FETCH_MEMBER_REQUEST = "members/FETCH_MEMBER_REQUEST";
export const FETCH_MEMBER_COMPLETED = "members/FETCH_MEMBER_COMPLETED";
export const FETCH_MEMBER_FAILED = "members/FETCH_MEMBER_FAILED";

export const FILTER_MEMBERS_REQUEST = "members/FILTER_MEMBERS_REQUEST";
export const FILTER_MEMBERS_COMPLETED = "members/FILTER_MEMBERS_COMPLETED";
export const FILTER_MEMBERS_FAILED = "members/FILTER_MEMBERS_FAILED";

export const FETCH_STATISTICS_REQUEST = "members/FETCH_STATISTICS_REQUEST";
export const FETCH_STATISTICS_COMPLETED = "members/FETCH_STATISTICS_COMPLETED";
export const FETCH_STATISTICS_FAILED = "members/FETCH_STATISTICS_FAILED";

export const FETCH_MEMBERSHIPS_REQUEST = "members/FETCH_MEMBERSHIPS_REQUEST";
export const FETCH_MEMBERSHIPS_COMPLETED = "members/FETCH_MEMBERSHIPS_COMPLETED";
export const FETCH_MEMBERSHIPS_FAILED = "members/FETCH_MEMBERSHIPS_ERROR";

export const NEW_FETCH_REQUEST = "members/NEW_FETCH_DETAILS";
export const NEW_FETCH_DETAILS_COMPLETED = "members/NEW_FETCH_DETAILS_COMPLETED";
export const NEW_FETCH_DETAILS_FAILED = "members/NEW_FETCH_DETAILS_FAILED";

export const SET_FREE_MEMBER_TO_ADD_ID = "members/SET_FREE_MEMBER_TO_ADD_ID";
export const SET_FREE_MEMBER_TO_ADD_MEMBERSHIP = "members/SET_FREE_MEMBER_TO_ADD_MEMBERSHIP";

export const ADD_FREE_MEMBER_REQUEST = "members/ADD_FREE_MEMBER_REQUEST";
export const ADD_FREE_MEMBER_COMPLETED = "members/ADD_FREE_MEMBER_COMPLETED";
export const ADD_FREE_MEMBER_FAILED = "members/ADD_FREE_MEMBER_FAILED";

export const ADD_NEW_PERSON_REQUEST = "members/ADD_NEW_PERSON_REQUEST";
export const ADD_NEW_PERSON_COMPLETED = "members/ADD_NEW_PERSON_COMPLETED";
export const ADD_NEW_PERSON_FAILED = "members/ADD_NEW_PERSON_FAILED";
export const UPDATE_MEMBERS_COMPLETED = "members/UPDATE_MEMBERS_COMPLETED";
export const UPDATE_MEMBERS_BY_UUID = "members/UPDATE_MEMBERS_BY_UUID";
export const UPDATE_MULTIPLE_MEMBERS_BY_UUIDS = "members/UPDATE_MULTIPLE_MEMBERS_BY_UUIDS";

export const UPDATE_MEMBERS_FAILED = "members/UPDATE_MEMBERS_FAILED";
export const SET_MEMBER_USERNAME_MODAL = "members/SET_MEMBER_USERNAME_MODAL";

export const REMOVE_TAG_TO_MEMBER = "members/REMOVE_TAG_TO_MEMBER";
export const CHANGE_TAG_TO_MEMBER = "members/CHANGE_TAG_TO_MEMBER";

export const FETCH_MEMBER_WALLET_HISTORY_START = "members/FETCH_MEMBER_WALLET_HISTORY_START";
export const FETCH_MEMBER_WALLET_HISTORY_COMPLETED = "members/FETCH_MEMBER_WALLET_HISTORY_COMPLETED";
export const FETCH_MEMBER_WALLET_HISTORY_FAILED = "members/FETCH_MEMBER_WALLET_HISTORY_FAILED";

export const NEW_FETCH_MEMBER_WALLET_HISTORY_START = "members/NEW_FETCH_MEMBER_WALLET_HISTORY_START";
export const NEW_FETCH_MEMBER_WALLET_HISTORY_COMPLETED = "members/NEW_FETCH_MEMBER_WALLET_HISTORY_COMPLETED";
export const NEW_FETCH_MEMBER_WALLET_HISTORY_FAILED = "members/NEW_FETCH_MEMBER_WALLET_HISTORY_FAILED";


export const FETCH_MEMBER_TAGS_START = "members/FETCH_MEMBER_TAGS_START";
export const FETCH_MEMBER_TAGS_COMPLETED = "members/FETCH_MEMBER_TAGS_COMPLETED";
export const FETCH_MEMBER_TAGS_FAILED = "members/FETCH_MEMBER_TAGS_FAILED";

export const SET_ACTIVE_MEMBER = "members/SET_ACTIVE_MEMBER";

export const ADD_NOTE_START = 'members/ADD_NOTE_START';
export const ADD_NOTE_COMPLETED = 'members/ADD_NOTE_COMPLETED';
export const ADD_NOTE_FAILED = 'members/ADD_NOTE_FAILED';

export const DELETE_NOTE_START = 'members/DELETE_NOTE_START';
export const DELETE_NOTE_COMPLETED = 'members/DELETE_NOTE_COMPLETED';
export const DELETE_NOTE_FAILED = 'members/DELETE_NOTE_FAILED';

export const UPDATE_NOTE_START = 'members/UPDATE_NOTE_START';
export const UPDATE_NOTE_COMPLETED = 'members/UPDATE_NOTE_COMPLETED';
export const UPDATE_NOTE_FAILED = 'members/UPDATE_NOTE_FAILED';
