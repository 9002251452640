import PaypalIcon from 'assets/images/payment/payout/paypal.svg';
import VenmoIcon from 'assets/images/payment/payout/venmo.svg';
import CryptoIcon from 'assets/images/payment/payout/crypto.svg';
import Yoursafe from 'assets/images/payment/payout/yoursafe.svg';
import Paxum from 'assets/images/payment/payout/paxum.svg';
import Cosmopayment from 'assets/images/payment/payout/cosmopayment.svg';
import EPayments from 'assets/images/payment/payout/e-payments.svg';
import Bank from 'assets/images/payment/payout/bank.svg';
import Zelle from 'assets/images/payment/payout/zelle.svg';
import Wise from 'assets/images/payment/payout/wise.svg';
import Sepa from 'assets/images/payment/payout/sepa.svg';
import Revolut from 'assets/images/payment/payout/revolut.svg';
import RappiPay from 'assets/images/payment/payout/rappi-pay.svg';
import Pix from 'assets/images/payment/payout/pix.svg';
import CashApp from 'assets/images/payment/payout/cash-app.svg';
import DuitNow from 'assets/images/payment/payout/duit-now.svg';
import MercadoPago from 'assets/images/payment/payout/mercado-pago.svg';
import Mucap from 'assets/images/payment/payout/mucap.svg';
import PayId from 'assets/images/payment/payout/pay-id.svg';
import Paynow from 'assets/images/payment/payout/paynow.svg';

import NumberFormatter from 'common/utils/NumberFormatter';
import { ARTICLE_ID_ON_PAYOUT_METHODS } from 'common/utils/intercom-articles';

const numberFormatter = new NumberFormatter();
const termsModalDefaultValue = {
   isOpen: false,
   body: {},
   callback: null,
   type: null,
}


const initPayoutTypes = () => {
   const data = [
      { key: 'total_store', name: 'Store' },
      { key: 'total_unlocks', name: 'Unlocks' },
      { key: 'total_tips', name: 'Tips' },
      { key: 'total_downloads', name: 'Downloads' },
      { key: 'total_ppm', name: 'Pay-per-message' },
      { key: 'total_rcv', name: 'RCV' },
      { key: 'total_gross_revenues', name: 'Gross Revenue' },
   ]
   return data;
};

const initPayoutPercentagesTypes = () => {
   const data = [
      { key: 'base_payout_percentage', name: 'Percentage floor' },
      { key: 'revenue_bonus', name: 'Revenue bonus' },
      { key: 'referral_bonus', name: 'Referral bonus' },
      { key: 'referee_bonus', name: 'Referee bonus' },
   ]
   return data;
};
const getMethodInfo = _ => {
   const paymentMethods = {
      paypal: {
         icon: PaypalIcon,
         lable: 'PayPal',
         types: ['us', 'non_us'],
         infoFields: {
            account_type: 'Account type',
            email: 'Email address',
            // currency: 'Currency',
         },
         fees: <span className='text-sm text-secondary leading-[20px]'><span className='font-semibold'>Payout fees:</span> <br />U.S. accounts : 1 USD <br />Non-U.S. accounts: 2% capped at 20 USD</span>,
         moreInfoState: {
            text: 'More info on payout methods and fees',
            article: ARTICLE_ID_ON_PAYOUT_METHODS,
         },
      },
      venmo: {
         icon: VenmoIcon,
         lable: 'Venmo',
         types: ['PHONE', 'EMAIL', 'USER_HANDLE'],
         fees: <span className='text-sm text-secondary leading-[20px]'><span className='font-semibold'>Payout fees:</span> 1 USD </span>,
         moreInfoState: {
            text: 'More info on payout methods and fees',
            article: ARTICLE_ID_ON_PAYOUT_METHODS,
         },
      },
      crypto: {
         icon: CryptoIcon,
         lable: 'Crypto',
         type: 'payout_srv',
      },
      yoursafe: {
         icon: Yoursafe,
         lable: 'Yoursafe',
      },
      paxum: {
         icon: Paxum,
         lable: 'Paxum',
         infoFields: {
            email: 'Email address',
            currency: 'Currency',
         },
      },
      cosmopayment: {
         icon: Cosmopayment,
         lable: 'Cosmopayment',
      },
      e_payments: {
         icon: EPayments,
         lable: '@Payments',
      },
      bank: {
         icon: Bank,
         lable: 'Bank',
      },
      zelle: {
         icon: Zelle,
         lable: 'Zelle',
      },
      wise: {
         icon: Wise,
         lable: 'Wise',
      },
      sepa: {
         icon: Sepa,
         lable: 'Sepa',
      },
      revolut: {
         icon: Revolut,
         lable: 'Revolut',
      },
      rappi_pay: {
         icon: RappiPay,
         lable: 'Rappi pay',
      },
      pix: {
         icon: Pix,
         lable: 'Pix',
      },
      cash_app: {
         icon: CashApp,
         lable: 'Cash app',
      },
      duit_now: {
         icon: DuitNow,
         lable: 'Duit now',
      },
      mercado_pago: {
         icon: MercadoPago,
         lable: 'Mercado pago',
      },
      mucap: {
         icon: Mucap,
         lable: 'Mucap',
      },
      pay_id: {
         icon: PayId,
         lable: 'Pay id',
      },
      paynow: {
         icon: Paynow,
         lable: 'Paynow',
      },
   };
   return paymentMethods
}
const getMoreInfoState = (type) => {
   const payoutMethodState = getMethodInfo()[type];
   let moreInfoState = {
      text: 'More info on payout methods and fees',
      article: ARTICLE_ID_ON_PAYOUT_METHODS,
   }
   if(Boolean(payoutMethodState?.moreInfoState)){
      moreInfoState = payoutMethodState?.moreInfoState
   }
   return moreInfoState;
}
const generateValue = (value) => {
   if(!value){
      return 'N/A';
   }
   const valueKeys = {
      us: 'US account',
      non_us: 'Non-Us account',
      USER_HANDLE: 'Username',
      EMAIL: 'Email address',
      PHONE: 'Phone number',
   }

   if(valueKeys?.[value]){
      return  valueKeys[value]
   }
   return value;
}
const getMethodFees = (type, isHasPrefix = true) => {
   if(!type){
      return 'Fees depend on your country and bank deposit method';
   }
   let prefix = 'Fees: ';
   if('paypal' === type){
      prefix = 'Fees: <br/>';
   }
   let fees = getMethodInfo()[type]?.fees;
   if(!fees){
      fees = '0 USD';
   }
   if(!isHasPrefix){
      return fees;
   }
   return `${ prefix }${ fees }`;
}

const payoutBalance = (value, isNegative = false) => {
   let amount = value;
   if(isNegative && amount > 0){
      amount = amount * (-1)
   }
   return `${ numberFormatter.withDots(amount) } USD`;
}
const getMethodLable = (method) => {
   const currentMethod = getMethodInfo()?.[method];
   return currentMethod?.lable || method;
}
const getWithdraPayoutMethod = (data) => {
   return getMethodLable(data.method);
}
const getDeliveryType = (type) => {
   let name = ''
   switch (type) {
      case 'CRYPTOCURRENCY':{
         name = 'Crypto';
         break;
      }
      case 'BANK_DEPOSIT':{
         name = 'Bank';
         break;
      }

      default:{
         name = type;
         break;
      }
   }
   return name;
}
const getPayoutSrvInitData = (type, payload) => {
   if('payout_srv' === type && payload?.additional_info){
      const { additional_info } = payload;
      return additional_info
   }
   return {
      country_info: null,
      service_info: null,
   };
}
const getSelectedMethod = (payoutMethod, onlyText = false) => {
   const { type, payload } = payoutMethod;
   const { service_info } = getPayoutSrvInitData(type, payload);
   if('payout_srv' === payoutMethod?.type && service_info){
      return service_info.payer_name;
   }
   const methodState = getMethodInfo();
   if(methodState?.[type]?.lable && onlyText){
      return methodState[type].lable;
   }
   return type;
}

const getAccoutNumber = (payoutMethod) => {
   const { payload } = payoutMethod;
   if('payout_srv' === payoutMethod?.type){
      const attributesData = payload.attributes_data || {};
      const attributesInfo = payload.attributes_info;
      const additionalInfo = payload.additional_info;
      if(attributesInfo){
         const payerName = additionalInfo?.service_info?.payer_name;
         let tokenKey = 'BankAccountNumber';
         if('Instant Pay' === payerName){
            tokenKey = 'CardNumber';
         }
         const account = attributesInfo.find(attr => attr.type === tokenKey);
         const token = account?.token
         const value = token ? attributesData?.[token] : null;
         if(value){
            return `****${ value.slice(value.length - 4, value.length) }`;
            // const result = value.slice(0, value.length - 4);
            // return '*'.repeat(result.length) + value.slice(value.length - 4, value.length);
         }

      }
   }
   return null;
}

const get2FaInitalState = () => {
   return {
      isOpen: false,
      type: null,
      message: null,
      arg: [],
   }
}
const get2FaParams = (type, code) => {
   let params = [];
   if(type){
      params = [`selected_2fa_mode=${ type }`];
   }
   if(code){
      params = [...params, `2fa_code=${ code }`];
   }
   return params;
}

const isShowOnlyRequired = () => {
   return false;
}

const getMethodCurrency = () => {
   return [
      { name: 'USD', value: 'USD', flag: 'US' },
      { name: 'EUR', value: 'EUR', flag: 'EUR' },
      // { name: 'GBP', value: 'GBP', flag: 'GB' },
   ];
}
const getRequiredModalType = (info) => {
   if(!info?.legal_info_is_filled && !info?.profile_photo_is_filled){
      return 'profile_photo_and_legal_info';
   }
   if(!info?.profile_photo_is_filled){
      return 'profile_photo';
   }
   if(!info?.legal_info_is_filled){
      return 'legal_info';
   }
   return null
}

export {
   initPayoutTypes,
   initPayoutPercentagesTypes,
   getMethodInfo,
   getMethodFees,
   payoutBalance,
   getDeliveryType,
   generateValue,
   getMoreInfoState,
   getSelectedMethod,
   getAccoutNumber,
   getPayoutSrvInitData,
   getWithdraPayoutMethod,
   getMethodLable,
   get2FaInitalState,
   get2FaParams,
   isShowOnlyRequired,
   getMethodCurrency,
   termsModalDefaultValue,
   getRequiredModalType,
}
