import { termsModalDefaultValue } from 'admin/views/payouts-v2/modules/data';

const initialState = {
   isFetchingWithdrawals: true,
   isFetchingWithdrawalInfo: true,
   isFetchingAdjustments: true,
   isFetchingHolds: true,
   isFetchingInit: true,
   currentWithdrawalInfo: {},
   automaticWithdrawalsError: {},
   adjustments: {},
   init: {},
   withdrawalsList: [],
   emptyWithdrawalsList: false,
   emptyHoldsList: false,
   emptyAdjustments: false,
   holdsList: [],
   isFetchingcollapseAdjustments: {},
   collapseAdjustments: {},
   fetchPayoutSrvCountry: true,
   payoutSrvCountry: [],
   payoutSrvServices: [],
   fetchPayoutSrvServices: false,
   payoutSrvAttributes: [],
   fetchPayoutSrvAttributes: true,
   selectedPayoutSrvService: {},
   createPayoutSrvAttributesFetching: false,
   createPayoutSrvAttributesErrors: {},
   setWalletPayoutMethodFetching: false,
   setWalletPayoutMethodErrors: {},
   isFetchingCommitWithdrawal: false,
   isFetchingWithdrawalNow: false,
   withdrawalNowPayload: {},
   isFetchingHistory: true,
   historyList: [],
   emptyHistoryList: false,
   initialPayoutSrvValueByService: {},
   emptyPayoutSrvServices: false,
   isFetchingDeleteCurrentMethod: false,
   isFetchingUpdateAutomaticWithdrawals: false,
   twoFaVerifyModal: {
      isOpen: false,
      type: null,
      message: null,
      arg: [],
   },
   isFetchingUpdateNow: false,
   payoutTermsModal: termsModalDefaultValue,
   isOpenIncompleteLegalInfoModal: false,
   payoutToDoRequiredInfoFilled: null,
};

export default initialState;
