import * as types from "./types";
import createReducer from "../../utils/createReducer";
import DateFormatter from 'common/utils/DateFormatter';

import initialState from './initial-state';

let reducersMap = {
   [types.FETCH_WITHDRAWALS_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingWithdrawals: Boolean(action.payload),
      }
   },

   [types.FETCH_WITHDRAWALS_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingWithdrawals: false,
         withdrawalsList: action.payload,
         emptyWithdrawalsList: action?.payload?.length === 0,
      }
   },
   [types.FETCH_ADJUSTMENTS_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingWithdrawalInfo: Boolean(action.payload),
         currentWithdrawalInfo: {},
      }
   },
   [types.FETCH_COLLAPSE_ADJUSTMENT_COMPLETED]: (state, action) => {
      const { data, id } = action.payload;
      return {
         ...state,
         collapseAdjustments: {
            ...state.collapseAdjustments,
            [id]: data,
         },
         isFetchingcollapseAdjustments: {
            ...state.isFetchingcollapseAdjustments,
            [id]: false,
         },
      }
   },
   [types.FETCH_COLLAPSE_ADJUSTMENT_REQUEST]: (state, action) => {
      const { bool, id } = action.payload;
      return {
         ...state,
         isFetchingcollapseAdjustments: {
            ...state.isFetchingcollapseAdjustments,
            [id]: bool,
         },
      }
   },

   [types.FETCH_WITHDRAWAL_INFO_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingWithdrawalInfo: Boolean(action.payload),
      }
   },
   [types.FETCH_WITHDRAWAL_INFO_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingWithdrawalInfo: false,
         currentWithdrawalInfo: action.payload,
      }
   },
   [types.FETCH_ADJUSTMENTS_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingAdjustments: Boolean(action.payload),
      }
   },

   [types.FETCH_ADJUSTMENTS_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingAdjustments: false,
         adjustments: action.payload,
         emptyAdjustments: !action?.payload?.current_withdrawal && action?.payload?.completed_withdrawals?.length === 0,
      }
   },
   [types.FETCH_HOLDS_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingHolds: Boolean(action.payload),
      }
   },

   [types.FETCH_HOLDS_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingHolds: false,
         holdsList: action.payload,
         emptyHoldsList: action?.payload?.length === 0,
      }
   },
   [types.FETCH_HISTORY_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingHistory: Boolean(action.payload),
      }
   },

   [types.FETCH_HISTORY_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingHistory: false,
         historyList: action.payload,
         emptyHistoryList: action?.payload?.length === 0,
      }
   },
   [types.FETCH_INIT_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingInit: Boolean(action.payload),
      }
   },

   [types.FETCH_INIT_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingInit: false,
         init: action.payload,
      }
   },

   [types.UPDATE_PAYOUT_INIT]: (state, action) => {
      return {
         ...state,
         init: {
            ...state.init,
            ...action.payload,
         },
      }
   },
   [types.UPDATE_AUTOMATIC_WITHDRAWAL_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingUpdateAutomaticWithdrawals: false,
         automaticWithdrawalsError: action.payload,
      }
   },
   [types.UPDATE_AUTOMATIC_WITHDRAWAL_REQUEST]: (state, action) => {
      return {
         ...state,
         automaticWithdrawalsError: {},
         isFetchingUpdateAutomaticWithdrawals: true,
      }
   },
   [types.UPDATE_AUTOMATIC_WITHDRAWAL_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingUpdateAutomaticWithdrawals: false,
         init: {
            ...state.init,
            automatic_withdrawals: {
               ...state.init.automatic_withdrawals,
               ...action.payload,
            },
         },
      }
   },
   [types.FETCH_PAYOUT_SRV_COUNTRY_REQUEST]: (state, action) => {
      return {
         ...state,
         fetchPayoutSrvCountry: action.payload,
         fetchPayoutSrvServices: action.payload,
         fetchPayoutSrvAttributes: action.payload,
         emptyPayoutSrvServices: action.payload,
         payoutSrvServices: [],
         payoutSrvAttributes: [],
         selectedPayoutSrvService: {},

      }
   },
   [types.FETCH_PAYOUT_SRV_COUNTRY_COMPLETED]: (state, action) => {
      return {
         ...state,
         payoutSrvCountry: action.payload,
         fetchPayoutSrvCountry: false,
         fetchPayoutSrvServices: false,
         fetchPayoutSrvAttributes: false,
         emptyPayoutSrvServices: false,
         payoutSrvServices: [],
         payoutSrvAttributes: [],
         selectedPayoutSrvService: {},
         createPayoutSrvAttributesErrors: {},
      }
   },
   [types.GET_PAYOUT_SRV_SERVICES_FOR_COUNTRY_REQUEST]: (state, action) => {
      return {
         ...state,
         fetchPayoutSrvServices: action.payload,
         emptyPayoutSrvServices: true,
         payoutSrvServices: [],
      }
   },
   [types.GET_PAYOUT_SRV_SERVICES_FOR_COUNTRY_COMPLETED]: (state, action) => {
      return {
         ...state,
         payoutSrvServices: action.payload,
         emptyPayoutSrvServices: !action?.payload?.length,
         fetchPayoutSrvServices: false,
      }
   },
   [types.GET_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_REQUEST]: (state, action) => {
      return {
         ...state,
         fetchPayoutSrvAttributes: action.payload,
         payoutSrvAttributes: [],
         selectedPayoutSrvService: {},
         createPayoutSrvAttributesErrors: {},
      }
   },
   [types.GET_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_COMPLETED]: (state, action) => {
      const { data, service, initialData } = action.payload;

      return {
         ...state,
         payoutSrvAttributes: data,
         selectedPayoutSrvService: service,
         fetchPayoutSrvAttributes: false,
         initialPayoutSrvValueByService: initialData,
      }
   },
   [types.CREATE_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_REQUEST]: (state, action) => {
      return {
         ...state,
         createPayoutSrvAttributesFetching: action.payload,
         createPayoutSrvAttributesErrors: {},

      }
   },
   [types.CREATE_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_COMPLETED]: (state, action) => {
      return {
         ...state,
         createPayoutSrvAttributesFetching: false,
         withdrawalNowPayload: {},
      }
   },
   [types.CREATE_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_FAILED]: (state, action) => {
      return {
         ...state,
         createPayoutSrvAttributesFetching: false,
         createPayoutSrvAttributesErrors: action.payload,
      }
   },
   [types.SET_WALLET_PAYOUT_METHOD_REQUEST]: (state, action) => {
      return {
         ...state,
         setWalletPayoutMethodFetching: action.payload,
         setWalletPayoutMethodErrors: {},

      }
   },
   [types.SET_WALLET_PAYOUT_METHOD_COMPLETED]: (state, action) => {
      return {
         ...state,
         setWalletPayoutMethodFetching: false,
         setWalletPayoutMethodErrors: {},
         withdrawalNowPayload: {},
         twoFaVerifyModal: {
            isOpen: false,
            type: null,
            message: null,
            arg: [],
         },
      }
   },
   [types.SET_WALLET_PAYOUT_METHOD_FAILED]: (state, action) => {
      return {
         ...state,
         setWalletPayoutMethodFetching: false,
         setWalletPayoutMethodErrors: action.payload,
      }
   },

   [types.WITHDRAWAL_NOW_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingWithdrawalNow: action.payload,
      }
   },
   [types.WITHDRAWAL_NOW_COMPLETED]: (state, action) => {
      let data = { ...action.payload };
      if(data.expiration_in_seconds){
         data.expiration =  DateFormatter.addDatev2(new Date(), data.expiration_in_seconds)
      }
      return {
         ...state,
         isFetchingWithdrawalNow: false,
         withdrawalNowPayload: data,
      }
   },

   [types.COMMIT_WITHDRAWAL_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingCommitWithdrawal: action.payload,
      }
   },

   [types.COMMIT_WITHDRAWAL_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingCommitWithdrawal: false,
         withdrawalNowPayload: {},
      }
   },
   [types.DELETE_CURRENT_PAYOUT_METHOD_ACTION]: (state, action) => {
      const { data, isSuccess } = action.payload;
      let newState = {};
      if(isSuccess){
         newState = {
            twoFaVerifyModal: {
               isOpen: false,
               type: null,
               message: null,
               arg: [],
            },
         }
      }
      return {
         ...state,
         ...newState,
         isFetchingDeleteCurrentMethod: data,
      }
   },
   [types.UPDATE_2FA_MODAL_STATE_ACTION]: (state, action) => {
      return {
         ...state,
         twoFaVerifyModal: {
            ...state.twoFaVerifyModal,
            ...action.payload,
         },
      }
   },
   [types.UPDATE_NOW_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingUpdateNow: action.payload,
      }
   },
   [types.SET_PAYOUT_TERMS_MODAL]: (state, action) => {
      return {
         ...state,
         payoutTermsModal: action.payload,
      }
   },
   [types.OPEN_INCOMPLETE_LEGAL_INFO_MODAL]: (state, action) => {
      const { bool, data } = action.payload;
      let newState = {}
      return {
         ...state,
         ...newState,
         isOpenIncompleteLegalInfoModal: bool,
         payoutToDoRequiredInfoFilled: data || null,
      }
   },
};

export default createReducer(initialState)(reducersMap);
